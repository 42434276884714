import { NationOnboardingModalWelcomeStep } from './NationOnboardingModalSteps/NationOnboardingModalWelcomeStep'
import { translate } from '../../../../../intl/i18n'
import { NationOnboardingModalGemsEarnedStep } from './NationOnboardingModalSteps/NationOnboardingModalGemsEarnedStep/NationOnboardingModalGemsEarnedStep'
import { NationOnboardingModalGemsAndRxpStep } from './NationOnboardingModalSteps/NationOnboardingModalGemsAndRxpStep'
import { NationOnboardingModalSpendGemsStep } from './NationOnboardingModalSteps/NationOnboardingModalSpendGemsStep'
import { NationOnboardingModalCompleteChallengesStep } from './NationOnboardingModalSteps/NationOnboardingModalCompleteChallengesStep'

export const modalSteps = [
  { key: 'WELCOME', component: NationOnboardingModalWelcomeStep, ctaText: translate('nation.onboarding_welcome_cta') },
  {
    key: 'GEMS_EARNED',
    component: NationOnboardingModalGemsEarnedStep,
    ctaText: translate('nation.onboarding_gems_earned_cta'),
  },
  {
    key: 'GEMS_AND_RXP',
    component: NationOnboardingModalGemsAndRxpStep,
    ctaText: translate('nation.onboarding_gems_and_rxp_cta'),
  },
  {
    key: 'SPEND_GEMS',
    component: NationOnboardingModalSpendGemsStep,
    ctaText: translate('nation.onboarding_spend_gems_cta'),
  },
  {
    key: 'COMPLETE_CHALLENGES',
    component: NationOnboardingModalCompleteChallengesStep,
    ctaText: translate('nation.onboarding_complete_challenges_cta'),
  },
] as const
