import type React from 'react'
import { useCallback, useEffect, useMemo, useRef, type PropsWithChildren } from 'react'
import { heapEvents, heapStatuses } from '../../constants/heapEvents'
import { userflowEvents } from '../../constants/userflowEvents'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useAppSelector } from '../../hooks/useAppSelector'
import { selectAuthData, selectAuthRejected, selectAuthRequested, selectUserAddress } from '../../reducers/userSelector'
import { setAuthData, setAuthRejected, setAuthRequested } from '../../reducers/userSlice'
import { useGenEVMSignatureMutation } from '../../store/apis/auth.api'
import { LogFeature, makeLog } from '../../utils/makeLog'
import { trackHeapEvent } from '../apiService'
import { trackUserflowEvent } from '../apiService/trackUserflowEvent'
import { saveAuthData } from './saveAuthData'
import { isAuthDataValid } from './isAuthDataValid'
import { getAuthData } from './getAuthData'
import { authKey } from './authKey'
import { useWallet } from '../../hooks/useWallet'
import { AuthContext } from './createdAuthContext'

const log = makeLog(LogFeature.AUTH)

const getAuthMessageV3 = (date: Date) =>
  `To protect your rhino.fi privacy we ask you to sign in with your wallet to see your data.
Signing in on ${date.toUTCString()}. For your safety, only sign this message on rhino.fi!`

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const address = useAppSelector(selectUserAddress)
  const dispatch = useAppDispatch()
  const { ethersSigner } = useWallet()
  const authData = useAppSelector(selectAuthData)
  const authRequested = useAppSelector(selectAuthRequested)
  const authRejected = useAppSelector(selectAuthRejected)
  const signatureRequestSentRef = useRef(false)
  const [genEVMSignature] = useGenEVMSignatureMutation()

  const resetAuth = useCallback(() => {
    dispatch(setAuthData(null))
    dispatch(setAuthRejected(false))
    dispatch(setAuthRequested(false))
    signatureRequestSentRef.current = false
    if (authData?.ethAddress) {
      localStorage.removeItem(authKey(authData.ethAddress))
    }
  }, [authData, dispatch])

  const isNotReadyForAuth = !address || !ethersSigner || authRequested || authData

  const requestAuth = useCallback(
    async ({ triggerSignature = true }: { triggerSignature: boolean }) => {
      log('Requesting auth', { address, authRequested, authData, triggerSignature })
      if (isNotReadyForAuth || signatureRequestSentRef.current) {
        log('Aborting auth request', {
          address,
          authRequested,
          authData,
          requestSent: signatureRequestSentRef.current,
        })
        return null
      }

      const savedAuthData = getAuthData(address)
      if (savedAuthData && isAuthDataValid(savedAuthData)) {
        log('Using saved valid auth data', { savedAuthData })
        dispatch(setAuthData(savedAuthData))
        return savedAuthData
      }

      if (!triggerSignature) {
        return null
      }

      signatureRequestSentRef.current = true

      try {
        dispatch(setAuthRejected(false))
        dispatch(setAuthRequested(true))

        const createdAt = new Date()
        const message = getAuthMessageV3(createdAt)
        const signature = await ethersSigner.signMessage(message)

        const evmSignature = await genEVMSignature({
          path: { ethAddress: address },
          payload: { version: 3, signature, createdAt },
        }).unwrap()

        const newAuthData = {
          ...evmSignature,
          createdAt,
        }

        log('Successfully authenticated', { newAuthData })

        saveAuthData(newAuthData)
        dispatch(setAuthData(newAuthData))
        trackUserflowEvent(userflowEvents.authenticate)
        trackHeapEvent(heapEvents.authenticate, { status: heapStatuses.success })
        return newAuthData
      } catch (error) {
        log('Error during authentication', error)
        dispatch(setAuthData(null))
        dispatch(setAuthRejected(true))
        trackHeapEvent(heapEvents.authenticate, { status: heapStatuses.fail })
      } finally {
        dispatch(setAuthRequested(false))
        signatureRequestSentRef.current = false
      }

      return null
    },
    [address, authData, authRequested, dispatch, ethersSigner, genEVMSignature, isNotReadyForAuth],
  )

  useEffect(() => {
    if (authData) {
      saveAuthData(authData)
    }
  }, [authData])

  useEffect(() => {
    if (!isNotReadyForAuth) {
      void requestAuth({ triggerSignature: false })
    }
  }, [address, isNotReadyForAuth, requestAuth])

  const value = useMemo(
    () => ({ authData, requestAuth, authRequested, authRejected, resetAuth }),
    [authData, requestAuth, authRequested, authRejected, resetAuth],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
