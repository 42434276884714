import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { envConfig } from '../env/envConfig'
import { prepareHeaders } from './auth/addAuthorizationHeader'
import type { TurnstileData } from '../constants/types'

const endpointsWithAuth = ['updateUserMeta']

// Define a service using a base URL and expected endpoints
export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: envConfig.tradingApi,
    prepareHeaders: prepareHeaders(endpointsWithAuth),
  }),
  endpoints: (builder) => ({
    updateUserMeta: builder.mutation<
      void,
      {
        meta: {
          turnstile: TurnstileData
        }
      }
    >({
      query: (data) => ({
        url: '/v1/trading/w/userMeta',
        method: 'POST',
        body: data,
      }),
    }),
  }),
})

// Export hooks for usage in functional components
export const { useUpdateUserMetaMutation } = userApi
