import { useMemo } from 'react'
import { NATION_XP_LABEL } from '../constants/nationGeneral.constants'
import type { NationContentSectionTitleItemData } from '../types/nation.types'
import { translate } from '../../../intl/i18n'
import { useBannerGemsBalance } from './useBannerGemsBalance'
import { useUserRxpRank } from './useUserRxpRank'

export const useNationEarnSectionTitleItems = (): [
  NationContentSectionTitleItemData,
  NationContentSectionTitleItemData,
] => {
  const gemsRxpData = useBannerGemsBalance()
  const rankData = useUserRxpRank({ skipLeaderboardStats: true })

  const rxpItem = useMemo(
    () => ({
      id: 'rxp',
      loading: gemsRxpData.isLoading,
      error: !!gemsRxpData.error,
      value: gemsRxpData.rxp,
      valueGap: '2px',
      valueAlignItems: gemsRxpData.isLoading ? 'center' : 'baseline',
      postValueContent: NATION_XP_LABEL,
    }),
    [gemsRxpData],
  )

  const rankItem = useMemo(
    () => ({
      id: 'rank',
      loading: rankData.isLoading,
      error: !!rankData.error,
      value: rankData.userRank,
      postValueContent: translate('contracts_interactions.rank'),
      valueAlignItems: rankData.isLoading ? 'center' : 'baseline',
    }),
    [rankData],
  )

  return [rxpItem, rankItem]
}
