import { bigDecimalToNumericString } from '@rhinofi/effect-utils'
import { useMemo } from 'react'
import { selectBlockchainBalances } from '../../../actions/selectors/balanceSelectors'
import { useAppSelector } from '../../../hooks'
import { selectBridgeChainsConfig } from '../../../store/selectors/bridgeConfig.selectors'
import { SafeDecimal } from '../../../utils/SafeDecimal'
import { selectBridge } from '../slices/bridge.slice'
import { useBridgeGasEstimate } from './useBridgeGasEstimate'
import { usePublicQuote } from './usePublicQuote'

export const useMaxBalance = () => {
  const bridgeState = useAppSelector(selectBridge)
  const { chainInConfig } = useAppSelector(selectBridgeChainsConfig)
  const blockchainBalances = useAppSelector(selectBlockchainBalances)

  const { gasEstimate } = useBridgeGasEstimate()

  const tokenBalance = blockchainBalances?.[bridgeState.chainIn]?.[bridgeState.token]?.balance ?? '0'

  const { quote } = usePublicQuote({ amount: tokenBalance, mode: 'receive' })

  const maxBridgeableAmount = useMemo(() => {
    if (!chainInConfig) {
      return '0'
    }
    const isNativeToken = bridgeState.token === chainInConfig.nativeTokenName
    const fee = quote?.fees?.fee ? bigDecimalToNumericString(quote.fees.fee) : '0'
    const optionalGasEstimate = isNativeToken ? SafeDecimal(gasEstimate).plus(chainInConfig.nativeTokenSafeguard) : '0'
    const amount = SafeDecimal(tokenBalance).minus(fee).minus(optionalGasEstimate).toFixed()
    return parseFloat(amount) > 0 ? amount : '0'
  }, [bridgeState.token, chainInConfig, gasEstimate, tokenBalance, quote?.fees?.fee])

  return { tokenBalance, maxBridgeableAmount }
}
