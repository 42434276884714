import type { BridgeConfig } from '../context'

export const getChainConfig =
  (config: BridgeConfig) =>
  (
    props:
      | {
          chainId: string
        }
      | {
          chain: string
        },
  ) => {
    if ('chainId' in props) {
      //   change to array, find the chainId
      const chainConfig = Object.values(config).find(
        (chain) => chain.networkId.toLowerCase() === props.chainId.toLowerCase(),
      )
      if (!chainConfig) {
        throw new Error('chainId not found')
      }
      return chainConfig
    }

    if ('chain' in props) {
      const chainConfig = config[props.chain]
      if (!chainConfig) {
        throw new Error('chain not found')
      }
      return chainConfig
    }

    throw new Error('chain or chainId required')
  }
