import { trackUserflowEvent } from '../../../services/apiService/trackUserflowEvent'
import { trackHeapEvent } from '../../../services/apiService'
import { heapEvents } from '../../../constants/heapEvents'
import { userflowEvents } from '../../../constants/userflowEvents'

type BridgeEvent = {
  status: string
  token: string
  chainIn: string
  chainOut: string
  amount: string
  usdAmount: string
  fee: string
  feeUsd: string
}

export const trackBridgeEvent = ({ status, token, chainIn, chainOut, amount, usdAmount }: BridgeEvent) => {
  trackHeapEvent(heapEvents.bridge, {
    status,
    token,
    chainIn,
    chainOut,
    amount,
    usdAmount,
  })

  trackUserflowEvent(userflowEvents.bridge, {
    status,
    token,
    chainIn,
    chainOut,
    amount,
    usdAmount,
  })
}
