import type { ethers } from 'ethers'
import { SafeDecimal } from '../../utils/SafeDecimal'
import { makeLog, LogFeature } from '../../utils/makeLog'

const log = makeLog(LogFeature.L1_BALANCES_FETCH)

export const getNativeBalance = async ({
  address,
  provider,
  nativeToken,
  decimals,
  chain,
}: {
  address: string
  nativeToken: string
  provider?: ethers.JsonRpcProvider
  decimals: number | undefined
  chain: string
}) => {
  const defaultResponse = {
    [nativeToken]: {
      balance: '0',
      token: nativeToken,
    },
  }

  return (
    provider
      ?.getBalance(address)
      .then((weiBalance) => {
        const hrBalance = SafeDecimal(weiBalance || 0)
          .div(10 ** (decimals ?? 18))
          .toString()

        log('balance for', chain, hrBalance, nativeToken)
        return {
          [nativeToken]: {
            balance: hrBalance,
            token: nativeToken,
          },
        }
      })
      .catch((error) => {
        log('error fetching balance for', chain, error)

        return defaultResponse
      }) ?? Promise.resolve(defaultResponse)
  )
}
