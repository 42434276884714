import { getChainConfig } from './getChainConfig'
import type { BridgeConfig } from '../context'

export const safeGetChainName = (config: BridgeConfig | undefined) => (chain: string) => {
  try {
    if (config) {
      const chainConfig = getChainConfig(config)({ chain })
      return chainConfig.name
    }
  } catch {
    return '-'
  }

  return '-'
}
