import { validateYupSchema, yupToFormErrors } from 'formik'
import type { ISchema, InferType } from 'yup'

export const dynamicSchemaCreator = <TSchema extends ISchema<object>>(
  value: InferType<TSchema>,
  schema: TSchema,
  additionalContext?: object,
) => {
  try {
    void validateYupSchema(value, schema, true, {
      ...value,
      ...(additionalContext || {}),
    })
  } catch (err) {
    return yupToFormErrors(err)
  }
  return {}
}
