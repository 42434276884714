import { CoreCard } from '@rhinofi/dvf-shared-ui'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import { animated } from '@react-spring/web'
import styled from 'styled-components'

export const Center = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const FormWrapper = styled.div`
  width: 100%;
  position: relative;
`

export const OverflowCoreCard = styled(CoreCard)`
  overflow: hidden;
  width: 100%;

  max-width: 400px;
  min-width: 400px;

  @media ${respondBelow('xxs')} {
    margin: 0 auto;
    min-width: 100%;
    width: calc(100vw - 32px);
    max-width: 400px;
  }
`

export const BridgeLayout = styled(animated.div)`
  display: grid;
  grid-template-rows: min-content min-content min-content min-content min-content min-content min-content min-content;
  grid-template-areas:
    'header badge'
    'destination-details destination-details'
    'token-amount token-amount'
    'destination destination'
    'submit submit'
    'saved saved'
    'gas-boost gas-boost'
    'notice notice';

  @media ${respondBelow('xxs')} {
    width: 100%;
  }
`

export const TokenAmount = styled.div`
  grid-area: token-amount;
  margin-top: 32px;
`

export const TokenLabel = styled.div`
  grid-area: header;
  height: 38px;
  display: flex;
  align-items: center;
`

export const DestinationDetails = styled.div`
  grid-area: destination-details;

  display: flex;
  align-items: flex-end;

  margin-top: 10px;
`

export const LabelGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const Max = styled.div`
  grid-area: max;
  justify-self: end;
  align-self: end;
`

export const ReviewButtonSpace = styled.div`
  margin-top: 24px;
  grid-area: submit;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

export const FeeBreakdown = styled.div`
  padding: 16px;
  grid-area: saved;
  border-radius: 8px;
  margin-top: 16px;
  cursor: pointer;
  user-select: none;
  border: 1px solid ${({ theme }) => theme.divider};

  .breakdown-trigger {
    cursor: pointer !important;
  }

  &:hover {
    .see-details {
      text-decoration: underline;
      text-decoration-color: ${({ theme }) => theme.textLink};
    }
  }
`

export const GasBoostGrid = styled.div`
  margin-top: 24px;
  grid-area: gas-boost;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Destination = styled.div`
  margin-top: 24px;
  grid-area: destination;
`

export const SwitchNetworks = styled.div`
  margin: 0 4px 8px 4px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
