const linkStartMark = '%linkStart'
const linkEndMark = '%linkEnd'

export const parseI18nTextWithLink = (text: string) => {
  const linkStart = text.indexOf(linkStartMark)
  const linkEnd = text.indexOf(linkEndMark)

  if (linkStart < 0 || linkEnd < 0) {
    return { link: text, prefix: '', suffix: '' }
  }
  const prefix = text.substring(0, linkStart - 1)
  const link = text.substring(linkStart + linkStartMark.length + 1, linkEnd - 1)
  const suffix = text.substring(linkEnd + linkEndMark.length + 1)

  return {
    link,
    prefix,
    suffix,
  }
}
