import { Text, TextSize, TokenIcon, Button, ButtonSizes } from '@rhinofi/dvf-shared-ui'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { selectSecondaryWalletChain } from '../../actions/selectors/secondaryWalletSelectors'
import { selectBridgeConfig } from '../../store/apis/config.api'
import { isNonEVMChain } from '../../services/helperService/isNonEVMChain'
import { translate } from '../../intl/i18n'
import { FlexContainer } from '../common/Wrappers/Containers'
import { connectSecondaryWallet } from '../../reducers/secondaryWalletSlice'

export const MenuUnconnectedWallets = () => {
  const dispatch = useAppDispatch()
  const bridgeConfig = useAppSelector(selectBridgeConfig)
  const secondaryWalletChain = useAppSelector(selectSecondaryWalletChain)
  const secondaryWalletChains = Object.entries(bridgeConfig)
    .filter(([name]) => isNonEVMChain(name) && name !== secondaryWalletChain)
    .map(([name, chain]) => ({
      chain: name,
      chainName: chain.name,
    }))

  return (
    <>
      <Text size={TextSize.S} color="textSecondary" bold>
        {translate('onboarding.unnconnected_non_evm_wallets')}
      </Text>
      <FlexContainer $fullWidth $direction="column" $gap="8px">
        {secondaryWalletChains.map(({ chain, chainName }) => (
          <FlexContainer key={chain} $fullWidth $alignItems="center" $justifyContent="space-between">
            <FlexContainer $alignItems="center" $gap="8px">
              <TokenIcon token={chain} />
              <Text size={TextSize.S}>{chainName}</Text>
            </FlexContainer>
            <Button
              id="connect-non-evm-wallet"
              size={ButtonSizes.Small}
              onClick={() => dispatch(connectSecondaryWallet({ chain }))}
            >
              <ConnectButtonContent>{translate('global.connect')}</ConnectButtonContent>
            </Button>
          </FlexContainer>
        ))}
      </FlexContainer>
    </>
  )
}

const ConnectButtonContent = styled.span`
  padding: 0px 16px 0px 16px;
`
