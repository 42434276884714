import type { BridgeTokenEntry } from '@rhinofi/bridge-api-spec'

export type BridgeTokenEntryArray = BridgeTokenEntry[]

export const tokensToArray = (tokens: Record<string, BridgeTokenEntry>): BridgeTokenEntryArray => {
  if (!tokens) {
    return []
  }
  return Object.values(tokens)
}
