import Decimal from 'decimal.js'
import { NETWORKS } from '../../../../constants/types'
import type { MulticallBalance } from '../../../ethereum/multicall'
import type { BridgeTokenEntryArray } from '../../../helperService/tokensToArray'

export const getSolanaBalances = async (secondaryWalletAddress: string, tokens: BridgeTokenEntryArray) => {
  const { getSolanaConnection } = await import('../../providers/solanaProvider')
  const { PublicKey } = await import('@solana/web3.js')
  const { getAssociatedTokenAddressSync, getAccount } = await import('@solana/spl-token')
  const connection = getSolanaConnection()
  const balances = await Promise.allSettled(
    tokens.map(async ({ address, token, decimals }) => {
      const tokenAccount = getAssociatedTokenAddressSync(new PublicKey(address), new PublicKey(secondaryWalletAddress))
      const info = await getAccount(connection, tokenAccount)
      return { balance: new Decimal(info.amount.toString()).div(10 ** decimals).toString(), token }
    }),
  )
  const parsedBalances = balances.reduce<Record<string, MulticallBalance>>((accumulator, promiseResult) => {
    if (promiseResult.status !== 'fulfilled') {
      return accumulator
    }
    const { value } = promiseResult
    accumulator[value.token] = {
      balance: value.balance,
      token: value.token,
    }
    return accumulator
  }, {})

  return {
    [NETWORKS.SOLANA]: parsedBalances,
  }
}
