import { TextSize, TitleSize } from '@rhinofi/dvf-shared-ui'
import styled from 'styled-components'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'
import { NationContentSectionTitleItem } from '../NationContent/NationContentSectionTitle/NationContentSectionTitleItem'
import { useNationEarnSectionTitleItems } from '../../hooks/useNationEarnSectionTitleItems'
import { useNationSpendSectionTitleItems } from '../../hooks/useNationSpendSectionTitleItems'

export const NationWidgetStats = () => {
  const [balanceItem] = useNationSpendSectionTitleItems()
  const [rxpItem, rankItem] = useNationEarnSectionTitleItems()

  return (
    <FlexContainer $gap="8px" $direction="column" $alignItems="stretch">
      <NationContentSectionTitleItem
        data={{
          ...balanceItem,
          labelSize: { type: 'text', value: TextSize.M },
          valueSize: { type: 'title', value: TitleSize.XXS },
        }}
      />

      <FlexContainer $gap="8px">
        <NationContentSectionTitleItem
          data={{
            ...rxpItem,
            valueSize: { type: 'title', value: TitleSize.XXS },
          }}
        />
        <LineSeparator />
        <NationContentSectionTitleItem
          data={{
            ...rankItem,
            valueSize: { type: 'title', value: TitleSize.XXS },
          }}
        />
      </FlexContainer>
    </FlexContainer>
  )
}

export const LineSeparator = styled.div`
  height: 16px;
  width: 1px;
  background-color: ${({ theme }) => theme.secondary40};
`
