import type { MissionAPIDocument } from '@rhinofi/rhino-nation-api-spec'
import { translate } from '../../../intl/i18n'
import type { MissionModalStatItem } from '../types/missionModalStatItem.types'
import { getStatEndDateValue } from './getStatEndDateValue'
import { getStatStartDateValue } from './getStatStartDateValue'
import { hasDatePassed } from '../../../utils/hasDatePassed'

export const getMissionStatsItems = ({ mission }: { mission: MissionAPIDocument }): MissionModalStatItem[] => {
  const hasToken = 'token' in mission && mission.token !== null
  const hasChainFrom = 'chainFrom' in mission && mission.chainFrom !== null
  const hasChainTo = 'chainTo' in mission && mission.chainTo !== null
  const hasMinUsdAmount = 'minUsdAmount' in mission
  const hasMaxUsdAmount = 'maxUsdAmount' in mission && mission.maxUsdAmount !== null
  const hasMinBridgedUsdVolume = 'minBridgedUsdVolume' in mission && mission.minBridgedUsdVolume !== null
  const hasChain = 'chain' in mission && mission.chain !== null
  const hasMinTimesBridged = 'minTimesBridged' in mission && mission.minTimesBridged !== null
  const hasNftType = 'nftType' in mission && mission.nftType !== null

  return [
    {
      id: 'startDate',
      type: 'TEXT',
      label: '',
      value: mission.startDate ? getStatStartDateValue(mission.startDate) : '',
      show: !!mission.startDate,
    },
    {
      id: 'endDate',
      type: 'TEXT',
      label: mission.endDate && !hasDatePassed(mission.endDate) ? `${translate('nation.limited_time')}:` : '',
      value: mission.endDate ? getStatEndDateValue(mission.endDate) : '',
      show: !!mission.endDate,
    },
    {
      id: 'token',
      type: 'TOKEN',
      label: `${translate('helpers.token')}:`,
      value: hasToken ? mission.token : '',
      show: hasToken,
    },
    {
      id: 'chainFrom',
      type: 'CHAIN',
      label: `${translate('nation.from_chain')}:`,
      value: hasChainFrom ? mission.chainFrom : '',
      show: hasChainFrom,
    },
    {
      id: 'chainTo',
      type: 'CHAIN',
      label: `${translate('nation.to_chain')}:`,
      value: hasChainTo ? mission.chainTo : '',
      show: hasChainTo,
    },
    {
      id: 'minUsdAmount',
      type: 'TEXT',
      label: `${translate('nation.min_usd_in_bridge')}:`,
      value: hasMinUsdAmount ? `$${mission.minUsdAmount}` : '',
      show: hasMinUsdAmount && mission.minUsdAmount !== 0,
    },
    {
      id: 'maxUsdAmount',
      type: 'TEXT',
      label: `${translate('nation.max_usd_in_bridge')}:`,
      value: hasMaxUsdAmount ? `$${mission.maxUsdAmount}` : '',
      show: hasMaxUsdAmount && mission.maxUsdAmount !== 0,
    },
    {
      id: 'minBridgedUsdVolume',
      type: 'TEXT',
      label: `${translate('nation.min_usd_bridge_volume')}:`,
      value: hasMinBridgedUsdVolume ? `$${mission.minBridgedUsdVolume}` : '',
      show: hasMinBridgedUsdVolume && mission.minBridgedUsdVolume !== 0,
    },
    {
      id: 'chain',
      type: 'CHAIN',
      label: `${translate('yield.chain')}:`,
      value: hasChain ? mission.chain : '',
      show: hasChain,
    },
    {
      id: 'minTimesBridged',
      type: 'TEXT',
      label: `${translate('nation.number_of_bridges_required')}:`,
      value: hasMinTimesBridged ? mission.minTimesBridged : '',
      show: hasMinTimesBridged,
    },
    {
      id: 'nftType',
      type: 'NFT',
      label: `${translate('nation.specific_nft')}:`,
      value: hasNftType ? mission.nftType : null,
      chain: hasChain ? mission.chain : '',
      show: hasNftType,
    },
  ]
}
