import { NETWORKS } from '../../../constants/types'

const ACROSS_ROUTE_CHAINS = {
  [NETWORKS.MATIC_POS]: true,
  [NETWORKS.OPTIMISM]: true,
  [NETWORKS.ARBITRUM]: true,
  [NETWORKS.SCROLL]: true,
  [NETWORKS.BASE]: true,
}

export const isAcrossRoute = (chainIn: string, chainOut: string) => {
  return !!ACROSS_ROUTE_CHAINS[chainIn] && !!ACROSS_ROUTE_CHAINS[chainOut]
}
