import styled from 'styled-components'
import { FlexContainer } from '../../../../../components/common/Wrappers/Containers'

export const Container = styled(FlexContainer)`
  padding: 9px 12px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.strokeDivider};
  transition: all 0.3s ease-in-out;
  width: fit-content;
  cursor: pointer;

  &:hover {
    background: #0000000a;
  }
`
