import { useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

export const useSearchParams = () => {
  const navigate = useNavigate()
  const search = useLocation().search

  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const removeSearchParams = (params: string[]) => {
    params.forEach((param) => {
      searchParams.delete(param)
    })
    navigate(
      {
        search: searchParams.toString(),
      },
      {
        replace: true,
      },
    )
  }

  return { searchParams, removeSearchParams }
}
