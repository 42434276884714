import { BridgeWidget } from './components/BridgeWidget/BridgeWidget'
import { ResponsivePage, ResponsivePageContainerCentered } from '../../components/common/Wrappers/Page'
import { BridgeTabs } from './components/BridgeTabs/BridgeTabs'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import styled from 'styled-components'
// import { useCustomTheme } from '../../hooks/useCustomTheme'
import { BridgePoweredBy } from './components/BridgePoweredBy/BridgePoweredBy'
import { FlexContainer } from '../../components/common/Wrappers/Containers'
import { Helmet } from 'react-helmet'
import { translate } from '../../intl/i18n'

export const BridgePage = () => {
  // useCustomTheme()
  return (
    <ResponsivePage>
      <Helmet>
        <title>{translate('metadata.title_bridge')}</title>
        <meta name="description" content={translate('metadata.description_bridge')} />
      </Helmet>
      <ResponsivePageContainerCentered>
        <WidgetWrapper>
          <FlexContainer $direction="column">
            <BridgeWidget />
            <BridgePoweredBy />
          </FlexContainer>
          <BridgeTabs />
        </WidgetWrapper>
      </ResponsivePageContainerCentered>
    </ResponsivePage>
  )
}

export const WidgetWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: fit-content;
  gap: 16px;
  margin: 0 auto 32px;

  @media ${respondBelow('s')} {
    flex-direction: column;
    gap: 48px;
  }
`
