// import find from 'lodash-es/find'
// import get from 'lodash-es/get'
// import groupBy from 'lodash-es/groupBy'
// import isArray from 'lodash-es/isArray'
// import isString from 'lodash-es/isString'
// import _isFunction from 'lodash-es/isFunction'
// import _toUpper from 'lodash-es/toUpper'
// import Big from 'bignumber.js'

import { defaultThreshold } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'

// // obtains a symbol from a given pair with the corresponding prefix
// // ex. BTCUSD -> tBTCUSD
// // ex. USD -> fUSD
// export const addPrefix = (arg = '') => {
//   const symbol = arg ? arg.toString() : ''
//   const first = symbol.charAt(0)
//   // already okay
//   if (first === 't' || first === 'f') {
//     return symbol
//   }
//   const uppercaseSymbol = _toUpper(symbol)

//   switch (uppercaseSymbol.length) {
//     case 6:
//     case 7:
//     case 8:
//       return `t${uppercaseSymbol}`

//     case 3:
//     case 4:
//       return `f${uppercaseSymbol}`

//     default:
//       return uppercaseSymbol
//   }
// }

// export const getCode = (msg = [], channelInfo: { channel?: string } = {}) => {
//   if (!isArray(msg)) {
//     return '?'
//   }
//   const code = msg[1]
//   if (isString(code)) {
//     return code
//   }
//   const name = channelInfo.channel || '?'
//   const suffix = isArray(code) && isArray(code[0]) ? 'snapshot' : 'update'

//   return `${name}-${suffix}` // ex. book-update, ticket-snapshot, ?-update
// }

// export const formatSub = (channelName: 'unsubscribe' | 'tickers' | 'candles' | 'book') => {
//   const table = {
//     unsubscribe: (args: { chanId?: string } = {}) => {
//       const { chanId } = args

//       return {
//         event: 'unsubscribe',
//         chanId,
//       }
//     },
//     tickers: (args: { pair?: string } = {}) => {
//       const { pair } = args

//       return {
//         event: 'subscribe',
//         channel: 'ticker',
//         pair,
//       }
//     },
//     candles: (args: { pair?: string; resolution?: string } = {}) => {
//       const { pair, resolution } = args
//       const key = `trade:${resolution}:${addPrefix(pair)}`

//       return {
//         event: 'subscribe',
//         channel: 'candles',
//         key,
//       }
//     },
//     book: (args: { symbol?: string; prec?: string; freq?: string; len?: string } = {}) => {
//       const { symbol, prec, freq, len } = args

//       return {
//         event: 'subscribe',
//         channel: 'book',
//         symbol,
//         prec,
//         freq,
//         len,
//       }
//     },
//   }

//   const fx = table[channelName]
//   return _isFunction(fx) ? fx : <T>(event: T) => event
// }

// // accept an arbitrary number of arguments
// export const sum = (...numbers: number[]) => {
//   const total = numbers.reduce(
//     (accumulator, current = 0) => new Big(accumulator.toString()).plus(current.toString()),
//     new Big(0),
//   )

//   return Number(total)
// }

// /**
//  * returns a series of prices grouped by the current psnap levels
//  * useful for assigning alerts/orders to psnap price levels in books
//  *
//  * @param {Object} args
//  * @param {Array<Number>} args.psnap psnap book price levels
//  * @param {Array<Number|Object>} args.list prices to show in the list
//  * @param {String|Bool} args.prop name of the property to check in case it's not an array of numbers
//  * @param {Bool} args.isBid reverses the order of the sorting, if true
//  * @returns {Object} key: psnap price level, value: array of prices at that level
//  *
//  * ex.
//  * const psnap = [ 1, 2, 3, 4, 5, 10 ]
//  * const prices = [ 1.1, 1.2, 1.3, 4, 1000 ]
//  * groupPrices({ psnap, prices })
//  * returns {
//  *   "2": [ 1.1, 1.2, 1.3 ],
//  *   "4": [ 4 ],
//  *   "10": [ 1000 ]
//  * }
//  */
// type GroupPricesArgs<T> = {
//   psnap?: number[]
//   list?: T[]
//   prop?: string | false
//   isBid?: boolean
// }

// export const groupPrices = <T>(args: GroupPricesArgs<T> = {}) => {
//   const { psnap = [], list = [], prop = false, isBid = false } = args

//   const search = (el: T) =>
//     find(psnap, (curr, index) => {
//       const price = prop ? get(el, [prop]) : el
//       const extremum = isBid ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY

//       const prev = psnap[index - 1] || extremum

//       return isBid ? +curr <= +price && +price < +prev : +curr >= +price && +price > +prev
//     })

//   const prices = groupBy(list, search)

//   return prices
// }

// export const getLocalStorageKeys = () => {
//   const { localStorage } = window
//   const { length } = localStorage
//   const keys = new Array(length)
//   for (let i = 0; i < length; i += 1) {
//     keys[i] = localStorage.key(i)
//   }
//   return keys
// }

// export const removeLocalStorage = (key: string) => window.localStorage.removeItem(key)

// // some chart settings for example current trendline color are stored in the local storage
// // we want to clean them too when settings reset happens
// export const clearTradingViewLocalStorage = () => {
//   const tradingViewKeys: string[] = []
//   getLocalStorageKeys().forEach((key) => {
//     if (key.startsWith('tradingview') || key.startsWith('tv')) {
//       tradingViewKeys.push(key)
//     }
//   })
//   tradingViewKeys.forEach((key) => removeLocalStorage(key))
// }

// export const sleep = (ms: number) => {
//   return new Promise((resolve) => setTimeout(resolve, ms))
// }

export const isMobileDevice = () => window.innerWidth <= defaultThreshold.xxs

// export const validateMomentUnitFromString = (unit: string) => {
//   if (unit === 'days' || unit === 'months') {
//     return unit
//   }
//   throw new Error('Invalid unit')
// }
