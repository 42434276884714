import * as Yup from 'yup'
import { translate } from '../../intl/i18n'
import { emailSchema } from './validators/emailSchema'
import { phoneSchema } from './validators/phoneSchema'

export const EmailValidationSchema = Yup.object({
  credential: emailSchema,
})

export const PhoneValidationSchema = Yup.object({
  credential: phoneSchema,
})

export const VerificationCodeSchema = Yup.object({
  code_0: Yup.number().min(0).max(9),
  code_1: Yup.number().min(0).max(9),
  code_2: Yup.number().min(0).max(9),
  code_3: Yup.number().min(0).max(9),
  code_4: Yup.number().min(0).max(9),
  code_5: Yup.number().min(0).max(9),
})

const referralCodeLength = 10
export const ReferralCodeSchema = Yup.object({
  referralCode: Yup.string()
    .required(translate('rhino_alpha_key.referral_code_required'))
    .length(referralCodeLength, translate('rhino_alpha_key.referral_code_length', { '%n': referralCodeLength })),
})
