import { Text } from '@rhinofi/dvf-shared-ui'
import styled from 'styled-components'

/**
 * we need to show this everywhere a captcha is used
 * as the captcha logo is disabled main.scss
 * https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
 */

export const ReCaptchaPolicy = () => (
  <Policy className="policy">
    This site is protected by reCAPTCHA and the Google{' '}
    <a rel="noopener noreferrer" target="_blank" href="https://policies.google.com/privacy">
      Privacy Policy
    </a>{' '}
    and{' '}
    <a rel="noopener noreferrer" target="_blank" href="https://policies.google.com/terms">
      Terms of Service
    </a>{' '}
    apply.
  </Policy>
)

const Policy = styled(Text)`
  font-weight: 200;
  font-size: 10px;
  line-height: 16px;
  margin-top: 16px;
  text-align: center;
`
