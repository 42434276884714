import { getTronProvider } from '../../providers/tronProvider'
import { executeViewTronCall } from '../starknetService/executeViewTronCall'
import { parseTronResult } from './parseTronResult'
import type { AllowanceCallProps } from '../../../../pages/Bridge/services/callCheckAllowance'
import { SafeDecimal } from '../../../../utils/SafeDecimal'

export const getTronTokenAllowance = async ({
  tokenAddress,
  bridgeContractAddress,
  secondaryWalletAddress,
  decimals,
}: AllowanceCallProps) => {
  if (!secondaryWalletAddress) {
    return '0'
  }
  const { tronWeb } = getTronProvider()
  if (!tronWeb || tokenAddress === '' || bridgeContractAddress === '' || secondaryWalletAddress === '') {
    return '0'
  }
  const result = await executeViewTronCall(tokenAddress, 'allowance(address,address)', [
    { type: 'address', value: secondaryWalletAddress },
    { type: 'address', value: bridgeContractAddress },
  ])
  return SafeDecimal(parseTronResult(result))
    .div(10 ** decimals)
    .toString()
}
