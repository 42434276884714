import * as config from './config'

import * as devConfig from './config.dev'
import * as stgConfig from './config.stg'
import * as prodConfig from './config.prd'
import type { LegacyPortalConfig } from './config.type'

export const getEnvConfig = (environment?: 'local' | 'dev' | 'prod' | 'stg'): LegacyPortalConfig => {
  const override = environment ?? localStorage.getItem('ENV_OVERRIDE')
  if (!override) {
    return config
  }

  if (override === 'prod') {
    return prodConfig
  } else if (override === 'stg') {
    return stgConfig
  } else if (override === 'dev') {
    return devConfig
  } else {
    return config
  }
}

export const envConfig = getEnvConfig()
export type LegacyEnvConfig = typeof envConfig
