export type Bridge = {
  version: '0.1.0'
  name: 'bridge'
  constants: [
    {
      name: 'AUTHORITY_SEED'
      type: 'string'
      value: '"authority"'
    },
    {
      name: 'BRIDGE_SEED'
      type: 'string'
      value: '"rhino_bridge"'
    },
  ]
  instructions: [
    {
      name: 'createBridge'
      accounts: [
        {
          name: 'bridge'
          isMut: true
          isSigner: false
        },
        {
          name: 'admin'
          isMut: false
          isSigner: false
          docs: ['The admin of the Bridge']
        },
        {
          name: 'payer'
          isMut: true
          isSigner: true
          docs: ['The account paying for all rents']
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
          docs: ['Solana ecosystem accounts']
        },
      ]
      args: []
    },
    {
      name: 'deposit'
      accounts: [
        {
          name: 'bridge'
          isMut: false
          isSigner: false
        },
        {
          name: 'pool'
          isMut: false
          isSigner: false
        },
        {
          name: 'poolAuthority'
          isMut: false
          isSigner: false
        },
        {
          name: 'depositor'
          isMut: false
          isSigner: true
          docs: ['The account paying for all rents']
        },
        {
          name: 'mint'
          isMut: false
          isSigner: false
        },
        {
          name: 'poolAccount'
          isMut: true
          isSigner: false
        },
        {
          name: 'depositorAccount'
          isMut: true
          isSigner: false
        },
        {
          name: 'payer'
          isMut: true
          isSigner: true
          docs: ['The account paying for all rents']
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
          docs: ['Solana ecosystem accounts']
        },
        {
          name: 'associatedTokenProgram'
          isMut: false
          isSigner: false
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
        },
        {
          name: 'eventAuthority'
          isMut: false
          isSigner: false
        },
        {
          name: 'program'
          isMut: false
          isSigner: false
        },
      ]
      args: [
        {
          name: 'amount'
          type: 'u64'
        },
        {
          name: 'ethAddressUpper'
          type: 'u32'
        },
        {
          name: 'ethAddressLower'
          type: 'u128'
        },
      ]
    },
    {
      name: 'depositWithId'
      accounts: [
        {
          name: 'bridge'
          isMut: false
          isSigner: false
        },
        {
          name: 'pool'
          isMut: false
          isSigner: false
        },
        {
          name: 'poolAuthority'
          isMut: false
          isSigner: false
        },
        {
          name: 'depositor'
          isMut: false
          isSigner: true
          docs: ['The account paying for all rents']
        },
        {
          name: 'mint'
          isMut: false
          isSigner: false
        },
        {
          name: 'poolAccount'
          isMut: true
          isSigner: false
        },
        {
          name: 'depositorAccount'
          isMut: true
          isSigner: false
        },
        {
          name: 'payer'
          isMut: true
          isSigner: true
          docs: ['The account paying for all rents']
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
          docs: ['Solana ecosystem accounts']
        },
        {
          name: 'associatedTokenProgram'
          isMut: false
          isSigner: false
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
        },
        {
          name: 'eventAuthority'
          isMut: false
          isSigner: false
        },
        {
          name: 'program'
          isMut: false
          isSigner: false
        },
      ]
      args: [
        {
          name: 'amount'
          type: 'u64'
        },
        {
          name: 'commitmentId'
          type: 'u128'
        },
      ]
    },
    {
      name: 'createPool'
      accounts: [
        {
          name: 'bridge'
          isMut: false
          isSigner: false
        },
        {
          name: 'pool'
          isMut: true
          isSigner: false
        },
        {
          name: 'poolAuthority'
          isMut: false
          isSigner: false
        },
        {
          name: 'mint'
          isMut: false
          isSigner: false
        },
        {
          name: 'poolAccount'
          isMut: true
          isSigner: false
        },
        {
          name: 'admin'
          isMut: false
          isSigner: true
          docs: ['The admin of the Bridge']
        },
        {
          name: 'payer'
          isMut: true
          isSigner: true
          docs: ['The account paying for all rents']
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
          docs: ['Solana ecosystem accounts']
        },
        {
          name: 'associatedTokenProgram'
          isMut: false
          isSigner: false
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
        },
      ]
      args: []
    },
    {
      name: 'allowOperator'
      accounts: [
        {
          name: 'bridge'
          isMut: false
          isSigner: false
        },
        {
          name: 'operatorStorage'
          isMut: true
          isSigner: false
        },
        {
          name: 'operator'
          isMut: false
          isSigner: false
        },
        {
          name: 'admin'
          isMut: false
          isSigner: true
          docs: ['The admin of the Bridge']
        },
        {
          name: 'payer'
          isMut: true
          isSigner: true
          docs: ['The account paying for all rents']
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
          docs: ['Solana ecosystem accounts']
        },
      ]
      args: [
        {
          name: 'isAllowed'
          type: 'bool'
        },
      ]
    },
    {
      name: 'withdraw'
      accounts: [
        {
          name: 'bridge'
          isMut: false
          isSigner: false
        },
        {
          name: 'pool'
          isMut: false
          isSigner: false
        },
        {
          name: 'poolAuthority'
          isMut: false
          isSigner: false
        },
        {
          name: 'operatorStorage'
          isMut: false
          isSigner: false
        },
        {
          name: 'recipient'
          isMut: false
          isSigner: false
        },
        {
          name: 'operator'
          isMut: false
          isSigner: true
        },
        {
          name: 'mint'
          isMut: false
          isSigner: false
        },
        {
          name: 'poolAccount'
          isMut: true
          isSigner: false
        },
        {
          name: 'recipientAccount'
          isMut: true
          isSigner: false
        },
        {
          name: 'payer'
          isMut: true
          isSigner: true
          docs: ['The account paying for all rents']
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
          docs: ['Solana ecosystem accounts']
        },
        {
          name: 'associatedTokenProgram'
          isMut: false
          isSigner: false
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
        },
      ]
      args: [
        {
          name: 'amount'
          type: 'u64'
        },
      ]
    },
    {
      name: 'transferAdmin'
      accounts: [
        {
          name: 'bridge'
          isMut: true
          isSigner: false
        },
        {
          name: 'newAdmin'
          isMut: false
          isSigner: false
          docs: ['The admin of the Bridge']
        },
        {
          name: 'admin'
          isMut: false
          isSigner: true
          docs: ['The admin of the Bridge']
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
          docs: ['Solana ecosystem accounts']
        },
      ]
      args: []
    },
  ]
  accounts: [
    {
      name: 'bridge'
      type: {
        kind: 'struct'
        fields: [
          {
            name: 'admin'
            docs: ['Account that has admin authority over the Bridge']
            type: 'publicKey'
          },
        ]
      }
    },
    {
      name: 'pool'
      type: {
        kind: 'struct'
        fields: [
          {
            name: 'bridge'
            docs: ['Primary key of the Bridge']
            type: 'publicKey'
          },
          {
            name: 'mint'
            docs: ['Mint of token A']
            type: 'publicKey'
          },
        ]
      }
    },
    {
      name: 'operator'
      type: {
        kind: 'struct'
        fields: [
          {
            name: 'operator'
            docs: ['Public key of the operator']
            type: 'publicKey'
          },
          {
            name: 'isAllowed'
            docs: ['Whether the operator has access']
            type: 'bool'
          },
        ]
      }
    },
  ]
  events: [
    {
      name: 'BridgedDeposit'
      fields: [
        {
          name: 'from'
          type: 'publicKey'
          index: false
        },
        {
          name: 'to'
          type: 'publicKey'
          index: false
        },
        {
          name: 'token'
          type: 'publicKey'
          index: false
        },
        {
          name: 'amount'
          type: 'u64'
          index: false
        },
        {
          name: 'ethAddressUpper'
          type: 'u32'
          index: false
        },
        {
          name: 'ethAddressLower'
          type: 'u128'
          index: false
        },
      ]
    },
    {
      name: 'BridgedDepositWithId'
      fields: [
        {
          name: 'from'
          type: 'publicKey'
          index: false
        },
        {
          name: 'to'
          type: 'publicKey'
          index: false
        },
        {
          name: 'token'
          type: 'publicKey'
          index: false
        },
        {
          name: 'amount'
          type: 'u64'
          index: false
        },
        {
          name: 'commitmentId'
          type: 'u128'
          index: false
        },
      ]
    },
    {
      name: 'BridgeWithdraw'
      fields: [
        {
          name: 'from'
          type: 'publicKey'
          index: false
        },
        {
          name: 'to'
          type: 'publicKey'
          index: false
        },
        {
          name: 'amount'
          type: 'u64'
          index: false
        },
      ]
    },
  ]
  errors: [
    {
      code: 6000
      name: 'DepositNotAllowed'
      msg: 'DEPOSIT_NOT_ALLOWED'
    },
    {
      code: 6001
      name: 'Unauthorized'
      msg: 'UNAUTHORIZED'
    },
    {
      code: 6002
      name: 'NotEnoughLiquidity'
      msg: 'NOT_ENOUGH_LIQUIDITY'
    },
    {
      code: 6003
      name: 'NotEnoughBalance'
      msg: 'NOT_ENOUGH_BALANCE'
    },
    {
      code: 6004
      name: 'InvalidAmount'
      msg: 'INVALID_AMOUNT'
    },
  ]
}

export const IDL: Bridge = {
  version: '0.1.0',
  name: 'bridge',
  constants: [
    {
      name: 'AUTHORITY_SEED',
      type: 'string',
      value: '"authority"',
    },
    {
      name: 'BRIDGE_SEED',
      type: 'string',
      value: '"rhino_bridge"',
    },
  ],
  instructions: [
    {
      name: 'createBridge',
      accounts: [
        {
          name: 'bridge',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'admin',
          isMut: false,
          isSigner: false,
          docs: ['The admin of the Bridge'],
        },
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
          docs: ['The account paying for all rents'],
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
          docs: ['Solana ecosystem accounts'],
        },
      ],
      args: [],
    },
    {
      name: 'deposit',
      accounts: [
        {
          name: 'bridge',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'pool',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'poolAuthority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'depositor',
          isMut: false,
          isSigner: true,
          docs: ['The account paying for all rents'],
        },
        {
          name: 'mint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'poolAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'depositorAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
          docs: ['The account paying for all rents'],
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
          docs: ['Solana ecosystem accounts'],
        },
        {
          name: 'associatedTokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'eventAuthority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'program',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'amount',
          type: 'u64',
        },
        {
          name: 'ethAddressUpper',
          type: 'u32',
        },
        {
          name: 'ethAddressLower',
          type: 'u128',
        },
      ],
    },
    {
      name: 'depositWithId',
      accounts: [
        {
          name: 'bridge',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'pool',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'poolAuthority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'depositor',
          isMut: false,
          isSigner: true,
          docs: ['The account paying for all rents'],
        },
        {
          name: 'mint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'poolAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'depositorAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
          docs: ['The account paying for all rents'],
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
          docs: ['Solana ecosystem accounts'],
        },
        {
          name: 'associatedTokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'eventAuthority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'program',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'amount',
          type: 'u64',
        },
        {
          name: 'commitmentId',
          type: 'u128',
        },
      ],
    },
    {
      name: 'createPool',
      accounts: [
        {
          name: 'bridge',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'pool',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'poolAuthority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'mint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'poolAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'admin',
          isMut: false,
          isSigner: true,
          docs: ['The admin of the Bridge'],
        },
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
          docs: ['The account paying for all rents'],
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
          docs: ['Solana ecosystem accounts'],
        },
        {
          name: 'associatedTokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'allowOperator',
      accounts: [
        {
          name: 'bridge',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'operatorStorage',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'operator',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'admin',
          isMut: false,
          isSigner: true,
          docs: ['The admin of the Bridge'],
        },
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
          docs: ['The account paying for all rents'],
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
          docs: ['Solana ecosystem accounts'],
        },
      ],
      args: [
        {
          name: 'isAllowed',
          type: 'bool',
        },
      ],
    },
    {
      name: 'withdraw',
      accounts: [
        {
          name: 'bridge',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'pool',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'poolAuthority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'operatorStorage',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'recipient',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'operator',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'mint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'poolAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'recipientAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
          docs: ['The account paying for all rents'],
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
          docs: ['Solana ecosystem accounts'],
        },
        {
          name: 'associatedTokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'amount',
          type: 'u64',
        },
      ],
    },
    {
      name: 'transferAdmin',
      accounts: [
        {
          name: 'bridge',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'newAdmin',
          isMut: false,
          isSigner: false,
          docs: ['The admin of the Bridge'],
        },
        {
          name: 'admin',
          isMut: false,
          isSigner: true,
          docs: ['The admin of the Bridge'],
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
          docs: ['Solana ecosystem accounts'],
        },
      ],
      args: [],
    },
  ],
  accounts: [
    {
      name: 'bridge',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'admin',
            docs: ['Account that has admin authority over the Bridge'],
            type: 'publicKey',
          },
        ],
      },
    },
    {
      name: 'pool',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'bridge',
            docs: ['Primary key of the Bridge'],
            type: 'publicKey',
          },
          {
            name: 'mint',
            docs: ['Mint of token A'],
            type: 'publicKey',
          },
        ],
      },
    },
    {
      name: 'operator',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'operator',
            docs: ['Public key of the operator'],
            type: 'publicKey',
          },
          {
            name: 'isAllowed',
            docs: ['Whether the operator has access'],
            type: 'bool',
          },
        ],
      },
    },
  ],
  events: [
    {
      name: 'BridgedDeposit',
      fields: [
        {
          name: 'from',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'to',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'token',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'amount',
          type: 'u64',
          index: false,
        },
        {
          name: 'ethAddressUpper',
          type: 'u32',
          index: false,
        },
        {
          name: 'ethAddressLower',
          type: 'u128',
          index: false,
        },
      ],
    },
    {
      name: 'BridgedDepositWithId',
      fields: [
        {
          name: 'from',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'to',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'token',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'amount',
          type: 'u64',
          index: false,
        },
        {
          name: 'commitmentId',
          type: 'u128',
          index: false,
        },
      ],
    },
    {
      name: 'BridgeWithdraw',
      fields: [
        {
          name: 'from',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'to',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'amount',
          type: 'u64',
          index: false,
        },
      ],
    },
  ],
  errors: [
    {
      code: 6000,
      name: 'DepositNotAllowed',
      msg: 'DEPOSIT_NOT_ALLOWED',
    },
    {
      code: 6001,
      name: 'Unauthorized',
      msg: 'UNAUTHORIZED',
    },
    {
      code: 6002,
      name: 'NotEnoughLiquidity',
      msg: 'NOT_ENOUGH_LIQUIDITY',
    },
    {
      code: 6003,
      name: 'NotEnoughBalance',
      msg: 'NOT_ENOUGH_BALANCE',
    },
    {
      code: 6004,
      name: 'InvalidAmount',
      msg: 'INVALID_AMOUNT',
    },
  ],
}
