/* eslint-disable -- legacy */
import React from 'react'
import { Icon, IconSizes } from '@rhinofi/dvf-shared-ui'
import { FullWidthCell } from './Table.Utils'

type GroupProps = {
  size?: number
  group: string
  isOpen?: boolean
  toggle?: () => void
}

class Group extends React.PureComponent<GroupProps> {
  // @ts-expect-error -- legacy code
  render() {
    const {
      size = 1,
      group,
      isOpen = true,
      toggle = () => {
        console.log('toggle', group)
      },
    } = this.props

    return (
      <FullWidthCell size={size} align="left">
        <div onClick={toggle}>
          <Icon size={IconSizes.Large} id={`${isOpen ? 'minus' : 'plus'}-square-o`} /> {group}
        </div>
      </FullWidthCell>
    )
  }
}

export { Group }
