import styled from 'styled-components'
import { Text, Spacing } from '@rhinofi/dvf-shared-ui'
import { TextSize, TypographyAlign } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import type { CSSProperties, FC, PropsWithChildren } from 'react'

type InformationCardProps = {
  title?: string
  style?: CSSProperties
  className?: string
}
export const InformationCard: FC<PropsWithChildren<InformationCardProps>> = ({ children, title, ...props }) => (
  <div>
    <Card {...props}>
      {title && (
        <>
          <Text size={TextSize.L} bold align={TypographyAlign.Center}>
            {title}
          </Text>
          <Spacing />
        </>
      )}
      {children}
    </Card>
  </div>
)

const Card = styled.div`
  padding: 16px;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: visible;

  background: ${(props) => props.theme.elevationL1};
  border: 1px solid ${({ theme }) => theme.accentBlue};
`
