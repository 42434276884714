import { FlexContainer } from '../../../../../components/common/Wrappers/Containers'
import { NationWidgetMissionsFilters } from './NationWidgetMissionsFilters'
import { NationWidgetMissionsContent } from './NationWidgetMissionsContent'

export const NationWidgetMissions = () => (
  <FlexContainer $gap="4px" $direction="column" $alignItems="stretch">
    <NationWidgetMissionsFilters />
    <NationWidgetMissionsContent />
  </FlexContainer>
)
