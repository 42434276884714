import type { TokenInputProps } from '@rhinofi/dvf-shared-ui/lib/components/token-input/TokenInput'
import { translate } from '../../intl/i18n'

export const tokenInputDictionary: TokenInputProps['dictionary'] = {
  all: translate('helpers.all'),
  allNetworks: translate('helpers.all_networks'),
  cancel: translate('helpers.cancel'),
  select: translate('helpers.select'),
  emptyList: translate('helpers.no_tokens_found'),
  yours: translate('helpers.your_tokens'),
  others: translate('helpers.other_tokens'),
  placeholder: translate('helpers.token_search_placeholder'),
  listLabel: translate('global.select_token'),
  notAvailable: translate('bridge.token_not_available_for_chain'),
}
