import { Button, ButtonVariant, Text, Icon, TextSize } from '@rhinofi/dvf-shared-ui'
import { TypographyAlign } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import styled from 'styled-components'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import { translate } from '../../intl/i18n'
import { boostBorderAndBackground } from '../../styles/boostBorderAndBackground'
import { setShouldUseCookies } from '../../services/settingsService'
import { zIndices } from '../../constants/zIndex'

type PopupProps = {
  toggleCookiePopup: () => void
}

export const CookiesPopup = ({ toggleCookiePopup }: PopupProps) => {
  const handleAction = (option: boolean) => {
    setShouldUseCookies(option)
    toggleCookiePopup()
  }

  return (
    <CookiesPopupWrapper>
      <CookiesContent>
        <CloseIcon>
          <Icon id="x-lg" handleClick={toggleCookiePopup} active />
        </CloseIcon>
        <Text className="cookie-title" bold={true} align={TypographyAlign.Center}>
          {translate('cookie_consent.title')}
        </Text>
        <Text className="cookie-text" align={TypographyAlign.Center} size={TextSize.S}>
          {translate('cookie_consent.content')}
          <br />
          <a href="https://www.rhino.fi/cookies-policy" target="_blank" rel="noopener noreferrer">
            {translate('cookie_consent.policy')}
          </a>
        </Text>
        <Actions>
          <Button id="functional-only" variant={ButtonVariant.secondary} fullWidth onClick={() => handleAction(false)}>
            {translate('cookie_consent.button_functional')}
          </Button>
          <Button id="accept-all-cookies" fullWidth onClick={() => handleAction(true)}>
            {translate('cookie_consent.button_allow')}
          </Button>
        </Actions>
      </CookiesContent>
    </CookiesPopupWrapper>
  )
}

const CloseIcon = styled.div`
  position: absolute;
  right: 8px;
  top: 6px;
`

const CookiesPopupWrapper = styled.div`
  position: absolute;
  right: 180px;
  display: flex;
  justify-content: center;
  top: calc(100vh - 256px);
  z-index: ${zIndices.cookies};

  @media ${respondBelow('xxs')} {
    width: auto;
    padding: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    align-items: flex-end;
    backdrop-filter: blur(2px);
    ${boostBorderAndBackground}
  }
`

const CookiesContent = styled.div`
  position: fixed;
  padding: 16px;
  background-color: ${(props) => props.theme.cardBackground};
  box-shadow: ${({ theme }) => theme.shadow};
  border-radius: 16px;
  width: 300px;
  height: fit-content;

  .cookie-title {
    margin-bottom: 8px;
    font-weight: 600;
  }

  .cookie-text {
    margin-bottom: 24px;
    max-width: 400px;
    font-weight: 400;
  }

  @media ${respondBelow('xxs')} {
    position: relative;
    width: auto;
    max-width: 360px;

    .cookie-text {
      max-width: 100%;
      margin-bottom: 16px;
    }
  }
`

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 487px;
  margin: 0 auto;
  gap: 8px;

  p {
    margin: 0;
  }

  @media ${respondBelow('xxs')} {
    flex-direction: column;
    align-items: center;
  }
`
