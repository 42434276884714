import { useCallback } from 'react'
import { useNavigate, useSearchParams, type NavigateOptions, type To } from 'react-router-dom'
import { initialState, type BridgeState } from '../../pages/Bridge/slices/bridge.slice'
import { publicRoutes } from '../../router'
import { selectAvailableChains } from '../../store/apis/config.api'
import { LogFeature, makeLog } from '../../utils/makeLog'
import { syncStateFromUrl } from '../../utils/rtk/bridgeUrlSync'
import { useAppDispatch } from '../useAppDispatch'
import { useAppSelector } from '../useAppSelector'
import { useConfig } from '../useConfig'

const log = makeLog(LogFeature.BRIDGE_NAVIGATE)

const BRIDGE_QUERY_PARAMS: (keyof Omit<BridgeState, 'isOtherAddress'>)[] = [
  'chainIn',
  'chainOut',
  'token',
  'amount',
  'recipient',
] as const

/**
 * Custom hook to navigate between bridge pages while ensuring that the app's state
 * remains synchronized with the URL.
 *
 * @returns {function} A callback function to navigate to a specified route and sync state.
 */
export const useBridgeNavigate = (): ((to: To, options?: NavigateOptions) => void) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [currentSearchParams] = useSearchParams()
  const { config } = useConfig()

  const availableChains = useAppSelector(selectAvailableChains)

  const getAvailableChainsPerToken = useCallback(
    (token: string) =>
      availableChains.filter(({ config: chainConfig }) => chainConfig.tokens[token]).map(({ chain }) => chain),
    [availableChains],
  )

  // Get the fallback chain for the given parameter making sure it's not the same as the target chain
  const getFallbackChain = useCallback(
    (param: 'chainIn' | 'chainOut', targetParams: URLSearchParams, availableTokenChains: string[]) => {
      const oppositeParam = param === 'chainIn' ? 'chainOut' : 'chainIn'
      const fallbackChain = availableTokenChains.find(
        (chain) => chain !== initialState.chainIn && chain !== initialState.chainOut,
      )

      if (targetParams.get(oppositeParam) === initialState[param]) {
        return availableTokenChains.includes(initialState[oppositeParam]) ? initialState[oppositeParam] : fallbackChain
      }

      return availableTokenChains.includes(initialState[param]) ? initialState[param] : fallbackChain
    },
    [],
  )

  const bridgeNavigate = useCallback(
    (to: To, options?: NavigateOptions): void => {
      if (!config) {
        throw new Error('Config is not loaded yet')
      }

      // Getting the search params from the target URL
      const targetSearchParams = new URLSearchParams(typeof to === 'string' ? to.split('?')[1] : to?.search || '')

      const selectedToken = targetSearchParams.get('token') || currentSearchParams.get('token') || initialState.token
      const availableTokenChains = getAvailableChainsPerToken(selectedToken)

      // Check if target URL has missing any of the bridge query params and add them from the current URL, initialState or availableTokenChains
      BRIDGE_QUERY_PARAMS.forEach((param) => {
        if (!targetSearchParams.has(param)) {
          const fallbackValue =
            currentSearchParams.get(param) ??
            (() => {
              if (param === 'chainIn' || param === 'chainOut') {
                return getFallbackChain(param, targetSearchParams, availableTokenChains)
              }
              return String(initialState[param])
            })()

          if (fallbackValue) {
            targetSearchParams.set(param, fallbackValue)
          }
        }
      })

      log('[bridgeNavigate]', {
        to,
        targetSearchParams: targetSearchParams.toString(),
        selectedToken,
        availableTokenChains,
      })

      navigate(
        {
          pathname: publicRoutes.bridge,
          search: targetSearchParams.toString(),
        },
        options,
      )
      syncStateFromUrl(dispatch, config)
    },
    [config, currentSearchParams, getAvailableChainsPerToken, navigate, dispatch, getFallbackChain],
  )

  return bridgeNavigate
}
