import { useContext } from 'react'
import { ConfigContext } from '../context/config/createdConfigContext'

export const useConfig = () => {
  const context = useContext(ConfigContext)
  if (!context) {
    throw new Error('useConfig must be used within a ConfigContext')
  }

  return context
}
