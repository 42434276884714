import * as Yup from 'yup'
import { tokenSchema } from '../../../services/validation/validators/tokenSchema'
import { bridgeAmountValidator } from '../validators/bridgeAmountValidator'
import { recipientAddressValidator } from '../validators/recipientAddressValidator'

export const BridgeValidationSchema = Yup.object({
  amount: bridgeAmountValidator,
  token: tokenSchema,
  recipient: recipientAddressValidator,
})
