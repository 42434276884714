import styled from 'styled-components'
import moment from 'moment'
import type { MissionType } from '@rhinofi/rhino-nation-api-spec'
import { Title, TitleSize, Text, TextSize, Icon } from '@rhinofi/dvf-shared-ui'
import { FlexContainer } from '../../../../../components/common/Wrappers/Containers'
import { translate } from '../../../../../intl/i18n'
import { NationMissionProgressStats } from '../NationMissionProgressStats/NationMissionProgressStats'
import { NationMissionProgressBar } from '../NationMissionProgressBar/NationMissionProgressBar'
import { getMissionCompletedPercentage } from '../../../utils/getMissionCompletedPercentage'
import type { MissionWithUserData } from '../../../types/nation.types'
import { isMissionFullyCompleted } from '../../../utils/isMissionFullyCompleted'
import { NationMissionModalReward } from './NationMissionModalReward/NationMissionModalReward'
import { NationMissionModalStats } from './NationMissionModalStats/NationMissionModalStats'
import { NationMissionModalCTAButton } from './NationMissionModalCTAButton/NationMissionModalCTAButton'
import { NationMissionModalReferral } from './NationMissionModalReferral/NationMissionModalReferral'
import { NationMissionModalEmailForm } from './NationMissionModalEmailForm/NationMissionModalEmailForm'
import { NationMissionModalDailyCheckin } from './NationMissionModalDailyCheckin/NationMissionModalDailyCheckin'

type Props = {
  data: MissionWithUserData
  isHistory?: boolean | undefined
  completedOn?: Date | undefined
}

const UNIQUE_LOGIC_MISSION_TYPES: Extract<MissionType, 'ENTER_EMAIL' | 'REFERRAL' | 'DAILY_CHECKIN'>[] = [
  'ENTER_EMAIL',
  'REFERRAL',
  'DAILY_CHECKIN',
]

export const NationMissionModalContent = ({ data, isHistory = false, completedOn }: Props) => {
  const { mission } = data
  const hasUniqueLogic = !!UNIQUE_LOGIC_MISSION_TYPES.find((type) => type === mission.type)

  const isCompleted = isMissionFullyCompleted(data) || isHistory
  const showProgress = mission.minTimesToAchieve > 1 && !isCompleted

  const completedPercentage = getMissionCompletedPercentage(data)

  return (
    <FlexContainer $gap="16px" $direction="column" $alignItems="stretch">
      <TitleContainer>
        <Title size={TitleSize.XXS} color="secondary80">
          {mission.title}
        </Title>
      </TitleContainer>

      <NationMissionModalStats mission={mission} />

      {mission.description && <Text weight={600}>{mission.description}</Text>}

      {isCompleted && completedOn && (
        <Text size={TextSize.XS} color="secondary80">
          {translate('nation.completed_on', {
            '%time': `@${moment(completedOn).format('HH:mm')}`,
            '%date': moment(completedOn).format('DD/MM/YYYY'),
          })}
        </Text>
      )}

      {showProgress && (
        <FlexContainer $direction="column" $alignItems="stretch" $gap="4px">
          <NationMissionProgressBar percentage={completedPercentage} />
          <NationMissionProgressStats data={data} />
        </FlexContainer>
      )}

      {hasUniqueLogic && !isCompleted && (
        <>
          {mission.type === 'REFERRAL' && <NationMissionModalReferral mission={mission} />}
          {mission.type === 'ENTER_EMAIL' && <NationMissionModalEmailForm gems={mission.gemsAwarded} />}
          {mission.type === 'DAILY_CHECKIN' && <NationMissionModalDailyCheckin gems={mission.gemsAwarded} />}
        </>
      )}

      {(!hasUniqueLogic || (hasUniqueLogic && isCompleted)) && <NationMissionModalReward gems={mission.gemsAwarded} />}

      {!hasUniqueLogic && !isCompleted && <NationMissionModalCTAButton mission={mission} />}
      {isCompleted && (
        <CompletedContainer $justifyContent="center" $gap="8px">
          <Text color="accentGreen">{translate('achievements.completed')}</Text>
          <Icon id="check-circle" color="accentGreen" />
        </CompletedContainer>
      )}
    </FlexContainer>
  )
}

const CompletedContainer = styled(FlexContainer)`
  padding: 8px;
  border-radius: 8px;
  background: ${({ theme }) => theme.accentGreenBg};
`

const TitleContainer = styled.div`
  padding-right: 30px;
`
