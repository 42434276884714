import styled, { keyframes } from 'styled-components'

export const PerpetualLoader = () => {
  return (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  )
}

const LoaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.elevationL2};
  border-radius: 8px;
  padding: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 24px;
`

const loaderAnimation = keyframes`
  0% {
    left: -160px;
  }
  100% {
    left: 100%;
  }
`

const Loader = styled.div`
  position: absolute;
  left: -100%;
  height: 24px;
  width: 160px;
  background: ${({ theme }) => theme.brand};
  border-radius: 8px;
  animation: ${loaderAnimation} infinite;
  animation-delay: 1s;
  animation-timing-function: cubic-bezier(0.5, 1.05, 0.5, -0.02);
  animation-duration: 1800ms;
`
