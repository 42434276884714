import { envConfig } from '../../env/envConfig'
import { Schema } from 'effect'
const { starknetApi } = envConfig

/**
 * Starknet ID API doc
 * https://docs.starknet.id/devs/starknetid-api
 */

export type StarknetDomainLookup = (name: string) => Promise<string>
export type StarknetReverseLookup = (domain: string) => Promise<string>

const StarknetIdToAddrResolveSchema = Schema.Struct({
  addr: Schema.String,
  // eslint-disable-next-line @typescript-eslint/naming-convention -- library requirement
  domain_expiry: Schema.String,
})

const StarknetAddrToIdResolveSchema = Schema.Struct({
  domain: Schema.String,
  // eslint-disable-next-line @typescript-eslint/naming-convention -- library requirement
  domain_expiry: Schema.String,
})

export const starknetIdReverseLookup: StarknetReverseLookup = async (domain: string) => {
  try {
    const response = await fetch(`${starknetApi}/domain_to_addr?domain=${domain}`)

    if (response.status !== 200) {
      return ''
    }

    const json = await response.json()
    const data = Schema.decodeUnknownSync(StarknetIdToAddrResolveSchema)(json)

    return data.addr
  } catch (error) {
    console.error(error)
    return ''
  }
}

export const starknetLookup: StarknetDomainLookup = async (starknetAddress: string): Promise<string> => {
  try {
    const response = await fetch(`${starknetApi}/addr_to_domain?addr=${starknetAddress}`)

    const json = await response.json()
    const data = Schema.decodeUnknownSync(StarknetAddrToIdResolveSchema)(json)

    return data.domain
    // eslint-disable-next-line no-unused-vars -- needed for catch
  } catch (error) {
    return ''
  }
}

export const validateStarknetName = (name: string): boolean => {
  const regex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.stark/
  return regex.test(name)
}
