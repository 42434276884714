import type { NationWidgetFiltersRouteParamCheckOption } from '../types/nationWidgetFiltersPageTracking.types'

export const getNationWidgetFiltersParamFromUrl = ({
  searchParams,
  routeParamCheckOption,
  pathname,
}: {
  routeParamCheckOption: NationWidgetFiltersRouteParamCheckOption
  searchParams: URLSearchParams
  pathname: string
}): string | null | undefined => {
  if (routeParamCheckOption.type === 'searchParams') {
    return searchParams.get(routeParamCheckOption.key) || null
  }

  if (routeParamCheckOption.type === 'urlPart') {
    const urlPathParts = pathname.split('/')
    return urlPathParts[routeParamCheckOption.splitPathPosition]
  }

  return null
}
