import { useMemo } from 'react'
import type { MissionsWithUserData } from '../types/nation.types'
import { useGemsTransactions } from './useGemsTransactions'

export const useEarnedGemsTransactions = () => {
  const { isLoading, error, data } = useGemsTransactions({
    input: { type: 'EARNED', latest: false },
  })

  const dataFormatted: MissionsWithUserData | null = useMemo(() => {
    if (!data) {
      return null
    }

    const onlyEarned = data.items.filter((item) => item._tag === 'EARNED' && 'mission' in item)
    const missionsWithUserData = onlyEarned.map(({ mission }) => ({
      mission,
      userData: null,
    }))

    return {
      items: missionsWithUserData,
    }
  }, [data])

  return {
    isLoading,
    error,
    data,
    dataFormatted,
  }
}
