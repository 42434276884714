import styled, { css } from 'styled-components'
import type { NationPerkClaimedBoxSize } from '../../../types/nationPerkClaimedBox.types'
import { FlexContainer } from '../../../../../components/common/Wrappers/Containers'

const handleSize = (size: NationPerkClaimedBoxSize) => {
  switch (size) {
    case 'big':
      return css`
        gap: 8px;
        border-radius: 8px;
      `
    case 'small':
      return css`
        gap: 4px;
        border-radius: 4px;
      `
    default:
      throw new Error('Expected perk claimed box size')
  }
}

export const Container = styled(FlexContainer)<{
  $widthType: 'auto' | 'full' | 'fit'
  $size: NationPerkClaimedBoxSize
}>`
  padding: 8px;
  background: ${({ theme }) => theme.accentGreen};
  ${({ $size }) => handleSize($size)};
  ${({ $widthType }) => {
    switch ($widthType) {
      case 'fit':
        return css`
          width: fit-content;
        `
      case 'full':
        return css`
          width: 100%;
        `
      case 'auto':
        return css`
          width: auto;
        `
    }
  }}
`
