import { Button, Icon, IconSizes, Spacing, Text, TokenIcon } from '@rhinofi/dvf-shared-ui'
import { TypographyAlign } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { translate } from '../../../intl/i18n'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { selectRequiredChain } from '../../../actions/selectors/portalSelectors'
import { selectSecondaryWalletNetworkId } from '../../../actions/selectors/secondaryWalletSelectors'
import { selectConnectedNetwork } from '../../../reducers/userSelector'
import { Modal } from '../../common/Modal/Modal'
import { switchStarknetWalletToNetworkId } from '../../../services/secondaryWallet/services/starknetService/switchStarknetWalletToNetworkId'
import { NETWORKS, NON_EVM_CHAINS } from '../../../constants/types'
import { selectBridgeConfig } from '../../../store/apis/config.api'
import { FlexContainer } from '../../common/Wrappers/Containers'
import { findBridgeKeyForNetworkId } from '../../../services/helperService/findBridgeKeyForNetworkId'
import { useWallet } from '../../../hooks/useWallet'

export const RequireChainModal = () => {
  const requiredChain = useAppSelector(selectRequiredChain)
  const connectedNetwork = useAppSelector(selectConnectedNetwork)
  const secondaryConnectedNetwork = useAppSelector(selectSecondaryWalletNetworkId)
  const bridgeConfig = useAppSelector(selectBridgeConfig)

  const [mustSwitchNetwork, setMustSwitchNetwork] = useState(false)

  const wallet = useWallet()

  const { chainName = '', resolve } = requiredChain || {}
  const requiredNetworkId = useMemo(
    () => (chainName && bridgeConfig ? bridgeConfig?.[chainName]?.networkId || '' : ''),
    [chainName, bridgeConfig],
  )
  const isSecondaryWalletChain = useMemo(() => !!NON_EVM_CHAINS[chainName || ''], [chainName])
  useEffect(() => {
    if (!isSecondaryWalletChain) {
      if (!requiredChain || !chainName || +requiredNetworkId === connectedNetwork) {
        setMustSwitchNetwork(false)
        return resolve?.({ done: true })
      }
    } else {
      if (requiredChain?.chainName === NETWORKS.PARADEX) {
        return resolve?.({ done: true })
      }
      if (requiredNetworkId.toString() === secondaryConnectedNetwork) {
        setMustSwitchNetwork(false)
        return resolve?.({ done: true })
      }
    }
    setMustSwitchNetwork(true)
  }, [
    requiredChain,
    resolve,
    connectedNetwork,
    requiredNetworkId,
    chainName,
    isSecondaryWalletChain,
    secondaryConnectedNetwork,
  ])

  const onClickClose = useCallback(() => {
    if (requiredChain) {
      requiredChain.resolve({ done: false, reason: 'Closed network change modal' })
    }
    setMustSwitchNetwork(false)
  }, [requiredChain])

  const networkName = useMemo(() => {
    if (!requiredChain) {
      return null
    }
    return bridgeConfig?.[requiredChain.chainName]?.name || ''
  }, [requiredChain, bridgeConfig])

  const switchToNetwork = useCallback(() => {
    if (!isSecondaryWalletChain) {
      const chain = requiredChain?.chainName
      if (chain && bridgeConfig?.[chain]) {
        void wallet?.connectionKit?.switchNetwork(requiredChain?.chainName, bridgeConfig[chain])
      }
    } else {
      if (requiredChain?.chainName === NETWORKS.STARKNET) {
        void switchStarknetWalletToNetworkId(requiredNetworkId.toString())
      }
    }
  }, [isSecondaryWalletChain, requiredNetworkId, requiredChain?.chainName, wallet, bridgeConfig])

  const connectedNetworkId = isSecondaryWalletChain ? secondaryConnectedNetwork : connectedNetwork
  const connectedNetworkName = connectedNetworkId
    ? findBridgeKeyForNetworkId(connectedNetworkId.toString(), bridgeConfig) || ''
    : ''

  return (
    <Modal
      isVisible={mustSwitchNetwork}
      onClose={onClickClose}
      width="410px"
      closeOnOverlayClick={false}
      title={translate('global.require_chain_modal_title')}
    >
      <Spacing size="16" />
      <FlexContainer $justifyContent="center" $fullWidth $gap="16px">
        <TokenIcon token={connectedNetworkName} size="large" />
        <Icon id="arrow-right" size={IconSizes.Big} />
        <TokenIcon token={chainName} size="large" />
      </FlexContainer>
      <Spacing size="24" />
      <Text align={TypographyAlign.Center}>
        {translate('global.require_chain_modal_text', {
          '%networkName': networkName ?? '',
        })}
      </Text>
      <Spacing size="24" />
      <Button id="change-network" fullWidth onClick={switchToNetwork}>
        {translate('global.require_chain_modal_cta')}
      </Button>
    </Modal>
  )
}
