import { Text, Title, TitleSize, TypographyAlign } from '@rhinofi/dvf-shared-ui'
import styled from 'styled-components'
import { FlexContainer } from '../../../../../../components/common/Wrappers/Containers'
import { translate } from '../../../../../../intl/i18n'
import onboardingBonus from '../../../../assets/onboarding_bonus.png'

const DUPLICATE_COUNT = 2

export const NationOnboardingModalSpendGemsStep = () => {
  return (
    <FlexContainer $direction="column" $padding="0 0 24px" $fullWidth $flexGrow="1">
      <Title size={TitleSize.XS}>{translate('nation.spend_gems_title')}</Title>
      <Text align={TypographyAlign.Center}>{translate('nation.spend_gems_desc')}</Text>
      <BonusContainer>
        {Array(DUPLICATE_COUNT)
          .fill(0)
          .map((_, index) => (
            <BonusImg key={index} src={onboardingBonus} alt="bonus-img" />
          ))}
      </BonusContainer>
    </FlexContainer>
  )
}

const BonusContainer = styled.div`
  padding-block: 56px;
  display: flex;
  gap: 16px;
  width: 100%;
  overflow: hidden;
`

const BonusImg = styled.img`
  display: flex;
  width: max-content;
  animation: loop 20s linear infinite;
  @keyframes loop {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`
