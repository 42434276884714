import { NETWORKS } from '../../../constants/types'
import { translate } from '../../../intl/i18n'
import { getDefaultBridgeRoute } from '../helpers/getDefaultBridgeRoute'

const defaultRoute = getDefaultBridgeRoute()

export const DEFAULT_CHAIN_IN = defaultRoute?.sourceChain || 'SCROLL'
export const DEFAULT_CHAIN_OUT = defaultRoute?.destinationChain || 'ZIRCUIT'
export const DEFAULT_TOKEN = defaultRoute?.token || 'USDT'

export const DEPOSITS_POLLING_INTERVAL = 10000

export const BRIDGE_HIGHLIGHTED_CATEGORY = { label: translate('bridge.highlighted_chains'), value: 'popular' }

export const BRIDGE_CATEGORY_SORT = { [BRIDGE_HIGHLIGHTED_CATEGORY.value]: 1 }

export const BRIDGE_RECEIVE_TOKEN: Record<string, Record<string, string>> = {
  [NETWORKS.CRONOS_ZKEVM]: {
    ETH: 'vETH',
  },
  [NETWORKS.APECHAIN]: {
    ETH: 'ApeETH',
  },
}
