import { Text, TextSize, Icon, IconSizes } from '@rhinofi/dvf-shared-ui'
import { FlexContainer } from '../../../../../components/common/Wrappers/Containers'
import { translate } from '../../../../../intl/i18n'
import type { NationPerkClaimedBoxSize } from '../../../types/nationPerkClaimedBox.types'
import { Container } from './NationPerkClaimedBox.styled'

type Props = {
  additionalText?: string | undefined
  size?: NationPerkClaimedBoxSize | undefined
  widthType?: 'auto' | 'full' | 'fit' | undefined
}

export const NationPerkClaimedBox = ({ additionalText = '', size = 'big', widthType = 'full' }: Props) => {
  const isBigSize = size === 'big'

  return (
    <Container $justifyContent="center" $size={size} $widthType={widthType}>
      <Icon id="check" color="accentGreenBg" size={isBigSize ? IconSizes.Large : IconSizes.Small} />

      <FlexContainer $gap="4px">
        <Text size={isBigSize ? TextSize.M : TextSize.XS} color="accentGreenBg">
          {translate('referrals.claimed')}
        </Text>

        {additionalText && (
          <Text size={isBigSize ? TextSize.M : TextSize.XS} color="accentGreenBg">
            {additionalText}
          </Text>
        )}
      </FlexContainer>
    </Container>
  )
}
