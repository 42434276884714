import type { ChangeEventHandler, FC, PropsWithChildren } from 'react'
import { useField } from 'formik'
import type { ToggleProps } from './Toggle'
import { Toggle } from './Toggle'
import type { TextSize } from '@rhinofi/dvf-shared-ui'

type FieldToggleProps = Omit<ToggleProps, 'inputProps'> & {
  name: string
  disabled?: boolean
  isChecked?: boolean
  size?: TextSize
  handleChange?: (isChecked: boolean) => void
}

export const FieldToggle: FC<PropsWithChildren<FieldToggleProps>> = ({
  handleChange = () => {},
  isChecked = undefined,
  children,
  size,
  ...props
}) => {
  const [field, , helpers] = useField({
    ...props,
    type: 'checkbox',
  })

  const onOptionChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    void helpers.setValue(event.target.checked)
    handleChange(event.target.checked)
  }

  return (
    <Toggle
      inputProps={field}
      isDisabled={!!props.disabled}
      onChange={onOptionChange}
      isChecked={!!isChecked}
      size={size}
    >
      {children}
    </Toggle>
  )
}
