import { getConfigKey } from '../../../services/helperService'
import { isFeatureEnabled } from '../../../services/helperService/isFeatureEnabled'
import { F_FLAGS_TYPES } from '../../../constants/types'

export const getEnabledNFTMintersChains = () =>
  getConfigKey('enabledNFTMinters').filter((name) => {
    if (name === 'ZIRCUIT' && !isFeatureEnabled(F_FLAGS_TYPES.ZIRCUIT_DOPE)) {
      return false
    }
    if (name === 'INK' && !isFeatureEnabled(F_FLAGS_TYPES.INK_NFT)) {
      return false
    }
    return true
  })
