import { useState } from 'react'
import styled from 'styled-components'
import { Icon, IconSizes, Button, ButtonSizes, ButtonVariant, Tooltip } from '@rhinofi/dvf-shared-ui'
import { SocialItem } from './SocialItem'
import { translate } from '../../intl/i18n'
import { useAppSelector } from '../../hooks'
import { selectWallet } from '../../reducers/userSelector'

enum ChatSupportSizes {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export const ChatSupport = ({ $static = false }) => {
  const [showChat, setShowChat] = useState(false)
  const wallet = useAppSelector(selectWallet)
  const [chatSize, setChatSize] = useState(ChatSupportSizes.Medium)

  const crispUrl = `https://go.crisp.chat/chat/embed/?website_id=598d8062-c46b-40e7-a7cd-0bf2061390a5&user_nickname=${
    wallet?.address || 'guest'
  }-${wallet?.walletType || 'not-connected'}`

  if ($static) {
    return (
      <StaticWrapper>
        <SocialItem className="static-chat-support" id="crisp-social">
          <a href={crispUrl} target="_blank" rel="noopener noreferrer" id="crisp-social-link">
            <Icon id="chat" className="socialIcon" size={IconSizes.Large} />
          </a>
        </SocialItem>
      </StaticWrapper>
    )
  }

  return (
    <SocialItem
      onClick={() => setShowChat(!showChat)}
      tooltip={showChat ? undefined : translate('social.get_support')}
      id="crisp-social"
    >
      <Icon id="chat" className="socialIcon" size={IconSizes.Large} />
      <ChatWidget className={showChat ? 'show' : ''} $chatSize={chatSize}>
        <ButtonTopLeft>
          <Tooltip
            tooltipContent={
              chatSize !== ChatSupportSizes.Large ? translate('global.enlarge') : translate('global.shrink')
            }
          >
            <Button
              variant={ButtonVariant.text}
              size={ButtonSizes.Small}
              id="chat-size-change"
              icon={chatSize !== ChatSupportSizes.Large ? 'arrows-angle-expand' : 'arrows-angle-contract'}
              onClick={(event) => {
                setChatSize(
                  chatSize === ChatSupportSizes.Small
                    ? ChatSupportSizes.Medium
                    : chatSize === ChatSupportSizes.Medium
                      ? ChatSupportSizes.Large
                      : ChatSupportSizes.Small,
                )
                event.preventDefault()
                event.stopPropagation()
              }}
            />
          </Tooltip>
        </ButtonTopLeft>
        {showChat ? <iframe className="support-chat" title="Chat" src={crispUrl} style={{ border: 0 }} /> : null}
      </ChatWidget>
    </SocialItem>
  )
}

const ButtonTopLeft = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;

  .coloredIcon {
    color: ${({ theme }) => theme.offWhite};
  }
`

const makeChatSizeStyles = ({ $chatSize }: { $chatSize: ChatSupportSizes }) => {
  const base = `
    max-width: 90vw;
    max-height: 90vh;
    overflow: hidden;
  `
  switch ($chatSize) {
    case ChatSupportSizes.Small:
      return `
        ${base}
        height: 400px;
        width: 320px;
      `
    case ChatSupportSizes.Medium:
      return `
        ${base}
        height: 600px;
        width: 480px;
      `
    case ChatSupportSizes.Large:
      return `
        ${base}
        height: 800px;
        width: 640px;
      `
  }
}

const ChatWidget = styled.div<{
  $chatSize: ChatSupportSizes
}>`
  position: absolute;
  display: none;
  bottom: 76px;
  right: 0;
  ${makeChatSizeStyles}

  transition: opacity 0.3s ease-in-out;
  box-shadow: 0 8px 24px rgba(25, 31, 48, 0.4);
  border-radius: 4px;
  opacity: 0;

  .support-chat {
    ${makeChatSizeStyles}
  }

  &.show {
    display: block;
    opacity: 1;
  }
`

const StaticWrapper = styled.div`
  display: inline-block;

  .static-chat-support {
    position: static;
    display: inline-block;
    height: 23px;
    width: 23px;
    margin-left: 4px;
    text-align: center;
    padding-bottom: 2px;

    .socialIcon {
      display: inline-flex;
    }
  }
`
