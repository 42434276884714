import { Title, Button } from '@rhinofi/dvf-shared-ui'
import { TitleSize } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import styled from 'styled-components'
import { useMemo } from 'react'
import { useAppDispatch } from '../../../hooks'
import { clearModal } from '../../../actions/modalActions/clearModal'
import { Modal } from '../../../components/common/Modal/Modal'
import { translate } from '../../../intl/i18n'
import type { NFTType } from '../types/mintNFT.types'
import { FlexContainer } from '../../../components/common/Wrappers/Containers'
import { NETWORKS } from '../../../constants/types'
import { NFTContractTypeSchema } from '../types/mintNFT.schemas'
import { useUserMintedNFTs } from '../hooks/useUserMintedNFTs'
import type { NFTMinter } from '../types/nfts.types'

type Props = {
  chain: string
  type: NFTType
}

const getTitleNumber = ({ tokenId, contractType, chain }: NFTMinter) => {
  if (chain === NETWORKS.INK && contractType === NFTContractTypeSchema.enums.RhinoERC1155) {
    return ''
  }
  if (tokenId <= 0) {
    return ''
  }

  return `#${tokenId}`
}

export const ViewNFTModal = ({ chain, type }: Props) => {
  const dispatch = useAppDispatch()
  const { nftsPerChain } = useUserMintedNFTs()

  const nftsToShow = useMemo(() => {
    const nfts = nftsPerChain[chain]

    if (!nfts) {
      return []
    }

    return nfts.filter((nft) => nft.type === type).sort((nftA, nftB) => (nftA.tokenId < nftB.tokenId ? 1 : -1))
  }, [nftsPerChain, chain, type])
  const closeModal = () => clearModal(dispatch)()

  return (
    <Modal isVisible width="360px" onClose={closeModal}>
      <FlexContainer $direction="column" $alignItems="flex-start" $fullWidth $gap="16px">
        <Title size={TitleSize.XS}>
          {nftsToShow.length === 1 ? translate('tracker.your_nft') : translate('tracker.your_nfts')}
        </Title>
        <Content>
          {nftsToShow.map((nft) => (
            <NFTWrapper key={`${nft.tokenId}-${nft.index !== undefined ? nft.index : ''}`}>
              <Title size={TitleSize.XXS}>{`${nft.name} ${getTitleNumber(nft)}`}</Title>
              <img
                src={nft.image}
                alt={nft.name}
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Legacy
                onError={(event) => ((event.target as HTMLImageElement).style.display = 'none')}
              />
            </NFTWrapper>
          ))}
        </Content>

        <Button id="close-view-nft-modal" fullWidth onClick={closeModal}>
          {translate('helpers.close')}
        </Button>
      </FlexContainer>
    </Modal>
  )
}

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 440px;
  overflow-y: auto;
`

const NFTWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.elevationL2};

  > img {
    width: 128px;
    height: 128px;
  }
`
