import type { MissionWithUserData } from '../types/nation.types'

export const getMissionCompletedStats = ({ mission, userData }: MissionWithUserData) => {
  if (!userData) {
    return {
      timesAchievedForCurrentLevel: 0,
      currentLevel: 0,
    }
  }

  const { minTimesToAchieve, maxTimesCanBeRewarded } = mission
  const { totalTimesAchieved } = userData

  const maxTimesCanBeRewardedNormalized = maxTimesCanBeRewarded || 1
  const totalTimesToAchieve = maxTimesCanBeRewardedNormalized * minTimesToAchieve

  const totalCompleted = totalTimesAchieved / totalTimesToAchieve
  const levelThreshold = minTimesToAchieve / totalTimesToAchieve
  const currentLevel = Math.floor(totalCompleted / levelThreshold)

  // Next level is always present if a mission is infinitely repeatable
  const nextLevel = currentLevel + 1

  const totalToAchieveToFinishLevel = nextLevel * minTimesToAchieve
  const timesAchievedForCurrentLevel = minTimesToAchieve - (totalToAchieveToFinishLevel - totalTimesAchieved)

  const isLastLevelCompleted = currentLevel === mission.maxTimesCanBeRewarded

  if (isLastLevelCompleted) {
    return {
      timesAchievedForCurrentLevel: minTimesToAchieve,
      currentLevel,
    }
  }

  // Handles cases where current level is e.g. 6.9999999 but should be 7
  if (timesAchievedForCurrentLevel === minTimesToAchieve) {
    return {
      timesAchievedForCurrentLevel: 0,
      currentLevel: nextLevel,
    }
  }

  return {
    timesAchievedForCurrentLevel,
    currentLevel,
  }
}
