import styled from 'styled-components'
import { NoUnderlineAnchor } from '../../../../components/common/Helpers/LinkHelpers'

export const PoweredByBanner = styled.div`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.bridgePickerBorder};
  height: fit-content;
  width: 100%;
  max-width: 400px; // Bridge widget width
  padding: 12px 16px;
  box-sizing: border-box;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  margin-top: 10px;

  .powered-by-icon-logo {
    max-height: 24px;
  }
`

export const PoweredByLink = styled(NoUnderlineAnchor)`
  width: 100%;
`
