import isNumber from 'lodash-es/isNumber'
import { useGetLeaderboardStatsQuery, useGetUserRankQuery } from '../services/nation.api'
import { selectAddress } from '../../../actions/selectors/userSelectors'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { useHasUserFullNationAccess } from './useHasUserFullNationAccess'

type Props = {
  skipLeaderboardStats?: boolean
}

export const useUserRxpRank = (props: Props = {}) => {
  const hasFullAccess = useHasUserFullNationAccess()
  const address = useAppSelector(selectAddress) || ''

  const { currentData, isLoading, error } = useGetUserRankQuery(address, { skip: !hasFullAccess || !address })
  const { currentData: statsData, isLoading: statsPending } = useGetLeaderboardStatsQuery(undefined, {
    skip: props?.skipLeaderboardStats || false,
  })

  const userRank = hasFullAccess ? currentData?.rank || 0 : '??'
  const userRxp = hasFullAccess ? currentData?.rxp || 0 : '??'

  const rxpEarnedThisWeek = hasFullAccess ? currentData?.rxpEarnedThisWeek || 0 : '??'

  const top10Rxp = statsData?.topTenPercentRxp || 0
  const userTotalCount = statsData?.userTotalCount || 0
  const userIsInTop10p = isNumber(userRxp) && userRxp > 0 && userRxp >= top10Rxp
  const progressToTop10 = userIsInTop10p ? 1 : top10Rxp && isNumber(userRxp) && top10Rxp > 0 ? userRxp / top10Rxp : 0

  return {
    error,
    isLoading,
    progressToTop10,
    rxpEarnedThisWeek,
    statsPending,
    top10Rxp,
    userIsInTop10p,
    userRank,
    userRxp,
    userTotalCount,
  }
}
