import { NETWORKS } from '../constants/types'
import { isNonEVMChain } from '../services/helperService/isNonEVMChain'
import { getChainConfig } from './getChainConfig'
import type { BridgeConfig } from '../context'

export const isWrongNetworkSelected = ({
  connectedNetworkId,
  secondaryConnectedNetworkId,
  targetChain,
  config,
}: {
  connectedNetworkId: number
  secondaryConnectedNetworkId: string
  targetChain: string
  config: BridgeConfig
}) => {
  if (targetChain === NETWORKS.PARADEX) {
    // Paradex has no network switching
    return false
  }
  const chainConfig = getChainConfig(config)({ chain: targetChain })
  if (isNonEVMChain(targetChain)) {
    return chainConfig.networkId !== secondaryConnectedNetworkId
  }
  return chainConfig.networkId !== `${connectedNetworkId}`
}
