import { string } from 'yup'
import type { StringSchema } from 'yup'
import { translate } from '../../../intl/i18n'

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- cast because context is originally of type any
export const emailSchema = (string() as StringSchema<string, { originalEmail: string | undefined }>)
  .required(translate('errors.field_is_required', { '%field': translate('helpers.email') }))
  .email(translate('errors.please_add_a_valid_email_address'))
  .test(
    'is-changed',
    translate('errors.please_change_the_address'),
    (value, context) => value !== context.options.context?.originalEmail,
  )
