import { string } from 'yup'
import { translate } from '../../../intl/i18n'

const phoneRegex = /^\d{7,14}$/

function testNumber(value = '') {
  return value === '' || phoneRegex.test(value)
}

export const phoneSchema = string().test('invalid-phone-number', translate('errors.invalid_phone_number'), testNumber)
