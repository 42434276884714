import type { BridgeConfigEntrySchema } from '@rhinofi/bridge-api-spec'
import moment from 'moment'

export const getBridgeDuration = (
  chainInConfig: BridgeConfigEntrySchema | undefined,
  chainOutConfig: BridgeConfigEntrySchema | undefined,
) => {
  try {
    if (!chainInConfig || !chainOutConfig) {
      return 0
    }
    const confirmationBlocks = chainInConfig.confirmationBlocks
    const blockTime = chainInConfig.avgBlockTime
    const chainOutBlockTime = chainOutConfig.avgBlockTime
    // Confirmation blocks + withdrawal execution
    const timeSeconds = Math.floor(confirmationBlocks * blockTime + chainOutBlockTime)
    const duration = moment.duration(timeSeconds, 'seconds')
    const minutes = duration.minutes()
    const seconds = duration.seconds()
    return `~${minutes ? `${minutes} min ` : ''}${seconds} sec`
    // eslint-disable-next-line no-unused-vars -- catch
  } catch (error) {
    return 0
  }
}
