/* eslint-disable -- legacy */
import React from 'react'
import type { CSSProperties } from 'react'
import type { useTheme } from 'styled-components'
import styled, { css } from 'styled-components'
import { isEmpty, isFunction } from 'lodash-es'
import { translate } from '../../../intl/i18n'
import { isMobileDevice } from '../../../utils'
import type { AppDispatch } from '../../../store/configureStore'
import type { SupportedTable } from '../../../reducers/settingsSlice/settingsSlice'
import TableBody from './Table.Body'
import { TableInfo } from './Table.Info'
import TableHead from './Table.Header'
import { Footer as TableFooter } from './Table.Footer'
import { getTableSize, getPagedList, getOrderedKeys } from './Table.Utils'

import type { DispatchTableProps, InjectedStateTableProps } from './mapReduxToTableProps'

const TableWrapper = styled.table<{ $fullSize?: boolean; $condensedView: boolean }>`
  width: 100%;
  border-spacing: ${({ $fullSize: fullSize }) => (fullSize ? '0 10px' : '0 2px')};
  overflow: hidden;

  ${({ $condensedView }) =>
    $condensedView &&
    css`
      border-spacing: 0 4px;
    `}
`

type State = {
  page: number
  loading?: boolean
}

export type OwnTableProps<T> = {
  name: SupportedTable
  data: readonly T[] | Record<string | number, T> | T[]
  error?: string
  onTRClick?: boolean | ((props: T) => void)
  isRowActive?: (props: T) => boolean
  columns: {
    id: string
    title?: string | JSX.Element
    hideOnMobile?: boolean
    sortingEnabled?: boolean
    align?: CSSProperties['textAlign']
    cells: {
      align?: CSSProperties['textAlign'] | string
      render: (
        props: T,
        rowInfo: {
          index: number
          name: string
          id: string
          cellId: string
          rowId: string
          rowActive: boolean
          theme: ReturnType<typeof useTheme>
          dispatch: AppDispatch
          isMobileSize?: boolean
        },
      ) => React.ReactNode
    }[]
  }[]
  onPageChange?: (props: { direction: boolean; page: number; maxPage: number }) => void
  panelStyle?: React.CSSProperties
  classes?: string
  rowClasses?: string | undefined
  fullSize?: boolean
  showHeader?: boolean
  emptyListMessage?: null | boolean | JSX.Element | string
  sortingCallback?: (name: string, field: string) => void
  defaultOrderBy?: string
  loading?: boolean
  noTextWrap?: boolean
  condensedView?: boolean
  ignoreInternalOrderBy?: boolean
  footer?: React.ReactNode
  onTRHover?: null
  onTRHoverLeave?: null
  pageSize?: number
  rowSelection?: false
  widget?: null
  pagination?: null
  afterSortingCallback?: null | ((keys: string[], data: T[] | readonly T[] | Record<string | number, T>) => string[])
  groupBy?: undefined
  isSecondLevel?: boolean
  isMobileSize?: boolean
}

type TableProps<T> = OwnTableProps<T> & DispatchTableProps & InjectedStateTableProps

class Table<T> extends React.Component<TableProps<T>, State> {
  constructor(props: TableProps<T>) {
    super(props)
    this.state = {
      page: 1,
    }
  }

  changePage(direction = false) {
    const { onPageChange } = this.props
    const { page = 1, loading } = this.state
    if (loading) {
      return
    }
    let newPage = null
    if (direction) {
      newPage = page + 1
    } else {
      if (page > 1) {
        newPage = page - 1
      }
    }
    if (page !== newPage) {
      this.setState({ page: newPage || 1 })
    }
    isFunction(onPageChange) &&
      onPageChange({
        direction,
        page: newPage || 1,
        maxPage: 2,
      })
  }

  // @ts-expect-error -- legacy code
  render() {
    const {
      panelStyle,
      classes,
      rowClasses,
      name,
      groupBy,
      loading,
      error,
      data = {},
      order,
      footer,
      applyOrder,
      defaultOrderBy = '-',
      emptyListMessage = translate('helpers.empty_list'),
      onTRClick = null,
      onTRHover = null,
      onTRHoverLeave = null,
      isRowActive = () => true,
      pageSize = 0,
      rowSelection = false,
      showHeader = true,
      widget = null,
      fullSize = false,
      sortingCallback = null,
      pagination = null,
      noTextWrap = false,
      afterSortingCallback = null,
      condensedView = false,
      ignoreInternalOrderBy = false,
      isSecondLevel = false,
      isMobileSize = false,
    } = this.props

    const isMobile = isMobileDevice()
    const columns = this.props.columns.filter((col) => !isMobile || !col.hideOnMobile)

    const colSize = getTableSize(columns)
    const currentOrder = order[name] || defaultOrderBy

    const orderedKeys =
      !ignoreInternalOrderBy && currentOrder !== '-' ? getOrderedKeys(data, currentOrder, name) : Object.keys(data)

    const afterOrderedKeys = afterSortingCallback ? afterSortingCallback(orderedKeys, data) : orderedKeys

    const keys = getPagedList({
      list: afterOrderedKeys,
      page: this.state.page,
      pageSize,
    })

    const dataProps = {
      keys,
      name,
      data,
      columns,
      groupBy,
      onTRClick,
      onTRHover,
      onTRHoverLeave,
      isRowActive,
      rowSelection,
      rowClasses,
      size: colSize,
      fullSize,
      pagination,
      noTextWrap,
      condensedView,
      isMobileSize,
    }

    const emptyPage = isEmpty(keys)
    const message = !error && !loading && emptyPage ? emptyListMessage : error
    const id = `table-${name}`

    return (
      <div style={panelStyle} className="table-container">
        <TableWrapper
          id={id}
          className={classes || 'table-wrapper highlight compact bordered'}
          $fullSize={fullSize}
          $condensedView={condensedView}
        >
          <thead>
            {showHeader ? (
              <TableHead
                name={name}
                columns={columns}
                currentOrder={currentOrder}
                applyOrder={applyOrder}
                sortingCallback={sortingCallback}
                fullSize={fullSize}
                noTextWrap={noTextWrap}
                condensedView={condensedView}
              />
            ) : null}
            {widget}
            <TableInfo message={message} loading={loading} size={colSize} />
          </thead>
          <tbody>{!loading && !error && <TableBody {...dataProps} isSecondLevel={isSecondLevel} />}</tbody>
          <tfoot>
            <TableFooter footer={footer} size={colSize} />
          </tfoot>
        </TableWrapper>
      </div>
    )
  }
}

export default Table
