import { config } from '@react-spring/web'

export const gentleFigma = {
  ...config.gentle,
  mass: 1,
  damping: 15,
  tension: 100,
} as const

export const clampedGentleFigma = {
  ...gentleFigma,
  clamp: true,
} as const

export const clampedGentleFigmaFast = {
  ...gentleFigma,
  clamp: true,
  tension: 150,
}
