/* eslint-disable -- legacy */
import styled from 'styled-components'

import { Icon, IconSizes, Tooltip } from '@rhinofi/dvf-shared-ui'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'

const IconWrapper = styled.span`
  position: relative;
  display: inline-block;
  height: 18px;
  width: 9px;
  margin-left: 4px;

  & i.bi-caret-up-fill,
  & i.bi-caret-down-fill {
    position: absolute;
    display: block;
    height: 12px;
    width: 9px;
    text-align: center;
    line-height: 13px;
    font-size: 10px !important;
  }
  i.bi-caret-down-fill {
    top: 6px;
  }
`

const TitleWrapper = styled.div<{ $fullSize?: boolean; $sortingEnabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  color: ${(props) => props.theme.secondary};
  font-size: 13px;
  font-weight: normal;
  line-height: 13px;
  border: 1px solid transparent;
  border-radius: 4px;

  transition: all 0.15s ease-in-out;
  padding: ${(props) => (props.$fullSize ? '4px 8px' : '2px')};

  @media ${respondBelow('xl')} {
    font-size: 11px;
  }

  cursor: ${({ $sortingEnabled }) => ($sortingEnabled ? 'pointer' : 'auto')};

  &:hover {
    background-color: ${({ $sortingEnabled: sortingEnabled, theme }) =>
      sortingEnabled ? theme.brandA2 : 'transparent'};
  }
`

const Title = ({ format = {}, fullSize = false, handleClick = () => {} }: any) => {
  const { alt, title = '', sortingEnabled = false } = format

  if (title === '') {
    return null
  }

  const arrows = sortingEnabled ? (
    <IconWrapper className="sort-icons">
      <Icon id="caret-down-fill" size={IconSizes.XSmall} />
      <Icon id="caret-up-fill" size={IconSizes.XSmall} />
    </IconWrapper>
  ) : null

  return (
    <TitleWrapper $fullSize={fullSize} $sortingEnabled={sortingEnabled} onClick={handleClick}>
      <Tooltip tooltipContent={alt}> {title} </Tooltip>
      {arrows}
    </TitleWrapper>
  )
}

export default Title
