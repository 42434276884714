import type { BridgeConfigsResponseSchema } from '@rhinofi/bridge-api-spec'

export const getTokenInfoOrThrow = (config: BridgeConfigsResponseSchema) => (token: string, chain: string) => {
  const chainConfig = config[chain]
  if (!chainConfig) {
    throw new Error('chain not found')
  }

  const tokenInfo = chainConfig.tokens[token]
  if (!tokenInfo) {
    throw new Error('token not found')
  }

  return tokenInfo
}
