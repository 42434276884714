import styled from 'styled-components'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'
import { NationWidgetStats } from './NationWidgetStats'
import { NationWidgetPromo } from './NationWidgetPromo'
import { NationWidgetMissions } from './NationWidgetMissions/NationWidgetMissions'
import { NationWidgetLastCompletedMission } from './NationWidgetLastCompletedMission'
import { NationWidgetStatsCTA } from './NationWidgetStatsCTA'

export const NationWidget = () => (
  <Container $direction="column" $alignItems="stretch" $gap="16px">
    <FlexContainer $direction="column" $alignItems="stretch" $gap="8px">
      <NationWidgetStats />
      <NationWidgetStatsCTA />
    </FlexContainer>

    <Divider />

    <NationWidgetPromo />
    <NationWidgetMissions />
    <NationWidgetLastCompletedMission />
  </Container>
)

const Container = styled(FlexContainer)`
  width: 288px;
  user-select: none;
`

export const Divider = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.secondary20};
`
