import type React from 'react'
import { useState, useEffect, useMemo } from 'react'
import { defaultThreshold } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import throttle from 'lodash-es/throttle'
import { ResizeContext } from './createdResizeContext'

export const ResizeContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth)
  const [isMobile, setIsMobile] = useState(window.innerWidth < defaultThreshold.xxs)
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= defaultThreshold.m)

  useEffect(() => {
    const handleResize = throttle(() => {
      setWidth(window.innerWidth)
      setIsMobile(window.innerWidth < defaultThreshold.xxs)
      setIsDesktop(window.innerWidth >= defaultThreshold.m)
    }, 1000 / 30)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const value = useMemo(
    () => ({
      width,
      isMobile,
      isDesktop,
    }),
    [width, isMobile, isDesktop],
  )

  return <ResizeContext.Provider value={value}>{children}</ResizeContext.Provider>
}
