import { Text, Button, ButtonVariant } from '@rhinofi/dvf-shared-ui'
import { useAppDispatch } from '../../../hooks'
import { translate } from '../../../intl/i18n'
import { FlexContainer } from '../../../components/common/Wrappers/Containers'
import { showModal } from '../../../actions/modalActions/showModal'
import { modalKeys } from '../../../constants/modalKeys'
import { ButtonLink } from '../../../components/common/Helpers/LinkHelpers'
import { publicRoutes } from '../../../router'
import { clearModal } from '../../../actions/modalActions/clearModal'
import { getNFTMintModalSuccessText } from '../helpers/getNFTMintModalSuccessText'
import { useChainKeyToName } from '../../../hooks/useChainKeyToName'
import type { NFTType } from '../types/mintNFT.types'

type Props = {
  type: NFTType
  chain: string
}

const MainCTAButton = ({ type, chain }: Props) => {
  const dispatch = useAppDispatch()
  const closeModal = () => clearModal(dispatch)()
  // TODO DOPE-422 - Integrate trackers
  // const activityTrackerExistsForChain = getEnabledActivityTrackers().includes(chain)
  const activityTrackerExistsForChain = false

  if (type === 'ALPHAKEY') {
    return (
      <ButtonLink id="alpha-key-minted" fullWidth to={publicRoutes.bridge} onClick={closeModal}>
        {translate('rhino_alpha_key.go_to_bridge')}
      </ButtonLink>
    )
  }

  if (type === 'BETAKEY') {
    return (
      <Button id="close-beta-key-mint" fullWidth onClick={closeModal}>
        {translate('gated.view_my_rewards')}
      </Button>
    )
  }

  return (
    <>
      <Button
        id={`view-nft-${type}-${chain}`}
        fullWidth
        variant={ButtonVariant.secondary}
        onClick={() => showModal(dispatch)(modalKeys.viewNftMinted, { chain, type })}
      >
        {translate('tracker.view_my_nfts')}
      </Button>

      {activityTrackerExistsForChain && (
        <Button
          id="check-score"
          fullWidth
          onClick={() => {
            closeModal()
            window.scrollTo(0, 0)
          }}
        >
          {translate('tracker.check_my_score')}
        </Button>
      )}
    </>
  )
}

export const NFTMintProgressSuccessFooter = ({ chain, type }: Props) => {
  const chainName = useChainKeyToName(chain)

  return (
    <FlexContainer $direction="column" $fullWidth $gap="16px">
      <Text>{getNFTMintModalSuccessText(type, chainName)}</Text>
      <MainCTAButton type={type} chain={chain} />
    </FlexContainer>
  )
}
