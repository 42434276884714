import { useEffect, type RefObject } from 'react'

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideClickHandler = (ref: RefObject<HTMLElement>, callback: () => void) => {
  useEffect(() => {
    const handleClickOutside: EventListener = (event) => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Legacy
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy. Reason unknown.
  }, [ref])
}
