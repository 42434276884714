/* eslint-disable -- legacy */
import type React from 'react'
import styled from 'styled-components'
import { isObject, size, slice, sortBy, reverse, includes } from 'lodash-es'

const Row = styled.tr<{
  $big: boolean
}>`
  ${(props) => (props.$big ? 'height: 3rem;' : '')}
`
const Cell = styled.td<{ $defaultFont?: string; $align: string }>`
  text-align: ${(props) => props.$align};
  font-family: ${(props) => props.$defaultFont};
  padding: 12px 0;
  font-size: 14px;
  color: ${(props) => props.theme.secondary};
`

type FullWidthCellProps = {
  size?: number
  big?: boolean
  align?: 'left' | 'center' | 'right' | 'justify' | 'char'
  children?: React.ReactNode
}

export function FullWidthCell(props: FullWidthCellProps = {}) {
  const { size = 1, big = false, align = 'center', children = null } = props

  return (
    <Row $big={big}>
      <Cell colSpan={size} $align={align}>
        {' '}
        {children}{' '}
      </Cell>
    </Row>
  )
}

export function getTableSize(
  columns: {
    cells?: unknown[]
  }[] = [],
) {
  let total = 0
  columns.forEach((column) => {
    total += column.cells ? size(column.cells) : 1
  })
  return total
}

type GetPagedListProps<T> = {
  list?: T[]
  pageSize?: number
  page?: number
}

export function getPagedList<T>(args: GetPagedListProps<T> = {}) {
  const { list = [], pageSize = 0, page = 1 } = args

  if (pageSize < 1) {
    return list
  }

  // only the data we see in the current page
  const firstIndex = pageSize * (page - 1)
  const lastIndex = pageSize * page
  const result = slice(list, firstIndex, lastIndex)

  return result
}

// returns a cell object to render the current cell
// some strings are mapped to some static types of cells
export function getCell(cellInfo: unknown) {
  if (isObject(cellInfo)) {
    return cellInfo
  }
  return {}
}

export function sortTableBy(sortingEnabled: any, setOrder: any, name: any, id: any, sortingCallback: any) {
  sortingEnabled && setOrder(name, id, sortingCallback)
}

export function getOrderedKeys(data = {}, currentOrder = '', name = '') {
  const [orderBy = '-', ascending = 'asc'] = currentOrder.split('-')
  let ethID
  let pairID

  const __data = Object.assign({}, data)
  for (const id in __data) {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    __data[id].__id = id
  }
  const list = Object.values(__data)
  const orderFunction = getOrderFunction(orderBy)
  const tmp = sortBy(list, orderFunction).map((el) => {
    if ((el as any).token === 'ETH') {
      ethID = (el as any).__id
    }

    return (el as any).__id
  })

  const result = ascending === 'asc' ? tmp : reverse(tmp)

  if (name === 'erc20Balances' && ethID) {
    result.splice(result.indexOf(ethID), 1)
    result.unshift(ethID)
  }

  if (name === 'ticker' && result.includes(pairID)) {
    result.splice(result.indexOf(pairID), 1)
    result.unshift(pairID)
  }

  return result
}

const ONE_DAY = 86400000

function getOrderFunction(orderBy: any) {
  // sort by...
  // sum of two columns
  if (includes(orderBy, '_SUM_')) {
    const [keyA, keyB] = orderBy.split('_SUM_')

    return (row: any) => parseFloat(row[keyA]) + parseFloat(row[keyB])
  }
  // multiplied columns
  if (includes(orderBy, '_MUL_')) {
    const [keyA, keyB] = orderBy.split('_MUL_')

    return (row: any) => parseFloat(row[keyA]) * parseFloat(row[keyB])
  }
  // 3 multiplied columns
  if (includes(orderBy, '_MMUL_')) {
    const [keyA, keyB, keyC] = orderBy.split('_MMUL_')

    return (row: any) => parseFloat(row[keyA]) * parseFloat(row[keyB]) * parseFloat(row[keyC])
  }
  // sum of days to a mts
  if (includes(orderBy, '_SUMDAYS_')) {
    const [keyA, keyB] = orderBy.split('_SUMDAYS_')

    return (row: any) => (ONE_DAY * row[keyB] + row[keyA]) * 1000
  }
  // more sorting functions can be added
  // ...

  return (row: any) => (isNaN(row[orderBy]) ? row[orderBy] : parseFloat(row[orderBy]))
}
