import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Button } from '@rhinofi/dvf-shared-ui'
import type { ComponentProps } from 'react'
import { BridgeLink } from '../../BridgeLink'

export const NoUnderlineLink = styled(Link)<{ $fullWidth?: boolean }>`
  text-decoration: none;
  cursor: pointer;
  ${({ $fullWidth }) => ($fullWidth ? 'width: 100%;' : '')}
`

export const NoUnderlineBridgeLink = styled(BridgeLink)<{ $fullWidth?: boolean }>`
  text-decoration: none;
  ${({ $fullWidth }) => ($fullWidth ? 'width: 100%;' : '')}
`

export const NoUnderlineAnchor = styled.a<{ $fullWidth?: boolean }>`
  text-decoration: none;
  ${({ $fullWidth }) => ($fullWidth ? 'width: 100%;' : '')}
`

type ButtonLinkProps = {
  to: string
} & ComponentProps<typeof Button> &
  ComponentProps<typeof NoUnderlineAnchor>

type ExternalButtonLinkProps = {
  href: string
} & ComponentProps<typeof Button> &
  ComponentProps<typeof NoUnderlineAnchor>

export const ButtonLink = (props: ButtonLinkProps) => {
  if (props.disabled) {
    return <Button {...props} />
  }
  return <Button as={NoUnderlineLink} {...props} />
}

export const ExternalButtonLink = (props: ExternalButtonLinkProps) => {
  return <Button as={NoUnderlineAnchor} {...props} />
}

export const NoStylingLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`
