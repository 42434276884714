import { publicRoutes } from '../../router'
import { MobileHeaderContainer, MobileNavLogo } from './MobileNavigation.styled'
import rhinoSolo from '../../assets/icons/rhino-solo.svg'
import { Link } from 'react-router-dom'
import { Button } from '@rhinofi/dvf-shared-ui'
import { translate } from '../../intl/i18n'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { selectUserAddress } from '../../reducers/userSelector'
import { Menu } from '../Menu/Menu'
import { modalKeys } from '../../constants/modalKeys'
import { showModal } from '../../actions/modalActions/showModal'

export const MobileHeader = () => {
  const dispatch = useAppDispatch()

  const walletAddress = useAppSelector(selectUserAddress)
  return (
    <MobileHeaderContainer>
      <Link to={publicRoutes.bridge} className="logo-link">
        <MobileNavLogo src={rhinoSolo} alt="rhino.fi logo" />
      </Link>
      {!walletAddress ? (
        <Button id="mobile-connect" onClick={() => showModal(dispatch)(modalKeys.connectWallet)}>
          {translate('global.connect')}
        </Button>
      ) : (
        <Menu />
      )}
    </MobileHeaderContainer>
  )
}
