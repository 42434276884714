import { PerkTypeSchema } from '@rhinofi/rhino-nation-api-spec'
import { translate } from '../../../intl/i18n'
import type { NationStorageKeyRecord } from '../types/nation.types'
import { SectionsIds } from '../types/nation.types'
import type { PerkTypeWithCustomReward } from '../types/perkRewards.types'
import { NETWORKS } from '../../../constants/types'
import type { EVMNFTType } from '../../Chain/types/mintNFT.types'

export const NATION_SECTIONS_TABS = [
  { label: translate('helpers.earn'), id: SectionsIds.EARN },
  { label: translate('helpers.spend'), id: SectionsIds.SPEND },
]

export const NATION_XP_LABEL = 'R-XP'

export const PERK_TYPES_WITH_CUSTOM_REWARD: PerkTypeWithCustomReward[] = [PerkTypeSchema.enums.VOUCHER]

export const NATION_STORAGE_KEYS: NationStorageKeyRecord = {
  ONBOARDING: 'nation-onboarding-shown',
  CAMPAIGN_USE_IT_OR_LOSE_IT: 'nation-use-it-or-lose-it-shown',
}

export const NATION_ACCESS_NFT_TYPE: EVMNFTType = 'BETAKEY'
export const NATION_ACCESS_NFT_NETWORK = NETWORKS.SCROLL
