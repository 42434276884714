import type { HttpApiClient, HttpApiGroup, HttpClient } from '@effect/platform'
import { FetchHttpClient } from '@effect/platform'
import { Context, Effect, ManagedRuntime } from 'effect'
import type { Simplify } from 'effect/Types'

export class AuthToken extends Context.Tag('AuthToken')<AuthToken, string>() {}

export const apiClientRuntime = ManagedRuntime.make(FetchHttpClient.layer)

export const makeApiClientRequest = <
  Groups extends HttpApiGroup.HttpApiGroup.Any,
  ApiError,
  Client extends Simplify<HttpApiClient.Client<Groups, ApiError>>,
  Result,
>(
  apiClient: Effect.Effect<Client, never, HttpClient.HttpClient>,
  clientOperation: (client: Client) => Effect.Effect<Result, ApiError, never>,
  jwt?: string,
  catchRequestErrors?: (
    effect: Effect.Effect<Result, ApiError, HttpClient.HttpClient>,
  ) => Effect.Effect<Result, ApiError, HttpClient.HttpClient>,
) => {
  if (catchRequestErrors) {
    return apiClientRuntime.runPromise(
      apiClient.pipe(
        Effect.flatMap(clientOperation),
        catchRequestErrors,
        jwt ? Effect.provideService(AuthToken, jwt) : (clientOp) => clientOp,
        Effect.orDie,
        Effect.scoped,
      ),
    )
  }
  return apiClientRuntime.runPromise(
    apiClient.pipe(
      Effect.flatMap(clientOperation),
      jwt ? Effect.provideService(AuthToken, jwt) : (clientOp) => clientOp,
      Effect.orDie,
      Effect.scoped,
    ),
  )
}
