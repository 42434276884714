import { useMemo } from 'react'
import type { GetUserPerkDataResponse } from '@rhinofi/rhino-nation-api-spec'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { getRTKErrMessage } from '../../../services/helperService'
import { useGetActivePerkQuery, useGetUserPerkDataQuery } from '../services/nation.api'
import type { PerksWithUserData } from '../types/nation.types'
import { invalidateNationTagsOnPotentialGemsChangeAction } from '../actions/nation.actions'
import { useHasUserFullNationAccess } from './useHasUserFullNationAccess'

type Props = {
  perkId: string
  skip?: boolean
}

export const usePerkWithUserData = ({
  perkId,
  skip = false,
}: Props): {
  isLoading: boolean
  error: string
  data: PerksWithUserData | null
} => {
  const dispatch = useAppDispatch()
  const hasFullAccess = useHasUserFullNationAccess()

  const { isLoading: isLoadingPerk, error: perkError, data: perkResponse } = useGetActivePerkQuery({ perkId }, { skip })

  const {
    isLoading: isLoadingPerkUserData,
    error: perksUserDataError,
    data: perksUserData,
  } = useGetUserPerkDataQuery(
    {
      onSuccess: invalidateNationTagsOnPotentialGemsChangeAction(dispatch),
    },
    { skip: !hasFullAccess },
  )

  const isLoading = hasFullAccess ? isLoadingPerk || isLoadingPerkUserData : isLoadingPerk
  const errorInitial = hasFullAccess ? perkError || perksUserDataError : perkError
  const error = errorInitial ? getRTKErrMessage(errorInitial) : ''

  const data = useMemo(() => {
    if (!perkResponse) {
      return null
    }

    const perksUserDataLocal: GetUserPerkDataResponse = perksUserData ?? { items: [] }

    const items = [perkResponse].map((perk) => {
      const perkUserData = perksUserDataLocal.items.find(({ _id }) => _id.perkId === perk._id)

      return {
        perk,
        userData: perkUserData || null,
      }
    })

    return {
      items,
    }
  }, [perkResponse, perksUserData])

  return {
    isLoading,
    error,
    data,
  }
}
