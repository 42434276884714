import styled from 'styled-components'
import { useMemo } from 'react'
import { showModal } from '../../../../actions/modalActions/showModal'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { modalKeys } from '../../../../constants/modalKeys'
import { translate } from '../../../../intl/i18n'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'
import type { UserHistoryResponseSchema } from '@rhinofi/bridge-api-spec'
import { bridgeHistoryColumns } from '../../../../components/BridgeHistory/BridgeHistory.columns'
import { useExplorerLink } from '../../../../hooks/util/useExplorerLink'
import Table from '../../../../components/bfx-legacy-ui/Table'
import { ButtonLink } from '../../../../components/common/Helpers/LinkHelpers'
import { ButtonSizes, ButtonVariant } from '@rhinofi/dvf-shared-ui'
import { makeRouteTo } from '../../../../router'

type RecentBridgeTransactionsProps = {
  bridgeHistory: UserHistoryResponseSchema | undefined
  isFetching: boolean
}

// const isRewardsCampaignLive = isFeatureEnabled(F_FLAGS_TYPES.BRIDGE_REWARDS_CAMPAIGN)

export const RecentBridgeTransactions = ({ isFetching, bridgeHistory }: RecentBridgeTransactionsProps) => {
  const dispatch = useAppDispatch()
  const { etherscanLink } = useExplorerLink()

  const tableData = useMemo(() => {
    const { items = [] } = bridgeHistory || {}
    return items.map(({ bridge, rejections }) => ({ ...bridge, rejections }))
  }, [bridgeHistory])

  // const bridgeRewardsData = useBridgeRewardsData({ bridges: tableData })

  // if (isApplicationDataLoading) {
  //   return <RecentTransactionsSkeleton />
  // }

  return (
    <Container $direction="column" $gap="16px">
      {/*{isRewardsCampaignLive && bridgeRewardsData.length > 0 && (*/}
      {/*  <RewardsContainer $gap="4px" $direction="column" $alignItems="stretch">*/}
      {/*    <Text size={TextSize.XS}>{translate('bridge.rhino_rewards')}</Text>*/}

      {/*    <FlexContainer $gap="8px" $direction="column">*/}
      {/*      {bridgeRewardsData.map((data) => (*/}
      {/*        <BridgeRewardCounter key={data.config.id} data={data} />*/}
      {/*      ))}*/}
      {/*    </FlexContainer>*/}
      {/*  </RewardsContainer>*/}
      {/*)}*/}

      <Table
        name="bridges-history"
        data={tableData}
        columns={bridgeHistoryColumns(etherscanLink, false)}
        emptyListMessage={translate('global.no_transaction_history')}
        loading={isFetching && tableData.length === 0}
        onTRClick={({ _id }) =>
          showModal(dispatch)(modalKeys.recentBridgeTx, {
            bridgeId: _id,
          })
        }
        condensedView
        isSecondLevel
      />
      {tableData.length > 0 && (
        <ButtonLink
          id="view-full-history"
          to={makeRouteTo.history()}
          variant={ButtonVariant.secondary}
          size={ButtonSizes.Large}
        >
          {translate('global.view_transaction_history')}
        </ButtonLink>
      )}
    </Container>
  )
}

const Container = styled(FlexContainer)`
  max-height: 440px;
  overflow: auto;

  .table-container {
    width: 100%;
  }
`

// const RewardsContainer = styled(FlexContainer)`
//   z-index: 2;
//   position: 'relative';
// `
//
// const RecentTransactionsSkeleton = styled.div`
//   animation: skeleton-loading 1s linear infinite alternate;
//   width: 360px;
//   height: 320px;
//
//   border-radius: 10px;
//
//   @keyframes skeleton-loading {
//     0% {
//       background-color: ${({ theme }) => theme.disabled};
//       filter: brightness(115%);
//     }
//     100% {
//       background-color: ${({ theme }) => theme.disabled};
//       filter: brightness(90%);
//     }
//   }
//
//   @media ${respondBelow('xxs')} {
//     width: 100%;
//   }
// `
