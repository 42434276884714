import { useMemo } from 'react'
import { useConfig } from './useConfig'
import { safeGetChainName } from '../utils/getChainName'

export const useChainKeyToName = (chainKey: string) => {
  const { config } = useConfig()

  return useMemo(() => {
    const chainToName = safeGetChainName(config)
    return chainToName(chainKey)
  }, [chainKey, config])
}
