import { NETWORKS } from '../../constants/types'
import { useCallback } from 'react'
import { formatTargetHash, getAccountSlug, getTransactionSlug } from '../../services/helperService'
import { useAppSelector } from '../useAppSelector'
import { selectBridgeConfig } from '../../store/apis/config.api'

// LEGACY: etherscanLink / etherscanLink -- switch to using this hook

const getType = (target: string, chain: string, isTransaction: boolean) => {
  // target Length doesnt work for STARKNET and PARADEX as they have the same length for contract and transaction
  if (chain === NETWORKS.STARKNET || chain === NETWORKS.PARADEX) {
    return isTransaction ? getTransactionSlug(chain) : 'contract'
  }

  const targetLength = NETWORKS.SOLANA ? 44 : 43
  return target.length >= targetLength || isTransaction ? getTransactionSlug(chain) : getAccountSlug(chain)
}

export const useExplorerLink = (directTarget?: string, directChain?: string, directIsTransaction?: boolean) => {
  const chainConfig = useAppSelector(selectBridgeConfig)
  const etherscanLink = useCallback(
    (target: string, chain = 'ETHEREUM', isTransaction = false) => {
      if (!chainConfig) {
        return '#'
      }
      const currentChainConfig = chainConfig[chain]
      if (!currentChainConfig) {
        return '#'
      }

      const type = getType(target, chain, isTransaction)
      const blockExplorerUrl = currentChainConfig.blockExplorer

      return `${blockExplorerUrl}/${type}/${encodeURIComponent(formatTargetHash(target, chain))}`
    },
    [chainConfig],
  )

  return {
    etherscanLink,
    link: directTarget && directChain ? etherscanLink(directTarget, directChain, directIsTransaction) : '#',
  }
}
