import { getTronProvider } from '../../providers/tronProvider'

export const executeViewTronCall = async (address: string, functionSelector: string, parameters?: unknown[]) => {
  try {
    const { tronWeb } = getTronProvider()
    if (!tronWeb) {
      return []
    }
    const result = await tronWeb.transactionBuilder.triggerSmartContract(
      address,
      functionSelector,
      {
        _isConstant: true,
      },
      parameters,
    )
    return result?.result ? result.constant_result : []
  } catch (error) {
    console.error(error)
    return []
  }
}
