import type { ReactChild, ReactElement } from 'react'
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { animated, useSpring } from '@react-spring/web'
import styled, { css } from 'styled-components'
import { useHoverOrClick } from '@rhinofi/dvf-shared-ui/lib/hooks/useHoverOrClick'
import { Icon, IconSizes } from '@rhinofi/dvf-shared-ui'
import { gentleFigma } from '../../constants/springs'

import { useIsMobile } from '../../hooks/useIsMobile'
import { getFinalStyle } from './getFinalStyle'
import { getInitialStyle } from './getInitialStyle'
import type { Direction } from './SlideInTooltip.types'

type TooltipVariant = 'info' | 'error' | 'warn' | 'elevation'
type SlideInTooltipProps = {
  direction: Direction
  alsoOnClick?: boolean
  children: ReactElement
  renderTooltip: () => ReactChild
  variant: TooltipVariant
  disabled?: boolean
  className?: string
  showTooltipIcon?: boolean
  minWidth?: number
}

export const SlideInTooltip = ({
  variant,
  direction,
  alsoOnClick = true,
  children,
  renderTooltip,
  disabled = false,
  className,
  showTooltipIcon,
  minWidth,
}: SlideInTooltipProps) => {
  const tooltipRef = useRef(null)
  const tooltipPositionRef = useRef<HTMLDivElement>(null)
  const [finalLeft, setFinalLeft] = useState<number | null>(null)
  const [maxWidth, setMaxWidth] = useState<number | null>(null)

  const { isMobile } = useIsMobile()
  const { clickProps, hoverProps, isHovering, isOpen } = useHoverOrClick({
    handleClicks: alsoOnClick,
    elementToClose: tooltipRef,
  })

  const isVisible = isOpen || isHovering
  const isVisibleRef = useRef(isOpen || isHovering)
  isVisibleRef.current = isVisible
  const [isVisibleDelayed, setIsVisibleDelayed] = useState(isOpen || isHovering)

  useEffect(() => {
    if (isVisible) {
      setIsVisibleDelayed(true)
    } else if (isVisibleDelayed) {
      const timeout = setTimeout(() => {
        if (!isVisibleRef.current) {
          setIsVisibleDelayed(false)
        }
      }, 300)

      return () => {
        clearTimeout(timeout)
      }
    }

    return
  }, [isVisible, isVisibleDelayed])
  // all tooltips on mobile to come from the bottom to limit options
  const finalDirection = isMobile ? 'bottom' : direction

  const calculateOffset = useCallback(() => {
    // own position
    const ownPosition = tooltipPositionRef.current?.getBoundingClientRect()
    // window size
    const windowWidth = window.innerWidth
    // if right side of tooltip is outside of window, adjust position. Limit width to window width
    // eslint-disable-next-line functional/no-let -- Legacy
    let offset = 0
    // eslint-disable-next-line functional/no-let -- Legacy
    let finalOffset = 0
    // eslint-disable-next-line functional/no-let -- Legacy
    let newMaxWidth = null
    if (ownPosition?.right && ownPosition.right > windowWidth) {
      offset = ownPosition.width - windowWidth + ownPosition.left + 16
      finalOffset = Math.min(offset, ownPosition.left - 16)
      newMaxWidth = Math.min(ownPosition.width, windowWidth - 32)
    }
    setMaxWidth(newMaxWidth)
    setFinalLeft(finalOffset)
  }, [])

  useLayoutEffect(() => {
    if (isMobile && isOpen && finalLeft === null) {
      calculateOffset()
    }
    if ((!isOpen || !isMobile) && finalLeft !== null) {
      setFinalLeft(null)
      setMaxWidth(null)
    }
  }, [calculateOffset, isMobile, isOpen, finalLeft])

  const style = useSpring({
    config: gentleFigma,
    reset: !isVisibleDelayed,
    pause: !isVisibleDelayed,
    from: getInitialStyle(direction),
    to: isVisible ? getFinalStyle(direction) : getInitialStyle(direction),
  })

  return disabled ? (
    children
  ) : (
    <InteractionWrapper {...hoverProps} ref={tooltipRef} className={className}>
      {React.cloneElement(children, clickProps)}
      {isVisible || isVisibleDelayed ? (
        <TooltipWrapper
          $maxWidth={maxWidth}
          $left={finalLeft}
          $direction={finalDirection}
          style={style}
          $variant={variant}
          $minWidth={minWidth}
          ref={tooltipPositionRef}
        >
          {renderTooltip()}
        </TooltipWrapper>
      ) : null}
      {showTooltipIcon ? (
        <TooltipIconWrapper>
          <Icon id="info-circle" size={IconSizes.XSmall} />
        </TooltipIconWrapper>
      ) : null}
    </InteractionWrapper>
  )
}

const TooltipWrapper = styled(animated.div)<{
  $direction: Direction
  $variant: TooltipVariant
  $minWidth?: number | undefined
  $left?: number | null
  $maxWidth?: number | null
}>`
  position: absolute;
  ${({ $left, $direction }) => ($left ? `left: -${$left}px` : $direction === 'right' ? `right: 0` : `left: 0`)};
  ${({ $direction }) => ($direction === 'bottom' ? `top: calc(100% + 8px)` : `bottom: calc(100% + 8px)`)};

  padding: 8px;
  border-radius: 6px;
  z-index: 1000;
  ${({ $variant, theme }) =>
    $variant === 'warn'
      ? css`
          color: ${theme.accentBlue};
          border: 1px solid ${theme.accentBlue};
          background: ${theme.blueBackground};
        `
      : $variant === 'error'
        ? css`
            color: ${theme.accentRed};
            border: 1px solid ${theme.accentRed};
            background: ${theme.redBackground};
          `
        : $variant === 'elevation'
          ? css`
              color: ${theme.primary};
              border: 1px solid ${theme.secondary40};
              background: ${theme.elevationL1};
            `
          : css`
              color: ${theme.brandA1};
              border: 1px solid ${theme.brandA1};
              background: ${theme.brandA2};
            `}

  ${({ $minWidth }) => `min-width: ${$minWidth || 200}px;`}
  ${({ $maxWidth }) => ($maxWidth ? `max-width: ${$maxWidth}px;` : '')}
`

const InteractionWrapper = styled.div`
  position: relative;
`

const TooltipIconWrapper = styled.div`
  position: absolute;

  top: 8px;
  right: 8px;
`
