import { Spacing, Text, TextSize, Title, TitleSize, TypographyAlign } from '@rhinofi/dvf-shared-ui'
import styled from 'styled-components'
import { PerpetualLoader } from '../../../../../components/common/Helpers/PerpetualLoader'
import { translate } from '../../../../../intl/i18n'

export const SpendingCapPending = ({ token }: { token: string }) => (
  <>
    <Title size={TitleSize.XXS} bold>
      {translate('deposit.set_spending_cap', { '%token': token })}
    </Title>
    <Spacing size="16" />
    <Text>{translate('deposit.what_is_spending_cap')}</Text>

    <Text size={TextSize.XS}>{translate('deposit.spending_cap_desc')}</Text>
    <Spacing size="16" />

    <Text size={TextSize.XS}>{translate('deposit.spending_cap_desc_1')}</Text>
    <Spacing size="16" />
    <PerpetualLoader />
    <Spacing size="16" />
    <FullWidthText align={TypographyAlign.Center}>{translate('deposit.wallet_follow_steps')}</FullWidthText>
  </>
)

export const FullWidthText = styled(Text)`
  width: 100%;
`
