/* eslint-disable -- Legacy code */
/* tslint:disable */
import React from 'react'
import type { DefaultTheme } from 'styled-components'
import styled, { css, withTheme } from 'styled-components'
import { isFunction } from 'lodash-es'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import type { AppDispatch } from '../../../store/configureStore'
import { getCell } from './Table.Utils'

export type RowColumnProps = {
  index: number
  name: string
  dispatch: AppDispatch
}

type RowValue = any
type TableRowProps = {
  rowClasses?: string
  theme: DefaultTheme
  condensedView: boolean
  name?: undefined
  rowIndex?: number
  onTRClick?: null | ((row: RowValue, event: React.MouseEvent) => void)
  onTRHoverLeave?: null | ((row: RowValue, event: React.MouseEvent) => void)
  onTRHover?: null | ((row: RowValue, event: React.MouseEvent) => void)
  fullSize?: boolean
  noTextWrap?: boolean
  dispatch: AppDispatch
  isSecondLevel?: boolean
  isMobileSize?: boolean
}

class Row extends React.PureComponent<TableRowProps> {
  // @ts-expect-error -- legacy code
  render() {
    const {
      name,
      // @ts-expect-error TS(2339): Property 'row' does not exist on type 'Readonly<{}... Remove this comment to see the full error message
      row = {},
      rowIndex = 0,
      rowClasses,
      // @ts-expect-error TS(2339): Property 'columns' does not exist on type 'Readonl... Remove this comment to see the full error message
      columns = [],
      onTRClick = null,
      onTRHoverLeave = null,
      onTRHover = null,
      // @ts-expect-error TS(2339): Property 'isRowActive' does not exist on type 'Rea... Remove this comment to see the full error message
      isRowActive = () => true,
      fullSize = false,
      noTextWrap = false,
      theme,
      dispatch,
      condensedView = false,
      isSecondLevel = false,
      isMobileSize = false,
    } = this.props
    const { id, hoverElement = null } = row
    const rowId = `table-${name}-${id}`
    const rowActive = isRowActive(row)

    return (
      <>
        <TR
          id={rowId}
          className={`${rowActive ? 'row--active' : ''} ${rowClasses || ''}`}
          $isClickable={!!onTRClick && rowActive}
          onClick={onTRClick && rowActive ? (event) => onTRClick(row, event) : () => {}}
          $hoverElement={hoverElement}
          $fullSize={fullSize}
          onMouseEnter={onTRHover ? (event) => onTRHover(row, event) : () => {}}
          onMouseLeave={onTRHoverLeave ? (event) => onTRHoverLeave(row, event) : () => {}}
          $noHover={!!row?.actionBar}
          $condensedView={condensedView}
          $isSecondLevel={isSecondLevel}
        >
          {}
          {columns.map((column = {}, index: any) => {
            // @ts-expect-error TS(2339): Property 'cells' does not exist on type '{}'.
            const { cells = [{}], classes = '', verticalAlign = 'center' } = column

            return cells.map((cellInfo: any, cellIndex: any) => {
              const cell = getCell(cellInfo)
              const {
                // @ts-expect-error TS(2339): Property 'render' does not exist on type '{}'.

                render = () => row[(column as any).id],
                // @ts-expect-error TS(2339): Property 'width' does not exist on type '{}'.
                width = 'auto',
                // @ts-expect-error TS(2339): Property 'align' does not exist on type '{}'.
                align = 'center',
                // @ts-expect-error TS(2339): Property 'cellStyle' does not exist on type '{}'.
                cellStyle = {
                  textAlign: align,
                  width: width,
                  verticalAlign,
                },
              } = cell

              const cellId = `${rowId}-${(column as any).id}-${cellIndex}`
              const resolvedClasses = isFunction(classes) ? classes(row) : classes

              return (
                <TD key={cellId} id={cellId} style={cellStyle} className={resolvedClasses} $noTextWrap={noTextWrap}>
                  {hoverElement && index === columns.length - 1 && hoverElement}
                  {render(row, { index: rowIndex, name, id, cellId, rowId, theme, isMobileSize, rowActive, dispatch })}
                </TD>
              )
            })
          })}
        </TR>
        {row.actionBar && (
          <TR $noHover>
            <TD colSpan={columns.length} className="no-padding">
              {row.actionBar}
            </TD>
          </TR>
        )}
      </>
    )
  }
}

export default withTheme(Row)

const TR = styled.tr<{
  $isClickable?: boolean
  $hoverElement?: JSX.Element | null
  $fullSize?: boolean
  $noHover?: boolean
  $actionBar?: boolean
  $condensedView?: boolean
  $isSecondLevel?: boolean
}>`
  position: relative;
  padding: 0 2px;
  transition: all 0.3s ease-out;
  cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'default')};

  td {
    transition: all 0.3s ease-out;

    &:not(:last-child):not(:first-child) {
      border-left: none;
      border-right: none;
    }
    &:first-child {
      border-right: none;
    }
    &:last-child {
      border-left: none;
    }
  }

  &:hover {
    td > .hover-element {
      opacity: 1;
    }

    td {
      border: 2px solid ${({ $isClickable, theme }) => (!$isClickable ? 'transparent' : `${theme.brandA1}cc`)};

      &:not(:last-child):not(:first-child) {
        border-left: none;
        border-right: none;
      }
      &:first-child {
        border-right: none;
      }
      &:last-child {
        border-left: none;
      }
    }
  }

  ${(props) =>
    props.$fullSize &&
    css`
      height: ${() => !props.$actionBar && '80px'};
      box-sizing: border-box;

      td {
        font-size: 14px;
        padding: 0 8px 0 8px;
        background-color: ${props.theme.elevationL1};

        &:first-child {
          padding-left: 16px;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        &:last-child {
          padding-right: 16px;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    `}

  ${({ $condensedView }) =>
    $condensedView &&
    css`
      height: 54px;
      td {
        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    `}

  ${(props) =>
    props.$noHover &&
    css`
      &:hover {
        td {
          background-color: ${props.theme.elevationL1};
          border-color: transparent;
        }
      }
    `}

  .no-padding {
    position: relative;
    top: -11px;
    background-color: ${(props) => props.theme.brandA2};

    &:hover {
      background-color: ${(props) => props.theme.brandA2};
      td {
        background-color: ${(props) => props.theme.brandA2};
      }
    }
  }
`

const TD = styled.td<{ $noTextWrap?: boolean }>`
  font-size: 14px;
  padding: 5px 8px;
  color: ${({ theme }) => theme.secondary};
  border: 2px solid transparent;
  ${(props) =>
    props.$noTextWrap &&
    css`
      white-space: nowrap;
    `};

  @media ${respondBelow('xl')} {
    padding: 3px 5px;
  }

  &.no-left-padding {
    padding-left: 0;
  }

  &.padded-right {
    padding-right: 15px;
  }

  &.no-padding {
    padding: 0;
  }
`
