import { useMemo } from 'react'
import { translate } from '../../../../intl/i18n'
import type { TxStatusTitleMapping } from '../../../../services/txService/txService.types'
import { TxStatus } from '../../../../services/txService/txService.types'
import { ContractInteractionsTxModal } from '../ContractInteractionsTxModal/ContractInteractionsTxModal'
import image from '../../assets/deploy_card_illustration.png'
import { ContractDeployModalSuccess } from './ContractDeployModalSuccess'

type Props = {
  txStatus: TxStatus
  txHash?: string | undefined
  chain: string
  contractAddress?: string | undefined | null
  retryFunc?: (() => void) | undefined
}

export const ContractDeployModal = ({ txStatus, txHash, retryFunc, chain, contractAddress }: Props) => {
  const title = useMemo(() => {
    const mapping: TxStatusTitleMapping = {
      [TxStatus.WAITING_WALLET]: `${translate('global.initiating_approval')}...`,
      [TxStatus.PENDING]: `${translate('global.submitting_transaction')}...`,
      [TxStatus.SUCCESS]: translate('global.success'),
      [TxStatus.ERROR]: translate('contracts_interactions.deploy_contract_fail'),
    }

    return mapping[txStatus]
  }, [txStatus])

  return (
    <ContractInteractionsTxModal
      image={image}
      txStatus={txStatus}
      txHash={txHash}
      chain={chain}
      title={title}
      SuccessContent={<ContractDeployModalSuccess contractAddress={contractAddress} chain={chain} />}
      retryFunc={retryFunc}
    />
  )
}
