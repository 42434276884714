import type { PerkAPIDocument } from '@rhinofi/rhino-nation-api-spec'
import { translate } from '../../../intl/i18n'
import { getStatStartDateValue } from './getStatStartDateValue'
import { getStatEndDateValue } from './getStatEndDateValue'
import { getRewardLabelForPerkType } from './getRewardLabelForPerkType'
import { hasDatePassed } from '../../../utils/hasDatePassed'

export const getPerkStatsItems = ({
  perk,
}: {
  perk: PerkAPIDocument
}): {
  id: string
  label: string
  value: string
  show: boolean
}[] => {
  const perkRewardLabel = getRewardLabelForPerkType(perk.type)

  return [
    {
      id: 'startDate',
      label: '',
      value: perk.startDate ? getStatStartDateValue(perk.startDate) : '',
      show: !!perk.startDate,
    },
    {
      id: 'endDate',
      label: perk.endDate && !hasDatePassed(perk.endDate) ? `${translate('nation.limited_time')}:` : '',
      value: perk.endDate ? getStatEndDateValue(perk.endDate) : '',
      show: !!perk.endDate,
    },
    {
      id: 'rewardType',
      label: `${translate('nation.reward_type')}:`,
      value: perkRewardLabel,
      show: perkRewardLabel !== '',
    },
  ]
}
