import { BigDecimal } from 'effect'
import { selectBridge } from '../slices/bridge.slice'
import { useAppSelector } from '../../../hooks'
import { useGetUserQuoteQuery } from '../../../store/apis/bridge.api'
import type { ConnectedQuotePayloadSchema, ConnectedQuoteResponseSchema } from '@rhinofi/bridge-api-spec'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { SerializedError } from '@reduxjs/toolkit'
import { isNonEVMChain } from '../../../services/helperService/isNonEVMChain'
import { selectIsAuthenticated, selectUserAddress } from '../../../reducers/userSelector'
import { selectSecondaryWalletAddress } from '../../../actions/selectors/secondaryWalletSelectors'

type UseUserQuoteInput = {
  mode?: ConnectedQuotePayloadSchema['mode']
  amount?: string
  gasBoostAmountNative?: string | undefined
  skip?: boolean
}

type UseUserQuoteOutput = {
  quote: ConnectedQuoteResponseSchema | undefined
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
}

export const useUserQuote = (
  { mode = 'receive', gasBoostAmountNative, skip = false }: UseUserQuoteInput = { mode: 'receive', skip: false },
): UseUserQuoteOutput => {
  const bridgeState = useAppSelector(selectBridge)
  const secondaryWalletAddress = useAppSelector(selectSecondaryWalletAddress)
  const address = useAppSelector(selectUserAddress)
  const isAuthenticated = useAppSelector(selectIsAuthenticated)

  const quoteAmount = bridgeState.amount
  const depositor = (isNonEVMChain(bridgeState.chainIn) ? secondaryWalletAddress : address) ?? ''
  const recipient =
    (bridgeState.recipient || (isNonEVMChain(bridgeState.chainOut) ? secondaryWalletAddress : address)) ?? ''
  const {
    data: userQuote,
    isError,
    error,
  } = useGetUserQuoteQuery(
    {
      path: {
        userId: address || '',
      },
      payload: {
        depositor,
        recipient,
        amount: BigDecimal.unsafeFromString(quoteAmount),
        chainIn: bridgeState.chainIn,
        chainOut: bridgeState.chainOut,
        token: bridgeState.token,
        mode,
        ...(gasBoostAmountNative && { amountNative: BigDecimal.unsafeFromString(gasBoostAmountNative) }),
      },
    },
    {
      skip:
        skip ||
        !isAuthenticated ||
        !depositor ||
        !quoteAmount ||
        quoteAmount.toString() === '0' ||
        !bridgeState.chainIn ||
        !bridgeState.chainOut ||
        !bridgeState.token,
    },
  )

  return { quote: !isError ? userQuote : undefined, isError, error }
}
