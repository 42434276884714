import { NotificationWrapper } from '../BottomCenterComms.styled'
import { translate } from '../../../intl/i18n'
import { TextWithLink } from '../../TextWithLink/TextWithLink'
import { envConfig } from '../../../env/envConfig'

export const LegacyPortalLink = () => {
  return (
    <NotificationWrapper>
      <TextWithLink spanWrapper text={() => translate('global.legacy_link')} href={envConfig.LEGACY_PORTAL_URL} />
    </NotificationWrapper>
  )
}
