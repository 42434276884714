import type { StringSchema } from 'yup'
import { string } from 'yup'
import { translate } from '../../../intl/i18n'
import { validateEthereumAddress } from '../../../services/helperService/validateEthereumAddress'
import { validateAddressByChain } from '../../../services/helperService/validateAddressByChain'

export const recipientAddressValidator =
  // prettier-ignore
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy
  (string() as StringSchema<
    string | undefined,
    {
      chainOut: string
      isOtherAddress: boolean
    },
    string | undefined
  >)
  .test('valid-address', translate('errors.invalid_recipient'), (address, { options }) => {
    const { chainOut, isOtherAddress } = options.context || {}

    if (!isOtherAddress) {
      return true
    }
    if (!chainOut) {
      return validateEthereumAddress(address)
    }

    return validateAddressByChain(chainOut, address)
  })
  .test(
    'required-address',
    translate('errors.field_is_required', { '%field': translate('helpers.address') }),
    (address, { options }) => {
      const { isOtherAddress } = options.context || {}
      if (isOtherAddress) {
        return !!address
      }

      return true
    },
  )
