import type EthereumProvider from '@walletconnect/ethereum-provider'
import { WalletType, type SupportedWallet } from '../wallet.types'
import type { WalletContextProps } from '../../../context/WalletContext'

export const isWalletConnect = (
  provider: WalletContextProps['provider'],
  walletType?: SupportedWallet,
): provider is EthereumProvider => {
  return walletType === WalletType.walletConnect && !!provider
}
