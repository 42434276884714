import lHas from 'lodash-es/has'
import lGet from 'lodash-es/get'
import lKeys from 'lodash-es/keys'
import lToLower from 'lodash-es/toLower'
import lReplace from 'lodash-es/replace'
import lCapitalize from 'lodash-es/capitalize'
import lIsEmpty from 'lodash-es/isEmpty'
import LescapeRegExp from 'lodash-es/escapeRegExp'
import { envConfig } from '../env/envConfig'
import type { I18nData } from './i18n.types'

/**
 * Handles translation missing terms gracefully with
 * a proper warning in the console
 *
 * @param {String} id : the missing dictionary term
 * @returns {String}
 */
const fallback = (id: string) => {
  if (lHas(window.I18nfallback, id)) {
    return lGet(window.I18nfallback, id)
  }

  return lCapitalize(lReplace(id, '.', ' '))
}

/**
 * replaces placeholders in the string with actual dynamic
 * values, provided in the custom data dictionary object
 *
 * @param {String} string : the template string
 * @param {Object} dictionary : the extended dictionary
 * @returns {String}
 */

const compile = (string = '', dictionary: Record<string, string | number> = {}) => {
  // eslint-disable-next-line functional/no-let -- Legacy
  let result = string

  lKeys(dictionary).forEach((field) => {
    const regex = new RegExp(LescapeRegExp(field), 'g')
    result = lReplace(result, regex, String(dictionary[field]))
  })

  return result
}

/**
 * it translates an id into a localized string from a
 * global dictionary object (window.I18n).
 * common placeholders will be automatically replaced.
 * you can provide extra terms with values, to extend the
 * dictionary, in the form of an object.
 *
 */
export const translate = <T extends keyof I18nData>(index: T, data?: I18nData[T]): string => {
  const id = lToLower(index)
  const string = lHas(window.I18n, id) ? lGet(window.I18n, id) : fallback(id)

  return lIsEmpty(data) || data === null ? string : compile(string, data)
}

export const dynamicT = (index: string, data?: Record<string, string | number>) => {
  const id = lToLower(index)
  const hasTranslation = lHas(window.I18n, id)

  if (!hasTranslation && envConfig.environment === 'dev') {
    console.error('Missing translation for: ', id)
  }

  const string = hasTranslation ? lGet(window.I18n, id) : fallback(id)

  return lIsEmpty(data) || data === null ? string : compile(string, data)
}

export const unsafeT = (key: string, data?: Record<string, unknown>) =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Legacy
  translate(key as keyof I18nData, data as I18nData[keyof I18nData])
