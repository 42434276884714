/* eslint-disable -- legacy */
import { connect } from 'react-redux'
import React from 'react'
import type { OwnTableProps } from './Table'
import Table from './Table'
import { mapStateToProps, mapDispatchToProps } from './mapReduxToTableProps'

const Component = connect(mapStateToProps, mapDispatchToProps)(Table)

const GenericTableConnected = <T>(props: OwnTableProps<T>) => React.createElement(Component, props as any)

export default GenericTableConnected
