import { Text } from '@rhinofi/dvf-shared-ui'
import type { PerkAPIDocument } from '@rhinofi/rhino-nation-api-spec'
import { FlexContainer } from '../../../../../../../components/common/Wrappers/Containers'
import { translate } from '../../../../../../../intl/i18n'

type Props = {
  perk: PerkAPIDocument
}

export const NationPerkModalDescription = ({ perk: { description } }: Props) => {
  if (description === null) {
    return null
  }

  return (
    <FlexContainer $direction="column" $alignItems="flex-start">
      <Text weight={600}>{translate('helpers.description')}</Text>
      <Text id="perk-modal-description-text">{description}</Text>
    </FlexContainer>
  )
}
