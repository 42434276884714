import styled, { css } from 'styled-components'
import { zIndices } from '../../constants/zIndex'

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  margin: 0 auto;
  z-index: ${zIndices.mobileHeader};
  transition: all 0.5s ease-in-out;
`

export const TabBar = styled.div<{ $isTrayOpen: boolean }>`
  position: relative;
  background-color: ${({ theme }) => theme.cardBackground};
  box-sizing: border-box;
  width: 100%;
  display: flex;
  transition: height 0.3s ease-in-out;
  height: 80px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.divider};
  cursor: pointer;

  ${({ $isTrayOpen }) =>
    $isTrayOpen
      ? css`
          border-radius: 0 0 16px 16px;
          box-shadow: none;
        `
      : ''}
`

export const Tab = styled.div<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  flex: 1;

  &:active {
    .header-tab-title {
      color: ${({ theme }) => theme.primaryDark};
    }
    .rhino-icon {
      color: ${({ theme }) => theme.primaryDark};
    }
  }
  ${({ $isActive }) =>
    $isActive
      ? css`
          .header-tab-title {
            color: ${({ theme }) => theme.primaryDark};
          }
          .rhino-icon {
            color: ${({ theme }) => theme.primaryDark};
          }
        `
      : ''}
`

export const TrayTab = styled(Tab).attrs({ as: 'div' })``

export const IconWrapper = styled.div`
  position: relative;
  height: 30px;
  display: flex;
  align-items: center;
`

export const MobileHeaderContainer = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`

export const MobileNavLogo = styled.img`
  width: 44px;
`
