import { envConfig } from '../../../env/envConfig'
import type { GmContractChainConfig } from '../types/contractsInteractions.types'

export const getGmContractChainConfig = (chain: string): GmContractChainConfig => {
  const config: Record<string, GmContractChainConfig> = envConfig.CONTRACTS_INTERACTIONS_CHAINS_CONFIG
  const chainConfig = config[chain]

  if (!chainConfig) {
    throw new Error(`GM contract config not configured for chain: ${chain}`)
  }

  return chainConfig
}
