import { memo } from 'react'
import { animated, useSpring } from '@react-spring/web'
import styled from 'styled-components'

type NumberCounterProps = {
  start: number
  end: number
  duration: number
}

export const AnimatedNumberCounter = memo(({ start, end, duration }: NumberCounterProps) => {
  const props = useSpring({
    from: { number: start },
    to: { number: end },
    config: { duration },
  })

  return <AnimatedCounter>{props.number.to((num) => Math.floor(num))}</AnimatedCounter>
})

const AnimatedCounter = styled(animated.div)`
  font-family: inherit;
`
