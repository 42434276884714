import { ContractInteractionTabId } from '../types/contractsInteractions.types'
import type { NameServices } from '../../../services/nameServices/nameServices'

export const CONTRACTS_INTERACTIONS_EARN_CARD_ITEMS_LIMIT = 3
export const CONTRACTS_INTERACTIONS_POPULAR_NEW_LIMIT = 5
export const CONTRACTS_INTERACTIONS_HISTORY_LIMIT = 10
export const CONTRACTS_INTERACTIONS_YOUR_LIMIT = 10

export const CONTRACTS_INTERACTIONS_LS_KEY_SELECTED_CHAIN = 'contracts-interactions-chain'

export const CHAIN_TWITTER_HANDLE_MAPPING: Record<string, string> = {
  SCROLL: '@Scroll_ZKP',
  LINEA: '@LineaBuild',
  ZKEVM: 'Polygon zkEVM',
  BASE: '@base',
}

export const CHAIN_NS_MAPPING: Record<string, NameServices> = {}

export const CI_POPULAR_TAB_IDS = [ContractInteractionTabId.POPULAR_ALL_TIME, ContractInteractionTabId.POPULAR_WEEKLY]

// Specific in order to be the same height as the rendered table when 5 elements are loaded
// Makes the page not jump when table tada is loaded for the first time
export const CI_INTERACTIONS_TABLES_LOADER_MIN_HEIGHT = 363
