import styled from 'styled-components'
import { MenuContent } from './MenuContent'
import { useAppSelector } from '../../hooks'
import { zIndices } from '../../constants/zIndex'
import { ConnectedWallets } from '../ConnectWallet/ConnectedWallets/ConnectedWallets'
import { selectAddress } from '../../actions/selectors/userSelectors'
import { Dropdown } from '../Dropdown/Dropdown'
import { Icon, IconSizes } from '@rhinofi/dvf-shared-ui'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.strokeDivider};
  border-radius: 8px;
  padding: 12px 16px;
  cursor: pointer;
  height: 44px;
  box-sizing: border-box;

  &:hover {
    background: #0000000a;
  }
`

export const Menu = () => {
  const address = useAppSelector(selectAddress)
  return address ? (
    <Dropdown
      element={
        <Wrapper>
          <ConnectedWallets />
          <Icon className="dropdown-caret" id="caret-down-fill" size={IconSizes.XSmall} />
        </Wrapper>
      }
      triggerOnClick
      clampRight
      fullWidth
      zIndex={zIndices.threeDotsMenu}
      propsToChildren
    >
      <MenuContent />
    </Dropdown>
  ) : (
    <ConnectedWallets />
  )
}
