import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../../../store/configureStore'

export type BridgeState = {
  chainIn: string
  chainOut: string
  token: string
  amount: string
  recipient: string
  isOtherAddress: boolean
}

export const initialState: BridgeState = {
  chainIn: 'TAIKO',
  chainOut: 'SCROLL',
  token: 'USDT',
  amount: '',
  recipient: '',
  isOtherAddress: false,
}

export const bridgeSlice = createSlice({
  name: 'bridge',
  initialState,
  reducers: {
    setChainIn(state, action: PayloadAction<string>) {
      state.chainIn = action.payload
    },
    setChainOut(state, action: PayloadAction<string>) {
      state.chainOut = action.payload
    },
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload
    },
    setAmount(state, action: PayloadAction<string>) {
      state.amount = action.payload
    },
    setRecipient(state, action: PayloadAction<string>) {
      state.recipient = action.payload
    },
    setIsOtherAddress(state, action: PayloadAction<boolean>) {
      state.isOtherAddress = action.payload
    },
    setBridgeState(state, action: PayloadAction<BridgeState>) {
      Object.assign(state, action.payload)
    },
    switchChains(state) {
      ;[state.chainIn, state.chainOut] = [state.chainOut, state.chainIn]
    },
    resetBridge(state) {
      Object.assign(state, initialState)
    },
  },
})

export const {
  setChainIn,
  setChainOut,
  setToken,
  setAmount,
  setRecipient,
  setIsOtherAddress,
  setBridgeState,
  switchChains,
  resetBridge,
} = bridgeSlice.actions

// Selectors
export const selectBridge = (state: RootState) => state.bridge
