import { FlexContainer } from '../../../../../../../components/common/Wrappers/Containers'
import type { PerkWithUserData } from '../../../../../types/nation.types'
import { NationPerkModalStats } from '../NationPerkModalStats/NationPerkModalStats'
import { NationPerkModalCardGemPrice } from '../NationPerkModalCardGemPrice/NationPerkModalCardGemPrice'

type Props = {
  data: PerkWithUserData
  claimedOn?: Date
}

export const NationPerkModalContent = ({ data: { perk } }: Props) => (
  <FlexContainer $gap="16px" $direction="column" $alignItems="stretch">
    <NationPerkModalStats perk={perk} />
    <NationPerkModalCardGemPrice perk={perk} />
  </FlexContainer>
)
