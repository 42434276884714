import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import { selectSecondaryWalletAddressAndChain } from '../../actions/selectors/secondaryWalletSelectors'
import {
  connectSecondaryWallet,
  secondaryWalletSlice,
  updateSecondaryAddress,
  updateSecondaryNetworkId,
} from '../../reducers/secondaryWalletSlice'
import { selectUserAddress } from '../../reducers/userSelector'
import { tryDisconnectSecondaryWallet } from '../../services/secondaryWallet/tryDisconnectSecondaryWallet'
import type { AppDispatch, RootState } from '../configureStore'
import { selectBridgeConfigForChain } from '../selectors/bridgeConfig.selectors'
import { fetchBlockChainBalanceForChain } from '../../actions/userActions/userActions'

export const secondaryWalletMiddleware = createListenerMiddleware()

secondaryWalletMiddleware.startListening.withTypes<RootState, AppDispatch>()({
  matcher: isAnyOf(
    updateSecondaryAddress.match,
    updateSecondaryNetworkId.match,
    connectSecondaryWallet.fulfilled.match,
  ),
  // eslint-disable-next-line @typescript-eslint/unbound-method -- noise
  effect: (_action, { dispatch, getState }) => {
    const secondaryWallet = selectSecondaryWalletAddressAndChain(getState())
    const address = selectUserAddress(getState())
    const chainConfig = selectBridgeConfigForChain(getState(), secondaryWallet.connectedChain)
    if (!address || !chainConfig) {
      return
    }
    void fetchBlockChainBalanceForChain(dispatch)({
      chain: secondaryWallet.connectedChain,
      address,
      secondaryWallet,
      chainConfig,
    })
  },
})

secondaryWalletMiddleware.startListening({
  matcher: secondaryWalletSlice.actions.disconnectSecondaryWallet.match,
  effect: () => {
    tryDisconnectSecondaryWallet()
  },
})
