import { useEffect, useMemo } from 'react'

import type { ABTestExperimentId } from '../services/ABTestService'
import { addABTestVariantUserPropertiesHeapOnAuthenticated, getABTestVariant } from '../services/ABTestService'
import { selectIsAuthenticated } from '../reducers/userSelector'
import { useAppSelector } from './useAppSelector'

export const useABTest = (experimentId: ABTestExperimentId) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const variant = useMemo(() => getABTestVariant(experimentId), [experimentId])
  console.error('implement useABTest to heap connection')
  // TODO FUD-1472: add heap tracking
  useEffect(() => {
    if (isAuthenticated) {
      addABTestVariantUserPropertiesHeapOnAuthenticated(experimentId, variant)
    }
  }, [isAuthenticated, experimentId, variant])

  return variant
}
