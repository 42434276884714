import { usePublicQuote } from './usePublicQuote'
import { useUserQuote } from './useUserQuote'
import { useAppSelector } from '../../../hooks'
import { selectIsAuthenticated } from '../../../reducers/userSelector'
import type { PublicQuoteQuerySchema } from '@rhinofi/bridge-api-spec'

type UseQuoteInput = {
  mode?: PublicQuoteQuerySchema['mode']
  gasBoostAmountNative?: string
}

export const useQuote = (
  { mode = 'receive', gasBoostAmountNative }: UseQuoteInput = {
    mode: 'receive',
  },
) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated)

  const publicQuote = usePublicQuote({ mode, gasBoostAmountNative, skip: isAuthenticated })
  const userQuote = useUserQuote({ mode, gasBoostAmountNative, skip: !isAuthenticated })

  return isAuthenticated ? userQuote : publicQuote
}
