import moment from 'moment'
import { translate } from '../../../intl/i18n'
import { hasDatePassed } from '../../../utils/hasDatePassed'

export const getStatStartDateValue = (date: Date) => {
  const hasStarted = hasDatePassed(date)

  const dateMoment = moment(date)

  const args = {
    '%time': `@${dateMoment.format('HH:mm')}`,
    '%date': dateMoment.format('DD/MM/YYYY'),
  }

  return hasStarted ? translate('nation.started_on', args) : translate('nation.starts_on', args)
}
