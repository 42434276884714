import { Schema } from 'effect'

export const NFTContractTypeEnum = {
  NFTMinter: 'NFTMinter',
  NFTReward: 'NFTReward',
  RhinoFiWrappedNFT: 'RhinoFiWrappedNFT',
  RhinoERC1155: 'RhinoERC1155',
} as const
// eslint-disable-next-line no-redeclare -- this is a type
export type NFTContractTypeEnum = typeof NFTContractTypeEnum

export const NFTContractTypeSchema = Schema.Enums(NFTContractTypeEnum)
export type NFTContractType = typeof NFTContractTypeSchema.Type

export const SingleNFTConfigSchema = Schema.Struct({
  type: NFTContractTypeSchema,
  address: Schema.String,
})
export type SingleNFTConfig = typeof SingleNFTConfigSchema.Type

export const ERC1155SingleNFTConfigSchema = Schema.extend(
  SingleNFTConfigSchema,
  Schema.Struct({
    tokenId: Schema.Number,
    reducedFeesContract: Schema.optional(Schema.String),
    reducedFeesTokenId: Schema.optional(Schema.Number),
  }),
)
export type ERC1155SingleNFTConfig = typeof ERC1155SingleNFTConfigSchema.Type

export const NFTConfigSchema = Schema.Record({
  key: Schema.String,
  value: Schema.Record({
    key: Schema.String,
    value: SingleNFTConfigSchema,
  }),
})
