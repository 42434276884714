import type { NftType as MissionNftType } from '@rhinofi/rhino-nation-api-spec'
import { NETWORKS } from '../../../constants/types'
import type { NFTMinter } from '../types/nfts.types'
import { getNFTImage } from './getNFTImage'
import { getNFTContractConfig } from './nftContractHelpers'
import { safeGetChainName } from '../../../utils/getChainName'
import type { NFTType } from '../types/mintNFT.types'
import type { BridgeConfig } from '../../../context'

const getNFTName = (type: NFTType, chain: string, config?: BridgeConfig) => {
  if (type === 'hunter' && chain === NETWORKS.INK) {
    return `Ink Genesis`
  }
  const chainToName = safeGetChainName(config)
  switch (type) {
    case 'wrapped2023':
      return 'rhino.fi Wrapped 2023'
    case 'hunter':
      return chain ? `rhino.fi ${chainToName(chain)} Hunter` : 'rhino.fi Hunter'
    case 'TOP30':
      return chain ? `rhino.fi ${chainToName(chain)} Pro Hunter` : 'rhino.fi Pro Hunter'
    case 'VOLUME':
      return chain ? `${chainToName(chain)} Trader` : 'Trader'
    case 'TXCOUNT':
      return chain ? `${chainToName(chain)} Frequent Flyer` : 'Frequent Flyer'
    case 'ALPHAKEY':
      return 'Rhino Alpha Key'
    case 'BETAKEY':
      return 'Rhino Passport'
    default:
      return ''
  }
}

export const getNftNameForMissionNFTType = ({ nftType, chain }: { nftType: MissionNftType; chain: string }) => {
  if (nftType === 'HUNTER') {
    return getNFTName('hunter', chain)
  }

  return getNFTName(nftType, chain)
}

export const getNFTMetaData = (type: NFTType, chain: string, tokenId: number, index?: number): NFTMinter => ({
  name: getNFTName(type, chain),
  image: getNFTImage(type, chain),
  contractType: getNFTContractConfig(chain, type).type,
  tokenId,
  chain,
  type,
  index,
})
