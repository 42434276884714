import styled from 'styled-components'
import { Icon } from '@rhinofi/dvf-shared-ui'

export const Divider = styled.div`
  width: 1px;
  background: ${({ theme }) => theme.offWhite};
`

export const CustomIcon = styled(Icon)`
  display: flex;
`
