import React, { Suspense } from 'react'
import type { LottieProps } from './Lottie.types'

const Confetti = React.lazy(() =>
  import('./StaticLottieConfetti').then((module) => ({ default: module.StaticLottieConfetti })),
)

export const DynamicLottieConfetti = (props: LottieProps) => {
  return (
    <Suspense fallback={null}>
      <Confetti {...props} />
    </Suspense>
  )
}
