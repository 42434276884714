import { getStarknetProvider } from '../../providers/starknetProvider'

export const disconnectStarknetWallet = async () => {
  try {
    const { disconnect } = await import('starknetkit')
    const starknetProvider = getStarknetProvider()
    if (starknetProvider) {
      await disconnect({ clearLastWallet: true })
    }
    // eslint-disable-next-line no-unused-vars -- fail silently
  } catch (error) {
    /* empty */
  }
}
