import { Fragment } from 'react'
import styled, { keyframes } from 'styled-components'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import { Text } from '@rhinofi/dvf-shared-ui'
import { FlexContainer } from '../Wrappers/Containers'
import RhinoSolo from '../assets/logo-light.svg?react'

type AppLoaderProps = {
  showOnPage?: boolean
  showInApp?: boolean
  source?: string
  showLogo?: boolean
}

export const AppLoader = ({ showOnPage, showInApp, source, showLogo }: AppLoaderProps) => {
  const Wrapper = showOnPage || showInApp ? LoaderWrapper : Fragment
  return (
    <Wrapper key={source} $showInApp={showInApp} $showOnPage={showOnPage} className={source}>
      <FlexContainer $direction="column" $fullWidth>
        {showLogo && <RhinoSolo />}
        <MiniLoaderWrapper>
          <MiniPerpetualLoader />
        </MiniLoaderWrapper>
        {showLogo && (
          <Text className="loader-text" bold>
            Bridging made easy
          </Text>
        )}
      </FlexContainer>
    </Wrapper>
  )
}

type LoaderWrapperProps = {
  $showInApp?: boolean | undefined
  $showOnPage?: boolean | undefined
}

const MiniLoaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.elevationL2}99;
  border-radius: 8px;
  padding: 2px;
  width: 100%;
  max-width: 152px;
  position: relative;
  overflow: hidden;
  height: 12px;
  margin: 8px 0;
  box-sizing: border-box;
`

const loaderAnimation = keyframes`
  0% {
    left: -160px;
  }
  100% {
    left: 100%;
  }
`

const MiniPerpetualLoader = styled.div`
  position: absolute;
  left: -100%;
  height: 8px;
  width: 64px;
  background: ${({ theme }) => theme.brand};
  border-radius: 8px;
  animation: ${loaderAnimation} infinite;
  animation-delay: 1s;
  animation-timing-function: cubic-bezier(0.52, 0.89, 0.82, 0.36);
  animation-duration: 1800ms;
`

const LoaderWrapper = styled.div<LoaderWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ $showInApp }) => ($showInApp ? '100%' : '100vh')};
  width: ${({ $showInApp }) => ($showInApp ? '100%' : '100vw')};
  background: ${({ $showOnPage, theme }) => ($showOnPage ? theme.pageBackground : '')};
  margin: auto;

  .loader-text {
    font-family: ${({ theme }) => theme.accentFont};
  }

  @media ${respondBelow('xxs')} {
    height: 100vh;
  }
`
