import { Title } from '@rhinofi/dvf-shared-ui'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import styled, { keyframes } from 'styled-components'
import GemIcon from '../../../../../../../assets/icons/gem.svg?react'
import { FlexContainer } from '../../../../../../../components/common/Wrappers/Containers'

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

export const FadeInText = styled.span<{ duration: number; delay: number }>`
  display: flex;
  font-family: inherit;
  align-items: baseline;
  gap: 24px;
  font-size: inherit;
  white-space: nowrap;
  justify-content: space-between;
  animation: ${fadeIn} ${({ duration }) => duration}ms ease-in;
  animation-delay: ${({ delay }) => delay}ms;
  animation-fill-mode: both;
  padding: 4px 8px;
  @media ${respondBelow('xxs')} {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
`

export const CustomTitle = styled(Title)`
  display: flex;
  align-items: baseline;
  white-space: break-spaces;
  min-width: 621px;
  justify-content: space-between;

  @media ${respondBelow('xs')} {
    min-width: unset;
    flex-direction: column;
    align-items: center;
  }
`

export const RxpSpan = styled.span<{ fontSize?: string }>`
  display: flex;
  align-items: flex-end;
  height: 100%;
  color: ${({ theme }) => theme.secondary60};
  font-family: ${({ theme }) => theme.accentFont};
  font-size: ${({ fontSize }) => fontSize ?? '28px'};
  font-style: normal;
  font-weight: 700;
  white-space: nowrap;
`

export const EarnedTitleSpan = styled.span`
  display: flex;
  align-items: baseline;
  font-family: inherit;
  line-height: normal;

  @media ${respondBelow('xs')} {
    min-width: 280px;
    & > :not(:last-child) {
      margin: auto;
    }
    & > :last-child {
      margin-left: auto;
    }
  }
`

export const EarnedItemContainer = styled.div<{ fontSize?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.secondary};
  font-family: ${({ theme }) => theme.accentFont};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  white-space: break-spaces;
  @media ${respondBelow('xs')} {
    white-space: wrap;
    font-size: 16px;
    align-self: flex-end;
  }
`

export const EarnedSpan = styled.span<{ fontSize?: string }>`
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  white-space: break-spaces;
  min-width: 140px;
  font-family: ${({ theme }) => theme.accentFont};
  @media ${respondBelow('xxs')} {
    min-width: unset;
  }
`

export const EarnedGemsImage = styled(GemIcon)`
  display: flex;
  align-self: center;
`

export const MissionItemsContainer = styled(FlexContainer)`
  flex-direction: column;
  gap: 12px;
  max-height: 310px;
  overflow-y: auto;
`
