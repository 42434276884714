import { Buffer } from 'buffer'

export const getSolanaBridgeKey = async (bridgeContractAddress: string) => {
  const { PublicKey } = await import('@solana/web3.js')
  const programId = new PublicKey(bridgeContractAddress)
  return PublicKey.findProgramAddressSync([Buffer.from('rhino_bridge')], programId)[0]
}

export const getSolanaPoolKey = async (bridgeContractAddress: string, mint: string) => {
  const { PublicKey } = await import('@solana/web3.js')
  const programId = new PublicKey(bridgeContractAddress)
  const bridgeKey = await getSolanaBridgeKey(bridgeContractAddress)
  const mintId = new PublicKey(mint)
  return PublicKey.findProgramAddressSync([bridgeKey.toBuffer(), mintId.toBuffer()], programId)[0]
}

export const getSolanaPoolAuthority = async (bridgeContractAddress: string, mint: string) => {
  const { PublicKey } = await import('@solana/web3.js')
  const programId = new PublicKey(bridgeContractAddress)
  const bridgeKey = await getSolanaBridgeKey(bridgeContractAddress)
  const mintId = new PublicKey(mint)
  return PublicKey.findProgramAddressSync(
    [bridgeKey.toBuffer(), mintId.toBuffer(), Buffer.from('authority')],
    programId,
  )[0]
}

export const getSolanaPoolTokenAccount = async (bridgeContractAddress: string, mint: string) => {
  const { PublicKey } = await import('@solana/web3.js')
  const { getAssociatedTokenAddressSync } = await import('@solana/spl-token')
  const poolAuthority = await getSolanaPoolAuthority(bridgeContractAddress, mint)
  const mintId = new PublicKey(mint)
  return getAssociatedTokenAddressSync(mintId, poolAuthority, true)
}

export const getSolanaDepositorAccount = async (secondaryWalletAddress: string, mint: string) => {
  const { PublicKey } = await import('@solana/web3.js')
  const { getAssociatedTokenAddressSync } = await import('@solana/spl-token')
  const mintId = new PublicKey(mint)
  const depositor = new PublicKey(secondaryWalletAddress)
  return getAssociatedTokenAddressSync(mintId, depositor, true)
}

export const formatEthAddressForSolana = (depositEthAddress: string) => {
  const rawHex = depositEthAddress.toLowerCase().replace('0x', '')
  const splitIndex = rawHex.length - 32 // 32 is size of uint128
  const firstPart = rawHex.slice(0, splitIndex)
  const secondPart = rawHex.slice(splitIndex)
  return { firstPart, secondPart }
}
