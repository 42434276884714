import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { showModal } from '../../../actions/modalActions/showModal'
import { modalKeys } from '../../../constants/modalKeys'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { publicRoutes } from '../../../router'

type Props = {
  perkId: string
}

export const useWidgetPerkOpenModal = ({ perkId }: Props) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  return useCallback(() => {
    navigate(publicRoutes.nation)
    showModal(dispatch)(modalKeys.nationActivePerk, {
      perkId,
      perkDataSource: 'single',
    })
  }, [dispatch, navigate, perkId])
}
