import { NETWORKS } from '../../../../constants/types'

export const gasTimeConf = {
  [NETWORKS.SCROLL]: {
    nativeFee: 0.00446,
    timeSeconds: 1200,
  },
  [NETWORKS.BASE]: {
    nativeFee: 0.00604,
    timeSeconds: 120,
  },
  [NETWORKS.ARBITRUM]: {
    nativeFee: 0.00315,
    timeSeconds: 600,
  },
  [NETWORKS.ZKSYNC]: {
    nativeFee: 0.00404,
    timeSeconds: 600,
  },
  [NETWORKS.LINEA]: {
    nativeFee: 0.00175,
    timeSeconds: 600,
  },
  [NETWORKS.MANTA]: {
    nativeFee: 0.00651,
    timeSeconds: 600,
  },
  [NETWORKS.ARBITRUMNOVA]: {
    nativeFee: 0.00242,
    timeSeconds: 600,
  },
  [NETWORKS.BINANCE]: {
    nativeFee: 0.00106,
    timeSeconds: 240,
  },
  [NETWORKS.OPTIMISM]: {
    nativeFee: 0.00341,
    timeSeconds: 60,
  },
  [NETWORKS.MATIC_POS]: {
    nativeFee: 0.0086,
    timeSeconds: 1800,
  },
  [NETWORKS.ZKEVM]: {
    nativeFee: 0.00446,
    timeSeconds: 900,
  },
}
