import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Button, Spacing, Text, TokenIcon } from '@rhinofi/dvf-shared-ui'
import { translate } from '../../../intl/i18n'
import { useAppSelector } from '../../../hooks'
import { makeRouteTo, publicRoutes } from '../../../router'
import { isSafariIOS } from '../../../services/helperService'
import { shortenAddress } from '../../../services/helperService/shortenAddress'
import { Copy } from '../../common/Helpers/Copy'
import { FlexContainer } from '../../common/Wrappers/Containers'
import type { TraySubLink } from '../MobileNavigation.types'
import { TrayLink, TrayLinksSection, TrayRow } from './TrayLink'
import { selectConnectedNetwork, selectUserAddress } from '../../../reducers/userSelector'
import { showModal } from '../../../actions/modalActions/showModal'
import { modalKeys } from '../../../constants/modalKeys'
import { selectBridgeConfig } from '../../../store/apis/config.api'
import { findBridgeKeyForNetworkId } from '../../../services/helperService/findBridgeKeyForNetworkId'
import { useWallet } from '../../../hooks/useWallet'
import { RhinoNationBalance } from '../../../pages/Nation/components/NationContent/RhinoNationBalance/RhinoNationBalance'
import { DEFAULT_FEATURE_CHAIN } from '../../../pages/Chain/constants/activityTrackers.constants'
import { NoUnderlineLink } from '../../common/Helpers/LinkHelpers'

const moreTrayLinks: TraySubLink[][] = [
  [
    {
      id: 'mint-nfts',
      title: translate('helpers.mint_nfts'),
      path: makeRouteTo.nftMinter({ chain: DEFAULT_FEATURE_CHAIN }),
      icon: 'image',
    },
    {
      id: 'history',
      title: translate('helpers.history'),
      path: makeRouteTo.history(),
      icon: 'receipt',
    },
  ],
  [
    // TODO DOPE-422 - Integrate trackers
    // {
    //   id: 'trackers',
    //   title: translate('helpers.trackers'),
    //   path: makeRouteTo.analytics(DEFAULT_FEATURE_CHAIN),
    //   icon: 'eye',
    // },
  ],
  [
    {
      id: 'website',
      title: translate('helpers.website'),
      icon: 'globe',
      href: 'https://rhino.fi/',
      isExternal: true,
    },
    {
      id: 'telegran',
      title: 'Telegram',
      icon: 'telegram',
      href: 'https://t.me/rhinofiannouncements',
      isExternal: true,
    },
    {
      id: 'discord',
      title: 'Discord',
      icon: 'discord',
      href: 'https://discord.gg/26sXx2KAhy',
      isExternal: true,
    },
    {
      id: 'twitter',
      title: 'X (Twitter)',
      icon: 'twitter-x',
      href: 'https://twitter.com/rhinofi',
      isExternal: true,
    },
  ],
  [
    {
      id: 'support',
      title: translate('helpers.support'),
      action: 'support',
      icon: 'chat-left-heart',
      isExternal: true,
    },
    {
      id: 'disconnect',
      title: translate('helpers.disconnect'),
      action: 'disconnect',
      icon: 'power',
    },
  ],
]

type Props = {
  closeTray: () => void
  showChat: () => void
}

export const MoreTray = React.forwardRef<HTMLDivElement, Props>(({ closeTray, showChat }, ref) => {
  const dispatch = useDispatch()
  const walletAddress = useAppSelector(selectUserAddress)
  // const nsName = useAppSelector(selectNameServiceName)
  const network = useAppSelector(selectConnectedNetwork)

  const walletProvider = useWallet()

  const showCrispChat = () => {
    // Open iframe in a new window as iOS safari doesn't support iframes with cross-origin cookies
    if (isSafariIOS()) {
      return window.open(
        `https://go.crisp.chat/chat/embed/?website_id=598d8062-c46b-40e7-a7cd-0bf2061390a5&user_nickname=${
          walletAddress || ''
        }-${walletProvider?.connectingWallet?.walletType || 'default'}`,
      )
    }
    showChat()
    return closeTray()
  }

  const handleActionClick = (action: TraySubLink['action']) => (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    closeTray()

    switch (action) {
      case 'support': {
        showCrispChat()
        return
      }
      case 'disconnect': {
        void walletProvider.disconnect()
        return
      }
    }
  }

  // hide the last row with disconnect button if user is not connected
  const moreTrayRows = walletAddress ? moreTrayLinks : moreTrayLinks.slice(0, moreTrayLinks.length - 1)
  const bridgeConfig = useAppSelector(selectBridgeConfig)
  const chainID = findBridgeKeyForNetworkId(network?.toString() || '', bridgeConfig) || ''

  return (
    <TrayWrapper ref={ref}>
      <TrayHandle />
      {walletAddress ? (
        <WalletPreview $fullWidth $gap="8px">
          <TokenIcon token={chainID} />
          <Copy preventDefault text={walletAddress}>
            <Text>{shortenAddress(walletAddress, false, 5)}</Text>
          </Copy>
        </WalletPreview>
      ) : (
        <>
          <Button
            id="mobile-tray-connect"
            fullWidth
            onClick={() => {
              closeTray()
              showModal(dispatch)(modalKeys.connectWallet)
            }}
          >
            {translate('onboarding.connect_wallet')}
          </Button>
          <Spacing size="16" />
        </>
      )}
      <NoUnderlineLink to={publicRoutes.nation} onClick={closeTray}>
        <RhinoNationBalance showRxp />
      </NoUnderlineLink>
      <TrayLinksSection>
        {moreTrayRows.map((links, index) => (
          <TrayRow key={index}>
            {links.map((link) => (
              <TrayLink
                key={link.id}
                handleActionClick={link.action ? handleActionClick(link.action) : closeTray}
                {...link}
              />
            ))}
          </TrayRow>
        ))}
      </TrayLinksSection>
    </TrayWrapper>
  )
})

const TrayHandle = styled.div`
  height: 2px;
  background: ${({ theme }) => theme.disabled};
  border-radius: 2px;
  width: 56px;
  margin: 0 auto 8px auto;
`

const TrayWrapper = styled.div`
  background: ${({ theme }) => theme.elevationL1};
  border-radius: 16px 16px 0 0;
  padding: 8px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.32);
  text-align: center;
  transform: translateY(0);
  transition: box-shadow 0.3s ease-in;

  &:before {
    content: '';
    position: absolute;
    top: 40px;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${({ theme }) => theme.elevationL1};
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  &.mobile-transition-enter {
    transform: translateY(100%);
  }

  &.mobile-transition-enter-active,
  &.mobile-transition-exit {
    transform: translateY(0);
  }

  &.mobile-transition-exit-active {
    transform: translateY(100%);
  }

  &.mobile-transition-enter-active,
  &.mobile-transition-exit-active {
    transition: transform 300ms cubic-bezier(0.91, 0.8, 0.53, 1.13);
  }
`

const WalletPreview = styled(FlexContainer)`
  margin-bottom: 16px;

  #disconnect-mobile i {
    color: ${({ theme }) => theme.errorMain};
  }
`
