import { defaultThreshold } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import { useResize } from '../context/resize/useResize'

type WidthThresholds = {
  desktop?: number
  mobile?: number
}

export const useIsMobile = (
  { desktop = defaultThreshold.xl, mobile = defaultThreshold.xxs }: WidthThresholds = {
    desktop: defaultThreshold.xl,
    mobile: defaultThreshold.xxs,
  },
) => {
  const { width } = useResize()

  return { isDesktop: width >= desktop, isMobile: width <= mobile, width }
}
