import type { BaseQueryApi } from '@reduxjs/toolkit/query'
import type { RootState } from '../../store/configureStore'

export const getJWTTokenFromState = (api: Pick<BaseQueryApi, 'getState' | 'endpoint'>): string => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Due redux limitations we need to assert the type, we know its RootState.
  const authData = (api.getState() as RootState).user.auth.data

  if (!authData) {
    throw new Error(`Auth data is missing, ${api.endpoint}`)
  }
  return authData.jwt
}
