/* eslint-disable @typescript-eslint/naming-convention -- names required by sendinblue*/
export const subscribeSendInBlueEmail = (formId: number, email: string) => {
  const sendInBlueUrl = `https://my.sendinblue.com/users/subscribeembed/js_id/2v4yx/id/${formId}`
  const sendInBlueParams: Record<string, string> = {
    primary_type: 'email',
    js_id: '2v4yx',
    from_url: 'yes',
    hdn_email_txt: new Date().getTime().toString(),
    req_hid: '',
    sib_simple: 'simple',
    email,
  }
  const searchParams = Object.keys(sendInBlueParams)
    .map((key) => {
      const value = sendInBlueParams[key]
      if (value) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(value)
      }
      return null
    })
    .filter((param) => param !== null)
    .join('&')
  return fetch(sendInBlueUrl, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: searchParams,
  })
}
