import { TextSize, Text, Title } from '@rhinofi/dvf-shared-ui'
import type { Color } from '@rhinofi/dvf-shared-ui'
import { translate } from '../../../../../intl/i18n'
import type { NationContentLoadingErrorTextSize } from '../../../types/nationContentLoadingErrorText'

type Props = {
  color?: Color | undefined
  text: string | number
  loading: boolean
  error: boolean
  size?: NationContentLoadingErrorTextSize | undefined
}

export const NationContentLoadingErrorText = ({
  color = 'secondary',
  text,
  loading,
  error,
  size = { type: 'text', value: TextSize.M },
}: Props) => {
  const finalColor = error && !loading ? 'accentRed' : color
  const formattedText = loading ? translate('helpers.loading') : error ? translate('global.error') : text

  if (size.type === 'title') {
    return (
      <Title size={size.value} color={finalColor}>
        {formattedText}
      </Title>
    )
  }

  if (size.type === 'text') {
    return (
      <Text size={size.value} weight={400} color={finalColor}>
        {formattedText}
      </Text>
    )
  }

  return null
}
