import { defaultThreshold } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import { createContext } from 'react'

type ResizeContextProps = {
  width: number
  isMobile: boolean
  isDesktop: boolean
}

export const ResizeContext = createContext<ResizeContextProps>({
  width: window.innerWidth,
  isMobile: window.innerWidth < defaultThreshold.xxs,
  isDesktop: window.innerWidth >= defaultThreshold.m,
})
