/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-assignment -- starknet lib issue */
import type { RpcProvider } from 'starknet'
import Decimal from 'decimal.js'
import { NETWORKS } from '../../../../constants/types'
import type { MulticallBalances } from '../../../ethereum/multicall'
import { getPublicStarknetProvider } from '../../providers/starknetProvider'
import { getParadexProvider } from '../../providers/paradexProvider'
import starknetMulticallABi from './abis/starknetMulticallABI.json'
import type { BridgeTokenEntryArray } from '../../../helperService/tokensToArray'

const pickStarknetProvider = (starknetChain: string) => {
  if (starknetChain === NETWORKS.STARKNET) {
    return getPublicStarknetProvider()
  } else if (starknetChain === NETWORKS.PARADEX) {
    const { provider } = getParadexProvider()
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- paradex provider
    return provider as unknown as RpcProvider
  }

  throw new Error(`No provider for ${starknetChain}`)
}

export const getStarknetBalances = async (
  secondaryWalletAddress: string,
  tokens: BridgeTokenEntryArray,
  starknetChain: string,
  multicallContractAddress: string | undefined,
) => {
  if (!secondaryWalletAddress || !tokens) {
    return {}
  }
  try {
    const { CallData, Contract } = await import('starknet')
    if (!multicallContractAddress) {
      throw new Error(`No multicall contract address for ${starknetChain}`)
    }
    const starknetProvider = pickStarknetProvider(starknetChain)
    const contract = new Contract(starknetMulticallABi, multicallContractAddress, starknetProvider)
    const aggregation = await contract['aggregate'](
      tokens.map(({ address }) => ({
        to: address,
        selector: '0x2e4263afad30923c891518314c3c95dbe830a16874e8abc5777a9a20b54c76e',
        calldata: CallData.compile({
          account: secondaryWalletAddress,
        }),
      })),
    )

    // Fix types for below lines
    const results = Object.values(aggregation)
    const balances = tokens.reduce<MulticallBalances>(
      (accumulator, { token, decimals }: { token: string; decimals: number }, index: number) => {
        if (!accumulator[starknetChain]) {
          accumulator[starknetChain] = {}
        }
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the type
        const result = results[1] as bigint[][]
        const balance = result?.[index] ? Number(result[index][0]) : 0
        accumulator[starknetChain][token] = {
          balance: new Decimal(balance).div(10 ** decimals).toString(),
          token,
        }
        return accumulator
      },
      {},
    )

    return balances
  } catch (error) {
    console.error(error)
    // Fail silently
    return {}
  }
}
