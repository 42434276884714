import type { NSLookup, NSReverseLookup } from './nameServices'

// TODO FUD-1471: Implement ENS service
export const getEns = () => {
  console.error('getEns needs to be implemented')
  // const web3 = getWeb3()
  // return new ENS({ provider: web3.ETHEREUM.currentProvider, network })
}

export const ensReverseLookup: NSReverseLookup = (address: string) => {
  console.error('ensReverseLookup needs to be implemented', address)
  return Promise.resolve('')
  // return new Promise((resolve) => {
  //   if (![1, 3, 5].includes(network)) {
  //     return resolve('')
  //   }
  //   const _ens = getEns()
  //   _ens
  //     .reverse(address)
  //     .then((res) => {
  //       resolve(res)
  //     })
  //     .catch(() => {
  //       resolve('')
  //     })
  // });
}

export const ensLookup: NSLookup = (name: string) => {
  console.error('ensLookup needs to be implemented', name)
  return Promise.resolve(false)
  // return new Promise<false | string>((resolve) => {
  //   if ([1, 3, 5].indexOf(network) === -1) {
  //     return resolve(false)
  //   }
  //   const _ens = getEns()
  //   _ens
  //     .lookup(name)
  //     .then((res) => {
  //       resolve(res)
  //     })
  //     .catch(() => {
  //       resolve(false)
  //     })
  // });
}

export const validateENSName = (name: string): boolean => {
  const regex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/
  return regex.test(name)
}
