import { useState, useCallback, useMemo } from 'react'
import type { PostPurchasePerkResponse } from '@rhinofi/rhino-nation-api-spec'
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch'
import { Modal } from '../../../../../../components/common/Modal/Modal'
import { clearModal } from '../../../../../../actions/modalActions/clearModal'
import { usePerksWithUserData } from '../../../../hooks/usePerksWithUserData'
import { ACTIVE_PERK_MODAL_VIEWS } from '../../../../constants/nationActivePerkModal.constants'
import type { ActivePerkModalViewType } from '../../../../types/nationActivePerkModal.types'
import { isPerkClaimed } from '../../../../utils/isPerkClaimed'
import { usePerkWithUserData } from '../../../../hooks/usePerkWithUserData'
import type { NationActivePerkModalState } from '../../../../types/nationActivePerkModalState.types'
import { NationActivePerkModalClaim } from './NationActivePerkModalClaim'
import { NationActivePerkModalOverview } from './NationActivePerkModalOverview'

export const NationActivePerkModal = ({ perkId, perkDataSource = 'multiple' }: NationActivePerkModalState) => {
  const [view, setView] = useState<ActivePerkModalViewType>(ACTIVE_PERK_MODAL_VIEWS[0])
  const [reward, setReward] = useState<PostPurchasePerkResponse | null>(null)
  const dispatch = useAppDispatch()

  const { data: perksData } = usePerksWithUserData()
  const { data: perkData } = usePerkWithUserData({ perkId, skip: perkDataSource === 'multiple' })

  const perkWithUserData = useMemo(() => {
    const data = perkDataSource === 'multiple' ? perksData : perkData
    return data?.items.find(({ perk }) => perk._id === perkId)
  }, [perksData, perkData, perkId, perkDataSource])

  const isClaimed = perkWithUserData ? isPerkClaimed({ perkWithUserData }) : false

  const setViewCallback = useCallback((newView: ActivePerkModalViewType) => {
    setView(newView)
  }, [])

  const setRewardCallback = useCallback((newReward: PostPurchasePerkResponse | null) => {
    setReward(newReward)
  }, [])

  if (!perkWithUserData) {
    return null
  }

  return (
    <Modal variant={isClaimed ? 'success' : undefined} isVisible onClose={() => clearModal(dispatch)()} width="480px">
      {view === 'OVERVIEW' && (
        <NationActivePerkModalOverview
          reward={reward}
          setReward={setRewardCallback}
          setView={setViewCallback}
          perkWithUserData={perkWithUserData}
        />
      )}

      {view === 'CLAIM' && (
        <NationActivePerkModalClaim
          perkWithUserData={perkWithUserData}
          setView={setViewCallback}
          setReward={setRewardCallback}
        />
      )}
    </Modal>
  )
}
