import styled from 'styled-components'
import { Text, Button } from '@rhinofi/dvf-shared-ui'
import { FlexContainer } from '../../../../../../components/common/Wrappers/Containers'

export const Container = styled(FlexContainer)`
  cursor: pointer;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.secondary20};
  background: ${({ theme }) => theme.elevationL1};
  border-radius: 8px;
`

export const TitleGemsContainer = styled(FlexContainer)`
  overflow: hidden;
`

export const EllipsisText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`

export const CloseButton = styled(Button)`
  min-width: 0;
  padding: 1px;

  &:focus {
    border-width: 1px;
  }
`
