import type { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit'
import { configureStore as rtkConfigureStore } from '@reduxjs/toolkit'
import { bridgeListenerMiddleware } from './middlewares/bridgeListener.middleware'
import { clearRTKCacheMiddleware } from './middlewares/clear-rtk-cache.middleware'
import { secondaryWalletMiddleware } from './middlewares/secondaryWallet.middleware'

import { usdPricesApi } from '../services/usdPricesApi'
import { userApi } from '../services/userApi'
import { userVerificationApi } from '../services/userVerificationApi'
import { configApi } from './apis/config.api'
import { rootReducer, type RootReducerState } from './rootReducer'
import { bridgeApi } from './apis/bridge.api'
import { authApi } from './apis/auth.api'
import { highlightsApi } from '../pages/Bridge/services/highlights.api'
import { nationApi } from '../pages/Nation/services/nation.api'
import { gatedFeaturesApi } from '../pages/Nation/services/gatedFeatures.api'
import { mintedNFTsApi } from '../pages/Chain/services/mintedNFTsApi'
import { referralApi } from '../services/referralApi'
import { contractsInteractionsApi } from '../pages/Chain/services/contractsInteractions.api'

const config = {
  development: import.meta.env.DEV,
}

// const enhanceSentry = isFeatureEnabled(F_FLAGS_TYPES.DEV_ENCHANCE_SENTRY)
// const sentryReduxEnhancer = Sentry.createReduxEnhancer({
//   stateTransformer: (state: FilledRootState) => {
//     if (!enhanceSentry) {
//       return null
//     }

//     return {
//       theme: state?.portal.selectedTheme,
//       application: state?.application,
//       address: state?.portal?.address,
//       secondaryWallet: state?.secondaryWallet,
//       authentication: state?.user?.authentication,
//       contractWallet: state?.user?.contractWallet?.isContractWallet,
//       wallet: {
//         walletType: state?.user?.wallet?.data?.walletType,
//         isConnecting: state?.user?.wallet?.isConnecting,
//         isRegistered: state?.user?.wallet?.isRegistered,
//       },
//     }
//   },
// })

export const configureStore = (preloadedState?: Partial<RootReducerState>) => {
  const { development } = config

  const store = rtkConfigureStore({
    ...(preloadedState ? { preloadedState } : {}),
    reducer: rootReducer,
    devTools: development,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      })
        .concat(authApi.middleware)
        .concat(bridgeApi.middleware)
        .concat(configApi.middleware)
        .concat(userVerificationApi.middleware)
        .concat(usdPricesApi.middleware)
        .concat(userApi.middleware)
        .concat(secondaryWalletMiddleware.middleware)
        .concat(clearRTKCacheMiddleware)
        .concat(bridgeListenerMiddleware.middleware)
        .concat(highlightsApi.middleware)
        .concat(nationApi.middleware)
        .concat(gatedFeaturesApi.middleware)
        .concat(referralApi.middleware)
        .concat(mintedNFTsApi.middleware)
        .concat(contractsInteractionsApi.middleware),
    enhancers: (getDefaultEnhancers) =>
      getDefaultEnhancers({
        autoBatch: { type: 'timer', timeout: 1000 },
      }),
  })

  return store
}

// Get the type of our store variable
export type AppStore = ReturnType<typeof configureStore>
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = ThunkDispatch<RootState, unknown, UnknownAction>
