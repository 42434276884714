import { useCallback, useState } from 'react'

type Props = {
  storageKey: string
}

const getStorageAddresses = (storageKey: string): string[] => {
  const storedAddresses = JSON.parse(localStorage.getItem(storageKey) ?? '[]')
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy
  return Array.isArray(storedAddresses) ? (storedAddresses as string[]) : []
}

export const useNationLocalStorage = ({ storageKey }: Props) => {
  const [addresses, setAddresses] = useState<string[]>(getStorageAddresses(storageKey))

  const isAddressInStorage = useCallback((address: string) => addresses.includes(address), [addresses])

  const addAddress = useCallback(
    (newAddress: string) => {
      if (!isAddressInStorage(newAddress)) {
        const updatedAddresses = [...addresses, newAddress]
        setAddresses(updatedAddresses)
        localStorage.setItem(storageKey, JSON.stringify(updatedAddresses))
      }
    },
    [addresses, isAddressInStorage, storageKey],
  )

  return {
    addresses,
    addAddress,
    isAddressInStorage,
  }
}
