import { BoxContainer, Container, Image } from '../ContractInteractionsTxModal.styled'
import { PerpetualLoader } from '../../../../../components/common/Helpers/PerpetualLoader'
import { ContractInteractionsTxModalTxStatusLink } from '../ContractInteractionsTxModalTxLink'
import { TxStatus } from '../../../../../services/txService/txService.types'

type Props = {
  image: string
  txHash?: string | undefined
  chain: string
}

export const ContractInteractionsTxModalPending = ({ image, txHash, chain }: Props) => (
  <Container>
    <BoxContainer>
      <Image src={image} alt="" />
      {txHash && <ContractInteractionsTxModalTxStatusLink txHash={txHash} chain={chain} txStatus={TxStatus.PENDING} />}
    </BoxContainer>

    <PerpetualLoader />
  </Container>
)
