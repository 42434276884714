import { useCallback } from 'react'
import { Text, TypographyAlign, Button, ButtonVariant } from '@rhinofi/dvf-shared-ui'
import { FlexContainer } from '../../../../../components/common/Wrappers/Containers'
import { useAppDispatch } from '../../../../../hooks/useAppDispatch'
import { resetNationWidgetSelectedFilters } from '../../../slices/nationWidget.slice'
import { translate } from '../../../../../intl/i18n'

export const NationWidgetMissionsEmptyState = () => {
  const dispatch = useAppDispatch()

  const resetCallback = useCallback(() => {
    dispatch(resetNationWidgetSelectedFilters())
  }, [dispatch])

  return (
    <FlexContainer $direction="column" $alignItems="center" $justifyContent="center" $flexGrow="1">
      <Text color="secondary80" align={TypographyAlign.Center}>
        {translate('nation.no_missions_found_for_selected_filters')}
      </Text>
      <Button id="nation-widget-reset-filters" onClick={resetCallback} variant={ButtonVariant.text}>
        {translate('nation.see_all_unfinished_missions')}
      </Button>
    </FlexContainer>
  )
}
