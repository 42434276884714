export const modalKeys = {
  newUserEmail: 'new-user-modal',
  verify: 'verify-modal',
  claimSuccess: 'claim-success-modal',
  yieldAlerts: 'yield-alerts-modal',
  rhinoFiWrapped: 'rhino-wrapped',
  userVerificationEmail: 'user-verification-email-modal',
  userVerificationPhone: 'user-verification-phone-modal',
  recentBridgeTx: 'recent-bridge-tx',
  nftMint: 'nft-mint-modal',
  viewNftMinted: 'view-nft-minted-modal',
  freeBridge: 'free-bridge-modal',
  reconnectModal: 'reconnect-modal',
  noWallet: 'no-wallet',
  spendingCap: 'spending-cap',
  contractDetails: 'contract-details',
  contractShare: 'contract-share',
  contractGM: 'contract-gm',
  contractDeploy: 'contract-deploy',
  referralCodeVerification: 'referral-code-verification',
  connectToParadex: 'connect-to-paradex',
  nationOnboarding: 'nation-onboarding',
  nationActiveMission: 'nation-active-mission',
  nationCompletedMission: 'nation-completed-mission',
  nationActivePerk: 'nation-active-perk',
  nationClaimedPerk: 'nation-claimed-perk',
  claimMilestoneReward: 'claim-milestone-reward',
  connectWallet: 'connect-wallet',
} as const
