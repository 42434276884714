import { createRoot } from 'react-dom/client'
// import { StrictMode } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Root } from './Root'
import { MainApp } from './MainApp'
import 'simplebar-react/dist/simplebar.min.css'
import 'overlayscrollbars/css/OverlayScrollbars.min.css'

const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        path: '/*',
        element: <MainApp />,
      },
    ],
  },
])

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- root element is always present
createRoot(document.getElementById('root')!).render(
  // <StrictMode>
  <RouterProvider router={router} />,
  // </StrictMode>,
)
