import styled from 'styled-components'

type Props = {
  percentage: number
}

export const NationMissionProgressBar = ({ percentage }: Props) => (
  <ProgressBarContainer>
    <ProgressBar style={{ width: `${percentage}%` }} />
  </ProgressBarContainer>
)

export const ProgressBarContainer = styled.div`
  background: ${({ theme }) => theme.elevationL2};
`

export const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background: ${({ theme }) => theme.accentGreen};
`
