import { useMemo } from 'react'
import styled from 'styled-components'
import { ButtonVariant, IconSizes, Icon, Text, TextSize, Button } from '@rhinofi/dvf-shared-ui'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../hooks/useAppSelector'

import { useNationStatus } from '../../../../hooks/nation/useNationStatus'
import { NationUserStatus } from '../../types/nation.types'
import { translate } from '../../../../intl/i18n'
import { makeRouteTo } from '../../../../router'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { isFreeMintCampaignActive } from '../../utils/isFreeMintCampaignActive'
import { triggerConnectWallet } from '../../../../utils/triggerConnectWallet'
import { selectIsWalletConnected } from '../../../../reducers/userSelector'
import { useWallet } from '../../../../hooks/useWallet'

const freeMintActive = isFreeMintCampaignActive()

export const NationWidgetStatsCTA = () => {
  const navigate = useNavigate()
  const isWalletConnected = useAppSelector(selectIsWalletConnected)
  const dispatch = useAppDispatch()
  const isConnecting = useWallet().connectingWallet.isConnecting
  const { status } = useNationStatus()

  const ctaData = useMemo(() => {
    if (!isWalletConnected) {
      return {
        id: 'nation-widget-connect-wallet',
        text: isConnecting ? translate('onboarding.connecting_wallet') : translate('gated.connect_wallet'),
        redirectLink: '',
        onClick: triggerConnectWallet(dispatch),
      }
    } else if (status === NationUserStatus.MintedNFT) {
      return {
        id: 'nation-widget-updating-rewards',
        text: translate('nation.updating_rewards'),
        onClick: () => {
          navigate(makeRouteTo.nation({ subRoute: 'access' }))
        },
      }
    } else if (status === NationUserStatus.NotJoined) {
      return {
        id: 'nation-widget-mint',
        text: translate('nation.mint_to_claim_gems'),
        onClick: () => {
          navigate(makeRouteTo.nation({ subRoute: 'access' }))
        },
      }
    }

    return {
      id: 'nation-widget-check-leaderboard',
      text: translate('nation.check_leaderboard'),
      icon: 'trophy',
      iconSize: IconSizes.Large,
      iconColor: 'brandA1',
      onClick: () => {
        navigate(makeRouteTo.nation({ subRoute: 'leaderboard' }))
      },
    }
  }, [status, isWalletConnected, dispatch, isConnecting, navigate])

  return (
    <FlexContainer $direction="column" $gap="2px">
      <CustomButton id={ctaData.id} variant={ButtonVariant.secondary} onClick={ctaData?.onClick} fullWidth>
        <FlexContainer $gap="8px">
          {ctaData.icon && <Icon id={ctaData.icon} color={ctaData?.iconColor || ''} size={ctaData?.iconSize} />}
          <Text color="brandA1" size={TextSize.XS}>
            {ctaData.text}
          </Text>
        </FlexContainer>
      </CustomButton>

      {freeMintActive && status === NationUserStatus.NotJoined && isWalletConnected && (
        <Text color="secondary80" size={TextSize.XS}>
          {translate('nation.free_mint_for_limited_time')}
        </Text>
      )}
    </FlexContainer>
  )
}

const CustomButton = styled(Button)`
  min-height: 32px;
`
