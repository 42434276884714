import { Text, TextSize, Tooltip, IconSizes } from '@rhinofi/dvf-shared-ui'
import { FlexContainer } from '../../../../../components/common/Wrappers/Containers'
import { SkeletonCard } from '../../../../../components/common/Skeletons/Skeleton.styled'
import { NATION_XP_LABEL } from '../../../constants/nationGeneral.constants'
import GemIcon from '../../../../../assets/icons/gem.svg?react'
import { Divider, CustomIcon } from './RhinoNationBalanceCount.styled'
import { Container } from './RhinoNationBalance.styled'

type Props = {
  gems: number
  rxp?: number | undefined
  isLoading: boolean
  tooltipText?: string | undefined
}

export const RhinoNationBalanceCount = ({ tooltipText, isLoading, gems, rxp }: Props) => (
  <Container $gap="8px">
    <FlexContainer $gap="8px" $alignItems="stretch">
      {rxp !== undefined && (
        <>
          <FlexContainer $gap="2px" $alignItems={isLoading ? 'flex-end' : 'baseline'}>
            {isLoading ? <SkeletonCard $height="24px" $width="24px" /> : <Text color="secondary">{rxp}</Text>}

            <Text size={TextSize.XS} color="secondary80">
              {NATION_XP_LABEL}
            </Text>
          </FlexContainer>

          <Divider />
        </>
      )}

      <FlexContainer $gap="4px">
        {isLoading ? <SkeletonCard $height="24px" $width="24px" /> : <Text color="secondary">{gems}</Text>}

        <GemIcon />
      </FlexContainer>
    </FlexContainer>

    {tooltipText && (
      <Tooltip tooltipContent={tooltipText}>
        <CustomIcon id="info-circle" size={IconSizes.XSmall} />
      </Tooltip>
    )}
  </Container>
)
