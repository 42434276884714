import type { WalletType } from '../wallet.types'

export const saveLastLogin = (
  lastLogin: { address: string } & (
    | { type: typeof WalletType.walletConnect }
    | {
        type: typeof WalletType.eip6963
        name: string
      }
  ),
) => {
  localStorage.setItem('last-login', JSON.stringify(lastLogin))
}
