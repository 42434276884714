import { Button, ButtonSizes, ButtonVariant, CoreCard } from '@rhinofi/dvf-shared-ui'
import { useEffect, useState } from 'react'
import { ExcludeFalsy } from '../../utils/ExcludeFalsy'
import { useIsMobile } from '../../hooks/useIsMobile'
import { translate } from '../../intl/i18n'
import { BottomCommsPosition } from './BottomCenterComms.styled'
import { shouldShowComms } from './shouldShowComms'
import { useBottomCenterConfig } from './bottomCenterComms.config'

type BottomCenterCommsProps = {
  showOnMobile: boolean
  topMargin?: number
}

export const BottomCenterComms = ({ showOnMobile, topMargin }: BottomCenterCommsProps) => {
  const bottomCoommsConfig = useBottomCenterConfig()
  const [isVisible, setIsVisible] = useState<Record<string, boolean>>({})

  useEffect(() => {
    setIsVisible(
      bottomCoommsConfig.reduce((accumulator: Record<string, boolean>, config) => {
        accumulator[config.id] = shouldShowComms(config)
        return accumulator
      }, {}),
    )
  }, [bottomCoommsConfig])

  const { isMobile, isDesktop } = useIsMobile({})

  if (showOnMobile && !isMobile) {
    return null
  }
  if (!showOnMobile && isMobile) {
    return null
  }

  const isMedium = !isDesktop
  const bottomCommsContent = bottomCoommsConfig
    .map((config) => {
      const content = config.content({ isMobile: isMedium })
      if (content && isVisible[config.id]) {
        return (
          <CoreCard className={'core-card-styler'} key={config.id}>
            {content}
            {config.hasCloseButton ? (
              <Button
                id="close-bottom-center-comms"
                size={isMedium ? ButtonSizes.Medium : ButtonSizes.Small}
                icon="x-lg"
                fullWidth={isMedium}
                variant={isMedium ? ButtonVariant.secondary : ButtonVariant.ghost}
                onClick={() => {
                  window.localStorage.setItem(config.id, new Date().toISOString())
                  setIsVisible({ ...isVisible, [config.id]: false })
                }}
              >
                {isMedium ? translate('yield.dismiss') : null}
              </Button>
            ) : null}
          </CoreCard>
        )
      }

      return null
    })
    .filter(ExcludeFalsy)

  if (bottomCommsContent.length === 0) {
    return null
  }

  return (
    <BottomCommsPosition $isMobile={isMobile} $topMargin={topMargin} id="bottom-center-comms">
      {bottomCommsContent}
    </BottomCommsPosition>
  )
}
