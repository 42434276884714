import I18nEn from '../assets/language-resources/I18-en.json'
// import I18nSC from '../assets/language-resources/I18-sc.json'
// import I18nTR from '../assets/language-resources/I18-tr.json'
// import I18nRU from '../assets/language-resources/I18-ru.json'
// import I18nUA from '../assets/language-resources/I18-uk.json'

// import { heapEvents } from '../constants/heapEvents'
// import { trackHeapEvent } from './apiService'

const langs = {
  en: I18nEn,
  // sc: I18nSC,
  // tr: I18nTR,
  // ru: I18nRU,
  // ua: I18nUA,
}
// eslint-disable-next-line functional/no-let -- Legacy
let isPTSansLoaded = false
const cyrillicLanguages = ['ua', 'ru']

const loadPTSansIfNeeded = (lang: string) => {
  if (!isPTSansLoaded && cyrillicLanguages.includes(lang)) {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = 'https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap'
    document.head.appendChild(link)
    isPTSansLoaded = true
  }
}

// export const getLang = () => localStorage.getItem('lang') || 'en'

// export const getLangFull = () =>
//   ({
//     en: 'EN',
//     sc: '中文 (简化)',
//     tc: '中文 (繁體)',
//     tr: 'TR',
//     ua: 'UA',
//     ru: 'RU',
//   }[getLang()])

// export const setLang = (lang: string) => {
//   localStorage.setItem('lang', lang)
//   trackHeapEvent(heapEvents.switchLanguage, { language: lang })
//   document.location.reload()
// }

export const translationSetup = () => {
  // prevent to read language from local storage until we re-enable language switcher
  // const lang = getLang()
  const lang = 'en'

  loadPTSansIfNeeded(lang)

  // @ts-expect-error TS(2322): Type '{ account: { advanced_withdrawal_options: st... Remove this comment to see the full error message
  window.I18nfallback = I18nEn
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  window.I18n = langs[lang] || I18nEn
}

translationSetup()
