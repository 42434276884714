import { ERC20__factory } from '../../../contracts/ERC20'
import { SafeDecimal } from '../../../utils/SafeDecimal'
import type { AllowanceCallProps } from './callCheckAllowance'

export const getEVMTokenAllowance = async ({
  tokenAddress,
  ethersSigner,
  walletAddress,
  bridgeContractAddress,
  decimals,
}: AllowanceCallProps) => {
  const erc20Contract = ERC20__factory.connect(tokenAddress, ethersSigner)
  const contractAllowance = await erc20Contract.allowance(walletAddress, bridgeContractAddress)
  return SafeDecimal(contractAllowance.toString())
    .div(10 ** decimals)
    .toString()
}
