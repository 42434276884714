import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { prepareHeaders } from './auth/addAuthorizationHeader'
import { envConfig } from '../env/envConfig'

type SetEmailOrPhoneRequest = {
  type: string
  value: string
  reCaptchaToken: string
  sendVerificationCode: boolean
  user: string
}

type IsUserVerifiedRequest = {
  user: string
}

type VerifyCodeRequest = {
  user: string
  type: string
  code: string
}

type UserVerificationResponse = {
  user: string
  email?: string
  emailVerified: boolean
  phone?: string
  phoneVerified: boolean
  verified: boolean
}

const setEmailEndpoint = '/user-verification/setEmailOrPhone'
const isUserVerifiedEndpoint = '/user-verification/isUserVerified'
const verifyCodeEndpoint = '/user-verification/verifyCode'

const endpointsWithAuth = ['setEmailOrPhone', 'isUserVerified', 'verifyCode']

export const userVerificationApi = createApi({
  tagTypes: ['UserVerification'],
  reducerPath: 'userVerificationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: envConfig.tradingApi,
    prepareHeaders: prepareHeaders(endpointsWithAuth),
  }),
  endpoints: (builder) => ({
    setEmailOrPhone: builder.mutation<void, SetEmailOrPhoneRequest>({
      query: (data) => ({
        url: setEmailEndpoint,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['UserVerification'],
    }),
    isUserVerified: builder.query<UserVerificationResponse, IsUserVerifiedRequest>({
      query: (data) => ({
        url: `${isUserVerifiedEndpoint}/${data.user}`,
        method: 'GET',
      }),
      providesTags: ['UserVerification'],
    }),
    verifyCode: builder.mutation<void, VerifyCodeRequest>({
      query: (data) => ({
        url: verifyCodeEndpoint,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['UserVerification'],
    }),
  }),
})

export const { useSetEmailOrPhoneMutation, useIsUserVerifiedQuery, useVerifyCodeMutation, useLazyIsUserVerifiedQuery } =
  userVerificationApi
