import type { BaseQueryApi } from '@reduxjs/toolkit/query/react'
import { getJWTTokenFromState } from './getJWTTokenFromState'

export const makeJwtHeader = (api: Pick<BaseQueryApi, 'getState' | 'endpoint'>) => {
  return {
    headers: {
      authorization: getJWTTokenFromState(api),
    },
  }
}
