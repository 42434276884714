import type { BridgeConfigEntrySchema } from '@rhinofi/bridge-api-spec'
import type { MulticallBalance } from './getMulticallBalances'
import { getMulticallBalances } from './getMulticallBalances'
import { tokensToArray } from '../helperService/tokensToArray'
import { NETWORKS, NON_EVM_CHAINS } from '../../constants/types'
import { getStarknetBalances } from '../secondaryWallet/services/starknetService/getStarknetBalances'
import { getTronBalances } from '../secondaryWallet/services/tronService/getTronBalances'
import { getTronNativeBalance } from '../secondaryWallet/services/tronService/getTronNativeBalance'
import { getSolanaBalances } from '../secondaryWallet/services/solanaService/getSolanaBalances'
import { getSolanaNativeBalance } from '../secondaryWallet/services/solanaService/getSolanaNativeBalance'
import { getParadexBalances } from '../secondaryWallet/services/paradexService/getParadexBalances'
import { getTonNativeBalance } from '../secondaryWallet/services/tonService/getTonNativeBalance'
import { getTonBalances } from '../secondaryWallet/services/tonService/getTonBalances'
import { getNativeBalance } from './getNativeBalance'
import { merge } from 'lodash-es'
import { ethers } from 'ethers'
import type { MulticallBalances } from './multicall'

export const getBlockchainBalanceForChain = async (
  chain: string,
  address: string,
  chainConfig: BridgeConfigEntrySchema,
  secondaryWallet: { secondaryWalletAddress: string; connectedChain: string },
): Promise<MulticallBalances> => {
  const promises: Promise<Record<string, MulticallBalance>>[] = []
  const { nativeTokenName, tokens, rpc, nativeTokenDecimals, multicallContractAddress, networkId, name } = chainConfig

  const tokenAddresses = tokensToArray(tokens)
  const erc20Tokens = tokenAddresses.filter(({ token }) => token !== nativeTokenName)

  if (NON_EVM_CHAINS[chain]) {
    if (chain === NETWORKS.STARKNET) {
      // ETH is marked as Starknet native token on config, but native tokens don't exist on Starknet.
      // ETH and STRK are used for paying for gas but both are erc20s
      // Therefore, we pass `tokenAddresses` directly to fetch balances for all tokens.
      return getStarknetBalances(
        secondaryWallet.secondaryWalletAddress,
        tokenAddresses,
        chain,
        multicallContractAddress,
      )
    } else if (chain === NETWORKS.TRON) {
      const erc20Balances = await getTronBalances(secondaryWallet.secondaryWalletAddress, erc20Tokens)
      const nativeBalance = await getTronNativeBalance(secondaryWallet.secondaryWalletAddress)
      return merge({}, erc20Balances, {
        [chain]: {
          [nativeTokenName]: {
            balance: nativeBalance,
            token: nativeTokenName,
          },
        },
      })
    } else if (chain === NETWORKS.SOLANA) {
      const erc20Balances = await getSolanaBalances(secondaryWallet.secondaryWalletAddress, erc20Tokens)
      const nativeBalance = await getSolanaNativeBalance(secondaryWallet.secondaryWalletAddress)
      return merge({}, erc20Balances, {
        [chain]: {
          [nativeTokenName]: {
            balance: nativeBalance,
            token: nativeTokenName,
          },
        },
      })
    } else if (chain === NETWORKS.PARADEX) {
      return getParadexBalances()
    } else if (chain === NETWORKS.TON) {
      const nativeBalance = await getTonNativeBalance(secondaryWallet.secondaryWalletAddress, nativeTokenDecimals)
      const jettonBalances = await getTonBalances(secondaryWallet.secondaryWalletAddress, erc20Tokens)
      return merge({}, jettonBalances, {
        [chain]: {
          [nativeTokenName]: {
            balance: nativeBalance,
            token: nativeTokenName,
          },
        },
      })
    } else {
      return {}
    }
  } else {
    // get chain native balance
    if (rpc) {
      const network = new ethers.Network(name, networkId)
      const provider = new ethers.JsonRpcProvider(rpc, network, { staticNetwork: true })
      promises.push(
        getNativeBalance({ address, provider, chain, nativeToken: nativeTokenName, decimals: nativeTokenDecimals }),
      )
      promises.push(
        getMulticallBalances({
          walletAddress: address,
          tokens: erc20Tokens,
          rpcUrl: rpc,
          chain,
          multicallContractAddress,
        }),
      )
    }

    const results = await Promise.allSettled(promises)
    const balances = results.reduce((acc: Record<string, MulticallBalance>, result) => {
      if (result.status === 'fulfilled') {
        acc = merge(acc, result.value)
      }
      return acc
    }, {})
    return {
      [chain]: balances,
    }
  }
}
