import styled, { css } from 'styled-components'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import { NoUnderlineLink } from '../common/Helpers/LinkHelpers'

export const NavigationLink = styled.div<{ $isActive: boolean; $noHover?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
  border-bottom: 1px solid transparent;
  transition: all 0.15s ease-out;

  .navigation-link-img {
    max-height: 20px;
  }

  &:focus {
    .navigation-link-text,
    .navigation-link-icon {
      color: ${({ theme }) => theme.inactiveText};
    }
  }

  &:active {
    .navigation-link-text,
    .navigation-link-icon {
      color: ${({ theme }) => theme.secondary};
    }
  }

  ${({ $noHover }) =>
    !$noHover
      ? css`
          &:hover {
            border-bottom: 1px solid ${({ theme }) => theme.secondary};
          }
        `
      : ''}

  ${({ $isActive, theme }) =>
    $isActive
      ? css`
          border-bottom: 1px solid ${theme.secondary};
        `
      : ''} @media ${respondBelow('l')} {
    .navigation-link-icon,
    .navigation-link-img {
      display: none;
    }
  }
`

export const NavigationLinksContent = styled.div`
  display: flex;
  gap: 28px;

  @media ${respondBelow('xs')} {
    gap: 16px;
  }
`

export const NavigationSubLink = styled(NoUnderlineLink)<{ $isActive: boolean }>`
  padding: 8px 0;
  color: ${({ theme }) => theme.secondary};
  font-size: 13px;
  border: 1px solid transparent;
  transition: all 0.15s ease-out;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.inactiveText};
  }

  &:active {
    color: ${({ theme }) => theme.secondary};
  }

  ${({ $isActive, theme }) =>
    $isActive
      ? css`
          border-bottom: 1px solid ${theme.secondary};
        `
      : ''}
`

export const NavigationSubLinksContent = styled.div`
  min-width: 148px;
  padding: 0 8px;
`
