import type { BottomCenterCommsConfig } from './bottomCenterComms.config'

type ShouldShowProps = BottomCenterCommsConfig

export const shouldShowComms = ({ endDate, startDate, intervalMs, id }: ShouldShowProps) => {
  const lastShownValue = localStorage.getItem(id)

  const now = new Date().getTime()
  const end = endDate ? new Date(endDate).getTime() : null
  const start = startDate ? new Date(startDate).getTime() : null
  const lastShown = lastShownValue ? new Date(lastShownValue).getTime() : null

  if (end) {
    const diff = end - now
    if (diff < 0) {
      return false
    }
  }

  if (start) {
    const diff = start - now
    if (diff > 0) {
      return false
    }
  }

  if (lastShown && intervalMs) {
    const diff = now - lastShown
    if (diff < intervalMs) {
      return false
    }
  }

  if (lastShown && !intervalMs) {
    return false
  }

  return true
}
