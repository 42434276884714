import styled from 'styled-components'

export const FadeTransition = styled.div`
  height: 100%;

  .fade-enter {
    opacity: 0;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-done {
    opacity: 0;
  }
  .fade-appear {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
  }
  .fade-appear-active {
    opacity: 1;
  }
  .fade-enter-active,
  .fade-exit-active,
  .fade-appear-active {
    transition: opacity 300ms ease-in-out;
  }
`

export const FadeTransitionWithZIndex = styled(FadeTransition)<{ $zIndex?: number | undefined }>`
  position: relative;
  z-index: ${({ $zIndex }) => $zIndex || 2};
  height: auto;
`
