import styled from 'styled-components'

export const BridgeHighlightsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

export const BridgeHighlightsImage = styled.img<{ $isActive: boolean }>`
  object-fit: cover;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid transparent;

  cursor: ${({ $isActive }) => ($isActive ? 'pointer' : 'default')};

  ${({ $isActive, theme }) =>
    $isActive
      ? `
        &:hover {
          border: 1px solid #e4e4e4;
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        }
        &:active {
          border: 1px solid ${theme.brandSolid};
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
        }
  `
      : ''}
`
