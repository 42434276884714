import type { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { setupTronProvider } from '../providers/tronProvider'
import { NETWORKS } from '../../../constants/types'
import { createTronListener } from '../services/tronService/createTronListener'
import type { SecondaryConnectionPayload } from './connectors.types'

export const connectToTron = async (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  networkId: string,
): Promise<SecondaryConnectionPayload> => {
  const provider = await setupTronProvider()
  const address = provider?.tronWeb ? provider.tronWeb.defaultAddress.base58 : ''
  if (!provider || !address) {
    throw new Error('Something went wrong when connecting your Tron wallet.')
  }
  createTronListener(dispatch)
  return {
    address,
    networkId,
    domain: '',
    connectedChain: NETWORKS.TRON,
  }
}
