import { CONTRACTS_INTERACTIONS_LS_KEY_SELECTED_CHAIN } from '../constants/contractsInteractions.constants'
import { getEnabledCIChains } from './getEnabledCIChains'

export const getContractsInteractionsSavedChain = () => {
  const enabledChains = getEnabledCIChains()
  const defaultChain = enabledChains[0]

  if (!defaultChain) {
    throw new Error('ENABLED_CI_CHAINS is empty')
  }

  const lsChain = localStorage.getItem(CONTRACTS_INTERACTIONS_LS_KEY_SELECTED_CHAIN)

  if (lsChain) {
    const isLsChainEnabled = enabledChains.includes(lsChain)

    if (isLsChainEnabled) {
      return lsChain
    } else {
      localStorage.removeItem(CONTRACTS_INTERACTIONS_LS_KEY_SELECTED_CHAIN)
    }
  }

  return defaultChain
}
