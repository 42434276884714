import { useMemo } from 'react'
import { LegacyPortalLink } from './LegacyPortalLink/LegacyPortalLink'
import type React from 'react'
export type BottomCenterCommsConfig = {
  hasCloseButton: boolean
  endDate?: string | Date
  startDate?: string | Date
  intervalMs?: number
  id: string
}

type ContentProps = { isMobile: boolean }
type BottomCenterCommsConfigAndComponent = BottomCenterCommsConfig & {
  content: React.VFC<ContentProps>
}

export const useBottomCenterConfig = (): BottomCenterCommsConfigAndComponent[] => {
  return useMemo(() => {
    const legacyPortalLinkConfig: BottomCenterCommsConfig | null = {
      hasCloseButton: true,
      id: 'legacy-link-notification',
      endDate: '2025-02-28',
    }

    return [
      {
        ...legacyPortalLinkConfig,
        content: () => <LegacyPortalLink />,
      },
    ]
  }, [])
}
