import { Text, TextSize } from '@rhinofi/dvf-shared-ui'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'
import { translate } from '../../../../intl/i18n'
import { useEarnedGemsTransactions } from '../../hooks/useEarnedGemsTransactions'
import { NationMissionRow } from '../NationContent/NationMissionsList/NationMissionRow/NationMissionRow'
import { useOpenNationCompletedMissionModalFromGemsTx } from '../../hooks/useOpenNationCompletedMissionModalFromGemsTx'
import { useHasUserFullNationAccess } from '../../hooks/useHasUserFullNationAccess'

export const NationWidgetLastCompletedMission = () => {
  const hasFullAccess = useHasUserFullNationAccess()
  const { dataFormatted, data } = useEarnedGemsTransactions()

  const openModalCallback = useOpenNationCompletedMissionModalFromGemsTx({ gemsTransactions: data })

  if (!dataFormatted || dataFormatted.items.length === 0 || !hasFullAccess) {
    return null
  }

  const firstItem = dataFormatted.items[0]
  if (!firstItem) {
    return null
  }
  return (
    <FlexContainer $gap="4px" $direction="column" $alignItems="stretch">
      <Text size={TextSize.XS} color="secondary80">
        {translate('nation.last_completed_mission')}
      </Text>

      <NationMissionRow
        fixedId="nation-widget-last-completed"
        data={firstItem}
        isHistory
        openModalFunc={openModalCallback}
        titleMaxWidth="145px"
      />
    </FlexContainer>
  )
}
