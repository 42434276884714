import { Title, TitleSize } from '@rhinofi/dvf-shared-ui'
import { PerkTypeSchema } from '@rhinofi/rhino-nation-api-spec'
import type { PostPurchasePerkResponse } from '@rhinofi/rhino-nation-api-spec'
import { NationPerkModalStockClaimed } from '../NationPerkModalsSections/NationPerkModalStockClaimed/NationPerkModalStockClaimed'
import { NationPerkModalContent } from '../NationPerkModalsSections/NationPerkModalContent/NationPerkModalContent'
import { NationPerkModalVoucherCode } from '../NationPerkModalsSections/NationPerkModalVoucherCode/NationPerkModalVoucherCode'
import { NationPerkModalDescription } from '../NationPerkModalsSections/NationPerkModalDescription/NationPerkModalDescription'
import { NationPerkModalClaimButtons } from '../NationPerkModalsSections/NationPerkModalClaimButtons/NationPerkModalClaimButtons'
import { FlexContainer } from '../../../../../../components/common/Wrappers/Containers'
import type { PerkWithUserData } from '../../../../types/nation.types'
import type {
  ActivePerkModalSetRewardFunc,
  ActivePerkModalSetViewFunc,
} from '../../../../types/nationActivePerkModal.types'

type Props = {
  perkWithUserData: PerkWithUserData
  setView: ActivePerkModalSetViewFunc
  reward: PostPurchasePerkResponse | null
  setReward: ActivePerkModalSetRewardFunc
}

export const NationActivePerkModalOverview = ({ perkWithUserData, setView, reward, setReward }: Props) => {
  const isVoucherType = perkWithUserData.perk.type === PerkTypeSchema.enums.VOUCHER

  return (
    <FlexContainer $gap="16px" $direction="column" $alignItems="stretch">
      <Title size={TitleSize.XXS} color="secondary80">
        {perkWithUserData.perk.title}
      </Title>

      <NationPerkModalStockClaimed perk={perkWithUserData.perk} />

      <NationPerkModalContent data={perkWithUserData} />

      {isVoucherType && <NationPerkModalVoucherCode voucher={reward?.result || null} perk={perkWithUserData.perk} />}

      <NationPerkModalDescription perk={perkWithUserData.perk} />

      <NationPerkModalClaimButtons setView={setView} setReward={setReward} perkWithUserData={perkWithUserData} />
    </FlexContainer>
  )
}
