import styled from 'styled-components'
import { Button, Icon, Text } from '@rhinofi/dvf-shared-ui'
import { BoxContainer, Container } from '../ContractInteractionsTxModal.styled'
import { ContractInteractionsTxModalTxStatusLink } from '../ContractInteractionsTxModalTxLink'
import { TxStatus } from '../../../../../services/txService/txService.types'
import { FlexContainer } from '../../../../../components/common/Wrappers/Containers'
import { translate } from '../../../../../intl/i18n'

type Props = {
  txHash?: string | undefined
  chain: string
  retryFunc?: () => void
}

export const ContractInteractionsTxModalError = ({ txHash, chain, retryFunc }: Props) => (
  <Container>
    <BoxContainer>
      <ErrorIcon id="x-circle" color="accentRedBg" />
      {txHash && <ContractInteractionsTxModalTxStatusLink txHash={txHash} chain={chain} txStatus={TxStatus.ERROR} />}
    </BoxContainer>

    <FlexContainer $gap="16px" $direction="column" $alignItems="stretch">
      <FlexContainer $direction="column" $alignItems="stretch">
        <Text bold>{translate('contracts_interactions.whats_next')}</Text>
        <Text>{translate('contracts_interactions.tx_failed_desc')}</Text>
      </FlexContainer>

      <Button id="contracts-interactions-tx-modal-error-try-again" onClick={retryFunc}>
        {translate('global.try_again')}
      </Button>
    </FlexContainer>
  </Container>
)

export const ErrorIcon = styled(Icon)`
  font-size: 256px !important;
`
