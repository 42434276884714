import * as Schema from 'effect/Schema'
import type { EIP1193ConnectionKit } from './kits/eip1193/EIP1193ConnectionKit'

export type SupportedWallet = 'walletConnect' | 'eip6963' | 'injected'

export const WalletType = {
  walletConnect: 'walletConnect',
  eip6963: 'eip6963',
  injected: 'injected',
} as const

export type WalletTypeProps =
  | { walletType: typeof WalletType.eip6963; name: string }
  | { walletType: typeof WalletType.injected; flag?: string | undefined }
  | { walletType: typeof WalletType.walletConnect }

export type Kits = EIP1193ConnectionKit

export const RequestAccountsSchema = Schema.NonEmptyArray(Schema.String)
