import { Title, TitleSize } from '@rhinofi/dvf-shared-ui'
import { defaultThreshold } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import { useResize } from '../../context'
import { MenuConnectedWallets } from './MenuConnectedWallets'
import { FlexContainer } from '../common/Wrappers/Containers'
import { translate } from '../../intl/i18n'
import { MenuUnconnectedWallets } from './MenuUnconnectedWallets'
import { useIsMobile } from '../../hooks/useIsMobile'
import { RhinoNationBalance } from '../../pages/Nation/components/NationContent/RhinoNationBalance/RhinoNationBalance'
import { publicRoutes } from '../../router'
import { NoUnderlineLink } from '../common/Helpers/LinkHelpers'

export const MenuContent = ({ close = () => {} }: { close?: () => void }) => {
  const { width } = useResize()
  const { isMobile } = useIsMobile()

  return (
    <FlexContainer $gap="16px" $direction="column" $alignItems="flex-start">
      <Title size={TitleSize.XXS}>{translate('onboarding.my_wallets')}</Title>
      {width < defaultThreshold.m && (
        <NoUnderlineLink to={publicRoutes.nation} onClick={close}>
          <RhinoNationBalance showRxp />
        </NoUnderlineLink>
      )}
      <MenuConnectedWallets close={close} />
      {!isMobile && <MenuUnconnectedWallets />}
    </FlexContainer>
  )
}
