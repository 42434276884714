/* eslint-disable @typescript-eslint/ban-tslint-comment -- Autogenerated file */
/* eslint-disable @eslint-community/eslint-comments/require-description -- Autogenerated file */
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, ContractFactory, ContractTransactionResponse, Interface } from 'ethers'
import type { Signer, BigNumberish, AddressLike, ContractDeployTransaction, ContractRunner } from 'ethers'
import type { PayableOverrides } from '../common'
import type { RhinoGMContract, RhinoGMContractInterface } from '../RhinoGMContract'

export const _abi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'deployFeeReceiver',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_feeOnDeploy',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_feeOnGM',
        type: 'uint256',
      },
    ],
    stateMutability: 'payable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'deployer',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'feeReceiver',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'contractAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'fee',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'feeOnGM',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256',
      },
    ],
    name: 'RhinoGMContractCreated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'deployer',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'contractAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256',
      },
    ],
    name: 'RhinoGMSaid',
    type: 'event',
  },
  {
    inputs: [],
    name: 'deployerAddress',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'feeOnGM',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'gm',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'gmData',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
] as const

export const _bytecode =
  '0x60806040526040516103ba3803806103ba83398101604081905261002291610133565b813410156100765760405162461bcd60e51b815260206004820152601b60248201527f496e73756666696369656e74206465706c6f796d656e74206665650000000000604482015260640160405180910390fd5b600080546001600160a01b0319163317815560028290556040516001600160a01b0385169134919081818185875af1925050503d80600081146100d5576040519150601f19603f3d011682016040523d82523d6000602084013e6100da565b606091505b50506040805134815260208101849052428183015290513092506001600160a01b0386169133917f21e7cd7f82cd180cc2efc8f6d34988374ee142862fb2f9a8f60192e9b3cba1f99181900360600190a4505050610174565b600080600060608486031215610147578283fd5b83516001600160a01b038116811461015d578384fd5b602085015160409095015190969495509392505050565b610237806101836000396000f3fe60806040526004361061003f5760003560e01c8063b0910a5814610044578063c0129d431461006d578063efdee94f14610077578063f7b98453146100af575b600080fd5b34801561005057600080fd5b5061005a60025481565b6040519081526020015b60405180910390f35b6100756100dc565b005b34801561008357600080fd5b50600054610097906001600160a01b031681565b6040516001600160a01b039091168152602001610064565b3480156100bb57600080fd5b5061005a6100ca3660046101d3565b60016020526000908152604090205481565b6002543410156101285760405162461bcd60e51b8152602060048201526013602482015272496e73756666696369656e7420676d2066656560681b604482015260640160405180910390fd5b33600090815260016020526040808220429055815490516001600160a01b039091169134919081818185875af1925050503d8060008114610185576040519150601f19603f3d011682016040523d82523d6000602084013e61018a565b606091505b505060005460405142815230925033916001600160a01b0316907ffa30e9e1a69333c24ba36197dd28ca3e0c5f35e57ddd18903b4fd4ea7d4912339060200160405180910390a4565b6000602082840312156101e4578081fd5b81356001600160a01b03811681146101fa578182fd5b939250505056fea26469706673582212205084495e17d7e67c22e09899255168827489804e496d7d6f983ad2752ca58a2764736f6c63430008040033'

type RhinoGMContractConstructorParams = [signer?: Signer] | ConstructorParameters<typeof ContractFactory>

const isSuperArgs = (xs: RhinoGMContractConstructorParams): xs is ConstructorParameters<typeof ContractFactory> =>
  xs.length > 1

export class RhinoGMContract__factory extends ContractFactory {
  constructor(...args: RhinoGMContractConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args)
    } else {
      super(_abi, _bytecode, args[0])
    }
  }

  override getDeployTransaction(
    deployFeeReceiver: AddressLike,
    _feeOnDeploy: BigNumberish,
    _feeOnGM: BigNumberish,
    overrides?: PayableOverrides & { from?: string },
  ): Promise<ContractDeployTransaction> {
    return super.getDeployTransaction(deployFeeReceiver, _feeOnDeploy, _feeOnGM, overrides || {})
  }
  override deploy(
    deployFeeReceiver: AddressLike,
    _feeOnDeploy: BigNumberish,
    _feeOnGM: BigNumberish,
    overrides?: PayableOverrides & { from?: string },
  ) {
    return super.deploy(deployFeeReceiver, _feeOnDeploy, _feeOnGM, overrides || {}) as Promise<
      RhinoGMContract & {
        deploymentTransaction(): ContractTransactionResponse
      }
    >
  }
  override connect(runner: ContractRunner | null): RhinoGMContract__factory {
    return super.connect(runner) as RhinoGMContract__factory
  }

  static readonly bytecode = _bytecode
  static readonly abi = _abi
  static createInterface(): RhinoGMContractInterface {
    return new Interface(_abi) as RhinoGMContractInterface
  }
  static connect(address: string, runner?: ContractRunner | null): RhinoGMContract {
    return new Contract(address, _abi, runner) as unknown as RhinoGMContract
  }
}
