import { useMemo } from 'react'
import type { GetGemsBalanceResponse } from '@rhinofi/rhino-nation-api-spec'
import { isFreeMintCampaignActive } from '../utils/isFreeMintCampaignActive'
import { useGemsBalance } from './useGemsBalance'
import { useHasUserFullNationAccess } from './useHasUserFullNationAccess'
import { useAppSelector } from '../../../hooks'
import { selectIsAuthenticated } from '../../../reducers/userSelector'

const GEMS_RECEIVED_FOR_MINTING_PASSPORT = isFreeMintCampaignActive() ? 40 : 160

type Props = {
  useGemsForMintingPassport: boolean
}

export const useBannerGemsBalance = (props?: Props) => {
  const isAuthenticationDone = useAppSelector(selectIsAuthenticated)
  const { currentData, isLoading, error } = useGemsBalance({ beforeFullAccess: true })
  const hasFullNationAccess = useHasUserFullNationAccess()

  const noAccessGems = useMemo(() => {
    if (props?.useGemsForMintingPassport) {
      return GEMS_RECEIVED_FOR_MINTING_PASSPORT
    }

    return currentData ? currentData.balance : 0
  }, [currentData, props])

  const gemsBalance: GetGemsBalanceResponse = currentData
    ? {
        ...currentData,
        balance: hasFullNationAccess ? currentData.balance : noAccessGems,
      }
    : {
        _id: 'gems_id',
        balance: noAccessGems,
        spent: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
      }

  const rxp = isAuthenticationDone ? gemsBalance.balance + gemsBalance.spent : '??'
  const gems = isAuthenticationDone ? gemsBalance.balance : '??'

  return {
    rxp,
    gems,
    isLoading,
    error,
  }
}
