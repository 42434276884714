import { Icon, IconSizes } from '@rhinofi/dvf-shared-ui'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import type { FC, MouseEvent, PropsWithChildren } from 'react'
import { useState, useEffect, useRef, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { translate } from '../../../intl/i18n'

type CopyProps = {
  text: string
  preventDefault?: boolean
  disabled?: boolean
  id?: string
  size?: IconSizes
  showLeft?: boolean
  showTop?: boolean
  hideIcon?: boolean
  copiedText?: string
}

type UseCopyTextProps = {
  disabled: boolean
  preventDefault?: boolean | undefined
  text: string
}

// eslint-disable-next-line react-refresh/only-export-components -- Legacy
export const useCopyText = ({ disabled, preventDefault, text }: UseCopyTextProps) => {
  const [copyPopUp, setCopyPopUp] = useState('')
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null)
  const copyToClipboard = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (disabled) {
        return
      }
      if (preventDefault) {
        event.preventDefault()
        event.stopPropagation()
      }
      setCopyPopUp('animate')
      const input = document.createElement('input')
      input.style.position = 'fixed'
      input.style.opacity = '0'
      input.value = text
      document.body.appendChild(input)
      /* Get the text field */
      try {
        /* Select the text field */
        input.focus()
        input.select()

        /* Copy the text inside the text field */
        document.execCommand('copy')
      } catch {
        //
      }
      document.body.removeChild(input)
      timeout.current = setTimeout(() => {
        setCopyPopUp('')
      }, 2000)
    },
    [disabled, preventDefault, text],
  )

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [])

  return { copyToClipboard, copyPopUp }
}

export const Copy: FC<PropsWithChildren<CopyProps>> = ({
  children,
  text,
  preventDefault,
  disabled = false,
  id = '',
  size = IconSizes.Small,
  showLeft = false,
  showTop = false,
  hideIcon = false,
  copiedText = translate('global.address_copied'),
}) => {
  const { copyToClipboard, copyPopUp } = useCopyText({ disabled, preventDefault, text })
  return (
    <CopyWrapper onClick={copyToClipboard} $disabled={disabled} id={id}>
      {children}
      {!hideIcon && <Icon id="files" active={!disabled} size={size} />}
      <CopyPopUp className={copyPopUp} $showLeft={showLeft} $showTop={showTop}>
        {copiedText}
      </CopyPopUp>
    </CopyWrapper>
  )
}

type CopyPopUpProps = {
  $showLeft?: boolean
  $showTop?: boolean
}

export const CopyPopUp = styled.div<CopyPopUpProps>`
  position: absolute;
  color: ${({ theme }) => theme.secondary};
  z-index: 1;
  margin: auto;
  background-color: ${({ theme }) => theme.brandA2};
  border-radius: 4px;
  padding: 4px 8px;
  word-break: keep-all;
  width: max-content;
  height: 24px;
  font-size: 13px;
  line-height: 16px;
  pointer-events: none;
  font-weight: normal;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;

  &.animate {
    opacity: 1;
  }
  ${({ $showLeft, $showTop }) =>
    $showLeft
      ? css`
          right: 100%;
          left: auto;
          top: 0;
          bottom: 0;
        `
      : $showTop
        ? css`
            /* top: 0; */
            bottom: 110%;
            left: auto;
            right: auto;
          `
        : css`
            left: 100%;
            top: 0;
            bottom: 0;
          `};

  @media ${respondBelow('xxs')} {
    position: fixed;
    top: 16px;
    left: 0;
    right: 0;
    bottom: auto;
  }
`

const CopyWrapper = styled.div<{ $disabled: boolean }>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  gap: 4px;
  svg {
    transition: opacity 0.1s linear;

    &:active {
      color: ${(props) => props.theme.brandA1};
    }

    &:hover {
      opacity: 0.6;
    }
  }
`
