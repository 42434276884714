import type { GetGemsBalanceResponse } from '@rhinofi/rhino-nation-api-spec'
import type { PerkWithUserData } from '../types/nation.types'

import { translate } from '../../../intl/i18n'
import { hasDatePassed } from '../../../utils/hasDatePassed'

export const canUserClaimPerk = ({
  perkWithUserData: { perk, userData },
  userGems,
}: {
  perkWithUserData: PerkWithUserData
  userGems?: GetGemsBalanceResponse | undefined
}): {
  type: 'rejected' | 'allowed'
  subType?: 'claimedMax' | 'claimedPartial'
  message: string
} => {
  if (!perk.startDate || (perk.startDate && !hasDatePassed(perk.startDate))) {
    return {
      type: 'rejected',
      message: translate('bridge.coming_soon'),
    }
  }

  if (perk.endDate && hasDatePassed(perk.endDate)) {
    return {
      type: 'rejected',
      message: translate('helpers.finished'),
    }
  }

  if (perk.isUpcoming) {
    return {
      type: 'rejected',
      message: translate('helpers.upcoming'),
    }
  }

  if ('maxStock' in perk && perk.maxStock === 0) {
    return {
      type: 'rejected',
      message: translate('nation.no_stock_available'),
    }
  }

  if ('currentStock' in perk && perk.currentStock === 0) {
    return {
      type: 'rejected',
      message: translate('helpers.sold_out'),
    }
  }

  if (!userGems || (userGems && perk.gemsPrice > userGems.balance)) {
    return {
      type: 'rejected',
      message: translate('nation.insufficient_gems'),
    }
  }

  if (userData && perk.maxClaimableTimes) {
    if (userData.timesPurchased >= perk.maxClaimableTimes) {
      return {
        type: 'rejected',
        subType: 'claimedMax',
        message: '',
      }
    }

    if (userData.timesPurchased < perk.maxClaimableTimes) {
      return {
        type: 'allowed',
        subType: 'claimedPartial',
        message: translate('helpers.claim_another'),
      }
    }
  }

  return {
    type: 'allowed',
    message: translate('helpers.claim'),
  }
}
