import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { Highlights } from '@rhinofi/campaigns-api-spec'
import { envConfig } from '../../../env/envConfig'

const baseUrl = `${envConfig.tradingApi}/campaigns`

export const highlightsApi = createApi({
  reducerPath: 'highlightsApi',
  baseQuery: fetchBaseQuery({ baseUrl }),
  tagTypes: ['Highlights'],
  endpoints: (builder) => ({
    getHighlightsById: builder.query<Highlights, string>({
      query: (highlightsId) => ({ url: `/highlights/${highlightsId}` }),
      providesTags: ['Highlights'],
    }),
  }),
})

export const { useGetHighlightsByIdQuery } = highlightsApi
