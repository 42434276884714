import { PerkTypeSchema } from '@rhinofi/rhino-nation-api-spec'
import type { PerkType } from '@rhinofi/rhino-nation-api-spec'
import { translate } from '../../../intl/i18n'

export const getRewardLabelForPerkType = (perkType: PerkType) => {
  switch (perkType) {
    case PerkTypeSchema.enums.VOUCHER:
      return translate('nation.voucher_code')
    default:
      return ''
  }
}
