import { useAppSelector } from '../../../hooks/useAppSelector'
import { selectAddress } from '../../../actions/selectors/userSelectors'
import { useNationStatus } from '../../../hooks/nation/useNationStatus'
import { NationUserStatus } from '../types/nation.types'
import { selectIsAuthenticated } from '../../../reducers/userSelector'

export const useHasUserFullNationAccess = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const { status } = useNationStatus()
  const address = useAppSelector(selectAddress)

  return status === NationUserStatus.Joined && isAuthenticated && address
}
