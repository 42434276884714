import { useState } from 'react'
import { CookiesPopup } from '../CookiesPopup/CookiesPopup'
import { UserVerificationModal, VerificationType } from '../UserVerification/UserVerificationModal'
import { modalKeys } from '../../constants/modalKeys'
import { useAppSelector } from '../../hooks'
import { AuthenticationModal } from '../Modals/AuthenticationModal/AuthenticationModal'
import { NewUserEmailSubscribe } from '../Modals/NewUserEmailSubscribe/NewUserEmailSubscribe'
import { ConnectToParadexModal } from '../Modals/ConnectToParadexModal/ConnectToParadexModal'
import { NonEvmReconnectModal } from '../Modals/NonEvmReconnectModal/NonEvmReconnectModal'
import { WalletNotInstalledModal } from '../Modals/WalletNotInstalledModal/WalletNotInstalledModal'
import { NFTMintProgressModal } from '../../pages/Chain/components/NFTMintModal'
import { ContractDetailsModal } from '../../pages/Chain/components/ContractDetailsModal'
import { selectModal } from '../../actions/selectors/modalSelectors'
import { ConnectWalletModal } from '../Modals/ConnectWalletModal/ConnectWalletModal'
import { RequireChainModal } from '../Modals/RequireChainModal/RequireChainModal'
import { RecentBridgeTxModal } from '../../pages/Bridge/components/RecentBridgeTransactions/RecentBridgeTxModal'
import { SpendingCapModal } from '../Modals/SpendingCapModal/SpendingCapModal'
import { NationActiveMissionModal } from '../../pages/Nation/components/NationContent/NationActiveMissionModal/NationActiveMissionModal'
import { NationCompletedMissionModal } from '../../pages/Nation/components/NationContent/NationCompletedMissionModal/NationCompletedMissionModal'
import { NationActivePerkModal } from '../../pages/Nation/components/NationContent/NationPerksModals/NationActivePerkModal/NationActivePerkModal'
import { NationClaimedPerkModal } from '../../pages/Nation/components/NationContent/NationPerksModals/NationClaimedPerkModal/NationClaimedPerkModal'
import { NationOnboardingModal } from '../../pages/Nation/components/NationContent/NationOnboardingModal/NationOnboardingModal'
import { ViewNFTModal } from '../../pages/Chain/components/ViewNFTModal'
import { ContractShareModal } from '../../pages/Chain/components/ContractShareModal'
import { ContractDeployModal } from '../../pages/Chain/components/ContractDeployModal/ContractDeployModal'
import { ContractGMModal } from '../../pages/Chain/components/ContractGMModal/ContractGMModal'

export const GlobalModals = () => {
  const [showCookiePopup, setShowCookiePopup] = useState(localStorage.getItem('cookies-confirmed') === null)
  const modal = useAppSelector(selectModal)

  const toggleCookiePopup = () => {
    setShowCookiePopup(!showCookiePopup)
  }

  return (
    <>
      <RequireChainModal />
      {/* Modal shown */}
      <AuthenticationModal />
      {modal.modalShown === modalKeys.noWallet && <WalletNotInstalledModal />}
      {modal.modalShown === modalKeys.newUserEmail && <NewUserEmailSubscribe />}
      {modal.modalShown === modalKeys.recentBridgeTx && <RecentBridgeTxModal />}
      {modal.modalShown === modalKeys.nftMint && <NFTMintProgressModal {...modal.modalPayload} />}
      {modal.modalShown === modalKeys.viewNftMinted && <ViewNFTModal {...modal.modalPayload} />}
      {modal.modalShown === modalKeys.contractShare && <ContractShareModal {...modal.modalPayload} />}
      {modal.modalShown === modalKeys.contractDeploy && <ContractDeployModal {...modal.modalPayload} />}
      {modal.modalShown === modalKeys.contractDetails && <ContractDetailsModal {...modal.modalPayload} />}
      {modal.modalShown === modalKeys.contractGM && <ContractGMModal {...modal.modalPayload} />}
      {/* TODO DOPE-424 - Add campaigns */}
      {/* {modal.modalShown === modalKeys.claimMilestoneReward && <MilestoneCampaignClaimModal {...modal.modalPayload} />} */}
      {modal.modalShown === modalKeys.connectWallet && <ConnectWalletModal />}
      {modal.modalShown === modalKeys.connectToParadex && <ConnectToParadexModal />}
      {modal.modalShown === modalKeys.reconnectModal && <NonEvmReconnectModal />}
      {modal.modalShown === modalKeys.spendingCap && <SpendingCapModal />}
      {modal.modalShown === modalKeys.nationOnboarding && <NationOnboardingModal />}
      {modal.modalShown === modalKeys.nationActiveMission && <NationActiveMissionModal {...modal.modalPayload} />}
      {modal.modalShown === modalKeys.nationCompletedMission && <NationCompletedMissionModal {...modal.modalPayload} />}
      {modal.modalShown === modalKeys.nationActivePerk && <NationActivePerkModal {...modal.modalPayload} />}
      {modal.modalShown === modalKeys.nationClaimedPerk && <NationClaimedPerkModal {...modal.modalPayload} />}
      {[modalKeys.userVerificationEmail, modalKeys.userVerificationPhone].includes(
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- cast because includes type is too narrow: https://github.com/microsoft/TypeScript/issues/26255
        modal.modalShown as typeof modalKeys.userVerificationEmail | typeof modalKeys.userVerificationPhone,
      ) && (
        <UserVerificationModal
          verificationType={
            modal.modalShown === modalKeys.userVerificationEmail ? VerificationType.email : VerificationType.phone
          }
          {...(modal.modalShown === modalKeys.userVerificationEmail
            ? {
                onComplete: modal.modalPayload.onComplete,
                initialStep: modal.modalPayload.initialStep,
                autoFocus: modal.modalPayload.autoFocus,
              }
            : {})}
        />
      )}
      {showCookiePopup && modal.modalShown !== modalKeys.connectWallet && (
        <CookiesPopup toggleCookiePopup={toggleCookiePopup} />
      )}
    </>
  )
}
