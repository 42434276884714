// export const showOrderConfirmation = (option: string) => {
//   localStorage.setItem('showOrderConfirmation', option)
// }

// export const getShouldShowOrderConfirmation = () => {
//   try {
//     const setting = localStorage.getItem('showOrderConfirmation')
//     if (setting === null) {
//       return true
//     }
//     return JSON.parse<boolean>(setting)
//   } catch (error) {
//     return true
//   }
// }

export const setShouldUseCookies = (value: boolean) => {
  localStorage.setItem('cookies-confirmed', String(value))
}

// export const shouldUseCookies = () => {
//   const shouldUse = localStorage.getItem('cookies-confirmed') || 'false'
//   return JSON.parse<boolean>(shouldUse)
// }
