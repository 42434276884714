import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import {
  resetNationWidgetSelectedFilters,
  setNationWidgetSelectedChain,
  setNationWidgetSelectedFeature,
} from '../slices/nationWidget.slice'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { selectNationWidgetChainOptions, selectNationWidgetFeatureOptions } from '../selectors/nationWidget.selectors'
import { NATION_ALL_FILTER_OPTION_DATA } from '../constants/nationAllFilterOptionData.constants'
import {
  NATION_WIDGET_FILTERS_MISSIONS_ROUTES_CHECK,
  NATION_WIDGET_FILTERS_MISSIONS_ROUTES_PARAMS_CHECK,
  NATION_WIDGET_MISSIONS_ROUTES_CHECK_KEYS,
} from '../constants/nationWidgetFiltersPageTracking.constants'
import { getNationWidgetFiltersParamFromUrl } from '../utils/getNationWidgetFiltersParamFromUrl'

enum HitType {
  CHAIN = 'CHAIN',
  FEATURE = 'FEATURE',
}

type Hit = {
  value: string
  type: HitType
}

export const useNationWidgetFiltersPageTracking = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()

  const featureOptions = useAppSelector(selectNationWidgetFeatureOptions)
  const chainOptions = useAppSelector(selectNationWidgetChainOptions)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    const hits = NATION_WIDGET_MISSIONS_ROUTES_CHECK_KEYS.reduce<Hit[]>((hitsAcc, missionType) => {
      const routeToCheck = NATION_WIDGET_FILTERS_MISSIONS_ROUTES_CHECK[missionType]
      const routeParamCheckOption = NATION_WIDGET_FILTERS_MISSIONS_ROUTES_PARAMS_CHECK[missionType]

      const param = getNationWidgetFiltersParamFromUrl({
        pathname: location.pathname,
        routeParamCheckOption,
        searchParams,
      })

      const routeHit = location.pathname.includes(routeToCheck)

      if (routeHit) {
        const existsAsOption = featureOptions.find(({ key }) => key === missionType)
        hitsAcc.push({
          type: HitType.FEATURE,
          value: existsAsOption ? missionType : NATION_ALL_FILTER_OPTION_DATA.key,
        })
      }

      if (routeHit && !!param) {
        const existsAsOption = chainOptions.find(({ key }) => key === param)
        hitsAcc.push({ type: HitType.CHAIN, value: existsAsOption ? param : NATION_ALL_FILTER_OPTION_DATA.key })
      }

      return hitsAcc
    }, [])

    if (hits.length > 0) {
      hits.forEach(({ type, value }) => {
        if (type === HitType.CHAIN) {
          dispatch(setNationWidgetSelectedChain(value))
        }

        if (type === HitType.FEATURE) {
          dispatch(setNationWidgetSelectedFeature(value))
        }
      })
    } else {
      dispatch(resetNationWidgetSelectedFilters())
    }
  }, [location, dispatch, chainOptions, featureOptions])
}
