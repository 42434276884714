import type { BridgeTokenEntry } from '@rhinofi/bridge-api-spec'
import Decimal from 'decimal.js'
import { isNull, isUndefined } from 'lodash-es'
import { translate } from '../../../intl/i18n'
import { formatFloat } from '../../../services/helperService/formatFloat'
import { bridgeWidgetLabels } from '../constants/bridgeWidgetLabels.constants'
import { makeBaseAmountValidator } from './helpers/makeBaseAmountValidator'

export const bridgeAmountValidator = makeBaseAmountValidator<{
  // General
  chain: string
  token?: string
  nativeToken?: string
  payAmount: string
  // Deposit
  depositLimit: BridgeTokenEntry['maxDepositLimit']
  needsMoreNativeForTx?: boolean
  safeMax?: string | number
  safeguardAmount?: number
  // Withdrawal
  feeAmount: string | number
  withdrawalLimit: string | number | undefined
  nativeTokenCost?: string | number
}>({})
  .test('safeguard-warning', '', (_, { options, createError }) => {
    const { payAmount, balance } = options.context || {}
    if (!payAmount || !balance) {
      return true
    }

    const { token, nativeToken, safeguardAmount } = options.context || {}

    if (token !== nativeToken) {
      return true
    }
    if (new Decimal(balance).minus(payAmount.toString()).lt(safeguardAmount || 0)) {
      return createError({
        message: translate('global.native_token_gas_warning', {
          '%token': nativeToken || '',
          '%amount': safeguardAmount || '',
        }),
      })
    }

    return true
  })
  .test('deposit-limit', translate('errors.deposit_above_limit'), (_, { options, createError }) => {
    const { depositLimit, token, chain, payAmount, feeAmount } = options.context || {}
    if (
      !depositLimit ||
      !payAmount ||
      isNull(depositLimit) ||
      new Decimal(payAmount.toString()).lte(new Decimal(depositLimit.toString()))
    ) {
      return true
    }
    if (parseFloat(depositLimit.toString()) === 0) {
      return createError({
        message: translate('errors.bridge_limit_zero', {
          '%chain': chain || '',
          '%token': token || '',
        }),
      })
    }
    return createError({
      message: translate('errors.bridge_above_limit', {
        '%depositLimit': `${formatFloat(depositLimit || 0)} ${token}`,
        '%payAmount': `${formatFloat(new Decimal(depositLimit).minus(feeAmount || '0'))} ${token}`,
      }),
    })
  })
  .test('pay-for-gas', '', (_value, { options, createError }) => {
    const { needsMoreNativeForTx, nativeToken, safeguardAmount } = options.context || {}

    if (!needsMoreNativeForTx || !nativeToken) {
      return true
    }

    return createError({
      message: translate(safeguardAmount ? 'global.non_native_token_gas_warning' : 'global.zero_gas_warning', {
        '%token': nativeToken || '',
        '%amount': safeguardAmount || '',
      }),
    })
  })
  // Withdraw amount not less than or equal to fee amount
  .test('min-fee', translate('errors.withdrawal_below_min'), (value, { options, createError }) => {
    const { feeAmount, token, nativeTokenCost } = options.context || { feeAmount: 0 }
    if (!value) {
      return true
    }
    const totalFee = new Decimal(nativeTokenCost || '0').add(feeAmount || '0').toString()
    if (nativeTokenCost && new Decimal(value.toString()).lt(totalFee)) {
      return createError({
        message: bridgeWidgetLabels.withdrawalBelowMinimum(totalFee, token || ''),
      })
    }

    if (new Decimal(value.toString()).lte(feeAmount)) {
      return createError({
        message: bridgeWidgetLabels.withdrawalBelowMinimum(feeAmount, token || 'TOKEN'),
      })
    }
    return true
  })
  // Withdraw amount is not greater than withdrawal limit
  .test('withdrawal-limit', translate('errors.withdrawal_above_limit'), (value, { options, createError }) => {
    const { withdrawalLimit, token } = options.context || {
      withdrawalLimit: 0,
    }

    if (!value || isUndefined(withdrawalLimit)) {
      return true
    }

    if (new Decimal(value.toString()).gt(new Decimal(withdrawalLimit.toString()))) {
      return createError({
        message: bridgeWidgetLabels.withdrawalAboveLimit(withdrawalLimit, token || 'TOKEN'),
      })
    }
    return true
  })
