import type { BridgeConfigEntrySchema } from '@rhinofi/bridge-api-spec'

export type SupportedKits = 'eip1193'

export const KitType = {
  eip1193: 'eip1193',
} as const

export type EvmConnectionKit = {
  type: SupportedKits
  connect: ({
    onAccountChange,
    onNetworkChange,
  }: {
    onAccountChange: (account: string) => void
    onNetworkChange: (networkId: string) => void
  }) => Promise<{ address: string | null }>
  disconnect: () => Promise<{ disconnected: boolean }>
  getIsConnected: () => boolean
  getAddress: () => string | null
  switchNetwork: (networkId: string, chainConfig: BridgeConfigEntrySchema) => Promise<void>
}
