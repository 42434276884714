import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  resetProgress,
  selectBridgeProgress,
  updateProgress,
  type BridgeProgressState,
} from '../slices/bridgeProgress.slice'

type UseBridgeProgressOutput = {
  progress: BridgeProgressState
  setProgress: (stateUpdater: (state: BridgeProgressState) => Partial<BridgeProgressState>) => void
  resetProgressState: () => void
}

export const useBridgeProgress = (): UseBridgeProgressOutput => {
  const dispatch = useAppDispatch()
  const progress = useAppSelector(selectBridgeProgress)

  const setProgress = useCallback(
    (stateUpdater: (state: BridgeProgressState) => Partial<BridgeProgressState>) => {
      dispatch(updateProgress(stateUpdater))
    },
    [dispatch],
  )

  const resetProgressState = useCallback(() => {
    dispatch(resetProgress())
  }, [dispatch])

  return { progress, setProgress, resetProgressState }
}
