import type { BridgeConfigsResponseSchema } from '@rhinofi/bridge-api-spec'
import { initialState, resetBridge, setBridgeState, type BridgeState } from '../../pages/Bridge/slices/bridge.slice'
import type { AppDispatch } from '../../store/configureStore'
import { LogFeature, makeLog } from '../makeLog'

const log = makeLog(LogFeature.BRIDGE_STATE)

const keysToSkip = ['isOtherAddress']

/**
 * Retrieves the bridge state from the current URL query parameters.
 * If a parameter is missing, default values are returned.
 *
 * @returns A `BridgeState` object derived from the URL.
 */
export const getStateFromUrl = (): BridgeState => {
  const urlParams = new URLSearchParams(window.location.search)

  // Replace `chain` with `chainIn` in the URL for backward compatibility
  if (urlParams.has('chain') && !urlParams.has('chainIn')) {
    const chain = urlParams.get('chain')
    urlParams.set('chainIn', chain || '')
    urlParams.delete('chain')

    // Update the URL without triggering a page reload
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`
    window.history.replaceState({}, '', newUrl)
  }

  return {
    chainIn: urlParams.get('chainIn') || '',
    chainOut: urlParams.get('chainOut') || '',
    token: urlParams.get('token') || '',
    amount: urlParams.get('amount') || '',
    recipient: urlParams.get('recipient') || '',
    isOtherAddress: urlParams.get('recipient') !== '' && urlParams.get('recipient') !== null,
  }
}
/**
 * Updates the URL to reflect the current bridge state.
 * The state is serialized into query parameters.
 */
export const updateUrlFromState = (state: BridgeState): void => {
  const urlParams = new URLSearchParams()
  Object.entries(state).forEach(([key, value]) => {
    if (value !== null && value !== undefined && value !== '' && !keysToSkip.includes(key)) {
      urlParams.set(key, String(value))
    }
  })
  const newUrl = `${window.location.pathname}?${urlParams.toString()}`
  log('updateUrlFromState', { state, urlParams, newUrl })
  window.history.pushState({}, '', newUrl)
}
/**
 * Validates if the given bridge configuration is valid based on the chain and token information.
 */
export const isValidBridgeCombination = (
  { chainIn, chainOut, token }: Omit<BridgeState, 'amount'>,
  chainConfig: BridgeConfigsResponseSchema | undefined,
): boolean => {
  if (!chainConfig) {
    log('[isValidBridgeCombination]: No chain config')
    return false
  }

  const sourceChainConfig = chainConfig[chainIn]
  const targetChainConfig = chainConfig[chainOut]

  if (!sourceChainConfig || !targetChainConfig) {
    return false
  }

  if (sourceChainConfig.status !== 'enabled' || targetChainConfig.status !== 'enabled') {
    return false
  }

  if (sourceChainConfig.networkId === targetChainConfig.networkId) {
    return false
  }

  if (!sourceChainConfig.tokens?.[token] || !targetChainConfig.tokens?.[token]) {
    return false
  }

  return true
}
/**
 * Resets the bridge state to its initial values if the provided combination is invalid.
 * Updates the URL to reflect the reset state.
 */
export const resetInvalidBridgeCombination = (
  dispatch: AppDispatch,
  scope: string,
  { chainIn, chainOut, token }: Omit<BridgeState, 'amount'>,
) => {
  log(
    `[${scope}] Invalid bridge combination. Bridge from ${chainIn} to ${chainOut} with token ${token} is not valid. Resetting to default values`,
  )
  dispatch(resetBridge())
  updateUrlFromState(initialState)
}
/**
 * Synchronizes the state from the URL and validates the retrieved combination.
 * If invalid, resets to default values.
 */
export const syncStateFromUrl = (dispatch: AppDispatch, chainConfig: BridgeConfigsResponseSchema) => {
  const urlState = getStateFromUrl()

  log('[syncStateFromUrl]:', { chainConfig, urlState })

  if (!isValidBridgeCombination(urlState, chainConfig)) {
    return resetInvalidBridgeCombination(dispatch, 'syncStateFromUrl', urlState)
  }

  dispatch(setBridgeState(urlState))
}

/**
 * Returns the new chainIn and chainOut values based on the available chains and the current bridge state, trying to keep original values if possible.
 */
export const getValidChains = (
  availableChains: { chain: string }[],
  bridgeState: BridgeState,
): { newChainIn: string | undefined; newChainOut: string | undefined } => {
  const availableChainsSet = new Set(availableChains.map(({ chain }) => chain))

  // Determine new chainIn
  const newChainIn = availableChainsSet.has(bridgeState.chainIn)
    ? bridgeState.chainIn
    : availableChains.find(({ chain }) => chain !== bridgeState.chainOut)?.chain ?? availableChains[0]?.chain

  // Determine new chainOut
  const newChainOut =
    availableChainsSet.has(bridgeState.chainOut) && bridgeState.chainOut !== newChainIn
      ? bridgeState.chainOut
      : availableChains.find(({ chain }) => chain !== newChainIn)?.chain ?? availableChains[0]?.chain

  return { newChainIn, newChainOut }
}
