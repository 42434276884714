import type { Direction } from './SlideInTooltip.types'

export const getFinalStyle = (direction: Direction) => {
  switch (direction) {
    case 'left':
      return {
        x: '0px',
        opacity: 1,
      }
    case 'right':
      return {
        x: '0px',
        opacity: 1,
      }
    case 'top':
      return {
        y: '0px',
        opacity: 1,
      }
    case 'bottom':
      return {
        y: '0px',
        opacity: 1,
      }
    default:
      return {}
  }
}
