import { useEffect } from 'react'
import { usePostDailyCheckinMutation } from '../services/nation.api'
import type { MissionsWithUserData } from '../types/nation.types'
import { selectIsPollingDailyCheckin } from '../selectors/nation.selectors'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { setDailyCheckinPolling } from '../slices/nation.slice'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useMissionsWithUserData } from './useMissionsWithUserData'

const userMissionDataPollInterval = 5000

const hasCheckedIn = (missionsWithUserData: MissionsWithUserData | null) => {
  if (!missionsWithUserData) {
    return false
  }
  return missionsWithUserData.items.some((item) => {
    const { mission, userData } = item
    return mission.type === 'DAILY_CHECKIN' && userData?.totalTimesAchieved === 1
  })
}

export const useDailyCheckin = () => {
  const dispatch = useAppDispatch()
  const [postDailyCheckin, postRequest] = usePostDailyCheckinMutation()
  const isPollingDailyCheckin = useAppSelector(selectIsPollingDailyCheckin)
  const missionsWithUserData = useMissionsWithUserData({
    frequency: 'ONE_TIME',
    userMissionDataPollInterval: isPollingDailyCheckin ? userMissionDataPollInterval : 0,
  })
  const didCheckIn = hasCheckedIn(missionsWithUserData.data)

  useEffect(() => {
    if (!isPollingDailyCheckin && postRequest.startedTimeStamp) {
      dispatch(setDailyCheckinPolling(true))
    } else if (isPollingDailyCheckin && didCheckIn) {
      dispatch(setDailyCheckinPolling(false))
    }
  }, [dispatch, postRequest.startedTimeStamp, didCheckIn, isPollingDailyCheckin])

  return {
    didCheckIn,
    postDailyCheckin,
    error: postRequest.error || missionsWithUserData.error,
    pending: postRequest.isLoading || isPollingDailyCheckin,
  }
}
