import { animated } from '@react-spring/web'
import styled, { css } from 'styled-components'
import SimpleBar from 'simplebar-react'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import { zIndices } from '../../../constants/zIndex'
import { Title } from '@rhinofi/dvf-shared-ui'

export const ModalWrapper = styled.div<{ $hasCustomOverlay: boolean; $noFade: boolean; $mobileFullScreen?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ $mobileFullScreen }) =>
    $mobileFullScreen ? zIndices.modalMobileFullscreen : zIndices.modalDesktop}; // allow mobile nav on top
  overflow: hidden;
  margin: auto auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 64px 0 0 0;
  backdrop-filter: blur(3px);

  &.modal-transition-appear,
  &.modal-transition-enter {
    opacity: 0;
  }

  &.modal-transition-appear-active,
  &.modal-transition-enter-active,
  &.modal-transition-exit {
    opacity: 1;
  }

  &.modal-transition-exit-active {
    opacity: 0;
  }

  &.modal-transition-appear-active,
  &.modal-transition-enter-active,
  &.modal-transition-exit-active {
    transition: opacity 100ms linear;
  }

  ${(props) =>
    props.$noFade &&
    css`
      background-color: transparent;
    `}

  @media ${respondBelow('xxs')} {
    padding-top: 16px;
  }
`
export const ModalOverlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-family: ${({ theme }) => theme.defaultFont};
  background: #000000;
  opacity: 0.4;

  transition: all 0.15s ease-in-out;
`
export const ModalTitle = styled(Title)`
  display: block;
  max-width: 95%;
  margin: 8px 0 16px 8px;

  @media ${respondBelow('xxs')} {
    text-align: center;
  }
`

export const CloseWrapper = styled.div<{ $hasTitle?: boolean }>`
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1;

  ${({ $hasTitle }) =>
    $hasTitle
      ? css`
          top: 20px;
        `
      : ''}
`

export const NeonWrapper = styled(animated.div)<{
  $width?: string
  $maxWidth?: string
  $background?: string
  $backgroundTransitionSpeed?: string
  $maxHeight?: boolean
  $mobileFullScreen?: boolean
  $hideScrollbar?: boolean
}>`
  position: relative;
  max-height: calc(100vh - 32px);
  overflow-x: ${({ $maxHeight }) => ($maxHeight ? 'unset' : 'hidden')};
  overflow-y: ${({ $maxHeight }) => ($maxHeight ? 'unset' : 'auto')};

  ${({ $hideScrollbar }) =>
    $hideScrollbar &&
    css`
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    `}

  padding: 2px;

  @media ${respondBelow('xxs')} {
      padding-bottom: 64px;
      max-width: 95vw;
      
      .modal-card-wrapper {
          margin-bottom: 96px;
      }
  }

  & > div {
    max-width: ${({ $maxWidth }) => $maxWidth || '100%'};
    width: ${({ $width }) => $width || '100%'};

    & > div {
      transition: ${({ $backgroundTransitionSpeed }) =>
        $backgroundTransitionSpeed
          ? `background ${$backgroundTransitionSpeed} ease-in-out`
          : 'background 0.15s ease-in-out'};
      background: ${({ $background }) => $background || ''}};
    }
  }

  @media ${respondBelow('xxs')} {
    box-sizing: border-box;
    ${({ $mobileFullScreen }) =>
      $mobileFullScreen
        ? css`
            max-height: unset;
            width: 100vw;
            height: 100vh;
            padding: 0px;

            & > div {
              max-width: 100%;
              width: 100%;
              height: 100%;
            }
          `
        : css`
            max-width: calc(100vw - 8px);
            max-height: 100vh;
            max-height: -webkit-fill-available;

            & > div {
              max-width: 100%;
              height: 100%;
            }
          `}
  }

  @media all and (max-width: 400px) {
    padding-bottom: 0;
  }
`

export const ModalSimpleBar = styled(SimpleBar)<{ $maxHeight: boolean | number }>`
  max-height: ${({ $maxHeight }) => ($maxHeight === true ? 'calc(100vh - 80px)' : `${$maxHeight}px`)};
  padding: 16px;
  margin: -16px;
`

export const MobileFullScreenCard = styled.div`
  padding: 16px 16px 80px !important;
  box-sizing: border-box;
  background: ${({ theme }) => theme.elevationL1};
  overflow: auto;
  position: relative;
`

export const BackWrapper = styled.div`
  position: absolute !important;
  top: 24px;
  left: 10px;
  z-index: 1;
`
