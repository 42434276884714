import type { BridgeConfigEntrySchema } from '@rhinofi/bridge-api-spec'
import type React from 'react'
import { useCallback, useEffect, useMemo, type PropsWithChildren } from 'react'
import { useMatch } from 'react-router-dom'
import { useAppDispatch } from '../../hooks'
import { publicRoutes } from '../../router'
import { useGetConfigQuery } from '../../store/apis/config.api'
import { LogFeature, makeLog } from '../../utils/makeLog'
import { syncStateFromUrl } from '../../utils/rtk/bridgeUrlSync'
import { ConfigContext } from './createdConfigContext'

const log = makeLog(LogFeature.CONFIG)

export type BridgeConfigEntry = BridgeConfigEntrySchema & {
  chainKey: string
}

export type BridgeConfig = Record<string, BridgeConfigEntry>

export const ConfigProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch()
  const query = useGetConfigQuery()
  const { data } = query
  const isBridgeRoute = useMatch(publicRoutes.bridge)

  const syncState = useCallback(() => {
    if (data && isBridgeRoute) {
      syncStateFromUrl(dispatch, data)
    }
  }, [data, dispatch, isBridgeRoute])

  useEffect(() => {
    syncState()

    window.addEventListener('popstate', syncState)

    return () => {
      window.removeEventListener('popstate', syncState)
    }
  }, [dispatch, data, isBridgeRoute, syncState])

  log('ConfigProvider', { data, isBridgeRoute })

  // loop through configs to add the key to the value as chainKey
  const value = useMemo(() => {
    if (!data) {
      return { config: data }
    }

    const filled = Object.entries(data).reduce<BridgeConfig>((acc, [key, recordValue]) => {
      acc[key] = { ...recordValue, chainKey: key }
      return acc
    }, {})

    return { config: filled }
  }, [data])

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
}
