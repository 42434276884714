import { Title, Icon, Spacing, Text, Button } from '@rhinofi/dvf-shared-ui'
import { TitleSize } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import { Modal } from '../../common/Modal/Modal'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { clearModal } from '../../../actions/modalActions/clearModal'
import { translate } from '../../../intl/i18n'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { selectModal } from '../../../actions/selectors/modalSelectors'
import { modalKeys } from '../../../constants/modalKeys'
import { selectBridgeConfigForChain } from '../../../store/selectors/bridgeConfig.selectors'

export const WalletNotInstalledModal = () => {
  const dispatch = useAppDispatch()
  const { modalPayload, modalShown } = useAppSelector(selectModal)
  const chain = modalShown === modalKeys.noWallet ? modalPayload.chain : ''
  const chainConfig = useAppSelector((state) => selectBridgeConfigForChain(state, chain))

  if (modalShown !== modalKeys.noWallet) {
    return null
  }

  return (
    <Modal isVisible onClose={() => clearModal(dispatch)()} width="360px" variant="secondary">
      <Title size={TitleSize.XXS}>
        <Icon id="info-square" /> {translate('onboarding.no_wallet_installed')}
      </Title>
      <Spacing size="16" />
      <Text bold>
        {translate('onboarding.no_wallet_installed_desc', {
          '%chain': chainConfig ? chainConfig.name : '',
        })}
      </Text>
      <Spacing size="16" />
      <Button id="close-no-wallet" fullWidth onClick={() => clearModal(dispatch)()}>
        {translate('helpers.close')}
      </Button>
    </Modal>
  )
}
