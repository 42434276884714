/* eslint-disable -- legacy */
import type React from 'react'
import { Icon, IconSizes, Text, TextSize, TypographyAlign } from '@rhinofi/dvf-shared-ui'
import { translate } from '../../../intl/i18n'

import { FullWidthCell } from './Table.Utils'

type TableInfoProps = {
  message?: string | boolean | React.ReactElement | null | undefined
  loading?: boolean | undefined
  size?: number
  big?: boolean | undefined
  children?: React.ReactNode
}

/**
 * TableInfo
 *
 * prepares an informative row about loading status, errors or empty list
 * returns null if everything is okay and contents can be rendered safely
 *
 * @param {String} message text to display
 * @param {Boolean} big gives extra padding space to the row
 * @param {Boolean} loading current loading status
 * @param {number} cols number of columns
 *
 * @returns {JSX} null if no info are required
 */
export const TableInfo = (props: TableInfoProps = {}) => {
  const { message = '', loading = false, size = 1, children, big = false } = props
  const content = message || children
  let el = null
  if (loading || content) {
    el = loading ? (
      <span className="show-pending pending show75">
        <Icon id="circle-o-notch" spinning size={IconSizes.Large} /> {translate('helpers.loading')}
      </span>
    ) : (
      <Text color="textSecondary" align={TypographyAlign.Center} size={TextSize.S}>
        {content}
      </Text>
    )
  }

  return el ? (
    <FullWidthCell big={big} size={size}>
      {' '}
      {el}{' '}
    </FullWidthCell>
  ) : null
}
