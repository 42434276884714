import styled from 'styled-components'
import { ButtonVariant, IconSizes } from '@rhinofi/dvf-shared-ui'
import { MissionTypeSchema } from '@rhinofi/rhino-nation-api-spec'
import { useAppDispatch } from '../../../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../../../hooks/useAppSelector'
import {
  selectNationWidgetFeatureOptions,
  selectNationWidgetSelectedFeature,
} from '../../../selectors/nationWidget.selectors'
import { setNationWidgetSelectedChain, setNationWidgetSelectedFeature } from '../../../slices/nationWidget.slice'
import { getNationWidgetFilterText } from '../../../utils/getNationWidgetFilterText'
import { trackHeapEvent } from '../../../../../services/apiService'
import { heapEvents } from '../../../../../constants/heapEvents'
import { NATION_ALL_FILTER_OPTION_DATA } from '../../../constants/nationAllFilterOptionData.constants'
import { CustomDropdownSelect } from './NationWidgetMissionsFilters.styled'
import { NationWidgetMissionsFilterItem } from './NationWidgetMissionsFilterItem'

export const NationWidgetMissionsFeatureFilter = () => {
  const dispatch = useAppDispatch()
  const selectedKey = useAppSelector(selectNationWidgetSelectedFeature)
  const items = useAppSelector(selectNationWidgetFeatureOptions)
  const formattedItems = items.map((data) => ({
    ...data,
    name: getNationWidgetFilterText(data.name),
  }))

  return (
    <FeaturesDropdownSelect
      id="nation-widget-mission-type-select"
      items={formattedItems}
      selectedKey={selectedKey}
      onSelect={(missionType) => {
        dispatch(setNationWidgetSelectedFeature(missionType))

        if (missionType === MissionTypeSchema.enums.REFERRAL || missionType === MissionTypeSchema.enums.ENTER_EMAIL) {
          dispatch(setNationWidgetSelectedChain(NATION_ALL_FILTER_OPTION_DATA.key))
        }

        trackHeapEvent(heapEvents.nationWidgetMissionTypeFilter, { type: missionType })
      }}
      chevronSize={IconSizes.XSmall}
      buttonVariant={ButtonVariant.ghost}
      openHeight="240px"
      renderItem={(item) => <NationWidgetMissionsFilterItem item={item} />}
    />
  )
}

const FeaturesDropdownSelect = styled(CustomDropdownSelect)`
  .select-list-wrapper {
    left: revert-layer;
  }
`
