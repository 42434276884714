import type { MissionType } from '@rhinofi/rhino-nation-api-spec'
import type { I18nData } from '../../../intl/i18n.types'

export type ActiveMissionType = Exclude<MissionType, 'CUSTOM'>
export type EnabledMissionsType = Exclude<
  MissionType,
  'CUSTOM' | 'REGISTER' | 'TRACKERS_SCORE' | 'GM_CONTRACT' | 'DAILY_CHECKIN' | 'DAILY_CHECKIN_STREAK'
>

export const MISSION_TYPE_ICON_ID_MAPPING: Record<ActiveMissionType, string> = {
  REGISTER: 'card-checklist',
  BRIDGE: 'rainbow',
  MINT_NFT: 'images',
  DEPLOY_CONTRACT: 'terminal',
  GM_CONTRACT: 'chat-right-heart',
  TRACKERS_SCORE: 'speedometer',
  REFERRAL: 'person-plus',
  ENTER_EMAIL: 'envelope-check',
  DAILY_CHECKIN: 'fire',
  DAILY_CHECKIN_STREAK: 'fire',
}

export const MISSION_TYPE_NAME_MAPPING: Record<EnabledMissionsType, keyof I18nData> = {
  BRIDGE: 'bridge.bridge',
  MINT_NFT: 'helpers.mint_nfts',
  DEPLOY_CONTRACT: 'helpers.deploy_contracts',
  REFERRAL: 'helpers.referral',
  ENTER_EMAIL: 'helpers.email',
}
