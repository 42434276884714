import { TokenIcon, Icon, Text, TextSize } from '@rhinofi/dvf-shared-ui'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'
import { PoweredByBanner, PoweredByLink } from './BridgePoweredBy.styled'
import { useBridgePoweredByConfig } from './useBridgePoweredByConfig'
import { useAppSelector } from '../../../../hooks'
import { selectBridge } from '../../slices/bridge.slice'

export const BridgePoweredBy = () => {
  const { chainIn, chainOut, token } = useAppSelector(selectBridge)
  const { title, asset, desc, desc2, url } = useBridgePoweredByConfig({ chainIn, chainOut, token })

  return (
    <PoweredByLink href={url} target="_blank" rel="noopener noreferrer">
      <PoweredByBanner>
        <FlexContainer $justifyContent="space-between">
          <FlexContainer $gap="12px">
            {asset.type === 'path' ? (
              <img className="powered-by-icon-logo" src={asset.value} alt="logo" />
            ) : (
              <TokenIcon token={asset.value} />
            )}
            <FlexContainer $direction="column" $alignItems="flex-start">
              <Text size={TextSize.S} bold color="textSecondary">
                {title}
              </Text>
              <div>
                <Text size={TextSize.XXS} color="textSecondary">
                  {desc}
                  <br />
                  {desc2}
                </Text>
              </div>
            </FlexContainer>
          </FlexContainer>
          <Icon id="chevron-right" />
        </FlexContainer>
      </PoweredByBanner>
    </PoweredByLink>
  )
}
