import styled from 'styled-components'
import { FlexContainer } from '../../../../../../../components/common/Wrappers/Containers'

export const ActionContainer = styled.div`
  flex-shrink: 0;
`

export const ClickContainer = styled.div<{ $clickable: boolean }>`
  width: 100%;
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};
`

export const CodeContainer = styled(FlexContainer)<{ $hasBorder?: boolean; $padding?: string }>`
  justify-content: center;
  padding: ${({ $padding = '7px' }) => $padding};
  border-radius: 6px;
  flex-grow: 1;
  background: ${({ theme }) => theme.elevationL2};
  border: ${({ theme, $hasBorder = true }) => ($hasBorder ? `1px solid ${theme.secondary40}` : 'none')};
`
