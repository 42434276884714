/* eslint-disable -- Legacy code */
/* tslint:disable */
import React from 'react'
import { connect } from 'react-redux'
import { groupBy as _groupBy } from 'lodash-es'

import Row from './Row'
import { Group } from './Group'

type TableBodyState = any
type TableBodyProps = {
  rowClasses?: string | undefined
  condensedView: boolean
  isSecondLevel?: boolean | undefined
  isMobileSize?: boolean | undefined
}
class TableBody extends React.PureComponent<TableBodyProps, TableBodyState> {
  constructor() {
    // @ts-expect-error TS(2554): Expected 1-2 arguments, but got 0.
    super()
    this.state = {
      keys: [],
      data: {},
    }
  }

  // @ts-expect-error -- legacy code
  componentDidMount() {
    this.initData(this.props)
  }

  // @ts-expect-error -- legacy code

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    // @ts-expect-error TS(2339): Property 'keys' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { keys = [], data = {} } = this.props
    const nextData = nextProps.data
    const nextKeys = nextProps.keys

    if (data !== nextData || keys !== nextKeys) {
      this.initData(nextProps)
    }
  }

  initData(props: any) {
    const { keys, data } = props
    // @ts-expect-error TS(2339): Property 'name' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { name, groupBy, rowSelection = false, initSelectorKeys = () => {} } = this.props

    const rows = groupBy
      ? this.getGroupedData(props)
      : // original version: keys.map(key => data[ key ])
        // add ids if missing

        keys.map((key: any) => {
          const row = data[key] || {}
          const { id = key } = row

          return { ...row, id }
        })

    this.setState((state: any) => ({
      ...state,
      rows,
    }))
    rowSelection && initSelectorKeys(rows, name)
  }

  isOpen(gid: any) {
    return !this.state[`group-${gid}`]
  }

  toggle(gid: any) {
    const key = `group-${gid}`

    this.setState((state: any) => ({
      [key]: !state[key],
    }))
  }

  getGroupedData(props = {}) {
    // @ts-expect-error TS(2339): Property 'data' does not exist on type '{}'.
    const { data = {}, groupBy } = props
    const groups = _groupBy(data, groupBy)
    const keys = Object.keys(groups)

    // @ts-expect-error TS(2769): No overload matches this call.
    return keys.reduce((result, key) => {
      const rest = this.isOpen(key) ? groups[key] : []
      // @ts-expect-error -- legacy code
      return [...result, { group: key }, ...rest]
    }, [])
  }

  // @ts-expect-error -- legacy code
  render() {
    const {
      // @ts-expect-error TS(2339): Property 'name' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      name,
      // @ts-expect-error TS(2339): Property 'size' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      size = 1,
      // @ts-expect-error TS(2339): Property 'columns' does not exist on type 'Readonl... Remove this comment to see the full error message
      columns = [],
      // @ts-expect-error TS(2339): Property 'onTRClick' does not exist on type 'Reado... Remove this comment to see the full error message
      onTRClick = null,
      // @ts-expect-error TS(2339): Property 'onTRHover' does not exist on type 'Reado... Remove this comment to see the full error message
      onTRHover = null,
      // @ts-expect-error TS(2339): Property 'onTRHoverLeave' does not exist on type '... Remove this comment to see the full error message
      onTRHoverLeave = null,
      // @ts-expect-error TS(2339): Property 'isRowActive' does not exist on type 'Rea... Remove this comment to see the full error message
      isRowActive = () => true,
      rowClasses,
      // @ts-expect-error TS(2339): Property 'showGroup' does not exist on type 'Reado... Remove this comment to see the full error message
      showGroup = false,
      // @ts-expect-error TS(2339): Property 'fullSize' does not exist on type 'Readon... Remove this comment to see the full error message
      fullSize = false,
      // @ts-expect-error TS(2339): Property 'pagination' does not exist on type 'Read... Remove this comment to see the full error message
      pagination = null,
      // @ts-expect-error TS(2339): Property 'noTextWrap' does not exist on type 'Read... Remove this comment to see the full error message
      noTextWrap = false,
      condensedView = false,
      // @ts-expect-error TS(2339): Property 'dispatch' does not exist on type 'Read... Remove this comment to see the full error message
      dispatch,
      isSecondLevel = false,
      isMobileSize = false,
    } = this.props
    const { rows = [] } = this.state

    return rows
      .filter(
        (_: any, index: any) =>
          !pagination ||
          (index >= pagination.page * pagination.itemsPerPage &&
            index < (pagination.page + 1) * pagination.itemsPerPage),
      )

      .map((row: any, id: any) => {
        return row.group && showGroup ? (
          <Group
            key={id}
            size={size}
            group={row.group}
            isOpen={this.isOpen(row.group)}
            toggle={() => this.toggle(row.group)}
          />
        ) : (
          <Row
            key={id}
            // @ts-expect-error TS(2322): Type '{ key: any; name: any; row: any; rowIndex: a... Remove this comment to see the full error message
            name={name}
            row={row}
            rowIndex={id}
            columns={columns}
            onTRClick={onTRClick}
            onTRHover={onTRHover}
            onTRHoverLeave={onTRHoverLeave}
            isRowActive={isRowActive}
            rowClasses={rowClasses}
            fullSize={fullSize}
            noTextWrap={noTextWrap}
            condensedView={condensedView}
            dispatch={dispatch()}
            isSecondLevel={isSecondLevel}
            isMobileSize={isMobileSize}
          />
        )
      })
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  initSelectorKeys: (keys: any, name: any) => {
    const action = {
      type: 'TABLE_SELECT_NONE',
      payload: {
        table: name,
        keys,
      },
    }
    dispatch(action)
  },
  dispatch: () => dispatch,
})

export default connect(null, mapDispatchToProps)(TableBody)
