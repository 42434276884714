import type { MissionAPIDocument } from '@rhinofi/rhino-nation-api-spec'
import { deployContractsSubRoutes, makeRouteTo } from '../../../router'
import { DEFAULT_FEATURE_CHAIN } from '../../Chain/constants/activityTrackers.constants'

export const getUrlPathForMission = ({ mission }: { mission: MissionAPIDocument }) => {
  const hasChain = 'chain' in mission && mission.chain !== null

  if (mission.type === 'BRIDGE') {
    const hasToken = 'token' in mission && mission.token !== null
    const hasChainFrom = 'chainFrom' in mission && mission.chainFrom !== null
    const hasChainTo = 'chainTo' in mission && mission.chainTo !== null

    return makeRouteTo.bridgeOptional({
      token: hasToken ? mission.token : 'ETH',
      from: hasChainFrom ? mission.chainFrom : '',
      to: hasChainTo ? mission.chainTo : '',
    })
  }

  if (mission.type === 'MINT_NFT') {
    return makeRouteTo.nftMinter({
      chain: hasChain ? mission.chain : DEFAULT_FEATURE_CHAIN,
    })
  }

  if (mission.type === 'DEPLOY_CONTRACT') {
    return makeRouteTo.chainDeployContracts({
      chain: hasChain ? mission.chain : DEFAULT_FEATURE_CHAIN,
    })
  }

  if (mission.type === 'GM_CONTRACT') {
    return makeRouteTo.chainDeployContracts({
      chain: hasChain ? mission.chain : DEFAULT_FEATURE_CHAIN,
      subRoute: deployContractsSubRoutes.interactions,
    })
  }

  return '/'
}
