import { Text, TextSize } from '@rhinofi/dvf-shared-ui'
import styled from 'styled-components'
import { useContractDetailsInfoItems } from '../hooks/useContractDetailsInfoItems'
import { translate } from '../../../intl/i18n'
import type { ContractDetailsFeeType, UserContractCodec } from '../types/contractsInteractions.types'
import { shortenAddress } from '../../../services/helperService/shortenAddress'
import { FlexContainer } from '../../../components/common/Wrappers/Containers'
import { ContractsInteractionsExplorerLinkCelItem } from './Tables/Cells/ContractsInteractionsTxLinkCelItem'

type Props = {
  data: UserContractCodec
  showFees: boolean
  feeType?: ContractDetailsFeeType
  showChain?: boolean
}

export const ContractDetails = ({ data, showFees, feeType, showChain = false }: Props) => {
  const items = useContractDetailsInfoItems({ contractData: data, showFees, feeType, showChain })

  return (
    <Container $gap="16px" $direction="column" $alignItems="stretch" $flexGrow="1">
      <FlexContainer $gap="4px">
        <Text bold>{translate('contracts_interactions.contract')}:</Text>

        <ContractsInteractionsExplorerLinkCelItem
          target={data._id.address}
          chain={data._id.chain}
          textSize={TextSize.M}
          sliceStart={7}
          bold
        />
      </FlexContainer>

      <FlexContainer $gap="4px" $direction="column" $alignItems="stretch">
        <FlexContainer $justifyContent="space-between">
          <Text>{translate('contracts_interactions.contract_owner')}</Text>

          <Text size={TextSize.XS} color="secondary60">
            {shortenAddress(data.deployer)}
          </Text>
        </FlexContainer>
        {items.map(({ Label, value, id }) => (
          <FlexContainer $justifyContent="space-between" key={id}>
            {typeof Label === 'function' ? <Label /> : <Text>{Label}</Text>}

            <Text size={TextSize.XS} color="secondary60">
              {value}
            </Text>
          </FlexContainer>
        ))}
      </FlexContainer>
    </Container>
  )
}

const Container = styled(FlexContainer)`
  padding: 8px;
  border-radius: 8px;
  background: ${({ theme }) => theme.primary};
`
