import { useMemo, useCallback } from 'react'
import { Button } from '@rhinofi/dvf-shared-ui'
import type { ContractDeployButtonVariant } from '../types/contractsInteractions.types'
import { ButtonLink } from '../../../components/common/Helpers/LinkHelpers'
import { deployContractsSubRoutes } from '../../../router'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { selectAddress, selectIsAuthenticated } from '../../../actions/selectors/userSelectors'
import { translate } from '../../../intl/i18n'
import { showModal } from '../../../actions/modalActions/showModal'
import { modalKeys } from '../../../constants/modalKeys'
import { TxStatus } from '../../../services/txService/txService.types'
import type {
  ChainTxOnErrorHandlerArgs,
  ChainTxOnReceiptHandlerArgs,
  ChainTxOnTxHashHandlerArgs,
  TxStatusChangeFunc,
} from '../../../services/txService/txService.types'
import { contractsInteractionsApi } from '../services/contractsInteractions.api'
import { trackHeapEvent } from '../../../services/apiService'
import { heapEvents } from '../../../constants/heapEvents'
import { useDoChainTx } from '../../../hooks/useDoChainTx'
import { selectSelectedChain } from '../selectors/contractsInteractionsSelectors'
import { contractsInteractionsDeployFactory } from '../services/contractsInteractionsDeployFactory'

type Props = {
  typeVariant: ContractDeployButtonVariant
  numDeployedContracts?: number | undefined
  onDeployTxStatusChange?: TxStatusChangeFunc | undefined
  onLinkClick?: (() => void) | undefined
}

export const ContractsInteractionsDeployButton = ({
  typeVariant,
  numDeployedContracts,
  onDeployTxStatusChange,
  onLinkClick = () => {},
}: Props) => {
  const dispatch = useAppDispatch()

  const address = useAppSelector(selectAddress)
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const chain = useAppSelector(selectSelectedChain)

  const notLoggedIn = useMemo(() => !address || !isAuthenticated, [address, isAuthenticated])

  const ctaText = useMemo(
    () =>
      numDeployedContracts
        ? translate('contracts_interactions.deploy_another_contract')
        : translate('contracts_interactions.deploy_your_contract'),
    [numDeployedContracts],
  )

  const onDeployInitCallback = useCallback(() => {
    onDeployTxStatusChange?.({ txStatus: TxStatus.WAITING_WALLET, chain })
    showModal(dispatch)(modalKeys.contractDeploy, {
      txStatus: TxStatus.WAITING_WALLET,
      chain,
    })
  }, [dispatch, chain, onDeployTxStatusChange])

  const onDeployTxHashCallback = useCallback(
    ({ txHash }: ChainTxOnTxHashHandlerArgs) => {
      onDeployTxStatusChange?.({ txHash, txStatus: TxStatus.PENDING, chain })
      showModal(dispatch)(modalKeys.contractDeploy, {
        txStatus: TxStatus.PENDING,
        txHash,
        chain,
      })
    },
    [dispatch, chain, onDeployTxStatusChange],
  )

  const onDeployErrorCallback = useCallback(
    ({ txHash, retryFunc }: ChainTxOnErrorHandlerArgs) => {
      if (txHash) {
        onDeployTxStatusChange?.({ txHash, txStatus: TxStatus.ERROR, chain })
      }

      showModal(dispatch)(modalKeys.contractDeploy, {
        txStatus: TxStatus.ERROR,
        chain,
        retryFunc,
        txHash,
      })
    },
    [dispatch, chain, onDeployTxStatusChange],
  )

  const onDeployReceiptCallback = useCallback(
    ({ txHash, receipt: { contractAddress } }: ChainTxOnReceiptHandlerArgs) => {
      dispatch(contractsInteractionsApi.util.invalidateTags(['MultipleContracts']))
      trackHeapEvent(heapEvents.gmContractDeployed, {
        chain,
      })

      onDeployTxStatusChange?.({ txHash, txStatus: TxStatus.SUCCESS, chain })

      showModal(dispatch)(modalKeys.contractDeploy, {
        txStatus: TxStatus.SUCCESS,
        chain,
        contractAddress,
        txHash,
      })
    },
    [dispatch, chain, onDeployTxStatusChange],
  )

  const deployCallback = useDoChainTx({
    executionArgs: null,
    executionFuncFactory: contractsInteractionsDeployFactory(chain),
    destinationChainKey: chain,
    onInit: onDeployInitCallback,
    onError: onDeployErrorCallback,
    onTxHash: onDeployTxHashCallback,
    onReceipt: onDeployReceiptCallback,
  })

  return (
    <>
      {typeVariant === 'link' ? (
        <ButtonLink
          id="contracts-intercations-deploy-cta-link"
          disabled={notLoggedIn}
          to={deployContractsSubRoutes.yourContracts}
          onClick={onLinkClick}
        >
          {ctaText}
        </ButtonLink>
      ) : (
        <Button id="contracts-intercations-deploy-cta-action" disabled={notLoggedIn} onClick={deployCallback}>
          {ctaText}
        </Button>
      )}
    </>
  )
}
