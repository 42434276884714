import { Title, TitleSize } from '@rhinofi/dvf-shared-ui'
import { PerkTypeSchema } from '@rhinofi/rhino-nation-api-spec'
import type { GemsTransactionAPIDocument } from '@rhinofi/rhino-nation-api-spec'
import { clearModal } from '../../../../../../actions/modalActions/clearModal'
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch'
import { Modal } from '../../../../../../components/common/Modal/Modal'
import { NationPerkModalContent } from '../NationPerkModalsSections/NationPerkModalContent/NationPerkModalContent'
import { NationPerkModalVoucherCode } from '../NationPerkModalsSections/NationPerkModalVoucherCode/NationPerkModalVoucherCode'
import { NationPerkModalDescription } from '../NationPerkModalsSections/NationPerkModalDescription/NationPerkModalDescription'
import { NationPerkClaimedBox } from '../../NationPerkClaimedBox/NationPerkClaimedBox'
import { FlexContainer } from '../../../../../../components/common/Wrappers/Containers'

type Props = {
  gemsTransaction: GemsTransactionAPIDocument
}

export const NationClaimedPerkModal = ({ gemsTransaction }: Props) => {
  const dispatch = useAppDispatch()

  if (!('perk' in gemsTransaction) || gemsTransaction._tag !== 'SPENT') {
    return null
  }

  return (
    <Modal variant="success" isVisible onClose={() => clearModal(dispatch)()} width="480px">
      <FlexContainer $gap="16px" $direction="column" $alignItems="stretch">
        <Title size={TitleSize.XXS} color="secondary80">
          {gemsTransaction.perk.title}
        </Title>

        <NationPerkModalContent data={{ perk: gemsTransaction.perk, userData: null }} />

        {'reward' in gemsTransaction && gemsTransaction.perk.type === PerkTypeSchema.enums.VOUCHER && (
          <NationPerkModalVoucherCode voucher={gemsTransaction.reward} perk={gemsTransaction.perk} />
        )}

        <NationPerkModalDescription perk={gemsTransaction.perk} />

        <NationPerkClaimedBox widthType="auto" />
      </FlexContainer>
    </Modal>
  )
}
