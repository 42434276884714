import { Title, TitleSize, TypographyAlign } from '@rhinofi/dvf-shared-ui'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import styled from 'styled-components'
import { FlexContainer } from '../../../../../../components/common/Wrappers/Containers'
import { translate } from '../../../../../../intl/i18n'
import mapBg from '../../../../assets/map.png'
import nationPassport from '../../../../assets/nation_passport.png'

export const NationOnboardingModalWelcomeStep = () => {
  return (
    <FlexContainer $direction="column" $gap="16px" $padding="0 0 24px" $flexGrow="1">
      <Title size={TitleSize.XS} align={TypographyAlign.Center}>
        {translate('gated.welcome_to_rhino_nation')}
      </Title>
      <NationPassportImg src={nationPassport} alt="rhino-nation-passport" width={300} height={247} />
      <Title size={TitleSize.XXS} align={TypographyAlign.Center}>
        {translate('nation.onboarding_modal_intro')}
      </Title>
      <Background />
    </FlexContainer>
  )
}

const NationPassportImg = styled.img`
  @media ${respondBelow('xxxs')} {
    width: 200px;
    height: 164px;
  }
`

const Background = styled.div`
  max-width: 600px;
  height: 380px;
  position: absolute;
  bottom: 0;
  inset-inline: 0;
  background-image: url(${mapBg});
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.5;
  z-index: 0;

  @media ${respondBelow('xs')} {
    margin-inline: -14px;
    background-position: top;
  }
`
