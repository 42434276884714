import styled from 'styled-components'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'

export const Container = styled(FlexContainer)`
  padding-top: 16px;
  gap: 16px;
  flex-direction: column;
  align-items: stretch;
`

export const BoxContainer = styled(FlexContainer)`
  gap: 16px;
  flex-direction: column;
  border-radius: 8px;
  padding: 16px 32px;
  background: ${({ theme }) => theme.elevationL2};
`

export const Image = styled.img`
  width: 256px;
  height: 256px;
  border-radius: 50%;
  object-fit: cover;
`
