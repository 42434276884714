import { HttpApiClient } from '@effect/platform'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { BridgeConfigsResponseSchema } from '@rhinofi/bridge-api-spec'
import { apiSpec } from '@rhinofi/bridge-api-spec'
import { createSelector } from 'reselect'
import { envConfig } from '../../env/envConfig'
import { makeApiClientRequest, runEffectHttpCallback } from '../../utils/effect'

const BASE_URL = `${envConfig.tradingApi}/bridge`

const apiClient = HttpApiClient.make(apiSpec, { baseUrl: BASE_URL })

export const configApi = createApi({
  reducerPath: 'configApi',
  baseQuery: fetchBaseQuery({}),
  endpoints: (builder) => ({
    getConfig: builder.query<BridgeConfigsResponseSchema, void>({
      queryFn: () => runEffectHttpCallback(() => makeApiClientRequest(apiClient, (client) => client.configs.configs())),
    }),
  }),
})

export const { useGetConfigQuery } = configApi

// Selectors
export const selectConfig = configApi.endpoints.getConfig.select()

export const selectBridgeConfig = createSelector(selectConfig, (result) => {
  if (!result?.data) {
    return {}
  }
  return result.data
})

export const selectAvailableChains = createSelector(selectConfig, (result) => {
  if (!result?.data) {
    return []
  }
  return Object.entries(result.data)
    .filter(([, config]) => config.status === 'enabled')
    .map(([chain, config]) => ({ chain, config }))
})

export const selectAvailableTokens = createSelector(selectAvailableChains, (availableChains) => {
  const availableTokens = availableChains.flatMap(({ config }) => Object.keys(config.tokens))

  return [...new Set(availableTokens)]
})
