// The event names must match the one defined in Userflow

export const userflowEvents = {
  connectWallet: 'connect_wallet',
  authenticate: 'authenticate',
  bridge: 'bridge',
  activityTracker: 'activity_tracker',
} as const

export type UserflowEvent = (typeof userflowEvents)[keyof typeof userflowEvents]
