import styled from 'styled-components'

export const Skeleton = styled.div`
  animation: skeleton-loading 1s linear infinite alternate;
  width: 100%;
  border-radius: 8px;
  @keyframes skeleton-loading {
    0% {
      background-color: ${({ theme }) => theme.disabled};
      filter: brightness(115%);
    }
    100% {
      background-color: ${({ theme }) => theme.disabled};
      filter: brightness(90%);
    }
  }
`

export const SkeletonCard = styled(Skeleton)<{ $height: string; $width?: string; $borderRadius?: string }>`
  height: ${({ $height }) => $height};
  width: ${({ $width }) => $width};
  border-radius: ${({ $borderRadius }) => $borderRadius};
`
