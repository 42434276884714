import type { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { NETWORKS } from '../../../constants/types'
import { createSolanaListener } from '../services/solanaService/createSolanaListener'
import type { SecondaryConnectionPayload } from './connectors.types'

export const connectToSolana = async (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  rpcUrl: string,
  networkId: string,
): Promise<SecondaryConnectionPayload> => {
  const { setupSolanaProvider } = await import('../providers/solanaProvider')
  const provider = await setupSolanaProvider(rpcUrl)
  await provider?.connect()
  const address = provider?.publicKey?.toString()
  if (!provider || !address) {
    throw new Error('Something went wrong when connecting your Tron wallet.')
  }
  createSolanaListener(dispatch, provider)
  return {
    address,
    networkId: networkId,
    domain: '',
    connectedChain: NETWORKS.SOLANA,
  }
}
