import { useEffect } from 'react'
import { envConfig } from '../../env/envConfig'

const siteKey = envConfig.reCaptchaSiteKey

export const useReCaptcha = () => {
  useEffect(() => {
    const loadScriptByURL = (id: string, url: string, callback: () => void) => {
      const isScriptExist = document.getElementById(id)

      function runCallback() {
        if (callback) {
          callback()
        }
      }

      if (!isScriptExist) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        script.id = id
        script.onload = runCallback
        document.body.appendChild(script)
      }

      if (isScriptExist && callback) {
        callback()
      }
    }

    // load the script by passing the URL
    // eslint-disable-next-line func-names  -- Legacy
    loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${siteKey}`, function () {
      // console.log('Script loaded!')
    })
  }, [])

  const submitWithReCaptchaToken = (callback: (token: string) => Promise<void>) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access -- Legacy
    window.grecaptcha.ready(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access  -- Legacy
      window.grecaptcha.execute(siteKey, { action: 'submit' }).then(async (reCaptchaToken: string) => {
        await callback(reCaptchaToken)
      })
    })
  }

  return {
    submitWithReCaptchaToken,
  }
}
