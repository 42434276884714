import Decimal from 'decimal.js'
import { getTonProvider } from '../../providers/tonProvider'

export const getTonNativeBalance = async (secondaryWalletAddress: string, decimals: number) => {
  const { Address } = await import('@ton/core')
  const { publicProvider } = getTonProvider()

  if (!secondaryWalletAddress || !publicProvider) {
    throw new Error('Please connect your Ton wallet first.')
  }

  const tonAddress = Address.parse(secondaryWalletAddress)
  const balance = (await publicProvider.getBalance(tonAddress)).toString()
  return new Decimal(balance).div(10 ** decimals).toString()
}
