import { DVFDepositContract__factory } from '../../../../contracts/DVFDepositContract'
import { ethers, formatEther } from 'ethers'
import { SafeDecimal } from '../../../../utils/SafeDecimal'
import { estimateL1DataFee } from '../../../../services/helperService/estimateL1DataFee'
import type { EstimateBridgeGasCostProps } from './estimateBridgeGasCost'

export const estimateEvmGasCost = async ({
  address,
  isNative,
  chainInConfig: { name, networkId, contractAddress, rpc, nativeTokenSafeguard },
  tokenAddress,
  chain,
}: EstimateBridgeGasCostProps) => {
  try {
    const network = new ethers.Network(name, networkId)
    const rpcProvider = new ethers.JsonRpcProvider(rpc, network, { staticNetwork: true })
    const depositContract = DVFDepositContract__factory.connect(contractAddress, rpcProvider)
    const method = isNative ? 'depositNativeWithId' : 'depositWithId'
    const args = isNative ? ['0'] : [tokenAddress, '0', '0x01']
    const gasLimit = await depositContract[method].estimateGas(...args, {
      // @ts-expect-error -- doesn't support second param typing yet
      from: address,
    })

    const gasPrice = await rpcProvider.getFeeData()
    const l1Datafee = await estimateL1DataFee(
      {
        chain,
        functionName: method,
        args,
        to: contractAddress,
      },
      rpcProvider,
    )
    const gasFee = SafeDecimal(gasLimit)
      .times(gasPrice?.gasPrice?.toString() || '0')
      .plus(l1Datafee.toString())
      .toString()

    return Number(formatEther(gasFee)).toString()
  } catch (error) {
    console.error(error)
    return nativeTokenSafeguard.toString()
  }
}
