import { TextSize } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import type { FieldInputProps } from 'formik'
import type { ChangeEventHandler, FC, FocusEventHandler, PropsWithChildren } from 'react'
import { CustomToggleLabel, ToggleInput, ToggleWrapper, Toggler } from '../../buttons/ToggleButtons'

export type ToggleProps = {
  isDisabled?: boolean
  size?: TextSize | undefined
  reverse?: boolean
  color?: string
  isChecked?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  inputProps?: FieldInputProps<string>
  className?: string
  id?: string
}

export const Toggle: FC<PropsWithChildren<ToggleProps>> = ({
  onBlur,
  onChange,
  isChecked = false,
  color = 'neutral',
  reverse = false,
  size = TextSize.XS,
  children = null,
  isDisabled = false,
  inputProps,
  className,
  id,
}) => {
  const combinedInputProps = {
    onBlur,
    checked: isChecked,
    ...(inputProps || {}),
    onChange,
  }
  return (
    <CustomToggleLabel className={className}>
      <ToggleWrapper $size={size} id={id}>
        <ToggleInput $size={size} type="checkbox" disabled={isDisabled} {...combinedInputProps} />
        <Toggler
          id="toggler"
          className="slider"
          color={color}
          $isChecked={isChecked}
          $reverse={reverse}
          $size={size}
          $isDisabled={isDisabled}
        />
      </ToggleWrapper>
      {children}
    </CustomToggleLabel>
  )
}
