import { Text, TextSize } from '@rhinofi/dvf-shared-ui'
import { translate } from '../../../../../intl/i18n'
import { useExplorerLink } from '../../../../../hooks/util/useExplorerLink'

type Props = {
  target: string
  chain: string
  isTransaction?: boolean
  textSize?: TextSize
  sliceStart?: number
  sliceEnd?: number
  useTxText?: boolean
  bold?: boolean
}

export const ContractsInteractionsExplorerLinkCelItem = ({
  target,
  chain,
  isTransaction = false,
  textSize = TextSize.XS,
  sliceStart = 5,
  sliceEnd = -5,
  useTxText = true,
  bold = false,
}: Props) => {
  const { link } = useExplorerLink(target, chain)

  return (
    <Text color="secondary" linkColor="secondary" size={textSize} bold={bold}>
      <a
        href={link}
        target="_blank"
        rel="noreferrer noopener"
        id="contracts-interactions-explorer-link-cell"
        onClick={(event) => event.stopPropagation()}
      >
        {isTransaction && useTxText
          ? translate('global.view_on_explorer')
          : `${target.slice(0, sliceStart)}...${target.slice(sliceEnd)}`}
      </a>
    </Text>
  )
}
