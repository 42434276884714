import styled from 'styled-components'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import { Icon, Text } from '@rhinofi/dvf-shared-ui'
import { FlexContainer } from '../../../../../../components/common/Wrappers/Containers'

export const ContentContainer = styled.div`
  cursor: pointer;
  padding: 1px;
  border-radius: 8px;
  background: ${({ theme }) => theme.secondary20};
  transition: all 0.3s ease-in-out;
  overflow: hidden;

  &:hover {
    background: ${({ theme }) => theme.brand};
  }
`

export const ContentWrapper = styled(FlexContainer)`
  background: ${({ theme }) => theme.elevationL1};
  border-radius: 7px;
  transition: all 0.15s ease-in-out;
  overflow: hidden;

  &:hover {
    transform: scale(0.995, 0.95);
  }
`

export const DescriptionContainer = styled(FlexContainer)`
  padding: 12px 0 12px 16px;
`

export const EllipsisText = styled(Text)<{ $maxWidth?: string | undefined }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${({ $maxWidth }) => $maxWidth || '275px'};

  @media ${respondBelow('xxxs')} {
    max-width: 190px;
  }
`

export const CheckMarkIcon = styled(Icon)`
  font-size: 32px !important;
  color: ${({ theme }) => theme.accentGreenBg};
`

export const CompletedContainer = styled(FlexContainer)`
  padding: 0 16px;
  background: ${({ theme }) => theme.accentGreen};
`

export const GemsContainer = styled(FlexContainer)`
  padding-right: 16px;
`

export const ProgressInfoContainer = styled(FlexContainer)`
  padding: 0 8px;
`
