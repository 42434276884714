import { estimateEvmGasCost } from './estimateEvmGasCost'
import { NETWORKS } from '../../../../constants/types'
import { estimateSolanaGasFee } from '../../../../services/secondaryWallet/services/solanaService/estimateSolanaGasFee'
import { isNonEVMChain } from '../../../../services/helperService/isNonEVMChain'
import type { BridgeConfigEntrySchema } from '@rhinofi/bridge-api-spec'

export type EstimateBridgeGasCostProps = {
  chain: string
  address: string
  isNative: boolean
  tokenAddress: string
  chainInConfig: BridgeConfigEntrySchema
  secondaryWalletAddress: string | undefined
}

export const estimateBridgeGasCost = async (props: EstimateBridgeGasCostProps) => {
  const {
    chain,
    chainInConfig: { nativeTokenSafeguard },
  } = props
  if (isNonEVMChain(chain)) {
    if (chain === NETWORKS.SOLANA) {
      return estimateSolanaGasFee(props)
    } else if (chain === NETWORKS.PARADEX) {
      return '0'
    } else {
      return nativeTokenSafeguard.toString()
    }
  } else {
    return estimateEvmGasCost(props)
  }
}
