import { validateTronAddress } from '../secondaryWallet/services/tronService/validateTronAddress'
import { NETWORKS } from '../../constants/types'
import { validateSolanaAddress } from '../secondaryWallet/services/solanaService/validateSolanaAddress'
import { validateTonAddress } from '../secondaryWallet/services/tonService/validateTonAddress'
import { validateEthereumAddress } from './validateEthereumAddress'
import { validateStarknetAddress } from './validateStarknetAddress'

export const validateAddressByChain = (chain: string, address?: string) => {
  if (chain === NETWORKS.STARKNET || chain === NETWORKS.PARADEX) {
    return validateStarknetAddress(address)
  } else if (chain === NETWORKS.TRON) {
    return Promise.resolve(validateTronAddress(address))
  } else if (chain === NETWORKS.SOLANA) {
    return validateSolanaAddress(address)
  } else if (chain === NETWORKS.TON) {
    return validateTonAddress(address)
  }

  return validateEthereumAddress(address)
}
