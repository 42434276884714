import type { MissionAPIDocument } from '@rhinofi/rhino-nation-api-spec'
import { translate } from '../../../intl/i18n'

export const getMissionCTAButtonTextForMission = ({ mission }: { mission: MissionAPIDocument }) => {
  switch (mission.type) {
    case 'BRIDGE':
      return translate('nation.go_to_bridge')

    case 'MINT_NFT':
      return translate('nation.go_to_mint_nfts')

    case 'GM_CONTRACT':
    case 'DEPLOY_CONTRACT':
      return translate('nation.go_to_deploy_and_interaction')

    default:
      return ''
  }
}
