import Decimal from 'decimal.js'
import { useCallback } from 'react'
import { useAppSelector } from '../../../hooks'
import { getTokenPrice } from '../../../services/helperService/getTokenPrice'
import { selectTokenPrices } from '../../../services/usdPricesApi'

export const useGetExchangeRate = () => {
  const tokenPrices = useAppSelector(selectTokenPrices)

  return useCallback(
    (tokenFrom: string, tokenTo: string) => {
      const fromUsd = getTokenPrice(tokenPrices, tokenFrom)
      const toUsd = getTokenPrice(tokenPrices, tokenTo)

      return new Decimal(fromUsd).div(toUsd).toNumber()
    },
    [tokenPrices],
  )
}
