import { useState } from 'react'
import { OwnershipAndComplianceModal } from './OwnershipAndComplianceModal'
import { Alert } from '../../../../components/common/alerts/Alert'
import { translate } from '../../../../intl/i18n'

const localStorageKey = 'hide-ownership-compliance'

export const OwnershipAndCompliance = () => {
  const [showAlert, setShowAlert] = useState(localStorage.getItem(localStorageKey) !== 'true')
  const [showModal, setShowModal] = useState(false)

  const handleCloseModal = (doNotShowAgain = false) => {
    if (doNotShowAgain) {
      localStorage.setItem(localStorageKey, 'true')
      setShowAlert(false)
    }
    setShowModal(false)
  }

  if (!showAlert) {
    return null
  }

  return (
    <>
      <Alert
        title={translate('bridge.compliance_title')}
        rightIcon="box-arrow-up-right"
        handleClick={() => setShowModal(true)}
      />
      {showModal && <OwnershipAndComplianceModal handleClose={handleCloseModal} />}
    </>
  )
}
