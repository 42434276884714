import { Button } from '@rhinofi/dvf-shared-ui'
import { translate } from '../../../../intl/i18n'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectIsAuthenticating, selectIsWalletConnected } from '../../../../reducers/userSelector'
import { useWallet } from '../../../../hooks/useWallet'
import { triggerConnectWallet } from '../../../../utils/triggerConnectWallet'

type Props = {
  id: string
  fullWidth?: boolean
}

export const NationConnectWalletButton = ({ id, fullWidth = false }: Props) => {
  const dispatch = useAppDispatch()
  const isConnecting = useWallet().connectingWallet.isConnecting
  const isConnected = useAppSelector(selectIsWalletConnected)
  const isAuthenticating = useAppSelector(selectIsAuthenticating)

  return (
    <Button disabled={isConnecting} id={id} onClick={triggerConnectWallet(dispatch)} fullWidth={fullWidth}>
      {(() => {
        if (isConnecting) {
          return translate('onboarding.connecting_wallet')
        }

        if (isConnected && isAuthenticating) {
          return translate('nation.authenticating')
        }

        return translate('gated.connect_wallet')
      })()}
    </Button>
  )
}
