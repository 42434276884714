import type { ReactNode } from 'react'
import styled, { css } from 'styled-components'

type Props = {
  children: ReactNode
  $borderWidth?: string | undefined
  $borderRadius?: string | undefined
  $turnedOn?: boolean | undefined
  fullWidth?: boolean | undefined
}

export const BrandBorder = ({ children, $borderWidth = '2px', $borderRadius = '8px', $turnedOn, fullWidth }: Props) => (
  <Container
    $borderWidth={$borderWidth}
    $borderRadius={$borderRadius}
    $fullWidth={fullWidth}
    $turnedOn={$turnedOn}
    className="brand-border"
  >
    {children}
  </Container>
)

const Container = styled.div<{
  $borderWidth?: string | undefined
  $borderRadius?: string | undefined
  $turnedOn?: boolean | undefined
  $fullWidth?: boolean | undefined
}>`
  padding: ${({ $borderWidth }) => $borderWidth};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  width: ${({ $fullWidth = false }) => ($fullWidth ? '100%' : 'auto')};
  overflow: hidden;
  transition: all 0.3s ease-out;

  @property --bg-angle {
    inherits: false;
    initial-value: 0deg;
    syntax: '<angle>';
  }

  @keyframes spin-custom-360 {
    to {
      --bg-angle: 360deg;
    }
  }

  ${({ $turnedOn, theme }) =>
    $turnedOn &&
    css`
      background: conic-gradient(from var(--bg-angle) at 50% 50%, ${theme.brandGradient1}, ${theme.brandGradient2})
        border-box;
      animation: spin-custom-360 1s infinite normal;
    `}
`
