import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Text, Button, ButtonVariant } from '@rhinofi/dvf-shared-ui'
import { translate } from '../../../../intl/i18n'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'
import { useAppDispatch } from '../../../../hooks'
import { clearModal } from '../../../../actions/modalActions/clearModal'
import { showModal } from '../../../../actions/modalActions/showModal'
import { modalKeys } from '../../../../constants/modalKeys'
import { makeRouteTo } from '../../../../router'

type Props = {
  contractAddress?: string | undefined | null
  chain: string
}

export const ContractDeployModalSuccess = ({ contractAddress, chain }: Props) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const openShareModalCallback = useCallback(() => {
    if (!contractAddress) {
      return
    }

    showModal(dispatch)(modalKeys.contractShare, {
      contractAddress,
      chain,
    })
  }, [dispatch, contractAddress, chain])

  const seeMyContractsCallback = useCallback(() => {
    navigate(makeRouteTo.chainDeployContracts({ subRoute: 'yourContracts' }))
    clearModal(dispatch)()
  }, [navigate, dispatch])

  return (
    <FlexContainer $gap="16px" $direction="column" $alignItems="stretch">
      <Text>{translate('contracts_interactions.you_successfully_deployed_your_contract')}</Text>

      <FlexContainer $direction="column" $alignItems="stretch">
        <Text bold>{translate('contracts_interactions.whats_next')}</Text>
        <Text>{translate('contracts_interactions.monetize_by_sharing')}</Text>
      </FlexContainer>

      <Button
        id="contracts-interactions-deploy-modal-success-share"
        disabled={!contractAddress}
        onClick={openShareModalCallback}
      >
        {translate('helpers.share')}
      </Button>

      <Button
        id="contracts-interactions-deploy-modal-success-view-contracts"
        variant={ButtonVariant.secondary}
        onClick={seeMyContractsCallback}
      >
        {translate('contracts_interactions.see_my_contracts')}
      </Button>
    </FlexContainer>
  )
}
