import alphaKey from '../assets/alpha-key.png'
import betaKey from '../assets/betakey.png'
import baseHunter from '../assets/base-hunter.png'
import baseProHunter from '../assets/base-pro-hunter.png'
import blastHunter from '../assets/blast-hunter.png'
import blastProHunter from '../assets/blast-pro-hunter.png'
import lineaHunter from '../assets/linea-hunter.png'
import lineaProHunter from '../assets/linea-pro-hunter.png'
import mantaHunter from '../assets/manta-hunter.png'
import mantaProHunter from '../assets/manta-pro-hunter.png'
import scrollHunter from '../assets/scroll-hunter.png'
import scrollProHunter from '../assets/scroll-pro-hunter.png'
import zkEvmHunter from '../assets/zkevm-hunter.png'
import zkEvmProHunter from '../assets/zkevm-pro-hunter.png'
import zkEvmTxCount from '../assets/zkevm-txcount.png'
import zkEvmVolume from '../assets/zkevm-volume.png'
import taikoHunter from '../assets/taiko-hunter.png'
import taikoProHunter from '../assets/taiko-pro-hunter.png'
import taikoVolume from '../assets/taiko-volume.png'
import taikoTxCount from '../assets/taiko-txcount.png'
import zircuitHunter from '../assets/zircuit-hunter.png'
import inkGenesis from '../assets/ink-genesis.png'
import type { NFTType } from '../types/mintNFT.types'

export const getNFTImage = (type: NFTType, chain: string): string => {
  const chainImages = nftImages[chain]

  if (!chainImages) {
    throw new Error(`No image found for ${chain}`)
  }

  const img = chainImages[type]

  if (!img) {
    throw new Error(`No image found for ${type} on ${chain}`)
  }
  return img
}

const nftImages: Record<string, Partial<Record<NFTType, string>>> = {
  LINEA: {
    hunter: lineaHunter,
    TOP30: lineaProHunter,
  },
  SCROLL: {
    hunter: scrollHunter,
    TOP30: scrollProHunter,
    ALPHAKEY: alphaKey,
    BETAKEY: betaKey,
  },
  ZKEVM: {
    hunter: zkEvmHunter,
    TOP30: zkEvmProHunter,
    VOLUME: zkEvmVolume,
    TXCOUNT: zkEvmTxCount,
  },
  MANTA: {
    hunter: mantaHunter,
    TOP30: mantaProHunter,
  },
  BLAST: {
    hunter: blastHunter,
    TOP30: blastProHunter,
  },
  TAIKO: {
    hunter: taikoHunter,
    TOP30: taikoProHunter,
    VOLUME: taikoVolume,
    TXCOUNT: taikoTxCount,
  },
  ZIRCUIT: {
    hunter: zircuitHunter,
  },
  BASE: {
    hunter: baseHunter,
    TOP30: baseProHunter,
  },
  INK: {
    hunter: inkGenesis,
  },
}
