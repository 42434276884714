/* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment -- bn import issue */
import type { Wallet } from '@coral-xyz/anchor'
import {
  getSolanaBridgeKey,
  getSolanaDepositorAccount,
  getSolanaPoolAuthority,
  getSolanaPoolKey,
  getSolanaPoolTokenAccount,
} from './solanaBridgeHelpers'
import { IDL } from './idls/bridgeIDL'
import type { EstimateBridgeGasCostProps } from '../../../../pages/Bridge/services/gas/estimateBridgeGasCost'

export const estimateSolanaGasFee = async ({
  tokenAddress,
  chainInConfig: { contractAddress },
  secondaryWalletAddress,
}: EstimateBridgeGasCostProps) => {
  if (!secondaryWalletAddress) {
    throw new Error('Please connect your Solana wallet first.')
  }
  const { AnchorProvider, Program, BN } = await import('@coral-xyz/anchor')
  const { LAMPORTS_PER_SOL, PublicKey } = await import('@solana/web3.js')
  const { getSolanaConnection, getSolanaProvider } = await import('../../providers/solanaProvider')

  const amount = 0.0000001
  const decimals = 6

  const solanaProvider = getSolanaProvider()
  const connection = getSolanaConnection()

  const options = AnchorProvider.defaultOptions()
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- convert to anchor format
  const anchorProvider = new AnchorProvider(connection, solanaProvider as unknown as Wallet, options)

  const depositor = new PublicKey(secondaryWalletAddress)
  const bridgeKey = await getSolanaBridgeKey(contractAddress)
  const poolKey = await getSolanaPoolKey(contractAddress, tokenAddress)
  const poolAuthority = await getSolanaPoolAuthority(contractAddress, tokenAddress)
  const poolAccount = await getSolanaPoolTokenAccount(contractAddress, tokenAddress)
  const depositorAccount = await getSolanaDepositorAccount(secondaryWalletAddress, tokenAddress)

  const program = new Program(IDL, contractAddress, anchorProvider)
  const depositAmount = new BN(amount).mul(new BN(10).pow(new BN(decimals)))

  const accounts = {
    bridge: bridgeKey,
    pool: poolKey,
    poolAuthority,
    poolAccount: poolAccount,
    depositor,
    mint: new PublicKey(tokenAddress),
    depositorAccount: depositorAccount,
  }

  if (!program.methods['deposit']) {
    throw new Error('Method deposit not found')
  }

  const transaction = await program.methods['depositWithId'](new BN(depositAmount), new BN(`0`, 'hex'))
    .accounts(accounts)
    .signers([])
    .transaction()

  transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash
  transaction.feePayer = new PublicKey(secondaryWalletAddress)

  const estimatedFee = await transaction.getEstimatedFee(connection)

  if (!estimatedFee) {
    return '0'
  }

  return (estimatedFee / LAMPORTS_PER_SOL).toString()
}
