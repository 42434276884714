import styled from 'styled-components'

type OpacifyProps = {
  $opacity?: number
  $active: boolean
}

export const Opacify = styled.div<OpacifyProps>`
  opacity: ${({ $opacity, $active }) => ($active ? $opacity || 0.5 : 1)};
`
