import styled, { css } from 'styled-components'
import type { MouseEventHandler, FC, PropsWithChildren } from 'react'
import { useState } from 'react'
import { Text, TextSize } from '@rhinofi/dvf-shared-ui'

type Props = {
  id?: string
  tooltip?: string | undefined
  onClick?: MouseEventHandler
  className?: string
}

export const SocialItem: FC<PropsWithChildren<Props>> = ({ id, tooltip, onClick = () => {}, className, children }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <Item
      id={id}
      className={className}
      onClick={onClick}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip && tooltip && (
        <Tooltip>
          <Text size={TextSize.XS} className="tooltip">
            {tooltip}
          </Text>
        </Tooltip>
      )}
    </Item>
  )
}

const Item = styled.div`
  position: relative;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.bridgePicker};
  border: 1px solid ${({ theme }) => theme.bridgePickerBorder};
  transition: all 0.15s ease-out;
  cursor: pointer;

  .socialIcon {
    cursor: pointer !important;
  }

  ${({ theme }) => css`
    .socialIcon {
      color: ${theme.primaryMain};
    }

    &:hover {
      background: ${theme.primaryMain};
      .socialIcon {
        color: ${theme.bridgePicker};
      }
    }
  `}
`

const Tooltip = styled.div`
  padding: 10px;
  border-radius: 8px;
  background: ${({ theme }) => theme.bridgePicker};
  border: 1px solid ${({ theme }) => theme.bridgePickerBorder};
  position: absolute;
  top: -64px;
  right: 0;

  .tooltip {
    white-space: nowrap;
  }
`
