import { ButtonSizes, Button } from '@rhinofi/dvf-shared-ui'
import { translate } from '../../../intl/i18n'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { selectAddress } from '../../../actions/selectors/userSelectors'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { FlexContainer } from '../../common/Wrappers/Containers'
import { NonEVMConnected } from './NonEVMConnected'
import { EVMConnected } from './EVMConnected'
import { ConnectedWalletsContainer } from './ConnectedWallets.styled'
import { showModal } from '../../../actions/modalActions/showModal'
import { modalKeys } from '../../../constants/modalKeys'

import { useWallet } from '../../../hooks/useWallet'

export const ConnectedWallets = () => {
  const dispatch = useAppDispatch()
  const wallet = useWallet()
  const isConnectingWallet = wallet.connectingWallet.isConnecting
  const address = useAppSelector(selectAddress)

  const connectWallet = () => {
    showModal(dispatch)(modalKeys.connectWallet)
  }

  if (!address) {
    return (
      <ConnectedWalletsContainer className="high-priority-display">
        <Button size={ButtonSizes.Large} onClick={connectWallet} id="heap-connect-wallet">
          {isConnectingWallet ? translate('onboarding.connecting_wallet') : translate('onboarding.connect_wallet')}
        </Button>
      </ConnectedWalletsContainer>
    )
  }

  return (
    <FlexContainer $gap="16px">
      <EVMConnected />
      <NonEVMConnected />
    </FlexContainer>
  )
}
