/* eslint-disable @typescript-eslint/ban-tslint-comment -- Autogenerated file */
/* eslint-disable @eslint-community/eslint-comments/require-description -- Autogenerated file */
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, ContractFactory, ContractTransactionResponse, Interface } from 'ethers'
import type { Signer, ContractDeployTransaction, ContractRunner } from 'ethers'
import type { NonPayableOverrides } from '../common'
import type { DVFDepositContract, DVFDepositContractInterface } from '../DVFDepositContract'

export const _abi = [
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'commitmentId',
        type: 'uint256',
      },
    ],
    name: 'depositNativeWithId',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'commitmentId',
        type: 'uint256',
      },
    ],
    name: 'depositWithId',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'origin',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'token',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'commitmentId',
        type: 'uint256',
      },
    ],
    name: 'BridgedDepositWithId',
    type: 'event',
  },
] as const

type DVFDepositContractConstructorParams = [signer?: Signer] | ConstructorParameters<typeof ContractFactory>

const isSuperArgs = (xs: DVFDepositContractConstructorParams): xs is ConstructorParameters<typeof ContractFactory> =>
  xs.length > 1

export class DVFDepositContract__factory extends ContractFactory {
  constructor(...args: DVFDepositContractConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args)
    } else {
      throw new Error('Not supported')
    }
  }

  override getDeployTransaction(
    overrides?: NonPayableOverrides & { from?: string },
  ): Promise<ContractDeployTransaction> {
    return super.getDeployTransaction(overrides || {})
  }
  override deploy(overrides?: NonPayableOverrides & { from?: string }) {
    return super.deploy(overrides || {}) as Promise<
      DVFDepositContract & {
        deploymentTransaction(): ContractTransactionResponse
      }
    >
  }
  override connect(runner: ContractRunner | null): DVFDepositContract__factory {
    return super.connect(runner) as DVFDepositContract__factory
  }

  static readonly abi = _abi
  static createInterface(): DVFDepositContractInterface {
    return new Interface(_abi) as DVFDepositContractInterface
  }
  static connect(address: string, runner?: ContractRunner | null): DVFDepositContract {
    return new Contract(address, _abi, runner) as unknown as DVFDepositContract
  }
}
