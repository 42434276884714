import { Icon, Spacing, Title, TitleSize, Text, ButtonVariant } from '@rhinofi/dvf-shared-ui'
import { useCallback } from 'react'
import { Modal } from '../../common/Modal/Modal'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { clearModal } from '../../../actions/modalActions/clearModal'
import { ExternalButtonLink } from '../../common/Helpers/LinkHelpers'
import { translate } from '../../../intl/i18n'

const blogURL = 'https://support.rhino.fi/en/article/rhinofi-what-is-erc20-8kb79a/'

export const SpendingCapModal = ({ handleClose }: { handleClose?: () => void }) => {
  const dispatch = useAppDispatch()

  const close = useCallback(() => {
    if (handleClose) {
      return handleClose()
    } else {
      clearModal(dispatch)()
    }
  }, [dispatch, handleClose])

  return (
    <Modal isVisible onClose={close} width="360px" variant="secondary">
      <Title size={TitleSize.XXS} bold>
        <Icon id="info-square" /> {translate('deposit.spending_cap')}
      </Title>

      <Spacing size="16" />
      <Text bold>{translate('deposit.spending_cap_desc')}</Text>
      <Spacing size="16" />
      <ExternalButtonLink
        id="learn-more"
        variant={ButtonVariant.secondary}
        href={blogURL}
        target="_blank"
        rel="noopener noreferrer"
      >
        {translate('helpers.learn_more')}
      </ExternalButtonLink>
    </Modal>
  )
}
