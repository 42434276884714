import { getTonProvider } from '../../providers/tonProvider'

export const disconnectTonWallet = () => {
  try {
    const tonProvider = getTonProvider()
    if (tonProvider) {
      void tonProvider?.walletProvider?.disconnect()
    }
    // eslint-disable-next-line no-unused-vars -- fail silently
  } catch (error) {
    /* empty */
  }
}
