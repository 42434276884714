import type { InvalidJwt, Unauthorized } from '@rhinofi/authentication-api-spec'
import type { HttpApiDecodeError } from '@effect/platform/HttpApiError'
import { Effect, pipe } from 'effect'
import type { ConnectedQuoteResponseSchema, DepositLimitReached, InvalidRequest } from '@rhinofi/bridge-api-spec'
import type { WithdrawLimitReached } from '@rhinofi/bridge-api-spec'
import type { RequestError, ResponseError } from '@effect/platform/HttpClientError'
import type { HttpClient } from '@effect/platform'
import { formatWithdrawLimitReachedError } from './formatWithdrawLimitReachedError'
import { formatDepositLimitReachedError } from './formatDepositLimitReachedError'

export const handleUserQuoteErrors = (
  effect: Effect.Effect<
    ConnectedQuoteResponseSchema,
    | HttpApiDecodeError
    | RequestError
    | ResponseError
    | Unauthorized
    | InvalidJwt
    | DepositLimitReached
    | WithdrawLimitReached
    | InvalidRequest
    | string,
    HttpClient.HttpClient
  >,
) =>
  pipe(
    effect,
    Effect.catchTags({
      WithdrawLimitReached: (error: WithdrawLimitReached) => Effect.fail(formatWithdrawLimitReachedError(error)),
      DepositLimitReached: (error: DepositLimitReached) => Effect.fail(formatDepositLimitReachedError(error)),
      InvalidRequest: () => Effect.fail('-'),
      InvalidJwt: () => Effect.fail('-'),
      Unauthorized: () => Effect.fail('-'),
      HttpApiDecodeError: () => Effect.fail('-'),
      RequestError: () => Effect.fail('-'),
      ResponseError: () => Effect.fail('-'),
    }),
  )
