import styled, { css } from 'styled-components'
import type React from 'react'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'

// default styling for FlexContainer (useful when replacing FlexContainer with something else):
// export const DefaultFlexContainer = styled.div`
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
//   flex-direction: row;
//   gap: '0';
//   flex-wrap: 'nowrap';
// `
export const FlexContainer = styled.div<{
  $direction?: string | undefined
  $alignItems?: string | undefined
  $justifyContent?: string
  $fullWidth?: boolean | undefined
  $fullHeight?: boolean | undefined
  $fullMinHeight?: boolean | undefined
  $gap?: string | undefined
  $flexGrow?: string | undefined
  $collapseOnMobile?: boolean | undefined
  $flexWrap?: string | undefined
  $flexBasis?: string | undefined
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  $padding?: string | undefined
  $maxWidth?: string | undefined
  $noRelative?: boolean | undefined
}>`
  position: ${({ $noRelative }) => ($noRelative ? 'static' : 'relative')};
  display: flex;
  align-items: ${({ $alignItems = 'center' }) => $alignItems};
  justify-content: ${({ $justifyContent = 'flex-start' }) => $justifyContent};
  flex-direction: ${({ $direction = 'row' }) => $direction};
  width: ${({ $fullWidth = false }) => ($fullWidth ? '100%' : '')};
  max-width: ${({ $maxWidth }) => $maxWidth || ''};
  height: ${({ $fullHeight = false }) => ($fullHeight ? '100%' : '')};
  min-height: ${({ $fullMinHeight = false }) => ($fullMinHeight ? '100%' : '')};
  gap: ${({ $gap = '0' }) => $gap};
  flex-grow: ${({ $flexGrow }) => $flexGrow || ''};
  flex-wrap: ${({ $flexWrap }) => $flexWrap || 'nowrap'};
  ${({ $padding }) => $padding && `padding: ${$padding};`};
  ${({ $flexBasis }) => $flexBasis && `flex-basis: ${$flexBasis};`};

  ${({ $collapseOnMobile }) =>
    $collapseOnMobile &&
    css`
      @media ${respondBelow('xxs')} {
        flex-direction: column;
        align-items: flex-start;
      }
    `}

  ${({ $maxWidth }) =>
    $maxWidth
      ? css`
          margin: 0 auto;
        `
      : ''};
`

type TokenColumnProps = {
  $fixedWidth?: boolean
  $isCrossChain?: boolean
}

export const TokenColumn = styled.div<TokenColumnProps>`
  height: 70px;
  display: flex;
  align-items: center;

  & > div:first-child {
    display: flex;
    align-items: center;
    margin-right: ${({ $isCrossChain }) => ($isCrossChain ? '20px' : '12px')};
  }

  ${({ $fixedWidth }) =>
    $fixedWidth &&
    `
    & > div:nth-child(2) {
        min-width: 180px;
    }
  `}

  @media ${respondBelow('xxs')} {
    flex-direction: column;
    justify-content: center;

    label,
    button,
    .rhino-button {
      display: none;
    }

    div {
      font-size: 10px;
    }
    p,
    div {
      text-align: center;
    }

    & > div:first-child {
      margin-right: 0;
      margin-bottom: 4px;
    }
    & > div:nth-child(2) {
      min-width: auto;
    }
  }
`

export const ChartHeader = styled.div`
  text-align: right;
  margin-bottom: 24px;
`

export const BackWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;
  transition: opacity 0.15s ease-in-out;

  & > svg {
    margin-right: 8px;
  }

  &:hover {
    opacity: 0.8;
  }
`
