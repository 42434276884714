import type { MissionWithUserData } from '../types/nation.types'

export const getMissionCompletedPercentage = ({ mission, userData }: MissionWithUserData) => {
  if (!userData) {
    return 0
  }

  const { minTimesToAchieve, maxTimesCanBeRewarded } = mission
  const maxTimesCanBeRewardedNormalized = maxTimesCanBeRewarded || 1

  const totalTimesToAchieve = maxTimesCanBeRewardedNormalized * minTimesToAchieve

  return (userData.totalTimesAchieved / totalTimesToAchieve) * 100
}
