import type { BridgeConfigEntrySchema } from '@rhinofi/bridge-api-spec'
import { BRIDGE_HIGHLIGHTED_CATEGORY } from '../constants/bridge.constants'
import type { BridgeCategory } from '../types/bridge-widget.types'

export const getBridgeCategory = (chainConfig: BridgeConfigEntrySchema): BridgeCategory | undefined => {
  if (chainConfig.category === 'popular') {
    return BRIDGE_HIGHLIGHTED_CATEGORY
  }
  return undefined
}
