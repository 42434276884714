import { Text, Label } from '@rhinofi/dvf-shared-ui'
import { TextSize, LabelSize } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import { translate } from '../../intl/i18n'

export const networkSelectorDictionary = {
  unavailableChain: translate('global.unavailable_for_this_token'),
  listLabel: translate('bridge.select_network'),
  otherNetworksCategory: () => translate('helpers.other_networks'),
  unavailableChainsCategory: (token: string) => translate('global.token_unavailable_for_chain', { '%token': token }),
  cancel: translate('helpers.cancel'),
  placeholder: translate('global.search_network_placeholder'),
  experimentalTooltip: (
    <div>
      <Label size={LabelSize.Label} bold>
        {translate('global.exp_networks_notice_title')}
      </Label>
      <br />
      <Text size={TextSize.XS}>{translate('global.exp_networks_notice_desc')}</Text>
    </div>
  ),
}
