// TODO VEN-592 - Improve group route handling -- Make sure that new added routes are used
import { getContractsInteractionsSavedChain } from './pages/Chain/helpers/getContractsInteractionsSavedChain'

export const publicRoutes = {
  history: '/history',
  bridge: '/bridge',
  chain: '/chain',
  chainFeature: '/chain/:chain',
  nftMinter: '/chain/:chain/mint-nfts',
  deployAndInteract: '/chain/:chain/deploy-and-interact',
  // TODO DOPE-422 - Integrate trackers
  // analytics: '/chain/:chain/analytics',
  // TODO VEN-592 - Improve group route handling
  nation: '/nation',
  nftMinterOld: '/realm/trackers/:chain/mint-nfts',
  deployContractsOld: '/realm/trackers/:chain/deploy-and-interact',
  deployContractsFriendOld: '/realm/trackers/:chain/deploy-and-interact/friend',
  deployContractsInteractionsOld: '/realm/trackers/:chain/deploy-and-interact/interactions',
  deployContractsYourContractsOld: '/realm/trackers/:chain/deploy-and-interact/your-contracts',
  // TODO DOPE-422 - Integrate trackers
  // analyticsOld: '/realm/trackers/:chain/analytics',
} as const

export const chainRoutes = {
  deploy: '/deploy-and-interact',
  mintNfts: '/mint-nfts',
  // TODO DOPE-422 - Integrate trackers
  // analytics: '/analytics',
} as const

export const nationSubRoutes = {
  access: 'access',
  leaderboard: 'leaderboard',
} as const

export const deployContractsSubRoutes = {
  friend: `friend`,
  yourContracts: 'your-contracts',
  interactions: 'interactions',
} as const

export const makeRouteTo = {
  history: () => `/history`,
  bridge: (token: string, from: string, to: string) =>
    `${publicRoutes.bridge}?token=${token}&chainIn=${from}&chainOut=${to}`,
  bridgeOptional: ({ token, from, to }: { token?: string; from?: string; to?: string }) => {
    const queryParams: string[] = []

    if (token) {
      queryParams.push(`token=${token}`)
    }

    if (from) {
      queryParams.push(`chainIn=${from}`)
    }

    if (to) {
      queryParams.push(`chainOut=${to}`)
    }

    if (queryParams.length > 0) {
      return `${publicRoutes.bridge}?${queryParams.join('&')}`
    } else {
      return publicRoutes.bridge
    }
  },
  chainFeature: ({ chain }: { chain: string }) => publicRoutes.chainFeature.replace(':chain', chain),
  chainBaseRouteRelativeToRoot: () => {
    // TODO DOPE-422 - Integrate trackers
    // const activityTrackers = getEnabledActivityTrackers()
    // const hasAnalyticsTab = activityTrackers.includes(chain)
    //
    // const realmRoute = hasAnalyticsTab ? chainRoutes.analytics : chainRoutes.mintNfts
    return chainRoutes.mintNfts.replace('/', '')
  },
  nftMinter: ({ chain }: { chain?: string | undefined }) => publicRoutes.nftMinter.replace(':chain', chain || ''),
  chainDeployContracts: ({
    subRoute,
    chain,
  }: {
    subRoute?: keyof typeof deployContractsSubRoutes | undefined
    chain?: string | undefined
  }): string => {
    const chainToUse = chain || getContractsInteractionsSavedChain() || ''
    const subRoutePath = subRoute ? `/${deployContractsSubRoutes[subRoute]}` : ''

    return `${publicRoutes.chainFeature.replace(':chain', chainToUse)}${chainRoutes.deploy}${subRoutePath}`
  },
  nation: ({ subRoute }: { subRoute?: keyof typeof nationSubRoutes }): string => {
    const subRoutePath = subRoute ? `/${nationSubRoutes[subRoute]}` : ''

    return `${publicRoutes.nation}${subRoutePath}`
  },
  // TODO DOPE-422 - Integrate trackers
  // analytics: (chain: string) => publicRoutes.analytics.replace(':chain', chain),
  // campaigns: (campaignId: MilestoneCampaignID) => publicRoutes.campaigns.replace(':campaignId', campaignId),
} as const
