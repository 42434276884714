import { useMemo } from 'react'
import type { GetUserPerkDataResponse } from '@rhinofi/rhino-nation-api-spec'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { getRTKErrMessage } from '../../../services/helperService'
import { useGetAllActivePerksQuery, useGetUserPerkDataQuery } from '../services/nation.api'
import type { PerksWithUserData } from '../types/nation.types'
import { invalidateNationTagsOnPotentialGemsChangeAction } from '../actions/nation.actions'
import { useHasUserFullNationAccess } from './useHasUserFullNationAccess'

export const usePerksWithUserData = (): {
  isLoading: boolean
  error: string
  data: PerksWithUserData | null
} => {
  const dispatch = useAppDispatch()
  const hasFullAccess = useHasUserFullNationAccess()

  const { isLoading: isLoadingPerks, error: perksError, data: perks } = useGetAllActivePerksQuery(undefined)

  const {
    isLoading: isLoadingPerksUserData,
    error: perksUserDataError,
    data: perksUserData,
  } = useGetUserPerkDataQuery(
    {
      onSuccess: invalidateNationTagsOnPotentialGemsChangeAction(dispatch),
    },
    { skip: !hasFullAccess },
  )

  const isLoading = hasFullAccess ? isLoadingPerks || isLoadingPerksUserData : isLoadingPerks
  const errorInitial = hasFullAccess ? perksError || perksUserDataError : perksError
  const error = errorInitial ? getRTKErrMessage(errorInitial) : ''

  const data = useMemo(() => {
    if (!perks) {
      return null
    }

    const perksUserDataLocal: GetUserPerkDataResponse = perksUserData ?? { items: [] }

    const items = perks.items.map((perk) => {
      const perkUserData = perksUserDataLocal.items.find(({ _id }) => _id.perkId === perk._id)

      return {
        perk,
        userData: perkUserData || null,
      }
    })

    return {
      items,
    }
  }, [perks, perksUserData])

  return {
    isLoading,
    error,
    data,
  }
}
