import type { UserState } from '../../reducers/userSlice'
import { createContext } from 'react'

type AuthContextProps = {
  requestAuth: ({ triggerSignature }: { triggerSignature: boolean }) => void
  resetAuth: () => void
  authData: UserState['auth']['data']
  authRequested: boolean
  authRejected: boolean
}

export const AuthContext = createContext<AuthContextProps | null>(null)
