import type { TransactionReceipt } from 'ethers'
import { ContractFactory } from 'ethers'
import type { ChainTxArgs, ChainTxHashCallbackFunc } from '../../../services/txService/txService.types'
import { getContractsInteractionsDeployArgs } from '../helpers/getContractsInteractionsDeployArgs'
import { _abi, _bytecode } from '../../../contracts/RhinoGMContract/factories/RhinoGMContract__factory'
import { envConfig } from '../../../env/envConfig'

export const deployInteractionsContractDefault =
  () =>
  async (
    { destinationChainKey, signer }: ChainTxArgs,
    txHashCallbackFunc: ChainTxHashCallbackFunc,
  ): Promise<TransactionReceipt> => {
    const args = getContractsInteractionsDeployArgs(destinationChainKey)
    const contractFactory = new ContractFactory(_abi, _bytecode, signer)

    const res = await contractFactory.deploy(...args, { value: envConfig.GM_CONTRACT_FEE_DEPLOY_WEI })
    const tx = res.deploymentTransaction()

    if (tx === null) {
      throw new Error('No deployment tx')
    }

    txHashCallbackFunc(null, tx.hash)
    const receipt = await tx.wait()

    if (receipt === null) {
      throw new Error('No receipt')
    }

    return receipt
  }
