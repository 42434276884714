import type { NFTType } from '../types/mintNFT.types'
import { translate } from '../../../intl/i18n'

export const formatNFTMintModalSubtitle = (type: NFTType, chainName: string) => {
  if (type === 'wrapped2023') {
    return 'rhino.fi Wrapped 2023 NFT'
  }
  if (type === 'ALPHAKEY') {
    return translate('rhino_alpha_key.alpha_key')
  }
  if (type === 'BETAKEY') {
    return translate('gated.rhino_passport')
  }

  return `${chainName} x rhino.fi NFT`
}
