import { Schema } from 'effect'

export const TokenInfoSchema = Schema.Struct({
  maxCap: Schema.NumberFromString,
  maxBalancePerUser: Schema.NumberFromString,
  isTransferable: Schema.Boolean,
  mintFee: Schema.NumberFromString,
  reducedMintFee: Schema.NumberFromString,
  reducedMintFeeContract: Schema.String,
  reducedMintFeeTokenID: Schema.NumberFromString,
  tokenURI: Schema.String,
})

// 0
// :
// {internalType: 'uint256', name: 'maxCap', type: 'uint256'}
// 1
// :
// {internalType: 'uint256', name: 'maxBalancePerUser', type: 'uint256'}
// 2
// :
// {internalType: 'bool', name: 'isTransferable', type: 'bool'}
// 3
// :
// {internalType: 'uint256', name: 'mintFee', type: 'uint256'}
// 4
// :
// {internalType: 'uint256', name: 'reducedMintFee', type: 'uint256'}
// 5
// :
// {internalType: 'address', name: 'reducedMintFeeContract', type: 'address'}
// 6
// :
// {internalType: 'uint256', name: 'reducedMintFeeTokenID', type: 'uint256'}
// 7
// :
// {internalType: 'address', name: 'signerAddress', type: 'address'}
// 8
// :
// {internalType: 'string', name: 'signaturePrefix', type: 'string'}
// 9
// :
// {internalType: 'string', name: 'tokenURI', type: 'string'}
export const TokenInfoSchemaArray = Schema.Tuple(
  Schema.BigIntFromSelf,
  Schema.BigIntFromSelf,
  Schema.Boolean,
  Schema.BigIntFromSelf,
  Schema.BigIntFromSelf,
  Schema.String,
  Schema.BigIntFromSelf,
  Schema.String,
  Schema.String,
  Schema.String,
)

export const parseTokenInfo = (tokenInfoRaw: unknown) => {
  const parsedArray = Schema.decodeUnknownSync(TokenInfoSchemaArray)(tokenInfoRaw)
  return {
    maxCap: parsedArray[0],
    maxBalancePerUser: parsedArray[1],
    isTransferable: parsedArray[2],
    mintFee: parsedArray[3],
    reducedMintFee: parsedArray[4],
    reducedMintFeeContract: parsedArray[5],
    reducedMintFeeTokenID: parsedArray[6],
    signerAddress: parsedArray[7],
    signaturePrefix: parsedArray[8],
    tokenURI: parsedArray[9],
  }
}
