import { Spacing, Text, TextSize } from '@rhinofi/dvf-shared-ui'
import { WalletButton } from './WalletButton'
import { ConnectSectionText, WalletsListContainer } from './ConnectWallet.styled'
import walletConnectIcon from './assets/walletConnect.svg'
import metamaskIcon from './assets/metamask.svg'
import okxIcon from './assets/okx.svg'
import trustIcon from './assets/trust.svg'
import { WalletType } from '../../services/wallet/wallet.types'
import { useCallback, useMemo, useState } from 'react'
import { useIsMobile } from '../../hooks/useIsMobile'
import { translate } from '../../intl/i18n'
import { useWallet } from '../../hooks/useWallet'

const OTHER_WALLETS = [
  {
    icon: metamaskIcon,
    label: 'MetaMask',
  },
  {
    icon: okxIcon,
    label: 'OKX Wallet',
  },
  {
    icon: trustIcon,
    label: 'Trust Wallet',
  },
  {
    icon: 'bi-wallet',
    label: 'Browser wallet',
  },
]

export const ConnectWallet = () => {
  const [error, setError] = useState<string>('')

  const wallet = useWallet()
  const { isMobile } = useIsMobile()

  const otherWallets = useMemo(() => {
    return OTHER_WALLETS.filter(
      ({ label }) => !wallet.eip6963Providers.some((provider) => provider.info.name === label),
    )
  }, [wallet.eip6963Providers])
  const connectInjected = useCallback(
    (label: string) => {
      const { ethereum } = window
      setError('')
      if (isMobile && ethereum) {
        void wallet.connect({
          walletType: WalletType.injected,
        })
      } else if (isMobile && !ethereum) {
        void wallet.connect({
          walletType: WalletType.walletConnect,
        })
      } else {
        if (label === 'Browser wallet') {
          void wallet.connect({
            walletType: WalletType.injected,
          })
        } else {
          setError(translate('errors.no_wallet_browser'))
        }
      }
    },
    [wallet, isMobile],
  )

  return (
    <div>
      <WalletsListContainer>
        {wallet.eip6963Providers.length > 0 && (
          <ConnectSectionText size={TextSize.XS} color="textSecondary">
            Detected
          </ConnectSectionText>
        )}
        {wallet.eip6963Providers.map((provider) => (
          <WalletButton
            key={`${provider.info.name}`}
            id="reown"
            isConnecting={wallet.connectingWallet.isConnecting && provider.info.name === wallet.connectingWallet.name}
            icon={provider.info.icon}
            onClick={async () => {
              await wallet.connect({
                walletType: WalletType.eip6963,
                name: provider.info.name,
              })
            }}
            label={provider.info.name}
            isDetected
          />
        ))}
        {wallet.eip6963Providers.length > 0 && (
          <ConnectSectionText size={TextSize.XS} color="textSecondary">
            Others
          </ConnectSectionText>
        )}
        <WalletButton
          isConnecting={
            wallet.connectingWallet.isConnecting && wallet.connectingWallet.name === WalletType.walletConnect
          }
          onClick={async () => {
            await wallet.connect({
              walletType: WalletType.walletConnect,
            })
          }}
          id="wallet"
          label="WalletConnect"
          icon={walletConnectIcon}
        />
        {otherWallets.map(({ icon, label }) => (
          <WalletButton
            key={label}
            isConnecting={
              wallet.connectingWallet.isConnecting &&
              wallet.connectingWallet.name === WalletType.injected &&
              label === 'Browser wallet'
            }
            onClick={() => connectInjected(label)}
            id={`wallet-${label}`}
            label={label}
            icon={icon}
          />
        ))}
      </WalletsListContainer>

      {(wallet.connectionError || error) && (
        <>
          <Spacing size="16" />
          <Text size={TextSize.XS} color="errorMain">
            {wallet.connectionError || error}
          </Text>
        </>
      )}
    </div>
  )
}
