import styled, { css } from 'styled-components'
import { Label } from '@rhinofi/dvf-shared-ui'
import { TextSize } from '@rhinofi/dvf-shared-ui/lib/types/formats'

type ToggleWrapperProps = {
  $size?: TextSize
}

export const ToggleWrapper = styled.label<ToggleWrapperProps>`
  ${({ $size }) => handleToggleSize($size)}
  position: relative;
  display: inline-block;

  input {
    display: none;
  }
`

export const CustomToggleLabel = styled.div`
  display: flex;
  align-items: center;
`

type ToggleInputProps = {
  $size?: TextSize
}

export const ToggleInput = styled.input<ToggleInputProps>`
  ${({ $size }) => handleToggleInputSize($size)}
  visibility: hidden; /* <-- hide the default checkbox, the rest is to hide and alllow tabbing, which display:none prevents */
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  overflow: hidden;

  &:checked + .slider {
  }

  &:focus + .slider {
  }
`

type ToggleLabelProps = {
  $selected: boolean
  title?: string
}
export const ToggleLabel = styled(Label)<ToggleLabelProps>`
  line-height: 1rem;
  color: ${({ theme }) => theme.textSecondary};
  margin-left: 4px;
  font-size: 12px;
  font-weight: 600;

  &::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }
`

type TogglerProps = {
  $isChecked?: boolean
  $reverse?: boolean
  $size?: TextSize
  $isDisabled?: boolean
}

// Why do we want to reverse colors?
export const Toggler = styled.span<TogglerProps>`
  background-color: ${({ $isChecked, $reverse, theme }) =>
    ($reverse ? !$isChecked : $isChecked) ? `${theme.textLink}80` : theme.textSecondary};
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.15s ease-in-out;
  border-radius: 34px;

  // DOT
  &::before {
    position: absolute;
    top: -3px;
    left: -4px;
    content: '';
    background-color: ${({ theme, $isChecked }) => ($isChecked ? theme.textLink : theme.primary)};
    transition: all 0.3s ease-out;
    border-radius: 50%;
    ${({ $size }) => handleTogglerSize($size)}
    filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.20)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12));
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      background: ${({ theme }) => theme.textSecondary};
      opacity: 0.8;
      cursor: not-allowed;

      &::before {
        background: ${({ theme }) => theme.primary};
      }
    `}
`

const handleToggleInputSize = (size?: TextSize) => {
  switch (size) {
    case TextSize.L:
      return css`
        &:checked + .slider:before {
          -webkit-transform: translateX(18px);
          -ms-transform: translateX(18px);
          transform: translateX(18px);
        }
      `
    case TextSize.XS:
    case TextSize.M:
    default:
      return css`
        &:checked + .slider:before {
          -webkit-transform: translateX(10px);
          -ms-transform: translateX(10px);
          transform: translateX(10px);
        }
      `
  }
}

const handleToggleSize = (size?: TextSize) => {
  switch (size) {
    case TextSize.XS:
    case TextSize.M:
      return css`
        width: 26px;
        height: 12px;
      `
    case TextSize.L:
      return css`
        width: 26px;
        height: 10px;
      `
    default:
      return css`
        width: 21px;
        height: 12px;
      `
  }
}

const handleTogglerSize = (size?: TextSize) => {
  switch (size) {
    case TextSize.XS:
    case TextSize.M:
      return css`
        height: 16px;
        width: 16px;
        top: 2px;
        left: 3px;
      `
    case TextSize.L:
      return css`
        height: 16px;
        width: 16px;
      `
    default:
      return css`
        height: 8px;
        width: 8px;
        bottom: 1px;
      `
  }
}
