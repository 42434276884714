/* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-assignment -- lazy load import issue */
import type { Wallet } from '@coral-xyz/anchor'
import {
  getSolanaBridgeKey,
  getSolanaDepositorAccount,
  getSolanaPoolAuthority,
  getSolanaPoolKey,
  getSolanaPoolTokenAccount,
} from './solanaBridgeHelpers'
import { IDL } from './idls/bridgeIDL'
import type { BridgeContractCallProps } from '../../../../pages/Bridge/services/bridge.contract'

export const handleSolanaDeposit = async ({
  bridgeContractAddress,
  tokenAddress,
  secondaryWalletAddress,
  commitmentId,
  amountWithDecimals,
  callback,
}: BridgeContractCallProps) => {
  if (!secondaryWalletAddress) {
    throw new Error('Please connect your Solana wallet first.')
  }
  const { getSolanaConnection, getSolanaProvider } = await import('../../providers/solanaProvider')
  const { PublicKey } = await import('@solana/web3.js')
  const { AnchorProvider, Program, BN } = await import('@coral-xyz/anchor')

  const solanaProvider = getSolanaProvider()
  const connection = getSolanaConnection()

  const options = AnchorProvider.defaultOptions()
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- convert to anchor format
  const anchorProvider = new AnchorProvider(connection, solanaProvider as unknown as Wallet, options)

  const depositor = new PublicKey(secondaryWalletAddress)
  const bridgeKey = await getSolanaBridgeKey(bridgeContractAddress)
  const poolKey = await getSolanaPoolKey(bridgeContractAddress, tokenAddress)
  const poolAuthority = await getSolanaPoolAuthority(bridgeContractAddress, tokenAddress)
  const poolAccount = await getSolanaPoolTokenAccount(bridgeContractAddress, tokenAddress)
  const depositorAccount = await getSolanaDepositorAccount(secondaryWalletAddress, tokenAddress)

  const program = new Program(IDL, bridgeContractAddress, anchorProvider)

  const accounts = {
    bridge: bridgeKey,
    pool: poolKey,
    poolAuthority,
    poolAccount: poolAccount,
    depositor,
    mint: new PublicKey(tokenAddress),
    depositorAccount: depositorAccount,
  }

  if (!program.methods?.['depositWithId']) {
    throw new Error('Program methods not found')
  }

  const depositTxHash = await program.methods['depositWithId'](new BN(amountWithDecimals), new BN(commitmentId, 'hex'))
    .accounts(accounts)
    .signers([])
    .rpc()

  await connection.getSignatureStatus(depositTxHash)
  callback?.(depositTxHash)

  return { transactionHash: depositTxHash }
}
