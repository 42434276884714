import { useCallback } from 'react'
import type { ReactElement } from 'react'
import { TxStatus } from '../../../../services/txService/txService.types'
import { Modal } from '../../../../components/common/Modal/Modal'
import { useAppDispatch } from '../../../../hooks'
import { clearModal } from '../../../../actions/modalActions/clearModal'
import { ContractInteractionsTxModalWaitingWallet } from './States/ContractInteractionsTxModalWaitingWallet'
import { ContractInteractionsTxModalPending } from './States/ContractInteractionsTxModalPending'
import { ContractInteractionsTxModalError } from './States/ContractInteractionsTxModalError'
import { ContractInteractionsTxModalSuccess } from './States/ContractInteractionsTxModalSuccess'

type Props = {
  image: string
  txStatus: TxStatus
  txHash?: string | undefined
  chain: string
  title: string
  retryFunc?: (() => void) | undefined
  SuccessContent: ReactElement
}

export const ContractInteractionsTxModal = ({
  image,
  txStatus,
  txHash,
  chain,
  title,
  retryFunc = () => {},
  SuccessContent,
}: Props) => {
  const dispatch = useAppDispatch()
  const closeModal = useCallback(() => clearModal(dispatch)(), [dispatch])

  return (
    <Modal isVisible width="380px" onClose={closeModal} title={title} variant="info">
      {txStatus === TxStatus.WAITING_WALLET && <ContractInteractionsTxModalWaitingWallet image={image} />}

      {txStatus === TxStatus.PENDING && (
        <ContractInteractionsTxModalPending image={image} txHash={txHash} chain={chain} />
      )}

      {txStatus === TxStatus.ERROR && (
        <ContractInteractionsTxModalError txHash={txHash} chain={chain} retryFunc={retryFunc} />
      )}

      {txStatus === TxStatus.SUCCESS && (
        <ContractInteractionsTxModalSuccess
          txHash={txHash}
          chain={chain}
          image={image}
          SuccessContent={SuccessContent}
        />
      )}
    </Modal>
  )
}
