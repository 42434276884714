import type { SupportedTable } from '../../../reducers/settingsSlice/settingsSlice'
import { settingsSlice } from '../../../reducers/settingsSlice/settingsSlice'
import type { AppDispatch, RootState } from '../../../store/configureStore'

export const mapStateToProps = (state: RootState) => {
  return { order: state.settings.tablesSorting }
}

export const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    applyOrder: (name: SupportedTable, orderBy: string, sortingCallback?: (name: string, orderBy: string) => void) => {
      if (sortingCallback) {
        sortingCallback(name, orderBy)
        return
      }

      dispatch(settingsSlice.actions.setTableOrder({ table: name, column: orderBy }))
    },
  }
}

export type DispatchTableProps = ReturnType<typeof mapDispatchToProps>
export type InjectedStateTableProps = ReturnType<typeof mapStateToProps>
