import type { ReactElement } from 'react'
import type { TxStatusChangeExistingResult } from '../../../services/txService/txService.types'

export type ContractsInteractionsCardSize = 'large' | 'small'

export type GmContractChainConfig = {
  feeReceiver: string
}

export type ContractsPaginated<T> = {
  items: T[]
  pagination: {
    limit: number
    skip: number
    totalItems: number
  }
}

export type UserContractCodec = {
  _id: {
    chain: string
    address: string
  }
  deployer: string
  interactionsCounter: number
  lastInteractionAt: Date
  deployedAt: number
}

export type GetMultipleContractsSortOption = 'latest' | 'mostInteractions'

export type GetMultipleContractsParams = {
  deployer?: string | undefined
  sortBy: GetMultipleContractsSortOption
  limit: number
  skip: number
  weekly?: boolean | undefined
}

export type GetMultipleContractsArgs = GetMultipleContractsParams & { chain: string }

export type GetMultipleContractsQueryConfig = {
  args: GetMultipleContractsArgs
  options: {
    skip: boolean
  }
}

export type ContractInteractionCodec = {
  _id: {
    chain: string
    transactionHash: string
  }
  contract: string
  user: string
  method: string
  timestamp: number
}

export enum ContractInteractionTabId {
  POPULAR_ALL_TIME = 'POPULAR_ALL_TIME',
  POPULAR_WEEKLY = 'POPULAR_WEEKLY',
  NEW = 'NEW',
  HISTORY = 'HISTORY',
}

export type ContractInteractionTabs = {
  id: ContractInteractionTabId
  label: string | ReactElement
  show: boolean
}[]

export type ContractDeployButtonVariant = 'link' | 'action'

export enum YourContractType {
  LOCAL = 'LOCAL',
  SERVER = 'SERVER',
}

export type YourContractsLocalItem = {
  type: YourContractType.LOCAL
  data: TxStatusChangeExistingResult
}
export type YourContractsServerItem = {
  type: YourContractType.SERVER
  data: UserContractCodec
}
export type YourContractsTableDataItem = YourContractsLocalItem | YourContractsServerItem

export type GmContractArgs = { contractAddress: string }

export type ContractDetailsFeeType = 'single' | 'cumulative'
export type GmSuccessType = 'regular' | 'friend'

export type ContractsInteractionsState = {
  selectedChain: string
}
