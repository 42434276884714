import type { PublicQuoteResponseSchema, QuoteFeeSchema, QuotePromotionSchema } from '@rhinofi/bridge-api-spec'
import { Icon, IconSizes, Spacing, Text, Tooltip } from '@rhinofi/dvf-shared-ui'
import { TextSize, TypographyAlign } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import { useABTest } from '../../../../hooks/useABTest'
import { ABTestExperimentId } from '../../../../services/ABTestService'
import Decimal from 'decimal.js'
import { BigDecimal } from 'effect'
import { useMemo, useState } from 'react'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'
import { NETWORKS } from '../../../../constants/types'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { translate } from '../../../../intl/i18n'
import { formatFloat } from '../../../../services/helperService/formatFloat'
import { getBridgeDuration } from '../../../../services/helperService/getBridgeDuration'
import { selectBridgeChainsConfig } from '../../../../store/selectors/bridgeConfig.selectors'
import { useGetExchangeRate } from '../../hooks/useGetExchangeRate'
import { FeeBreakdown } from '../BridgeWidget/BridgeWidget.styled'
import { FeeBreakdownPanel, SeeDetailsButton } from './BridgeFeeBreakdown.styled'
import { gasTimeConf } from './gasTimeConf'
import { bigDecimalToNumericString } from '@rhinofi/effect-utils'

type BridgeFeeBreakdownProps = {
  amount: string | number | undefined
  feesConfig: QuoteFeeSchema | undefined
  promoConfig: QuotePromotionSchema | undefined
  chainIn: string
  chainOut: string
  token: string
  payAmount: PublicQuoteResponseSchema['payAmount'] | undefined
  isSend?: boolean
}

const L1_CHAINS = {
  [NETWORKS.ETHEREUM]: true,
  [NETWORKS.BINANCE]: true,
}

export const BridgeFeeBreakdown = ({
  isSend,
  amount,
  token,
  feesConfig,
  promoConfig,
  chainIn,
  chainOut,
  payAmount,
}: BridgeFeeBreakdownProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const getExchangeRate = useGetExchangeRate()

  const { chainInConfig, chainOutConfig } = useAppSelector(selectBridgeChainsConfig)

  const nativeTokenSource = chainInConfig?.nativeTokenName ?? ''
  const nativeTokenDestination = chainOutConfig?.nativeTokenName ?? ''
  const moveYouWillPay = useABTest(ABTestExperimentId.MOVE_DOWN_YOU_PAY_IN_BRIDGE) === '1'

  const exchangeRates = useMemo(
    () => ({
      tokenToDestination: getExchangeRate(token, nativeTokenDestination),
      destinationToToken: getExchangeRate(nativeTokenDestination, token),
      sourceToToken: getExchangeRate(nativeTokenSource, token),
    }),
    [getExchangeRate, token, nativeTokenDestination, nativeTokenSource],
  )

  const fees = useMemo(() => {
    const hasFees = !!feesConfig
    const { fee, gasFee, platformFee, percentageFee } = feesConfig ?? {}

    const totalPlatformFee = platformFee && percentageFee && BigDecimal.sum(platformFee, percentageFee)

    return {
      hasFees,
      gasFee: gasFee ? bigDecimalToNumericString(gasFee) : '0',
      platformFee: totalPlatformFee ? bigDecimalToNumericString(totalPlatformFee) : '0',
      totalFee: fee ? bigDecimalToNumericString(fee) : '0',
    }
  }, [feesConfig])

  const costToBridge = useMemo(() => {
    if (!gasTimeConf[chainIn] || !gasTimeConf[chainOut]) {
      return 0
    }
    const sourceFeeInToken = new Decimal(gasTimeConf[chainIn].nativeFee).times(exchangeRates.sourceToToken)

    if (L1_CHAINS[chainIn] || L1_CHAINS[chainOut]) {
      return sourceFeeInToken.toString()
    } else {
      const destinationFeeInToken = new Decimal(gasTimeConf[chainOut].nativeFee).times(exchangeRates.destinationToToken)

      return sourceFeeInToken.plus(destinationFeeInToken).toString()
    }
  }, [chainIn, chainOut, exchangeRates])

  const gasSaved = useMemo(() => {
    if (!gasTimeConf[chainIn] || !fees.hasFees) {
      return 0
    }
    return Number(costToBridge) - Number(fees.totalFee)
  }, [fees.hasFees, fees.totalFee, chainIn, costToBridge])

  const showBaseLine = useMemo(
    () => !fees.hasFees || !amount || !gasTimeConf[chainIn] || !gasTimeConf[chainOut] || gasSaved < 0,
    [fees.hasFees, amount, chainIn, chainOut, gasSaved],
  )

  const isFreePromo = useMemo(() => promoConfig?.name === 'fee-free-promo', [promoConfig?.name])
  const freePromoMaxUsd = useMemo(
    () => (isFreePromo && promoConfig?.maxLimitUsd ? +promoConfig.maxLimitUsd : 0),
    [isFreePromo, promoConfig?.maxLimitUsd],
  )

  const payAmountFormatted = payAmount ? formatFloat(BigDecimal.format(payAmount)) : '0'

  const youWillPay = (
    <FlexContainer id="you-will-pay-container" $justifyContent="space-between">
      <Text id="will-pay-amount" size={TextSize.XS} color="textSecondary">
        {translate('bridge.you_will_pay')}
      </Text>
      <Text id="will-pay-amount" size={TextSize.XS} color="textSecondary">
        {payAmountFormatted} {token}
      </Text>
    </FlexContainer>
  )

  return (
    <FeeBreakdown onClick={() => setIsCollapsed(!isCollapsed)}>
      {!moveYouWillPay && youWillPay}
      <Spacing size="8" />
      <FlexContainer id="total-fee-container" $justifyContent="space-between">
        <Text id="total-fee-name" size={TextSize.XS} color="textSecondary">
          {translate('bridge.total_fee')}{' '}
          {!isSend && (
            <>
              {' '}
              <Tooltip
                tooltipContent={
                  <>
                    <Text size={TextSize.XS} bold>
                      {isFreePromo ? translate('bridge.zero_fees_promo') : translate('bridge.save_on_gas')}
                    </Text>{' '}
                    <br />
                    <Text size={TextSize.XS}>
                      {isFreePromo
                        ? freePromoMaxUsd > 0
                          ? translate('bridge.zero_fees_desc', {
                              '%thresholdUSD': `<$${freePromoMaxUsd}`,
                            })
                          : translate('bridge.zero_fees_any_amount')
                        : showBaseLine
                          ? translate('bridge.save_on_gas_desc')
                          : translate('bridge.save_on_gas_connected', {
                              '%nativeCost': `${formatFloat(costToBridge)} ${token}`,
                              '%rhinoCost': `${formatFloat(fees.totalFee)} ${token}`,
                            })}
                    </Text>
                  </>
                }
              >
                <Icon id="info-circle" size={IconSizes.XSmall} color="textSecondary" />
              </Tooltip>
            </>
          )}
        </Text>
        <Text id="total-fee-amount" size={TextSize.XS} color="textSecondary">
          {isFreePromo && !Number(fees.platformFee) ? (
            <Text color="successMain" size={TextSize.XS} inline bold>
              {translate('bridge.zero_platform_fees')}
            </Text>
          ) : (
            `${formatFloat(fees.totalFee)} ${token}`
          )}
        </Text>
      </FlexContainer>
      {!isSend && (
        <FeeBreakdownPanel $isCollapsed={isCollapsed}>
          <div />
          {chainOut === 'BINANCE' ? (
            <>
              <FlexContainer id="platform-fee-container" $justifyContent="space-between">
                <Text id="platform-fee-name" size={TextSize.XS} color="textSecondary">
                  {translate('bridge.rebalancing_fee')}
                </Text>
                <Text id="platform-fee-amount" size={TextSize.XS} color="textSecondary">
                  {fees.totalFee} {token}
                </Text>
              </FlexContainer>
              <FlexContainer id="destination-fee-container" $justifyContent="space-between">
                <Text id="destination-fee-name" size={TextSize.XS} color="textSecondary">
                  {translate('bridge.destination_gas')}
                </Text>
                <Text id="destination-fee-amount" size={TextSize.XS} color="textSecondary">
                  0 {nativeTokenDestination}
                </Text>
              </FlexContainer>
            </>
          ) : (
            <>
              <FlexContainer id="platform-fee-container" $justifyContent="space-between">
                <Text id="platform-fee-name" size={TextSize.XS} color="textSecondary">
                  {translate('bridge.platform_fee')}
                </Text>
                <Text id="platform-fee-amount" size={TextSize.XS} color="textSecondary">
                  {fees.platformFee} {token}
                </Text>
              </FlexContainer>
              <FlexContainer id="destination-fee-container" $justifyContent="space-between">
                <Text id="destination-fee-name" size={TextSize.XS} color="textSecondary">
                  {translate('bridge.destination_fee')}
                </Text>
                <Text id="destination-fee-amount" size={TextSize.XS} color="textSecondary">
                  {fees.gasFee} {token}
                </Text>
              </FlexContainer>
            </>
          )}
          {moveYouWillPay && youWillPay}
          <FlexContainer $justifyContent="space-between">
            <Text size={TextSize.XS} color="textSecondary">
              {translate('helpers.time')}
            </Text>
            <Text size={TextSize.XS} color="textSecondary">
              {getBridgeDuration(chainInConfig, chainOutConfig)}
            </Text>
          </FlexContainer>
        </FeeBreakdownPanel>
      )}
      <Spacing size="8" />
      <FlexContainer $justifyContent="space-between">
        <SeeDetailsButton onClick={() => setIsCollapsed(!isCollapsed)} type="button">
          <Text size={TextSize.XS} color="textLink" className="see-details" bold>
            {isCollapsed ? translate('bridge.see_details') : translate('bridge.hide_details')}{' '}
            <Icon
              id={isCollapsed ? 'chevron-down' : 'chevron-up'}
              className="breakdown-trigger"
              color="textLink"
              size={IconSizes.XSmall}
            />
          </Text>
        </SeeDetailsButton>
        {promoConfig?.name === 'best-price-in-defi' && !isSend && (
          <Text color="successMain" size={TextSize.XS} bold align={TypographyAlign.Right}>
            <Icon id="award-fill" color="successMain" size={IconSizes.XSmall} />
            &nbsp;
            {translate('bridge.best_price_in_defi')}
          </Text>
        )}
      </FlexContainer>
    </FeeBreakdown>
  )
}
