import type { BridgeConfigEntrySchema } from '@rhinofi/bridge-api-spec'
import type { BadgePropItem, NetworkType } from '@rhinofi/dvf-shared-ui'

export enum BridgeTabsStep {
  'highlights' = 'highlights',
  'portfolio' = 'portfolio',
  'history' = 'history',
}

export type BridgeFormValues = {
  amount: string
  token: string
  chainIn: string
  chainOut: string
  recipient: string
  isOtherAddress: boolean
}

export type TokenWithBalance = {
  token: string
  chain?: string
  chainName?: string
  balanceToken?: string
  balanceUsd?: string
}

export type BridgeCategory = {
  label: string
  value: string
}

export type ChainWithBalance = Omit<BridgeConfigEntrySchema, 'category' | 'badge'> & {
  type: NetworkType
  balance: string
  balanceUsd: string
  category?: BridgeCategory | undefined
  badge?: BadgePropItem | undefined
  // Check if we need to keep these fields
  chain: string
  chainName: string
  isAvailable: boolean
  value?: string
  icon?: string
}

export enum BridgeConfirmationScreen {
  'bridgeToAny' = 0,
  'lessThanDollar' = 1,
}

export type BridgeConfirmationPromise = {
  type: BridgeConfirmationScreen
  resolve: (result: boolean) => boolean
}
