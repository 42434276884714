export const validateSolanaAddress = async (address?: string) => {
  if (!address) {
    return false
  }
  try {
    const { PublicKey } = await import('@solana/web3.js')
    const publicKey = new PublicKey(address)
    return PublicKey.isOnCurve(publicKey.toBytes())
    // eslint-disable-next-line no-unused-vars -- catch requirement
  } catch (error) {
    return false
  }
}
