import type { EIP1193ConnectionKit, Eip1193ConstructorProps } from './EIP1193ConnectionKit'

// eslint-disable-next-line functional/no-let -- singleton
let eip1193Connection: EIP1193ConnectionKit | null = null

export const getEIP1193ConnectionKit = async (props: Eip1193ConstructorProps) => {
  const { EIP1193ConnectionKit } = await import('./EIP1193ConnectionKit')
  if (!eip1193Connection) {
    eip1193Connection = new EIP1193ConnectionKit(props)
  }

  return eip1193Connection
}
