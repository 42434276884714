import { Text } from '@rhinofi/dvf-shared-ui'
import styled from 'styled-components'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import { ResponsivePage, ResponsivePageContainer } from '../../components/common/Wrappers/Page'

export const BridgePageContainer = styled(ResponsivePageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const BridgePage = styled(ResponsivePage)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const WidgetWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: fit-content;
  gap: 16px;
  margin: 0 auto 32px;

  @media ${respondBelow('s')} {
    flex-direction: column;
    gap: 48px;
  }
`
export const BridgeWidgetWrapper = styled.div`
  padding-top: 2px;
`

export const WidgetColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const PendingSpinnerSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  margin-top: 24px;
`

export const SuccessSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  height: 150px;
`

export const Summary = styled.div<{ $withBorder?: boolean; $success?: boolean }>`
  position: relative;
  margin-top: 8px;
  padding: 20px 16px;
  border: ${({ theme, $withBorder }) => ($withBorder ? `1px solid ${theme.divider}` : '')};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;

  ${({ $success }) =>
    $success
      ? `
      min-height: 64px;
      box-sizing: content-box;

    `
      : ''}
`

export const SummaryItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const PendingCard = styled.div`
  position: relative;
  display: flex;
  gap: 16px;

  margin-top: 24px;

  border-radius: 8px;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.divider};

  .bridge-path-icon {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .success-bridge {
    color: ${({ theme }) => theme.successMain};
  }
`

export const PendingCardItem = styled.div`
  padding: 16px 9px;
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2px;

  .pending-text {
    line-height: 22px;
  }

  .pending-chain-title {
    margin-top: 12px;
  }
`

export const PaleText = styled(Text)`
  color: ${({ theme }) => theme.textSecondary};
`
