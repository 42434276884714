import { getTonProvider } from '../../providers/tonProvider'
import { NETWORKS } from '../../../../constants/types'
import type { MulticallBalances } from '../../../ethereum/multicall'
import { SafeDecimal } from '../../../../utils/SafeDecimal'
import { getJettonWalletAddress } from './getJettonWalletAddress'
import type { BridgeTokenEntryArray } from '../../../helperService/tokensToArray'

export const getTonBalances = async (secondaryWalletAddress: string, tokens: BridgeTokenEntryArray) => {
  try {
    const { Address } = await import('@ton/core')
    const { JettonWallet } = await import('@ton/ton')
    const { publicProvider } = getTonProvider()
    if (!secondaryWalletAddress || !publicProvider) {
      throw new Error('Please connect your Ton wallet first.')
    }
    const tonFormattedUserAddress = Address.parse(secondaryWalletAddress)

    const balances = await Promise.all(
      tokens.map(async ({ address }) => {
        const jettonWalletAddress = await getJettonWalletAddress(tonFormattedUserAddress, Address.parse(address))

        const jettonWallet = publicProvider.open(JettonWallet.create(jettonWalletAddress))
        const balance = await jettonWallet.getBalance()
        return balance.toString()
      }),
    )

    return {
      [NETWORKS.TON]: tokens.reduce<MulticallBalances[string]>(
        (
          accumulator,
          {
            token,
            decimals,
          }: {
            token: string
            decimals: number
          },
          index: number,
        ) => {
          const balance = balances[index]
          accumulator[token] = {
            balance: SafeDecimal(balance)
              .div(10 ** decimals)
              .toString(),
            token,
          }
          return accumulator
        },
        {},
      ),
    }
  } catch (error) {
    console.error(error)
    return {}
  }
}
