import { Button, ButtonSizes, ButtonVariant, Icon, IconSizes, Text, TextSize, Tooltip } from '@rhinofi/dvf-shared-ui'
import { useMemo } from 'react'
import { showModal } from '../../../actions/modalActions/showModal'
import { selectSecondaryWalletChain } from '../../../actions/selectors/secondaryWalletSelectors'
import { selectContractWallet, selectIsAuthenticated } from '../../../actions/selectors/userSelectors'
import { AnimatedError } from '../../../components/common/animations/AnimatedError'
import { modalKeys } from '../../../constants/modalKeys'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { translate } from '../../../intl/i18n'
import { connectSecondaryWallet } from '../../../reducers/secondaryWalletSlice'
import { isNonEVMChain } from '../../../services/helperService/isNonEVMChain'
import { SafeDecimal } from '../../../utils/SafeDecimal'
import { bridgeWidgetLabels } from '../constants/bridgeWidgetLabels.constants'
import type { NormalizedError } from '../helpers/normalizeErrors'
import { useBridgeAllowance } from '../hooks'
import { ReviewButtonSpace } from './BridgeWidget/BridgeWidget.styled'
import type { PublicQuoteResponseSchema } from '@rhinofi/bridge-api-spec'
import { FlexContainer } from '../../../components/common/Wrappers/Containers'

enum ReviewButtonType {
  Default = 'default',
  SecondaryConnect = 'secondaryConnect',
  Connect = 'connect',
  Unsupported = 'unsupported',
  ApproveSpendingCap = 'approveSpendingCap',
}

type Props = {
  normalizedError: NormalizedError
  token: string
  chain: string
  chainName: string
  withdrawChain: string
  withdrawChainName: string
  amount: string
  isOtherAddress: boolean
  payAmount: PublicQuoteResponseSchema['payAmount'] | undefined
  customLabel?: string
  idPrefix?: string
}

export const ReviewButton = ({
  normalizedError,
  token,
  amount,
  chain,
  chainName,
  withdrawChain,
  withdrawChainName,
  isOtherAddress,
  customLabel,
  idPrefix = '',
  payAmount,
}: Props) => {
  const dispatch = useAppDispatch()
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const secondaryConnectedChain = useAppSelector(selectSecondaryWalletChain)
  const contractWallet = useAppSelector(selectContractWallet)

  const { approve, shouldApprove, isApproving, isFetchingAllowance } = useBridgeAllowance({ payAmount })

  const { disabled, text, buttonType, buttonId } = useMemo(() => {
    const hasNonEVMInteraction = isNonEVMChain(chain) || (isNonEVMChain(withdrawChain) && !isOtherAddress)
    const targetNonEVMChain = hasNonEVMInteraction && isNonEVMChain(chain) ? chain : withdrawChain
    const targetNonEVMChainName = hasNonEVMInteraction && isNonEVMChain(chain) ? chainName : withdrawChainName

    if (contractWallet.isContractWallet) {
      return {
        disabled: true,
        text: bridgeWidgetLabels.unsupportedWallet(),
        buttonType: ReviewButtonType.Unsupported,
        buttonId: 'wallet-not-supported',
      }
    }
    if (!isAuthenticated) {
      return {
        disabled: false,
        text: bridgeWidgetLabels.connectWallet(),
        buttonType: ReviewButtonType.Connect,
        buttonId: `${idPrefix}connect-bridge`,
      }
    }
    if (hasNonEVMInteraction && secondaryConnectedChain !== targetNonEVMChain) {
      return {
        disabled: false,
        text: translate('bridge.connect_to_chain', { '%chain': targetNonEVMChainName }),
        buttonType: ReviewButtonType.SecondaryConnect,
        buttonId: `${idPrefix}connect-bridge-secondary`,
      }
    }

    if (SafeDecimal(amount).eq(0)) {
      return {
        disabled: true,
        text: `${bridgeWidgetLabels.enterAmount()}...`,
        buttonType: ReviewButtonType.Default,
        buttonId: 'review-bridge',
      }
    }

    if ('buttonDisabledError' in normalizedError && normalizedError.buttonDisabledError) {
      return {
        disabled: true,
        text: `${normalizedError.buttonDisabledError}...`,
        buttonType: ReviewButtonType.Default,
        buttonId: 'bridge-validation-error',
      }
    }

    if (shouldApprove) {
      return {
        disabled: false,
        text: translate('deposit.set_spending_cap', { '%token': token }),
        buttonType: ReviewButtonType.ApproveSpendingCap,
        buttonId: `${idPrefix}approve-bridge`,
      }
    }

    return {
      disabled: false,
      text: customLabel || bridgeWidgetLabels.bridge(),
      buttonType: ReviewButtonType.Default,
      buttonId: `${idPrefix}review-bridge`,
    }
  }, [
    amount,
    chain,
    chainName,
    contractWallet.isContractWallet,
    isAuthenticated,
    normalizedError,
    withdrawChain,
    withdrawChainName,
    secondaryConnectedChain,
    shouldApprove,
    token,
    isOtherAddress,
    customLabel,
    idPrefix,
  ])

  return (
    <ReviewButtonSpace>
      {normalizedError.overSubmitError && buttonType !== ReviewButtonType.SecondaryConnect ? (
        <AnimatedError text={normalizedError.overSubmitError} />
      ) : (
        <FlexContainer $gap="24px" $direction="column" $fullWidth>
          <Button
            disabled={disabled || isFetchingAllowance}
            loading={isApproving}
            loadingLabel={isApproving ? `${translate('global.initiating_approval')}...` : undefined}
            fullWidth={true}
            variant={ButtonVariant.primary}
            size={ButtonSizes.Large}
            id={buttonId}
            type={buttonType === ReviewButtonType.ApproveSpendingCap ? 'button' : 'submit'}
            onClick={(event) => {
              if (buttonType === ReviewButtonType.Connect) {
                event.preventDefault()
                event.stopPropagation()
                showModal(dispatch)(modalKeys.connectWallet)
              } else if (buttonType === ReviewButtonType.SecondaryConnect) {
                event.preventDefault()
                event.stopPropagation()
                const targetChain = isNonEVMChain(chain) ? chain : withdrawChain
                void dispatch(connectSecondaryWallet({ chain: targetChain }))
              } else if (buttonType === ReviewButtonType.ApproveSpendingCap) {
                event.preventDefault()
                event.stopPropagation()
                void approve()
              }
            }}
          >
            {text}
          </Button>
          {buttonId === 'approve-bridge' && (
            <Tooltip
              tooltipContent={
                <>
                  <Text size={TextSize.XS} bold>
                    {translate('deposit.spending_cap')}
                  </Text>
                  <br />
                  <Text size={TextSize.XS}>{translate('deposit.spending_cap_desc')}</Text>
                </>
              }
            >
              <Text size={TextSize.XS} id="spending-cap-bridge-text" color="textSecondary">
                {translate('deposit.what_is_spending_cap')}{' '}
                <Icon id="info-circle" color="textSecondary" size={IconSizes.XSmall} />
              </Text>
            </Tooltip>
          )}
        </FlexContainer>
      )}
    </ReviewButtonSpace>
  )
}
