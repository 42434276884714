import styled from 'styled-components'
import { Icon, IconSizes } from '@rhinofi/dvf-shared-ui'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import { useLocation } from 'react-router-dom'
import { translate } from '../../intl/i18n'
import { zIndices } from '../../constants/zIndex'
import { ChatSupport } from './ChatSupport'
import { SocialItem } from './SocialItem'

export const Social = () => {
  const { pathname } = useLocation()

  if (pathname.includes('/auth')) {
    return null
  }
  return (
    <Wrapper>
      <SocialItem tooltip={translate('social.join_telegram')} id="telegram-social">
        <a href="https://t.me/rhinofiannouncements" target="_blank" rel="noopener noreferrer" id="telegram-social-link">
          <Icon id="telegram" className="socialIcon" size={IconSizes.Large} />
        </a>
      </SocialItem>
      <SocialItem tooltip={translate('social.join_twitter_followers')} id="twitter-social">
        <a href="https://twitter.com/rhinofi" target="_blank" rel="noopener noreferrer" id="twitter-social-link">
          <Icon id="twitter" className="socialIcon" size={IconSizes.Large} />
        </a>
      </SocialItem>
      <SocialItem tooltip={translate('social.chat_discord')} id="discord-social">
        <a href="https://discord.gg/26sXx2KAhy" target="_blank" rel="noopener noreferrer" id="discord-social-link">
          <Icon id="discord" className="socialIcon" size={IconSizes.Large} />
        </a>
      </SocialItem>
      <ChatSupport />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;

  z-index: ${zIndices.social};
  height: 60px;
  padding: 0 16px 16px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;

  @media ${respondBelow('xxs')} {
    display: none;
  }
`
