import { Icon, Label, Loader, Text, Title, TokenIcon } from '@rhinofi/dvf-shared-ui'
import { LabelSize, TextSize, TitleSize, TypographyAlign } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import { bigDecimalToNumericString } from '@rhinofi/effect-utils'
import { truncate } from 'lodash-es'
import moment from 'moment'
import styled from 'styled-components'
import { clearModal } from '../../../../actions/modalActions/clearModal'
import { selectModal } from '../../../../actions/selectors/modalSelectors'
import { selectIsAuthenticated } from '../../../../actions/selectors/userSelectors'
import { SmallSpinner } from '../../../../components/common/Helpers/SmallSpinner'
import { Modal } from '../../../../components/common/Modal/Modal'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'
import { modalKeys } from '../../../../constants/modalKeys'
import { NETWORKS } from '../../../../constants/types'
import { useConfig } from '../../../../hooks'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useExplorerLink } from '../../../../hooks/util/useExplorerLink'
import { translate } from '../../../../intl/i18n'
import { capitalize } from '../../../../services/helperService'
import { shortenAddress } from '../../../../services/helperService/shortenAddress'
import { useGetBridgeHistoryQuery } from '../../../../store/apis/bridge.api'
import BridgePath from '../../assets/bridgepath.svg?react'
import { PaleText, PendingCard, PendingCardItem, Summary, SummaryItem } from '../../Bridge.styled'

const TOKEN_ICON_SIZE = '56px'

export const RecentBridgeTxModal = () => {
  const dispatch = useAppDispatch()
  const { config } = useConfig()

  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const modal = useAppSelector(selectModal)
  const bridgeId = modal.modalShown === modalKeys.recentBridgeTx ? modal.modalPayload.bridgeId : null
  const { data } = useGetBridgeHistoryQuery(
    {
      path: {
        bridgeId: bridgeId || '',
      },
    },
    {
      skip: !isAuthenticated || !bridgeId,
      pollingInterval: 10000,
    },
  )

  const { etherscanLink } = useExplorerLink()
  const bridgesQueryResult = data?.result

  if (modal.modalShown !== modalKeys.recentBridgeTx || !config) {
    return null
  }

  if (!bridgesQueryResult) {
    return (
      <Modal isVisible width="360px" onClose={() => clearModal(dispatch)()}>
        <Title size={TitleSize.XXS}>{capitalize(translate('global.details'))}</Title>
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      </Modal>
    )
  }

  const { createdAt, amountOut, token, chainIn, chainOut, state, recipient } = bridgesQueryResult
  const depositTxHash = 'depositTxHash' in bridgesQueryResult ? bridgesQueryResult.depositTxHash : ''
  const withdrawTxHash = 'withdrawTxHash' in bridgesQueryResult ? bridgesQueryResult.withdrawTxHash : ''
  const prettyChainInName = config[chainIn]?.name || chainIn
  const prettyChainOutName = config[chainOut]?.name || chainOut

  return (
    <Modal isVisible width="360px" onClose={() => clearModal(dispatch)()}>
      <Title size={TitleSize.XXS}>{capitalize(translate('global.details'))}</Title>
      <Summary>
        <SummaryItem>
          <Text size={TextSize.XS}>{translate('helpers.timestamp')}</Text>
          <PaleText size={TextSize.XS}>{createdAt ? moment(createdAt).format('DD-MM HH:mm:ss') : '...'}</PaleText>
        </SummaryItem>
        <SummaryItem>
          <Text size={TextSize.XS}>{translate('helpers.value')}</Text>
          <PaleText size={TextSize.XS}>
            {bigDecimalToNumericString(amountOut)} {token}
          </PaleText>
        </SummaryItem>
        <SummaryItem>
          <Text size={TextSize.XS}>{translate('helpers.recipient')}</Text>
          <PaleText size={TextSize.XS}>{shortenAddress(recipient)}</PaleText>
        </SummaryItem>
      </Summary>
      <PendingCard>
        <PendingCardItem>
          <Title size={TitleSize.XXS} align={TypographyAlign.Center}>
            {truncate(prettyChainInName, { length: 8 })}
          </Title>
          <TokenIcon customSize={TOKEN_ICON_SIZE} token={chainIn} size="large" />
          <FlexContainer $gap="8px" $alignItems="center">
            <Icon id="check-circle-fill" className="success-bridge" />
            <Label size={LabelSize.Label}>
              {depositTxHash ? (
                <a href={etherscanLink(depositTxHash, chainIn, true)} target="_blank" rel="noopener noreferrer">
                  {`tx:${depositTxHash.substr(0, 9)}...`}
                </a>
              ) : (
                translate('helpers.confirmed')
              )}
            </Label>
          </FlexContainer>
        </PendingCardItem>
        <PendingCardItem>
          <Title size={TitleSize.XXS} align={TypographyAlign.Center} title={prettyChainOutName}>
            {truncate(prettyChainOutName, { length: 12 })}
          </Title>
          <TokenIcon customSize={TOKEN_ICON_SIZE} token={chainOut} size="large" />
          <FlexContainer $gap="8px" $alignItems="center">
            {state === 'EXECUTED' ? <Icon id="check-circle-fill" className="success-bridge" /> : <SmallSpinner />}
            {withdrawTxHash ? (
              <Label size={LabelSize.Label}>
                {chainOut === NETWORKS.STARKEX ? (
                  translate('helpers.confirmed')
                ) : (
                  <a href={etherscanLink(withdrawTxHash, chainOut, true)} target="_blank" rel="noopener noreferrer">
                    {state === 'EXECUTED'
                      ? `tx:${withdrawTxHash.substr(0, 9)}...`
                      : translate('global.view_on_explorer')}
                  </a>
                )}
              </Label>
            ) : (
              <Label size={LabelSize.Label}>{translate('helpers.pending')}...</Label>
            )}
          </FlexContainer>
        </PendingCardItem>
        <BridgePath className="bridge-path-icon" />
      </PendingCard>
    </Modal>
  )
}

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 299px;
`
