import { Icon, IconSizes } from '@rhinofi/dvf-shared-ui'
import { Link } from 'react-router-dom'
import { parseI18nTextWithLink } from './parseI18nTextWithLink'

type TextWithLinkProps = {
  className?: string
  text: () => string
  linkIcon?: string
  linkId?: string
  spanWrapper?: boolean
} & ({ onClick: () => void } | { to: string } | { href: string; openNewTab?: boolean })

export const TextWithLink = ({ text, linkIcon, linkId, className, spanWrapper, ...props }: TextWithLinkProps) => {
  const { link, suffix, prefix } = parseI18nTextWithLink(text())

  // eslint-disable-next-line functional/no-let -- legacy
  let content
  const linkContent = (
    <>
      {link}
      {linkIcon ? ' ' : ''}
      {linkIcon ? <Icon id={linkIcon} size={IconSizes.Small} /> : null}
    </>
  )
  if ('href' in props) {
    const { openNewTab = true } = props

    const linkProps = openNewTab
      ? {
          target: '_blank',
          rel: 'noopener noreferrer',
        }
      : {}

    content = (
      <a
        id={linkId ?? 'inline-link'}
        className={className}
        href={props.href}
        onClick={(event) => {
          event.stopPropagation()
        }}
        {...linkProps}
      >
        {linkContent}
      </a>
    )
  } else if ('to' in props) {
    const { to } = props
    content = (
      <Link
        id={linkId ?? 'inline-link'}
        className={className}
        to={to}
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        {linkContent}
      </Link>
    )
  } else {
    const { onClick } = props
    content = (
      <span id={linkId ?? 'inline-link'} className={className} onClick={onClick}>
        {linkContent}
      </span>
    )
  }

  if (spanWrapper) {
    return (
      <span>
        {prefix}
        {prefix ? ' ' : ''}
        {content}
        {suffix ? ' ' : ''}
        {suffix}
      </span>
    )
  }
  return (
    <>
      {prefix}
      {prefix ? ' ' : ''}
      {content}
      {suffix ? ' ' : ''}
      {suffix}
    </>
  )
}
