import styled, { css } from 'styled-components'
import { Text } from '@rhinofi/dvf-shared-ui'
import type { DynamicTheme } from '@rhinofi/dvf-shared-ui/lib/types/theme'
import { TextSize } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import Spinner from '../../../assets/icons/spinner.svg?react'
import { translate } from '../../../intl/i18n'

type CircleColor = keyof Pick<DynamicTheme, 'brandA1' | 'secondary'>

type LoaderProps = {
  size?: string
  withText?: boolean
  circleColor?: CircleColor
}

export const StepLoader = ({ size, withText = false, circleColor = 'brandA1' }: LoaderProps) => (
  <Animate $size={size} $circleColor={circleColor}>
    <Spinner />
    {withText && (
      <Text color="textSecondary" size={TextSize.XS}>
        {translate('helpers.loading')}...
      </Text>
    )}
  </Animate>
)

type AnimateProps = {
  $size?: string | undefined
  $circleColor: CircleColor
}

const Animate = styled.div<AnimateProps>`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${({ theme, $circleColor }) => theme[$circleColor]};
  font-size: 0;
  svg {
    animation: rotateLoader linear 1s infinite;

    ${({ $size }) =>
      $size
        ? css`
            width: ${$size};
            height: ${$size};
          `
        : ''}
  }
  @keyframes rotateLoader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
