import type { MissionAPIDocument } from '@rhinofi/rhino-nation-api-spec'
import { Icon, IconSizes, Title, TitleSize } from '@rhinofi/dvf-shared-ui'
import { memo, useMemo } from 'react'
import GemIcon from '../../../../../../../assets/icons/gem.svg?react'
import { FlexContainer } from '../../../../../../../components/common/Wrappers/Containers'
import { translate } from '../../../../../../../intl/i18n'
import { NATION_XP_LABEL } from '../../../../../constants/nationGeneral.constants'
import { AnimatedNumberCounter } from './AnimatedNumberCounter'
import {
  CustomTitle,
  EarnedGemsImage,
  EarnedItemContainer,
  EarnedSpan,
  EarnedTitleSpan,
  FadeInText,
  MissionItemsContainer,
  RxpSpan,
} from './NationOnboardingModalGemsEarnedStep.styled'

const NUMBER_COUNTER_DURATION = 4000

type MissionItemProps = {
  title: string
  gemsAwarded: number
  delay: number
  duration: number
}

type GemsEarnedStepProps = {
  totalGemsEarned: number
  missionsCompleted: MissionAPIDocument[]
}

const MissionItem = memo(({ title, gemsAwarded, delay, duration }: MissionItemProps) => (
  <FadeInText delay={delay} duration={duration}>
    <FlexContainer $gap="8px">
      <Icon id="check2-circle" size={IconSizes.Large} color="accentGreen" />
      <Title size={TitleSize.XXS} color="secondary80">
        {title}
      </Title>
    </FlexContainer>
    <EarnedItemContainer>
      <EarnedSpan>
        +<AnimatedNumberCounter start={1} end={gemsAwarded} duration={NUMBER_COUNTER_DURATION} />
        <RxpSpan fontSize="16px">{NATION_XP_LABEL}</RxpSpan> +
        <AnimatedNumberCounter start={1} end={gemsAwarded} duration={NUMBER_COUNTER_DURATION} />
      </EarnedSpan>
      <GemIcon width={24} height={24} />
    </EarnedItemContainer>
  </FadeInText>
))

export const NationOnboardingModalGemsEarnedStep = ({ totalGemsEarned, missionsCompleted }: GemsEarnedStepProps) => {
  const missionAnimationDuration = useMemo(
    () => NUMBER_COUNTER_DURATION / missionsCompleted.length,
    [missionsCompleted.length],
  )

  return (
    <FlexContainer $gap="16px" $direction="column" $alignItems="center" $flexGrow="1" $padding="0 0 24px">
      <CustomTitle size={TitleSize.XS}>
        {translate('nation.you_earned')}
        {` `}
        <EarnedTitleSpan>
          <AnimatedNumberCounter start={1} end={totalGemsEarned} duration={NUMBER_COUNTER_DURATION} />
          <RxpSpan>{NATION_XP_LABEL}</RxpSpan> &{' '}
          <AnimatedNumberCounter start={1} end={totalGemsEarned} duration={NUMBER_COUNTER_DURATION} />
          <EarnedGemsImage width={40} height={40} />
        </EarnedTitleSpan>
      </CustomTitle>

      <MissionItemsContainer>
        {missionsCompleted.map((mission, index) => (
          <MissionItem
            key={mission._id.id}
            title={mission.title}
            gemsAwarded={mission.gemsAwarded}
            delay={index * missionAnimationDuration}
            duration={missionAnimationDuration}
          />
        ))}
      </MissionItemsContainer>
    </FlexContainer>
  )
}
