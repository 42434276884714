import { useEffect } from 'react'
import type { DefaultTheme } from 'styled-components'
import styled, { ThemeProvider } from 'styled-components'
import { Button, ButtonSizes, TextSize, Text } from '@rhinofi/dvf-shared-ui'
import { translate } from '../../intl/i18n'
import { Main } from '../../Root.styled'
import { GlobalStyle } from '../../styles/GlobalStyle'

const isErrorObject = (error: unknown): error is object => {
  return !!error && typeof error === 'object'
}

type ErrorPageProps = {
  theme: DefaultTheme
  error: unknown
}

export const ErrorPage = ({ error, theme }: ErrorPageProps) => {
  useEffect(() => {
    // In case of chunk load error, we've deployed a new version of the platform so all chunks are missing
    // refresh the page so new chunks are picked up, do it only once so it doesn't end up in a refresh loop
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') ?? 'false')
    if (
      isErrorObject(error) &&
      // eslint-disable-next-line @typescript-eslint/no-base-to-string -- Legacy
      /(Failed to fetch)|( error loading) dynamically imported module/.test(error.toString()) &&
      !hasRefreshed
    ) {
      window.sessionStorage.setItem('retry-lazy-refreshed', 'true')
      window.location.reload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- runs on mount
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-base-to-string -- we want default toString behavior
  const stringifiedError = isErrorObject(error) ? error.toString() : translate('errors.unknown_error')

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Main>
        <ErrorWrapper>
          <Text bold>{translate('errors.unexpected_error')}</Text>
          <Text size={TextSize.S} color="errorMain">
            <pre>{stringifiedError}</pre>
          </Text>
          <Button
            id="error-restart-app"
            size={ButtonSizes.Large}
            onClick={() => {
              window.location.reload()
            }}
          >
            {translate('errors.restart_app')}
          </Button>
        </ErrorWrapper>
      </Main>
    </ThemeProvider>
  )
}

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: auto;

  border-radius: 16px;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.divider};

  pre {
    overflow: auto;
    margin-bottom: 40px;
  }
`
