import { ConnectLoaderWrapper, NormalIconWrapper, WalletButtonStyled } from './ConnectWallet.styled'
import { Icon, IconSizes, Text, TextSize } from '@rhinofi/dvf-shared-ui'
import { SmallSpinner } from '../common/Helpers/SmallSpinner'
import { FlexContainer } from '../common/Wrappers/Containers'

type WalletButtonProps = {
  isConnecting: boolean
  isDetected?: boolean
  disabled?: boolean
  onClick: () => void
  label: string
  icon: string
  id?: string
}

export const WalletButton = ({ isConnecting, isDetected, icon, disabled, label, onClick, id }: WalletButtonProps) => (
  <WalletButtonStyled disabled={disabled} onClick={onClick} id={id}>
    <div className="wallet-icon-wrapper">
      {isConnecting ? (
        <ConnectLoaderWrapper>
          <SmallSpinner />
        </ConnectLoaderWrapper>
      ) : icon.startsWith('bi-') ? (
        <NormalIconWrapper>
          <Icon id={icon.replace('bi-', '')} size={IconSizes.Large} />
        </NormalIconWrapper>
      ) : (
        <img className="wallet-icon" src={icon} alt="wallet" />
      )}
    </div>
    <FlexContainer $justifyContent="space-between" $fullWidth>
      <Text size={TextSize.S} bold>
        {label}
      </Text>
      {isDetected ? (
        <Text size={TextSize.XS} color="textSecondary" bold>
          Detected
        </Text>
      ) : (
        ''
      )}
    </FlexContainer>
  </WalletButtonStyled>
)
