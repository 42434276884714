import { useCallback, useMemo } from 'react'
import { Text, TextSize, ButtonVariant, ButtonSizes } from '@rhinofi/dvf-shared-ui'
import type { GemsTransactionAPIDocument, MissionAPIDocument } from '@rhinofi/rhino-nation-api-spec'
import { FlexContainer } from '../../../../../../components/common/Wrappers/Containers'
import { NationMissionIcon } from '../../../NationMissionIcon/NationMissionIcon'
import GemIcon from '../../../../../../assets/icons/gem.svg?react'
import { showModal } from '../../../../../../actions/modalActions/showModal'
import { modalKeys } from '../../../../../../constants/modalKeys'
import { useAppDispatch } from '../../../../../../hooks'
import { NATION_XP_LABEL } from '../../../../constants/nationGeneral.constants'
import { Container, EllipsisText, CloseButton, TitleGemsContainer } from './NationMissionNotification.styled'
import type React from 'react'

type Props = {
  gemsTransaction: GemsTransactionAPIDocument
  setDismissedIds: (setDismissedIds: string[]) => void
}

export const NationMissionNotification = ({ gemsTransaction, setDismissedIds }: Props) => {
  const dispatch = useAppDispatch()

  const mission: null | MissionAPIDocument = useMemo(() => {
    if ('mission' in gemsTransaction) {
      return gemsTransaction.mission
    }
    return null
  }, [gemsTransaction])

  const onNotificationClickCallback = useCallback(() => {
    showModal(dispatch)(modalKeys.nationCompletedMission, { gemsTransaction })
  }, [gemsTransaction, dispatch])

  const setDismissedIdCallback = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setDismissedIds([gemsTransaction._id])
    },
    [setDismissedIds, gemsTransaction._id],
  )

  if (mission === null) {
    return null
  }

  return (
    <Container $gap="8px" $justifyContent="space-between" onClick={onNotificationClickCallback}>
      <TitleGemsContainer $gap="8px" $direction="column" $alignItems="stretch">
        <FlexContainer $gap="12px">
          <NationMissionIcon type={mission.type} />
          <EllipsisText size={TextSize.XS}>{mission.title}</EllipsisText>
        </FlexContainer>

        <FlexContainer $gap="8px">
          <FlexContainer $alignItems="baseline">
            <Text>+{mission.gemsAwarded}</Text>
            <Text size={TextSize.XS} color="secondary60">
              {NATION_XP_LABEL}
            </Text>
          </FlexContainer>

          <FlexContainer $gap="4px">
            <Text>+{mission.gemsAwarded}</Text>
            <GemIcon />
          </FlexContainer>
        </FlexContainer>
      </TitleGemsContainer>

      <FlexContainer $gap="8px">
        <CloseButton
          variant={ButtonVariant.close}
          size={ButtonSizes.ExtraSmall}
          id="close-mission-completed-notification"
          onClick={setDismissedIdCallback}
          icon="x-lg"
        />
      </FlexContainer>
    </Container>
  )
}
