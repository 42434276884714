import { useEffect, type ReactNode } from 'react'
import { animated, useSpringRef, useTransition } from '@react-spring/web'
import { clampedGentleFigmaFast } from '../../../constants/springs'
import type { BridgeTabsStep } from '../types/bridge-widget.types'
import type { BridgeProgressStep } from '../slices/bridgeProgress.slice'

type Props<T extends BridgeProgressStep | BridgeTabsStep> = {
  currentState: T
  children: ({ step }: { step: T }) => ReactNode
}

export const BridgeTransitionStates = <T extends BridgeProgressStep | BridgeTabsStep>({
  currentState,
  children,
}: Props<T>) => {
  const transRef = useSpringRef()
  const transitions = useTransition(currentState, {
    ref: transRef,
    keys: null,
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
    config: clampedGentleFigmaFast,
    exitBeforeEnter: true,
  })

  useEffect(() => {
    void transRef.start()
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on step change
  }, [currentState])

  if (!children) {
    return null
  }

  return (
    <>
      {transitions((style, step) => (
        <animated.div style={style}>{children({ step })}</animated.div>
      ))}
    </>
  )
}
