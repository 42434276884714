import type { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { updateSecondaryAddress } from '../../../../reducers/secondaryWalletSlice'
import type { SolanaProvider } from '../../types/solana.types'

export const createSolanaListener = (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  provider: SolanaProvider,
) => {
  if (provider && typeof provider.on === 'function') {
    provider.on('accountChanged', (publicKey) => {
      dispatch(updateSecondaryAddress(publicKey.toBase58()))
    })
  }
}
