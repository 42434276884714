import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { UserVerification } from './types/MigrationStampedeState'

export type VerificationState = {
  userVerification?: UserVerification
}

const getInitialState = (): VerificationState => {
  return {}
}

export const userVerificationSlice = createSlice({
  name: 'userVerificationSlice',
  initialState: getInitialState(),
  reducers: {
    setUserVerification(state, action: PayloadAction<UserVerification>) {
      state.userVerification = action.payload
    },
  },
})

export const { setUserVerification } = userVerificationSlice.actions
