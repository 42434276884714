/* eslint-disable -- legacy */
import type { CSSProperties } from 'react'
import React from 'react'
import styled, { css } from 'styled-components/'
import type { SupportedTable } from '../../../reducers/settingsSlice/settingsSlice'
import Title from './Title'
import { sortTableBy } from './Table.Utils'

const TH = styled.th<{
  $fullSize?: boolean
  $noTextWrap?: boolean
}>`
  padding: 8px 4px;
  user-select: none;
  font-family: ${(props) => props.theme.defaultFont};
  ${(props) =>
    props.$noTextWrap &&
    css`
      white-space: nowrap;
    `}

  ${(props) =>
    props.$fullSize &&
    css`
      height: 40px;
      box-sizing: border-box;
      padding: 0;

      &:first-child {
        padding-left: 16px;
      }
      &:last-child {
        padding-right: 16px;
      }
    `}
`
export type HeaderFormat = {
  id: string
  alt?: string
  align?: CSSProperties['textAlign'] | undefined
  cells?: unknown[]
  classes?: string[]
  width?: string
  sortingEnabled?: boolean
}

type HeaderProps = {
  name: string
  format: HeaderFormat
  setOrder: (tableName: SupportedTable, column: string) => void
  order: string
  fullSize?: boolean
  sortingCallback?: ((order: string, orderBy: string) => void) | null
  noTextWrap?: boolean
}

export class Header extends React.PureComponent<HeaderProps> {
  // @ts-expect-error -- legacy code
  render() {
    const { name, format, setOrder, order, fullSize, sortingCallback, noTextWrap } = this.props

    const { id, alt = '', align = 'center', cells = [], classes = [], width = 'auto', sortingEnabled = false } = format

    const colSpan = cells.length
    const [orderBy, ascending] = order.split('-')
    const mergedClasses = ['sortable', ...classes].join(' ')
    const sortHandler = () => sortTableBy(sortingEnabled, setOrder, name, id, sortingCallback)

    return (
      // @ts-expect-error -- legacy code
      <TH
        colSpan={colSpan}
        key={`th-${id}`}
        className={mergedClasses}
        style={{ width: width, textAlign: align }}
        $fullSize={fullSize}
        $noTextWrap={noTextWrap}
      >
        <Title
          title={alt}
          format={format}
          orderBy={orderBy}
          ascending={ascending === 'asc'}
          fullSize={fullSize}
          handleClick={sortHandler}
        />
      </TH>
    )
  }
}
