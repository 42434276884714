import type { StarknetWindowObject } from 'starknetkit'
import type { RpcProvider } from 'starknet'

// eslint-disable-next-line functional/no-let -- singleton
let starknetProvider: StarknetWindowObject | null = null
// eslint-disable-next-line functional/no-let -- singleton
let publicStarknetProvider: RpcProvider | null = null

export const setStarknetProvider = async (provider: StarknetWindowObject, rpcUrl: string) => {
  const { RpcProvider } = await import('starknet')
  starknetProvider = provider
  publicStarknetProvider = new RpcProvider({ nodeUrl: rpcUrl })
}

export const getStarknetProvider = (): StarknetWindowObject => {
  if (!starknetProvider) {
    throw new Error('Please connect your Starknet wallet first.')
  }
  return starknetProvider
}

export const getPublicStarknetProvider = () => {
  if (!publicStarknetProvider) {
    throw new Error('Please connect your Starknet wallet first.')
  }
  return publicStarknetProvider
}
