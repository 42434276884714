import type { RootState } from '../store/configureStore'

export const selectUserAddress = (state: RootState) => state.user.wallet?.address
export const selectWallet = (state: RootState) => state.user.wallet
export const selectAuthData = (state: RootState) => state.user.auth.data
export const selectAuthRequested = (state: RootState) => state.user.auth.requested
export const selectAuthRejected = (state: RootState) => state.user.auth.rejected
export const selectIsAuthenticated = (state: RootState) => !!state.user.auth.data && !!state.user.wallet?.address
export const selectIsAuthenticating = (state: RootState) =>
  state.user.auth.requested && !state.user.auth.rejected && !state.user.auth.data
export const selectContractWallet = (state: RootState) => state.user.contractWallet
export const selectConnectedNetwork = (state: RootState) => state.user.network
export const selectIsWalletConnected = (state: RootState) => !!state.user.wallet
