import { Text, Title, TitleSize, TypographyAlign } from '@rhinofi/dvf-shared-ui'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import styled from 'styled-components'
import GemIcon from '../../../../../../assets/icons/gem.svg?react'
import { FlexContainer } from '../../../../../../components/common/Wrappers/Containers'
import { translate } from '../../../../../../intl/i18n'
import { NATION_XP_LABEL } from '../../../../constants/nationGeneral.constants'

export const NationOnboardingModalGemsAndRxpStep = () => {
  return (
    <Wrapper $direction="column" $padding="0 24px 24px" $gap="32px" $flexGrow="1">
      <CustomTitle size={TitleSize.XS} align={TypographyAlign.Center}>
        {translate('nation.what_are_gems_and_rxp_title')}
      </CustomTitle>
      <Container $gap="32px" $padding="0 0 24px" $collapseOnMobile>
        <FlexContainer $direction="column" $alignItems="flex-start" $gap="8px">
          <GemIconCustom width={64} height={64} />
          <Text>{translate('nation.what_are_gems_desc')}</Text>
        </FlexContainer>
        <FlexContainer $direction="column" $alignItems="flex-start">
          <Title size={TitleSize.XS} color="secondary60" align={TypographyAlign.Left}>
            {NATION_XP_LABEL}
          </Title>
          <Text>{translate('nation.what_is_rxp_desc')}</Text>
        </FlexContainer>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled(FlexContainer)`
  @media ${respondBelow('xxxs')} {
    gap: 0px;
    padding: 16px 0;
    flex-grow: 0;
  }
`

const CustomTitle = styled(Title)`
  @media ${respondBelow('xxxs')} {
    padding: 0 24px;
  }
`

const Container = styled(FlexContainer)`
  @media ${respondBelow('xxxs')} {
    gap: 6px;
    padding: 0;
  }
`

const GemIconCustom = styled(GemIcon)`
  @media ${respondBelow('xxxs')} {
    width: 40px;
    height: 40px;
  }
`
