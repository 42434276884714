import { NETWORKS } from '../../../../constants/types'
import type { MulticallBalances } from '../../../ethereum/multicall'
import { executeViewTronCall } from '../starknetService/executeViewTronCall'
import { parseTronResult } from './parseTronResult'
import { SafeDecimal } from '../../../../utils/SafeDecimal'
import type { BridgeTokenEntryArray } from '../../../helperService/tokensToArray'

export const getTronBalances = async (secondaryWalletAddress: string, tokens: BridgeTokenEntryArray) => {
  if (!secondaryWalletAddress) {
    return {}
  }
  try {
    const balances = await Promise.all(
      tokens.map(async ({ address }) => {
        const result = await executeViewTronCall(address, 'balanceOf(address)', [
          { type: 'address', value: secondaryWalletAddress },
        ])
        if (result && result.length > 0) {
          return parseTronResult(result)
        } else {
          return '0'
        }
      }),
    )
    return {
      [NETWORKS.TRON]: tokens.reduce<MulticallBalances[string]>(
        (accumulator, { token, decimals }: { token: string; decimals: number }, index: number) => {
          const balance = balances[index]
          accumulator[token] = {
            balance: SafeDecimal(balance)
              .div(10 ** decimals)
              .toString(),
            token,
          }
          return accumulator
        },
        {},
      ),
    }
  } catch (error) {
    console.error(error)
    return {}
  }
}
