import Decimal from 'decimal.js'
import type { MixedSchema } from 'yup'
import { mixed } from 'yup'
import { translate } from '../../../../intl/i18n'
import { hasPrimitiveOriginalValue } from '../../../../services/validation/hasOriginalValue'

export const makeBaseAmountValidator = <AdditionalContext>({
  notValidNumber = translate('errors.number_not_valid'),
  amountAboveZero = translate('errors.amount_greater_than_zero'),
  insufficientBalance = translate('errors.insufficient_balance'),
  fieldRequired = translate('errors.field_is_required', { '%field': translate('helpers.amount') }),
}: {
  notValidNumber?: string
  amountAboveZero?: string
  insufficientBalance?: string
  fieldRequired?: string
}) => {
  return (
    // prettier-ignore
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Legacy
    (mixed() as MixedSchema<
        number | string | undefined,
        { usdPrice?: number | string; balance: number | string } & AdditionalContext,
        number | string | undefined
      >)
      .typeError(notValidNumber)
      .test('decimal-library-cheeck', notValidNumber, (value, testContext) => {
        if (!value) {
          return true
        }

        try {
          if (hasPrimitiveOriginalValue(testContext)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- we only want to test whether Decimal throws, so no assignment
            new Decimal(testContext.originalValue)
          }
        } catch {
          return false
        }
        return true
      })
      .test('positive-amount', amountAboveZero, (value) => new Decimal(value?.toString() ?? 0).gt(0))
      .test('insufficient-balance', insufficientBalance, (value, { options }) => {
        if (!value) {
          return true
        }

        const { balance } = options.context ?? { balance: 0 }

        return !new Decimal(value.toString()).gt(balance)
      })
      .required(fieldRequired)
  )
}
