import { Title, Label, Text, Spacing, Icon, Button } from '@rhinofi/dvf-shared-ui'
import { TitleSize, TypographyAlign, LabelSize } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import { type ReactNode } from 'react'
import styled from 'styled-components'
import { useAppDispatch } from '../../../hooks'
import { clearModal } from '../../../actions/modalActions/clearModal'
import { PendingCard, PendingCardItem } from '../../Bridge/Bridge.styled'
import { translate } from '../../../intl/i18n'
import { PerpetualLoader } from '../../../components/common/Helpers/PerpetualLoader'
import { SmallSpinner } from '../../../components/common/Helpers/SmallSpinner'
import { FlexContainer } from '../../../components/common/Wrappers/Containers'
import { TxStatus } from '../../../services/txService/txService.types'
import { getNFTImage } from '../helpers/getNFTImage'
import { formatNFTMintModalSubtitle } from '../helpers/formatNFTMintModalSubtitle'
import { NFTMintProgressSuccessFooter } from './NFTMintProgressSuccessFooter'
import { DynamicLottieConfetti } from '../../../components/common/Lottie/DynamicLottieConfetti'
import { ModalOverlay } from '../../../components/common/Modal/Modal.styled'
import { Modal } from '../../../components/common/Modal/Modal'
import { useExplorerLink } from '../../../hooks/util/useExplorerLink'
import { useChainKeyToName } from '../../../hooks/useChainKeyToName'
import type { NFTType } from '../types/mintNFT.types'

type MintProgressProps = {
  title: string
  subtitle: string | ReactNode
  image?: string | ReactNode
  label: ReactNode
  showLoader?: boolean
  footer?: ReactNode
  confetti?: boolean
}

export const MintProgress = ({
  title,
  subtitle,
  image,
  label,
  showLoader = false,
  footer,
  confetti = false,
}: MintProgressProps) => {
  const dispatch = useAppDispatch()

  return (
    <Modal
      isVisible
      width="360px"
      onClose={() => clearModal(dispatch)()}
      renderCustomOverlay={
        confetti
          ? ({ onClick }) => (
              <ModalOverlay onClick={onClick}>
                <DynamicLottieConfetti loop />
              </ModalOverlay>
            )
          : undefined
      }
    >
      <Title size={TitleSize.XS}>{title}</Title>
      <PendingCard>
        <PendingCardItem>
          {typeof subtitle === 'string' ? (
            <Title size={TitleSize.XXS} align={TypographyAlign.Center}>
              {subtitle}
            </Title>
          ) : (
            subtitle
          )}
          {image &&
            (typeof image === 'string' ? (
              <NFTWrapper>
                <img src={image} id="nft-image-modal" alt="nft-image" />
              </NFTWrapper>
            ) : (
              image
            ))}
          {label}
        </PendingCardItem>
      </PendingCard>
      {showLoader && <PerpetualLoader />}
      {footer && (
        <>
          <Spacing size="16" />
          {footer}
        </>
      )}
    </Modal>
  )
}

type Props = {
  chain: string
  type: NFTType
  status: TxStatus
  txHash?: string
}

export const NFTMintProgressModal = ({ chain, type, status, txHash = '' }: Props) => {
  const dispatch = useAppDispatch()
  const chainName = useChainKeyToName(chain)
  const subtitle = formatNFTMintModalSubtitle(type, chainName)
  const nftImage = getNFTImage(type, chain)
  const { link: explorerLink } = useExplorerLink(txHash, chain, true)

  const closeModal = () => clearModal(dispatch)()

  if (status === TxStatus.WAITING_WALLET) {
    return (
      <MintProgress
        title={translate('tracker.initiating_nft_minting')}
        subtitle={subtitle}
        image={nftImage}
        label={<Label size={LabelSize.Label}>{translate('helpers.pending')}...</Label>}
        showLoader
        footer={<Text align={TypographyAlign.Center}>{translate('global.please_approve_in_wallet')}</Text>}
      />
    )
  }

  if (status === TxStatus.ERROR) {
    return (
      <Modal isVisible width="360px" onClose={closeModal}>
        <FlexContainer $direction="column" $fullWidth $gap="16px">
          <Title size={TitleSize.XS}>{translate('tracker.cancelled')}</Title>
          <Text>{translate('tracker.cancelled_desc')}</Text>
          <Button id="close-nft-mint-modal" fullWidth onClick={closeModal}>
            {translate('helpers.close')}
          </Button>
        </FlexContainer>
      </Modal>
    )
  }

  if (status === TxStatus.PENDING) {
    return (
      <MintProgress
        title={translate('tracker.minting_nft')}
        subtitle={subtitle}
        image={nftImage}
        label={
          <FlexContainer $gap="6px">
            <SmallSpinner />
            <ExplorerLink href={explorerLink} target="_blank" rel="noopener noreferrer">
              {translate('global.view_on_explorer')}
            </ExplorerLink>
          </FlexContainer>
        }
        showLoader
      />
    )
  }

  if (status === TxStatus.SUCCESS) {
    return (
      <MintProgress
        title={translate('global.success')}
        subtitle={subtitle}
        image={nftImage}
        label={
          <FlexContainer $gap="6px">
            <Icon id="check-circle-fill" className="success-bridge" />
            <ExplorerLink href={explorerLink} target="_blank" rel="noopener noreferrer">
              {`tx:${txHash.substr(0, 9)}...`}
            </ExplorerLink>
          </FlexContainer>
        }
        footer={<NFTMintProgressSuccessFooter chain={chain} type={type} />}
      />
    )
  }

  return null
}

const NFTWrapper = styled.div`
  > img {
    max-width: 128px;
    max-height: 128px;
  }
`

const ExplorerLink = styled.a`
  color: ${({ theme }) => theme.secondary};
`
