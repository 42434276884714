import { ERC20__factory } from '../../../contracts/ERC20'
import { LogFeature, makeLog } from '../../../utils/makeLog'
import type { ApprovalContractCallProps } from './callApprovalContract'

const log = makeLog(LogFeature.BRIDGE_APPROVALS)

export const approveEVMTokenContract = async ({
  bridgeContractAddress,
  tokenAddress,
  ethersSigner,
  amountWithDecimals,
}: ApprovalContractCallProps) => {
  const erc20Contract = ERC20__factory.connect(tokenAddress, ethersSigner)
  const tx = await erc20Contract.approve(bridgeContractAddress, amountWithDecimals)
  log('Approve transaction sent:', tx)
  const receipt = await tx.wait()
  log('Approve transaction confirmed:', receipt)
  return receipt
}
