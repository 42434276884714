import type { HighlightItem } from '@rhinofi/campaigns-api-spec'
import earnGemsAsset from '../assets/highlights/nation/earn-gems.png'
import joinNationAsset from '../assets/highlights/nation/join-nation.png'
import { publicRoutes } from '../../../router'
import { NationUserStatus } from '../../Nation/types/nation.types'

export const getRhinoNationBridgeBanner = (status: NationUserStatus): HighlightItem => {
  // User is a part of rhino nation
  if (status === NationUserStatus.Joined) {
    return {
      alt: 'Bridge and earn Gems. Spend your Gems on unique real-world perks.',
      href: publicRoutes.nation,
      id: 'earn-gems',
      imageUrl: earnGemsAsset,
      isActive: true,
      isInternalLink: true,
    }
  }

  // User hasn't joined rhino nation
  return {
    alt: 'Join Rhino Nation. Earn gems, unlock great perks.',
    href: publicRoutes.nation,
    id: 'join-nation',
    imageUrl: joinNationAsset,
    isActive: true,
    isInternalLink: true,
  }
}
