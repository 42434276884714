import type { TonConnectUI } from '@tonconnect/ui'
import type { TonClient } from '@ton/ton'

// eslint-disable-next-line functional/no-let -- singleton
let tonWalletProvider: TonConnectUI | undefined
// eslint-disable-next-line functional/no-let -- singleton
let tonPublicProvider: TonClient | undefined

export const setupTonProvider = async (walletProvider: TonConnectUI, rpcUrl: string) => {
  const { TonClient } = await import('@ton/ton')
  tonWalletProvider = walletProvider
  tonPublicProvider = new TonClient({
    endpoint: rpcUrl,
  })
}

export const getTonProvider = (dontThrow?: boolean) => {
  if (!tonWalletProvider && !dontThrow) {
    throw new Error('Please connect your TON wallet first.')
  }
  return {
    walletProvider: tonWalletProvider,
    publicProvider: tonPublicProvider,
  }
}
