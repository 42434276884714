import userflow from 'userflow.js'
import type { UserflowEvent } from '../../constants/userflowEvents'
import { LogFeature, makeLog } from '../../utils/makeLog'

const log = makeLog(LogFeature.HEAP_TRACKING)
export const trackUserflowEvent = (event: UserflowEvent, data: Record<string, string | number | boolean> = {}) => {
  try {
    if (!userflow.isIdentified()) {
      return
    }
    log('Userflow Event:', event, data)

    void userflow.track(event, prepareUserflowData(data))
  } catch (error) {
    console.error("Couldn't send tracked event to Userflow.", error)
  }
}

const prepareUserflowData = (data: Record<string, string | number | boolean> = {}) => {
  // go through data values, and truncate any numbers to 4 decimal poitns
  const truncatedData: Record<string, string | number | boolean | undefined> = {}

  // eslint-disable-next-line functional/no-loop-statements -- Legacy
  for (const key in data) {
    if (typeof data[key] === 'number') {
      truncatedData[key] = parseFloat(Number(data[key]).toFixed(4))
    } else {
      truncatedData[key] = data[key]
    }
  }

  return truncatedData
}
