import { Button, ButtonSizes, ButtonVariant, Spacing, Text, TextSize } from '@rhinofi/dvf-shared-ui'
import { Modal } from '../../../../components/common/Modal/Modal'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'
import { translate } from '../../../../intl/i18n'
import type { BridgeConfirmationModalProps } from './ConfirmationModals'

export const BridgeToAnyConfirmation = ({
  confirmationPromise,
  setConfirmationPromise,
}: BridgeConfirmationModalProps) => {
  const close = () => {
    confirmationPromise.resolve(false)
    setConfirmationPromise(null)
  }
  const confirm = () => {
    confirmationPromise.resolve(true)
    setConfirmationPromise(null)
  }
  return (
    <Modal isVisible onClose={() => {}} width="360px" noClose variant="info" title={translate('helpers.information')}>
      <Text size={TextSize.S} bold>
        {translate('bridge.sending_to_other')}
      </Text>
      <Spacing size="16" />

      <FlexContainer $fullWidth $gap="16px" $direction="column">
        <Button id="confirm-other-address" onClick={confirm} fullWidth size={ButtonSizes.Large}>
          {translate('helpers.confirm')}
        </Button>
        <Button id="cancel-other-address" onClick={close} fullWidth variant={ButtonVariant.secondary}>
          {translate('helpers.cancel')}
        </Button>
      </FlexContainer>
    </Modal>
  )
}
