import { translate } from '../../../intl/i18n'
import type { NFTType } from '../types//mintNFT.types'

export const getNFTMintModalSuccessText = (type: NFTType, chainName: string) => {
  if (type === 'wrapped2023') {
    return translate('tracker.nft_minted_desc_wrapped')
  }

  if (type === 'BETAKEY') {
    return translate('gated.success_mint_desc')
  }

  // TODO DOPE-422 - Integrate trackers
  // const activityTrackerExistsForChain = getEnabledActivityTrackers().includes(chain)
  const activityTrackerExistsForChain = false

  return translate(activityTrackerExistsForChain ? 'tracker.nft_minted_desc' : 'tracker.nft_minted_desc_no_trackers', {
    '%chain': chainName,
  })
}
