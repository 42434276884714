import styled, { css } from 'styled-components'
import { Icon, IconSizes } from '@rhinofi/dvf-shared-ui'
import { StepLoader } from '../../../common/Helpers/StepLoader'

// eslint-disable-next-line react-refresh/only-export-components -- irrelevant
export enum StepNumberSize {
  Small = 'small',
  Normal = 'normal',
}

type StepNumberProps = {
  iconId: string
  isDone?: boolean
  isLoading?: boolean
  isActive?: boolean
  size?: StepNumberSize
  isError?: boolean
}

export const StepIcon = ({ isActive, isDone, isLoading, size, iconId, isError = false }: StepNumberProps) => {
  if (isLoading) {
    return (
      <IconContainer>
        <StepLoader />
      </IconContainer>
    )
  }

  if (isError) {
    return (
      <IconContainer>
        <ErrorIcon id={'x-circle'} />
      </IconContainer>
    )
  }

  return (
    <Step $isActive={isActive} $isDone={isDone} $size={size}>
      {isDone ? <SuccessIcon id={'check-lg'} size={IconSizes.Big} /> : <Icon id={iconId} size={IconSizes.Big} />}
    </Step>
  )
}

const SuccessIcon = styled(Icon)`
  color: #fff;
`

const ErrorIcon = styled(Icon)`
  color: ${({ theme }) => theme.errorMain};
  font-size: 48px !important;
`

const handleSize = ($size: StepNumberSize) => {
  switch ($size) {
    case StepNumberSize.Small:
      return css`
        height: 41px;
        width: 41px;
        min-width: 41px;
        font-size: 21px;
        line-height: 26px;
      `
    default:
      return css`
        height: 54px;
        width: 54px;
        min-width: 54px;
        font-size: 28px;
        line-height: 34px;
      `
  }
}

type StepProps = {
  $isActive?: boolean | undefined
  $size?: StepNumberSize | undefined
  $isDone?: boolean | undefined
}

const IconContainer = styled.div`
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: 28px;
  line-height: 34px;
  font-weight: bold;
  transition: all 0.15s ease-in-out;
  height: 54px;
  width: 54px;
  min-width: 54px;
  font-size: 28px;
  line-height: 34px;
`

const Step = styled.div<StepProps>`
  color: ${({ theme }) => theme.offWhite};
  ${({ $size = StepNumberSize.Normal }) => handleSize($size)};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: 28px;
  line-height: 34px;
  font-weight: bold;
  transition: all 0.15s ease-in-out;

  ${({ $isDone }) =>
    $isDone &&
    css`
      background-color: ${({ theme }) => theme.successMain};
      opacity: 0.4;
    `}

  ${({ $isActive }) =>
    !$isActive &&
    css`
      opacity: 0.4;
    `}

  .bi {
    font-size: 42px !important;
    margin-left: -2px;
  }
`
