import type { Signer } from 'ethers'
import { NETWORKS } from '../../../constants/types'
import { setParadexProvider } from '../providers/paradexProvider'
import { envConfig } from '../../../env/envConfig'
import type { SecondaryConnectionPayload } from './connectors.types'

const { configEnv } = envConfig

export const ParadexWalletOptions = {
  EVM: 'EVM',
  STARKNET: 'STARKNET',
}

export const connectToParadex = async (
  walletOption: string,
  ethersSigner: Signer,
  starknetRpcUrl: string,
): Promise<SecondaryConnectionPayload> => {
  const Paradex = await import('@paradex/sdk')
  const { InjectedConnector } = await import('starknetkit/injected')
  const { connect } = await import('starknetkit')
  const { RpcProvider } = await import('starknet')
  const configType = configEnv === 'production' ? 'prod' : 'testnet'
  const config = await Paradex.Config.fetchConfig(configType)
  const paraclearProvider = new Paradex.ParaclearProvider.DefaultProvider(config)

  if (walletOption === ParadexWalletOptions.STARKNET) {
    const { wallet } = await connect({
      modalMode: 'alwaysAsk',
      dappName: 'rhino.fi',
      connectors: [
        new InjectedConnector({
          options: {
            id: 'argentX',
            provider: new RpcProvider({
              nodeUrl: starknetRpcUrl,
            }),
          },
        }),
        new InjectedConnector({
          options: {
            id: 'braavos',
            provider: new RpcProvider({
              nodeUrl: starknetRpcUrl,
            }),
          },
        }),
      ],
    })
    if (!wallet?.selectedAddress || !wallet.account) {
      throw new Error('Something went wrong when connecting your Starknet wallet.')
    }
    const account = await Paradex.Account.fromStarknetAccount({
      provider: paraclearProvider,
      config,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- starknet lib issue
      account: wallet.account,
    })
    setParadexProvider(paraclearProvider, account, config)

    return {
      address: account.address,
      networkId: '0',
      domain: '',
      connectedChain: NETWORKS.PARADEX,
    }
  } else if (walletOption === ParadexWalletOptions.EVM) {
    const signer = Paradex.Signer.ethersSignerAdapter(ethersSigner)
    const account = await Paradex.Account.fromEthSigner({ provider: paraclearProvider, config, signer })

    setParadexProvider(paraclearProvider, account, config)

    return {
      address: account.address,
      networkId: '0',
      domain: '',
      connectedChain: NETWORKS.PARADEX,
    }
  } else {
    throw new Error('Invalid wallet option')
  }
}
