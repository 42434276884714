import { validateEthereumAddress } from './validateEthereumAddress'

export const validateStarknetAddress = async (address?: string) => {
  if (!address) {
    return false
  }

  const { validateAndParseAddress, number } = await import('starknet')

  try {
    const validatedAddress = number.cleanHex(validateAndParseAddress(address))
    const userAddress = number.cleanHex(address)

    return validatedAddress.toLowerCase() === userAddress.toLowerCase() && !validateEthereumAddress(address)
  } catch {
    return false
  }
}
