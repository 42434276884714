import { F_FLAGS_TYPES } from '../../constants/types'
import { envConfig } from '../../env/envConfig'

const { FEATURE_FLAGS } = envConfig

export const isFeatureEnabled = (key: keyof typeof F_FLAGS_TYPES) => {
  const value = localStorage.getItem(key)

  if (value === 'force-off') {
    return false
  }
  if (value === 'force-on') {
    return true
  }
  if (value === 'true') {
    return true
  }
  if (value === 'false') {
    return false
  }
  return FEATURE_FLAGS[key]
}

export const isFeatureForcedOff = (key: keyof typeof F_FLAGS_TYPES) => {
  const value = localStorage.getItem(key)
  return value === 'force-off'
}

export const isFeatureForcedOn = (key: keyof typeof F_FLAGS_TYPES) => {
  const value = localStorage.getItem(key)
  return value === 'force-on'
}

export const isFeatureFlag = (key: string): key is keyof typeof F_FLAGS_TYPES => {
  return key in F_FLAGS_TYPES
}
