import { showModal } from '../../actions/modalActions/showModal'
import { modalKeys } from '../../constants/modalKeys'
import { disconnectSecondaryWallet } from '../../reducers/secondaryWalletSlice'
import type { AppDispatch } from '../../store/configureStore'

export const reconnectLocalStorageKey = 'reconnect-hidden'

export const triggerReconnectModal = async (dispatch: AppDispatch, connectedChain: string, chainToConnect: string) => {
  if (localStorage.getItem(reconnectLocalStorageKey)) {
    return true
  }

  // eslint-disable-next-line functional/no-let -- promise pattern
  let resolvePromise
  const promise = new Promise((resolve) => {
    resolvePromise = resolve
  })
  if (!resolvePromise) {
    return false
  }
  showModal(dispatch)(modalKeys.reconnectModal, { connectedChain, chainToConnect, resolve: resolvePromise })
  const result = await promise
  if (result) {
    dispatch(disconnectSecondaryWallet())
  }
  return result
}
