import { clearModal } from '../../../actions/modalActions/clearModal'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { Modal } from '../../common/Modal/Modal'
import { ConnectWallet } from '../../ConnectWallet/ConnectWallet'
import { useAppSelector } from '../../../hooks'
import { selectUserAddress } from '../../../reducers/userSelector'
import { useEffect } from 'react'

export const ConnectWalletModal = () => {
  const dispatch = useAppDispatch()

  const userAddress = useAppSelector(selectUserAddress)

  useEffect(() => {
    if (userAddress) {
      clearModal(dispatch)()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on address change
  }, [userAddress])

  return (
    <Modal isVisible onClose={() => clearModal(dispatch)()} width="400px" title="Connect your wallet" maxHeight={600}>
      <ConnectWallet />
    </Modal>
  )
}
