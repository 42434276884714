import { Button, ButtonSizes, CheckBox, Spacing, Text, TextSize } from '@rhinofi/dvf-shared-ui'
import styled from 'styled-components'
import { Modal } from '../../../../components/common/Modal/Modal'
import { translate } from '../../../../intl/i18n'
import { useState } from 'react'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'

type Props = {
  handleClose: (doNotShowAgain: boolean) => void
}

export const OwnershipAndComplianceModal = ({ handleClose }: Props) => {
  const [isChecked, setIsChecked] = useState(false)

  return (
    <Modal
      isVisible
      onClose={() => handleClose(false)}
      width="360px"
      variant="core"
      title={translate('bridge.ownership_compliance')}
    >
      <TextWrapper>
        <Text size={TextSize.XS} bold>
          {translate('bridge.responsibility')}
        </Text>
        <Text size={TextSize.XS} color="textSecondary">
          {translate('global.sending_to_other_warning')}
        </Text>
        <Text size={TextSize.XS} bold>
          {translate('bridge.our_obligation')}
        </Text>
        <Text size={TextSize.XS} color="textSecondary">
          {translate('bridge.compliance_obligation')}
        </Text>
        <FlexContainer $alignItems="center">
          <CheckBox
            input={{ value: isChecked ? 'true' : '' }}
            handleChange={(event) => setIsChecked(event.target.checked)}
          />
          <Text size={TextSize.XS} bold color="textSecondary" noLineHeight>
            {translate('bridge.dont_show_again')}
          </Text>
        </FlexContainer>
      </TextWrapper>
      <Spacing size="16" />
      <Button
        id="confirm-ownership-compliance"
        onClick={() => handleClose(isChecked)}
        fullWidth
        size={ButtonSizes.Medium}
      >
        {translate('bridge.ok_i_understand')}
      </Button>
    </Modal>
  )
}

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
