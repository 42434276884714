import type { GetAllGemsTransactionsInputQuery } from '@rhinofi/rhino-nation-api-spec'
import { useGetAllGemsTransactionsQuery } from '../services/nation.api'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { invalidateNationTagsOnPotentialGemsChangeAction } from '../actions/nation.actions'
import { useHasUserFullNationAccess } from './useHasUserFullNationAccess'

type Props = {
  input: GetAllGemsTransactionsInputQuery
  skip?: boolean
  pollingInterval?: number
}

export const useGemsTransactions = ({ input, pollingInterval, skip = false }: Props) => {
  const dispatch = useAppDispatch()
  const hasFullAccess = useHasUserFullNationAccess()

  const { data, isLoading, isFetching, error, currentData } = useGetAllGemsTransactionsQuery(
    {
      input,
      onSuccess: invalidateNationTagsOnPotentialGemsChangeAction(dispatch),
    },
    {
      skip: !hasFullAccess || skip,
      ...(pollingInterval && { pollingInterval }),
    },
  )

  return {
    data,
    error,
    currentData,
    isLoading,
    isFetching,
  }
}
