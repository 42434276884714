import type { Direction } from './SlideInTooltip.types'

export const getInitialStyle = (direction: Direction) => {
  switch (direction) {
    case 'left':
      return {
        x: '8px',
        opacity: 0,
      }
    case 'right':
      return {
        x: '-8px',
        opacity: 0,
      }
    case 'top':
      return {
        y: '8px',
        opacity: 0,
      }
    case 'bottom':
      return {
        y: '-8px',
        opacity: 0,
      }
    default:
      return {}
  }
}
