import { Button, ButtonVariant, Text, TextSize, Title, TitleSize } from '@rhinofi/dvf-shared-ui'
import { useCallback, useMemo } from 'react'
import { FlexContainer } from '../../../../../../components/common/Wrappers/Containers'
import { translate } from '../../../../../../intl/i18n'
import { getRTKErrMessage } from '../../../../../../services/helperService'
import { usePostPurchasePerkMutation } from '../../../../services/nation.api'
import type { PerkWithUserData } from '../../../../types/nation.types'
import type {
  ActivePerkModalSetRewardFunc,
  ActivePerkModalSetViewFunc,
} from '../../../../types/nationActivePerkModal.types'
import { canUserClaimPerk } from '../../../../utils/canUserClaimPerk'
import { NationPerkModalCardGemPrice } from '../NationPerkModalsSections/NationPerkModalCardGemPrice/NationPerkModalCardGemPrice'
import { createPerkHeapId } from '../../../../utils/createPerkHeapId'
import { useGemsBalance } from '../../../../hooks/useGemsBalance'

type Props = {
  perkWithUserData: PerkWithUserData
  setView: ActivePerkModalSetViewFunc
  setReward: ActivePerkModalSetRewardFunc
}

export const NationActivePerkModalClaim = ({ perkWithUserData, setView, setReward }: Props) => {
  const [postPurchasePerk, { isLoading: claiming, error: claimError }] = usePostPurchasePerkMutation()

  const { currentData } = useGemsBalance()
  const canClaimRes = canUserClaimPerk({ perkWithUserData, userGems: currentData })

  const canClaim = canClaimRes.type === 'allowed'
  const disableClaim = !canClaim || claiming

  const handleClaimCallback = useCallback(async () => {
    const res = await postPurchasePerk({ perkId: perkWithUserData.perk._id })

    if (res?.data) {
      setReward(res.data)
      setView('OVERVIEW')
    }
  }, [setView, postPurchasePerk, setReward, perkWithUserData.perk._id])

  const claimButtonText = useMemo(() => {
    if (!canClaim) {
      return canClaimRes.message
    }

    if (claiming) {
      return translate('helpers.claiming')
    }

    return translate('helpers.confirm')
  }, [canClaim, canClaimRes, claiming])

  const perkId = createPerkHeapId(perkWithUserData.perk)

  return (
    <FlexContainer $gap="16px" $direction="column" $alignItems="stretch">
      <Title size={TitleSize.XS} color="secondary80">
        {translate('helpers.claiming')}
      </Title>

      <Title size={TitleSize.XXS} color="secondary80">
        {perkWithUserData.perk.title}
      </Title>

      <NationPerkModalCardGemPrice perk={perkWithUserData.perk} />

      <FlexContainer $gap="16px">
        <Button
          variant={ButtonVariant.secondary}
          fullWidth
          id={`perk-claim-cancel-${perkId}`}
          onClick={() => setView('OVERVIEW')}
        >
          {translate('helpers.cancel')}
        </Button>

        <Button fullWidth id={`perk-claim-execute-${perkId}`} onClick={handleClaimCallback} disabled={disableClaim}>
          {claimButtonText}
        </Button>
      </FlexContainer>

      {claimError && (
        <Text color="accentRed" size={TextSize.XS}>
          {getRTKErrMessage(claimError)}
        </Text>
      )}
    </FlexContainer>
  )
}
