import { Text, TextSize } from '@rhinofi/dvf-shared-ui'
import { translate } from '../../../../../intl/i18n'
import { FlexContainer } from '../../../../../components/common/Wrappers/Containers'
import { useNationWidgetFiltersPageTracking } from '../../../hooks/useNationWidgetFiltersPageTracking'
import { NationWidgetMissionsChainFilter } from './NationWidgetMissionsChainFilter'
import { NationWidgetMissionsFeatureFilter } from './NationWidgetMissionsFeatureFilter'

export const NationWidgetMissionsFilters = () => {
  useNationWidgetFiltersPageTracking()

  return (
    <FlexContainer $gap="4px" $direction="column" $alignItems="stretch">
      <Text size={TextSize.XS} color="secondary80">
        {translate('nation.complete_missions_to_earn_more_gems')}
      </Text>

      <FlexContainer $gap="4px" $flexGrow="1">
        <NationWidgetMissionsChainFilter />
        <NationWidgetMissionsFeatureFilter />
      </FlexContainer>
    </FlexContainer>
  )
}
