import type { ReactNode } from 'react'

export type Trays = 'more' | 'exchange'

type Link = {
  id: string
  title: string
}

type DirectLink = Link & {
  icon: ReactNode
  path: string
}

type TrayLink = Link & {
  icon: ReactNode
  tray: Trays
  showAlert?: boolean
}

export type MobileLink = DirectLink | TrayLink

export const isDirectLink = (link: MobileLink): link is DirectLink => {
  return 'path' in link
}

export type TraySubLink = Link & {
  icon: string
  path?: string
  href?: string
  isExternal?: boolean
  action?: 'support' | 'toggleDVFClaim' | 'theme' | 'disconnect'
}
