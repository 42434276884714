import type { TronLink } from '@agrozyme/types-tronweb'

// eslint-disable-next-line functional/no-let -- singleton
let tronProvider: TronLink | undefined

export const setupTronProvider = async () => {
  if (window.tronLink?.ready) {
    tronProvider = window.tronLink
  } else {
    const res = await window.tronLink?.request<{ code: number }>({ method: 'tron_requestAccounts' })
    if (res?.code === 200) {
      tronProvider = window.tronLink
    }
  }
  return tronProvider
}

export const getTronProvider = () => {
  if (!tronProvider?.tronWeb) {
    throw new Error('Please connect your Tron wallet first.')
  }
  return tronProvider
}
