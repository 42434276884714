import { Icon, Text, TextSize } from '@rhinofi/dvf-shared-ui'
import type React from 'react'
import styled from 'styled-components'

type Props = {
  title: string
  rightIcon: string
  handleClick: () => void
}

export const Alert = ({ title, rightIcon, handleClick }: Props) => {
  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    event.preventDefault()
    handleClick()
  }

  return (
    <Wrapper onClick={onClick}>
      <Content>
        <Icon id="info-circle" color="warningDark" isOnButton />
        <Text color="warningDark" bold size={TextSize.XS}>
          {title}
        </Text>
      </Content>
      {rightIcon && <Icon id={rightIcon} color="warningDark" isOnButton />}
    </Wrapper>
  )
}

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.warningLight};
  border: 1px solid ${({ theme }) => theme.warningMain};
  border-radius: 8px;
  padding: 0px 16px;
  min-height: 48px;
  cursor: pointer;
  width: 100%;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid ${({ theme }) => theme.warningDark};
  }
`

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`
