import { getStarknetProvider } from '../../providers/starknetProvider'

export const switchStarknetWalletToNetworkId = async (requiredNetworkId: string) => {
  const starknetProvider = getStarknetProvider()
  if (!starknetProvider || typeof starknetProvider.request !== 'function') {
    return
  }
  try {
    await starknetProvider.request({
      type: 'wallet_switchStarknetChain',
      params: {
        chainId: requiredNetworkId,
      },
    })
    // eslint-disable-next-line no-unused-vars -- fail silently
  } catch (error) {
    /* empty */
  }
}
