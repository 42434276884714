import { Title, Icon, Spacing, Text, Button, ButtonVariant } from '@rhinofi/dvf-shared-ui'
import { TitleSize } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import { Modal } from '../../../../components/common/Modal/Modal'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'
import { translate } from '../../../../intl/i18n'
import type { BridgeConfirmationModalProps } from './ConfirmationModals'

export const LowAmountConfirmation = ({
  confirmationPromise,
  setConfirmationPromise,
}: BridgeConfirmationModalProps) => {
  const handleConfirm = (didConfirm: boolean) => {
    confirmationPromise.resolve(didConfirm)
    setConfirmationPromise(null)
  }
  return (
    <Modal isVisible onClose={() => {}} width="360px" noClose variant="info">
      <Title size={TitleSize.XXS}>
        <Icon id="info-square" /> {translate('helpers.information')}
      </Title>
      <Spacing size="16" />
      <Text bold>{translate('bridge.less_than_dollar')}</Text>
      <Spacing size="16" />

      <FlexContainer $fullWidth $gap="16px">
        <Button
          id="cancel-other-address"
          onClick={() => handleConfirm(false)}
          fullWidth
          variant={ButtonVariant.secondary}
        >
          {translate('helpers.cancel')}
        </Button>
        <Button id="confirm-other-address" onClick={() => handleConfirm(true)} fullWidth>
          {translate('helpers.confirm')}
        </Button>
      </FlexContainer>
    </Modal>
  )
}
