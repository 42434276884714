import { WalletType } from './wallet.types'
import * as Schema from 'effect/Schema'

export const LastWalletSchema = Schema.Union(
  Schema.Struct({
    type: Schema.Literal(WalletType.walletConnect),
    address: Schema.String,
  }),
  Schema.Struct({
    type: Schema.Literal(WalletType.eip6963),
    address: Schema.String,
    name: Schema.String,
  }),
  Schema.Struct({
    type: Schema.Literal(WalletType.injected),
    address: Schema.String,
  }),
)
