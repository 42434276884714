import { Schema, Effect } from 'effect'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { GetAccessInput, GetAccessResponse } from '@rhinofi/gated-features-api-spec/dist/apiSpec/access/endpoints'
import { apiSpec } from '@rhinofi/gated-features-api-spec'
import { Client } from 'effect-http'

import { NETWORKS } from '../../../constants/types'
import { envConfig } from '../../../env/envConfig'

import { getERC1155ContractConfig, getNFTAbi } from '../../Chain/helpers/nftContractHelpers'
import { runEffectHttpCallback } from '../../../utils/effect'
import { parseTokenInfo } from '../../../schema/TokenInfoSchema'
import { Contract, ethers } from 'ethers'
import { getChainConfig } from '../../../utils/getChainConfig'
import type { BridgeConfig } from '../../../context'

const client = Client.make(apiSpec, { baseUrl: `${envConfig.tradingApi}/gated-features` })

export const gatedFeaturesApi = createApi({
  reducerPath: 'gatedFeatures',
  baseQuery: fetchBaseQuery({}),
  tagTypes: ['Access'],
  endpoints: (builder) => ({
    getBetaKeyCounter: builder.query<{ maxCap: string; minted: string }, { config: BridgeConfig }>({
      queryFn: async ({ config }) => {
        try {
          const chain = NETWORKS.SCROLL
          const contractConfig = getERC1155ContractConfig(chain, 'BETAKEY')
          const { address: contractAddress, tokenId: contractTokenId } = contractConfig
          const contractAbi = getNFTAbi(contractConfig.type)

          const { rpc, networkId, name } = getChainConfig(config)({ chain })
          const network = new ethers.Network(name, networkId)
          const provider = new ethers.JsonRpcProvider(rpc, network, { staticNetwork: true })
          const contract = new Contract(contractAddress, contractAbi, provider)
          const tokenInfoRaw: unknown = await contract['tokens']?.(contractTokenId)
          const { maxCap } = parseTokenInfo(tokenInfoRaw)
          const totalSupplyRaw: unknown = await contract['totalSupply']?.(contractTokenId, { chain })
          const totalSupply = Schema.decodeUnknownSync(Schema.BigIntFromSelf)(totalSupplyRaw)

          return {
            data: {
              maxCap: maxCap.toString(),
              minted: totalSupply.toString(),
            },
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch' } }
        }
      },
    }),
    hasAccessToFeature: builder.query<GetAccessResponse, GetAccessInput>({
      queryFn: async (path) => runEffectHttpCallback(async () => Effect.runPromise(client.access({ path }))),
      providesTags: ['Access'],
    }),
  }),
})

export const { useGetBetaKeyCounterQuery, useHasAccessToFeatureQuery } = gatedFeaturesApi
