import { Multicall } from 'ethereum-multicall'
import { envConfig } from '../../env/envConfig'
import { erc20Abi } from '../abis/erc20Abi'
import { LogFeature, makeLog } from '../../utils/makeLog'
import { SafeDecimal } from '../../utils/SafeDecimal'
import type { BridgeTokenEntryArray } from '../helperService/tokensToArray'

const { multiCallDoNotAggregate } = envConfig
const log = makeLog(LogFeature.L1_BALANCES_FETCH)

export type MulticallBalance = {
  balance: string
  token: string
}

export const getMulticallBalances = async ({
  walletAddress,
  tokens,
  rpcUrl,
  chain,
  multicallContractAddress,
}: {
  walletAddress: string
  tokens: BridgeTokenEntryArray
  rpcUrl: string
  chain: string
  multicallContractAddress: string | undefined
}) =>
  new Promise<Record<string, MulticallBalance>>((resolve) => {
    // Init multicall
    const timeoutId = setTimeout(() => {
      resolve({})
    }, 6000)

    const multicall = new Multicall({
      // ethers v6 support is in progress so use custom json rpc provider
      // https://github.com/joshstevens19/ethereum-multicall/pull/72
      nodeUrl: rpcUrl,
      tryAggregate: !multiCallDoNotAggregate.includes(chain),
      ...(multicallContractAddress && { multicallCustomContractAddress: multicallContractAddress }),
    })

    // Build calls
    const contractCallContext = tokens.map(({ token, address, decimals }) => {
      return {
        reference: token,
        contractAddress: address,
        calls: [{ reference: 'balanceOfCall', methodName: 'balanceOf', methodParameters: [walletAddress] }],
        abi: [erc20Abi[10]],
        context: {
          decimals,
        },
      }
    })

    multicall
      .call(contractCallContext)
      .then((calls) => {
        if (timeoutId) {
          clearTimeout(timeoutId)
        }

        const parsedResult = Object.entries(calls.results).reduce(
          (acc: Record<string, MulticallBalance>, [token, result]) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- comes from a blockchain call
            const balance: string = result.callsReturnContext[0]?.returnValues[0]?.hex || '0'
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- comes from a blockchain call
            const decimals: number = result.originalContractCallContext?.context?.decimals || 18

            acc[token] = {
              balance: SafeDecimal(balance)
                .div(10 ** decimals)
                .toFixed(),
              token,
            }
            return acc
          },
          {},
        )
        log('parsed result for', chain, parsedResult)
        resolve(parsedResult)
      })
      .catch((error) => {
        if (timeoutId) {
          clearTimeout(timeoutId)
        }
        log('error fetching multicall balances', chain, error)
        resolve({})
      })
  })
