import userflow from 'userflow.js'
import { identifyHeap, identifyUserflow } from '../apiService'
import { WalletType, type Kits, type SupportedWallet } from '../wallet/wallet.types'
import { isWalletConnect } from '../wallet/helpers/isWalletConnect'

export const trackUserLoginEvent = ({
  address,
  walletType,
  name,
  connectionKit,
  isAutoConnected = false,
}: {
  address: string
  walletType: SupportedWallet
  name?: string
  connectionKit: Kits
  isAutoConnected?: boolean | undefined
}) => {
  // eslint-disable-next-line functional/no-let -- Legacy
  let walletTypeWithProvider: string = walletType

  if (walletType === WalletType.walletConnect) {
    const provider = connectionKit.getProvider()
    if (isWalletConnect(provider, walletType)) {
      walletTypeWithProvider = `${walletType}-${provider?.session?.peer?.metadata?.name}`
    }
  } else if (name) {
    walletTypeWithProvider = `${walletType}-${name}`
  }

  identifyHeap(address, walletTypeWithProvider, isAutoConnected)
  if (userflow.isIdentified()) {
    userflow.reset()
  }
  identifyUserflow(address, walletTypeWithProvider)
}
