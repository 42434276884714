import type { ReactElement } from 'react'
import { BoxContainer, Container, Image } from '../ContractInteractionsTxModal.styled'
import { ContractInteractionsTxModalTxStatusLink } from '../ContractInteractionsTxModalTxLink'
import { TxStatus } from '../../../../../services/txService/txService.types'

type Props = {
  image: string
  txHash?: string | undefined
  chain: string
  SuccessContent: ReactElement
}

export const ContractInteractionsTxModalSuccess = ({ txHash, chain, image, SuccessContent }: Props) => (
  <Container>
    <BoxContainer>
      <Image src={image} alt="" />
      {txHash && <ContractInteractionsTxModalTxStatusLink txHash={txHash} chain={chain} txStatus={TxStatus.SUCCESS} />}
    </BoxContainer>

    {SuccessContent}
  </Container>
)
