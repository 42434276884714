export type ThrownAPIError = {
  name: string
  message: string
  url: string
  statusCode: number
  body: {
    statusCode: number
    message: string
    error: string
  }
}

export const isAPIError = (error: unknown): error is ThrownAPIError => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Legacy
  return !Number.isNaN((error as ThrownAPIError)?.statusCode)
}
