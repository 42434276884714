import type { NationWidgetMissionsFilterItemState } from '../types/nationWidgetMissionsFilterItem.types'
import { type EnabledMissionsType, MISSION_TYPE_NAME_MAPPING } from './nationMissions.constants'
import { NATION_ALL_FILTER_OPTION_DATA } from './nationAllFilterOptionData.constants'

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy
const ENABLED_MISSION_TYPES_KEYS = Object.keys(MISSION_TYPE_NAME_MAPPING) as EnabledMissionsType[]

const FEATURES_OPTIONS_FOR_ENABLED_MISSION_TYPES: NationWidgetMissionsFilterItemState[] =
  ENABLED_MISSION_TYPES_KEYS.map((key) => ({
    key,
    name: { type: 'translation', text: MISSION_TYPE_NAME_MAPPING[key] },
    icon: {
      id: key,
      type: 'nation-feature',
    },
  }))

export const FEATURES_OPTIONS: NationWidgetMissionsFilterItemState[] = [
  { ...NATION_ALL_FILTER_OPTION_DATA, name: { type: 'translation', text: 'nation.all_mission_types' } },
  ...FEATURES_OPTIONS_FOR_ENABLED_MISSION_TYPES,
]
