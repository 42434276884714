import moment from 'moment'
import { Icon, Text, TypographyAlign, TokenIcon } from '@rhinofi/dvf-shared-ui'
import { FlexContainer } from '../common/Wrappers/Containers'
import { SmallSpinner } from '../common/Helpers/SmallSpinner'
import type { UserHistoryItemSchema } from '@rhinofi/bridge-api-spec'
import { translate } from '../../intl/i18n'
import { shortenAddress } from '../../services/helperService/shortenAddress'
import { formatFloat } from '../../services/helperService/formatFloat'
import { BigDecimal } from 'effect'
import { AnchorContainer } from './BridgeHistory.columns.styled'
import { bigDecimalToNumericString } from '@rhinofi/effect-utils'

export const bridgeHistoryColumns = (
  etherscanLink: (target: string, chain?: string, isTransaction?: boolean, forceIsTransaction?: boolean) => string,
  showAllColumns?: boolean,
) => [
  {
    id: 'status',
    title: translate('helpers.status'),
    sortingEnabled: false,
    align: 'center' as const,
    cells: [
      {
        align: 'center' as const,
        render: ({ state }: UserHistoryItemSchema) => {
          return (
            <FlexContainer $alignItems="center" $justifyContent="center">
              {state === 'EXECUTED' ? (
                <Icon id="check-circle-fill" className="success-bridge" color="primaryMain" />
              ) : state === 'CANCELLED' ? (
                <Icon id="cross-circle-fill" className="success-bridge" color="accentRed" />
              ) : (
                <SmallSpinner />
              )}
            </FlexContainer>
          )
        },
      },
    ],
  },
  {
    id: 'date',
    title: translate('helpers.date'),
    align: 'left' as const,
    cells: [
      {
        align: 'left' as const,
        render: ({ createdAt }: UserHistoryItemSchema) => {
          return <Text>{moment(createdAt).format('DD MMM')}</Text>
        },
      },
    ],
  },
  {
    id: 'value',
    title: translate('helpers.amount'),
    align: 'left' as const,
    cells: [
      {
        align: 'left' as const,
        render: ({ amountOut, token }: UserHistoryItemSchema) => {
          return (
            <Text>
              {formatFloat(BigDecimal.format(amountOut))} {token}
            </Text>
          )
        },
      },
    ],
  },
  {
    id: 'to',
    title: translate('helpers.network'),
    align: 'center' as const,
    cells: [
      {
        align: 'center' as const,
        render: ({ chainIn, chainOut }: UserHistoryItemSchema) => (
          <FlexContainer $alignItems="center" $justifyContent="center" $gap="8px">
            <TokenIcon token={chainIn} size="small" />
            <Icon id="arrow-right" />
            <TokenIcon token={chainOut} size="small" />
          </FlexContainer>
        ),
      },
    ],
  },
  ...(showAllColumns
    ? [
        {
          id: 'recipient',
          title: translate('helpers.recipient'),
          align: 'center' as const,
          hideOnMobile: true,
          cells: [
            {
              align: 'center' as const,
              render: ({ recipient, chainOut }: UserHistoryItemSchema) => (
                <AnchorContainer>
                  <a
                    href={etherscanLink(recipient, chainOut, false, false)}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(event) => event.stopPropagation()}
                  >
                    <Text align={TypographyAlign.Center}>{shortenAddress(recipient)}</Text>
                  </a>
                </AnchorContainer>
              ),
            },
          ],
        },
        {
          id: 'fee',
          title: translate('helpers.fee'),
          align: 'center' as const,
          cells: [
            {
              align: 'center' as const,
              render: ({ fee, token }: UserHistoryItemSchema) => (
                <FlexContainer $alignItems="center" $justifyContent="center">
                  <Text>
                    {bigDecimalToNumericString(fee)} {token}
                  </Text>
                </FlexContainer>
              ),
            },
          ],
        },
      ]
    : []),
]
