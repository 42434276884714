import { useCallback, useMemo } from 'react'
import { selectAddress } from '../../../actions/selectors/userSelectors'
import { useAppSelector, useConfig } from '../../../hooks'
import { useGetMintedNFTsQuery } from '../services/mintedNFTsApi'
import type { NFTMinter } from '../types/nfts.types'
import type { NFTType } from '../types/mintNFT.types'

export const useUserMintedNFTs = () => {
  const { config } = useConfig()
  const address = useAppSelector(selectAddress) || ''

  const {
    data: nfts,
    isUninitialized,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useGetMintedNFTsQuery({ address, config }, { skip: !address || !config })

  const allNFTs = useMemo(() => nfts || [], [nfts])

  const nftsPerChain = useMemo(() => {
    const nftsPerEvm = (nfts || []).reduce(
      (acc, nft) => {
        if (!acc[nft.chain]) {
          acc[nft.chain] = []
        }

        acc[nft.chain]?.push(nft)

        return acc
      },
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Legacy
      {} as Record<string, NFTMinter[]>,
    )

    return nftsPerEvm
  }, [nfts])

  const refreshMintedNFTs = useCallback(() => {
    if (!isUninitialized) {
      refetch().catch(console.error)
    }
  }, [refetch, isUninitialized])

  const hasMintedNFT = useCallback(
    (type: NFTType, chain: string) => {
      const userTokens = nftsPerChain[chain]?.filter((nft) => nft.type === type)
      return !!userTokens && userTokens.length > 0
    },
    [nftsPerChain],
  )

  return {
    allNFTs,
    nftsPerChain,
    isLoading,
    isFetching,
    error,
    refreshMintedNFTs,
    hasMintedNFT,
  }
}
