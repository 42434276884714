import { Button, Spacing, Text, Title } from '@rhinofi/dvf-shared-ui'
import { TitleSize, TypographyAlign } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import { useState } from 'react'
import { FlexContainer } from '../common/Wrappers/Containers'
import { translate } from '../../intl/i18n'
import { UserCredentialForm } from './UserCredentialForm'
import { VerificationCodeForm } from './VerificationCodeForm'
import { VerificationType, Step } from './UserVerificationModal'

type UserVerificationModalContentProps = {
  verificationType: VerificationType
  onLastStepSubmitSuccess: () => Promise<void>
  onPreviousStep?: () => void
  initialStep?: number
  autoFocus?: boolean
}

const threeLengthArray = new Array(3).fill('')

export const UserVerificationModalContent = ({
  onPreviousStep,
  verificationType,
  onLastStepSubmitSuccess,
  initialStep = 0,
  autoFocus,
}: UserVerificationModalContentProps) => {
  const [step, setStep] = useState(initialStep)

  return (
    <>
      <Title size={TitleSize.XXS} align={TypographyAlign.Center} transparency={false}>
        {verificationType === VerificationType.email
          ? translate('user_verification.verify_email')
          : translate('user_verification.verify_phone')}
      </Title>
      <Spacing size="10" />
      <FlexContainer $fullWidth>
        {threeLengthArray.map((_, index) => (
          <Step $active={index === step} key={index} />
        ))}
      </FlexContainer>
      {step === 0 && (
        <FlexContainer $direction="column">
          <Spacing size="30" />
          <Text transparency={false}>{translate('user_verification.verify_intro')}</Text>
          <br />
          <Text transparency={false}>{translate('user_verification.verify_intro2')}</Text>
          <Spacing size="30" />
          <Button id="start-verification" onClick={() => setStep(1)} icon="arrow-right" fullWidth>
            {translate('user_verification.get_started')}
          </Button>
        </FlexContainer>
      )}
      {step === 1 && <UserCredentialForm verificationType={verificationType} nextStep={() => setStep(step + 1)} />}
      {step === 2 && (
        <VerificationCodeForm
          autoFocus={autoFocus}
          onSubmitSuccess={onLastStepSubmitSuccess}
          verificationType={verificationType}
          previousStep={() => {
            setStep(step - 1)
            onPreviousStep?.()
          }}
        />
      )}
    </>
  )
}
