import { useMemo } from 'react'
import { Text, TextSize } from '@rhinofi/dvf-shared-ui'
import type { PerkAPIDocument } from '@rhinofi/rhino-nation-api-spec'
import { getPerkStatsItems } from '../../../../../utils/getPerkStatsItems'
import { FlexContainer } from '../../../../../../../components/common/Wrappers/Containers'

type Props = {
  perk: PerkAPIDocument
}

export const NationPerkModalStats = ({ perk }: Props) => {
  const items = useMemo(() => getPerkStatsItems({ perk }).filter(({ show }) => show), [perk])

  if (items.length === 0) {
    return null
  }

  return (
    <FlexContainer $gap="8px" $direction="column" $alignItems="stretch">
      {items.map(({ id, label, value }) => (
        <FlexContainer key={id} $gap="8px">
          {label !== '' && (
            <Text color="secondary" size={TextSize.XS}>
              {label}
            </Text>
          )}

          <Text size={TextSize.XS}>{value}</Text>
        </FlexContainer>
      ))}
    </FlexContainer>
  )
}
