/* eslint-disable @typescript-eslint/consistent-type-assertions -- Legacy */
import { Schema } from 'effect'
import type { JsonRpcProvider } from 'ethers'
import { Contract } from 'ethers'
import type { JsonFragment } from 'ethers'
import { _abi as nftMinterAbi } from '../../../contracts/NFTMinter/factories/NFTMinter__factory'
import { _abi as nftRewardAbi } from '../../../contracts/NFTReward/factories/NFTReward__factory'
import { _abi as rhinofiWrappedAbi } from '../../../contracts/RhinoFiWrappedNFT/factories/RhinoFiWrappedNFT__factory'
import { _abi as rhinoERC1155Abi } from '../../../contracts/RhinoERC1155/factories/RhinoERC1155__factory'
import { envConfig } from '../../../env/envConfig'
import type { EVMNFTType, NFTType } from '../types/mintNFT.types'
import type { ERC1155SingleNFTConfig, NFTContractType, SingleNFTConfig } from '../types/mintNFT.schemas'
import { ERC1155SingleNFTConfigSchema, NFTConfigSchema, NFTContractTypeSchema } from '../types/mintNFT.schemas'
import type { NftContract } from '../../../env/config.type'

const nftContractsAbis: Record<NFTContractType, JsonFragment[]> = {
  [NFTContractTypeSchema.enums.NFTMinter]: nftMinterAbi as unknown as JsonFragment[],
  [NFTContractTypeSchema.enums.NFTReward]: nftRewardAbi as unknown as JsonFragment[],
  [NFTContractTypeSchema.enums.RhinoFiWrappedNFT]: rhinofiWrappedAbi as unknown as JsonFragment[],
  [NFTContractTypeSchema.enums.RhinoERC1155]: rhinoERC1155Abi as unknown as JsonFragment[],
}

export const getNFTAbi = (contractType: NFTContractType): JsonFragment[] => nftContractsAbis[contractType]

export const getNFTContractConfig = (chain: string, nftType: NFTType): SingleNFTConfig => {
  const configRaw: unknown = envConfig.nftsContracts
  const config = Schema.decodeUnknownSync(NFTConfigSchema)(configRaw, { onExcessProperty: 'preserve' })

  if (!config?.[chain]?.[nftType]) {
    throw new Error(`No contract found for ${nftType} on ${chain}`)
  }

  return config[chain][nftType]
}

export const getERC1155ContractConfig = (chain: string, nftType: EVMNFTType): ERC1155SingleNFTConfig => {
  const baseConfig = getNFTContractConfig(chain, nftType)
  return Schema.decodeUnknownSync(ERC1155SingleNFTConfigSchema)(baseConfig)
}

export const getNFTContract = (type: EVMNFTType, chain: string, provider: JsonRpcProvider) => {
  try {
    const contractConfig = getNFTContractConfig(chain, type)
    const contractAbi = getNFTAbi(contractConfig.type)

    if (!provider || !contractConfig.address || !contractAbi) {
      return null
    }

    return new Contract(contractConfig.address, contractAbi, provider)
  } catch (error) {
    console.error(error)
    return null
  }
}

export const hasNFTTypeContract = (type: EVMNFTType, chain: string): boolean => {
  const contracts: Record<string, NftContract> = envConfig.nftsContracts
  return !!contracts?.[chain]?.[type]
}
