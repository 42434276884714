import type { HighlightItem } from '@rhinofi/campaigns-api-spec'
import {
  NoUnderlineAnchor,
  NoUnderlineBridgeLink,
  NoUnderlineLink,
} from '../../../../components/common/Helpers/LinkHelpers'
import { publicRoutes } from '../../../../router'
import highlighPlaceholder from '../../assets/highlights/highlight-fallback.png'
import { BridgeHighlightsImage } from './BridgeHighlights.styled'

export const BridgeHighlightItem = ({ highlight }: { highlight: HighlightItem }) => {
  const { id, alt, href, imageUrl, isInternalLink, isActive } = highlight
  const content = (
    <BridgeHighlightsImage
      id={id}
      $isActive={isActive}
      src={imageUrl}
      alt={alt}
      width={328}
      height={100}
      onError={(err) => {
        err.currentTarget.src = highlighPlaceholder
      }}
    />
  )

  if (isInternalLink) {
    const isBridgeLink = href.startsWith(publicRoutes.bridge)
    const LinkElement = isBridgeLink ? NoUnderlineBridgeLink : NoUnderlineLink
    return (
      <LinkElement to={href} $fullWidth>
        {content}
      </LinkElement>
    )
  }

  if (href && isActive) {
    return (
      <NoUnderlineAnchor href={href} $fullWidth target="_blank" rel="noopener noreferrer">
        {content}
      </NoUnderlineAnchor>
    )
  }

  return content
}
