import styled from 'styled-components'
import Spinner from '../../../assets/icons/spinner.svg?react'
import { rotate } from '../animations/rotate'

export const SmallSpinner = ({ size = 16 }: { size?: number }) => {
  return (
    <Animate $size={size}>
      <Spinner viewBox="0 0 48 48" />
    </Animate>
  )
}

const Animate = styled.div<{ $size?: number | undefined }>`
  animation: ${rotate} 1s infinite linear;
  font-size: 0;
  overflow: visible;

  &,
  svg {
    height: ${({ $size }) => ($size ? `${$size}px` : '16px')};
    width: ${({ $size }) => ($size ? `${$size}px` : '16px')};
  }
`
