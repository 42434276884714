import { useMemo, useEffect } from 'react'
import { IconSizes, ButtonVariant } from '@rhinofi/dvf-shared-ui'
import type { NationWidgetMissionsFilterItemState } from '../../../types/nationWidgetMissionsFilterItem.types'
import {
  selectNationWidgetChainOptions,
  selectNationWidgetSelectedChain,
} from '../../../selectors/nationWidget.selectors'
import { setNationWidgetChainOptions, setNationWidgetSelectedChain } from '../../../slices/nationWidget.slice'
import { useAppDispatch, useAppSelector } from '../../../../../hooks'
import { NATION_ALL_FILTER_OPTION_DATA } from '../../../constants/nationAllFilterOptionData.constants'
import { getNationWidgetFilterText } from '../../../utils/getNationWidgetFilterText'
import { trackHeapEvent } from '../../../../../services/apiService'
import { heapEvents } from '../../../../../constants/heapEvents'
import { NationWidgetMissionsFilterItem } from './NationWidgetMissionsFilterItem'
import { CustomDropdownSelect } from './NationWidgetMissionsFilters.styled'
import { useConfig } from '../../../../../hooks'
import { safeGetChainName } from '../../../../../utils/getChainName'
import { selectAvailableChains } from '../../../../../store/apis/config.api'

export const NationWidgetMissionsChainFilter = () => {
  const dispatch = useAppDispatch()
  const items = useAppSelector(selectNationWidgetChainOptions)
  const selectedKey = useAppSelector(selectNationWidgetSelectedChain)

  const enabledBridgeChains = useAppSelector(selectAvailableChains)
  const { config } = useConfig()

  const chainSelectItems: NationWidgetMissionsFilterItemState[] = useMemo(() => {
    const chainToName = safeGetChainName(config)

    const chains: NationWidgetMissionsFilterItemState[] = enabledBridgeChains.map(({ chain }) => ({
      key: chain,
      name: { type: 'text', text: chainToName(chain) },
      icon: {
        type: 'chain',
        id: chain,
      },
    }))

    return [
      { ...NATION_ALL_FILTER_OPTION_DATA, name: { type: 'translation', text: 'helpers.other_networks' } },
      ...chains,
    ]
  }, [config, enabledBridgeChains])

  useEffect(() => {
    dispatch(setNationWidgetChainOptions(chainSelectItems))
  }, [chainSelectItems, dispatch])

  const formattedItems = items.map((data) => ({
    ...data,
    name: getNationWidgetFilterText(data.name),
  }))

  return (
    <CustomDropdownSelect
      id="nation-widget-chain-select"
      items={formattedItems}
      selectedKey={selectedKey}
      onSelect={(key) => {
        dispatch(setNationWidgetSelectedChain(key))
        trackHeapEvent(heapEvents.nationWidgetChainFilter, { chain: key })
      }}
      chevronSize={IconSizes.XSmall}
      buttonVariant={ButtonVariant.ghost}
      openHeight="240px"
      renderItem={(item) => <NationWidgetMissionsFilterItem item={item} />}
    />
  )
}
