import { BigDecimal } from 'effect'
import { selectBridge } from '../slices/bridge.slice'
import { useAppSelector } from '../../../hooks'
import { useGetPublicQuoteQuery } from '../../../store/apis/bridge.api'
import type { PublicQuoteQuerySchema, PublicQuoteResponseSchema } from '@rhinofi/bridge-api-spec'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { SerializedError } from '@reduxjs/toolkit'

type UsePublicQuoteInput = {
  mode?: PublicQuoteQuerySchema['mode']
  amount?: string
  gasBoostAmountNative?: string | undefined
  skip?: boolean
}

type UsePublicQuoteOutput = {
  quote: PublicQuoteResponseSchema | undefined
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
}

export const usePublicQuote = (
  { mode = 'receive', amount, gasBoostAmountNative, skip = false }: UsePublicQuoteInput = {
    mode: 'receive',
    skip: false,
  },
): UsePublicQuoteOutput => {
  const bridgeState = useAppSelector(selectBridge)

  const quoteAmount = amount !== undefined ? amount : bridgeState.amount

  const {
    data: publicQuote,
    isError,
    error,
  } = useGetPublicQuoteQuery(
    {
      amount: BigDecimal.unsafeFromString(quoteAmount),
      chainIn: bridgeState.chainIn,
      chainOut: bridgeState.chainOut,
      token: bridgeState.token,
      mode,
      ...(gasBoostAmountNative && { amountNative: BigDecimal.unsafeFromString(gasBoostAmountNative) }),
    },
    {
      skip:
        skip ||
        !quoteAmount ||
        quoteAmount.toString() === '0' ||
        !bridgeState.chainIn ||
        !bridgeState.chainOut ||
        !bridgeState.token,
    },
  )

  return { quote: publicQuote, isError, error }
}
