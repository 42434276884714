import { HttpApiClient } from '@effect/platform'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { envConfig } from '../../env/envConfig'
import { makeApiClientRequest, runEffectHttpCallback } from '../../utils/effect'
import { apiSpec } from '@rhinofi/authentication-api-spec'
import type {
  AuthEvmSignaturePathSchema,
  AuthEvmSignaturePayloadSchema,
  AuthEvmSignatureResponseSchema,
} from '@rhinofi/authentication-api-spec'

const BASE_URL = `${envConfig.tradingApi}/authentication`

const apiClient = HttpApiClient.make(apiSpec, { baseUrl: BASE_URL })

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({}),
  endpoints: (builder) => ({
    genEVMSignature: builder.mutation<
      AuthEvmSignatureResponseSchema,
      { path: AuthEvmSignaturePathSchema; payload: AuthEvmSignaturePayloadSchema }
    >({
      queryFn: ({ path, payload }) =>
        runEffectHttpCallback(() =>
          makeApiClientRequest(apiClient, (client) =>
            client.auth.authEvmSignature({
              path,
              payload,
            }),
          ),
        ),
    }),
  }),
})

export const { useGenEVMSignatureMutation } = authApi
