/* eslint-disable -- legacy */
import React from 'react'
import styled, { css } from 'styled-components'
import type { SupportedTable } from '../../../reducers/settingsSlice/settingsSlice'
import type { HeaderFormat } from './Header'
import { Header } from './Header'

const TR = styled.tr<{ $condensedView: boolean; $fullSize: boolean }>`
  ${(props) =>
    props.$fullSize &&
    css`
      height: 50px;

      span {
        font-size: 13px;
        line-height: 20px;
      }
    `}

  ${({ $condensedView }) =>
    $condensedView &&
    css`
      height: 22px;

      th {
        padding: 0;
        border-bottom: 1px solid ${({ theme }) => theme.divider};
      }

      div {
        font-size: 10px;
        color: ${({ theme }) => theme.textSecondary};
      }

      i {
        color: ${({ theme }) => theme.textSecondary};
      }
    `}
`
type TableHeadersProps = {
  name: string
  columns: HeaderFormat[]
  currentOrder: string
  applyOrder: (name: SupportedTable, orderBy: string, sortingCallback?: (name: string, orderBy: string) => void) => void
  sortingCallback: ((order: string, orderBy: string) => void) | null
  fullSize: boolean
  noTextWrap: boolean
  condensedView: boolean
}

class TableHeaders extends React.PureComponent<TableHeadersProps> {
  // @ts-expect-error -- legacy code
  render() {
    const {
      name = 'unknown',
      columns = [],
      currentOrder,
      applyOrder = () => {},
      sortingCallback = null,
      fullSize = false,
      noTextWrap = false,
      condensedView = false,
    } = this.props

    return (
      <TR $fullSize={fullSize} $condensedView={condensedView}>
        {columns.map((format: HeaderFormat) => (
          <Header
            name={name}
            key={format.id}
            format={format}
            order={currentOrder}
            setOrder={applyOrder}
            sortingCallback={sortingCallback}
            fullSize={fullSize}
            noTextWrap={noTextWrap}
          />
        ))}
      </TR>
    )
  }
}

export default TableHeaders
