import { requireChain } from '../../actions/userActions/userActions'
import { errorToString } from '../helperService'
import { isWrongNetworkSelected } from '../../utils/isWrongNetworkSelected'
import type { ChainExecutionFuncFactory, ChainTxArgs } from './txService.types'
import type { BridgeConfig } from '../../context'

export const doChainTx = async <T>(args: {
  executionArgs: T
  executionFuncFactory: ChainExecutionFuncFactory<T>
  chainTxArgs: ChainTxArgs
  config: BridgeConfig | undefined
}) => {
  const { executionArgs, executionFuncFactory, chainTxArgs, config } = args

  if (!config || !chainTxArgs.signer) {
    return
  }

  const {
    dispatch,
    destinationChainKey,
    secondaryConnectedChainKey,
    currentChainId,
    onError,
    onInit,
    onReceipt,
    onTxHash,
  } = chainTxArgs

  if (
    isWrongNetworkSelected({
      connectedNetworkId: currentChainId,
      secondaryConnectedNetworkId: secondaryConnectedChainKey || '',
      targetChain: destinationChainKey,
      config,
    })
  ) {
    const switchChainResult = await requireChain(dispatch)(destinationChainKey)
    if (switchChainResult.done === false) {
      return {
        refusedNetworkSwitch: true,
      }
    }
  }

  const onErrorHandler = (error: unknown, txHash?: string) => {
    onError({
      error: errorToString(error),
      txHash: txHash || '',
      retryFunc: () => {
        doChainTx(args).catch(console.error)
      },
    })
  }

  try {
    onInit()

    const txHashErrorHandler = (error: unknown, txHash?: string) => {
      if (error) {
        return onErrorHandler(error, txHash)
      } else if (txHash) {
        onTxHash({ txHash })
      }
    }

    const executionFunc = executionFuncFactory(executionArgs)
    const receipt = await executionFunc(chainTxArgs, txHashErrorHandler)

    onReceipt({ receipt, txHash: receipt.hash })
    return
  } catch (error) {
    console.error(error)
    onErrorHandler(error)
    return
  }
}
