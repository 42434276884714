import type { MissionType } from '@rhinofi/rhino-nation-api-spec'
import { Icon, IconSizes, TokenIcon } from '@rhinofi/dvf-shared-ui'
import { NETWORKS } from '../../../../constants/types'
import { MISSION_TYPE_ICON_ID_MAPPING } from '../../constants/nationMissions.constants'

type Props = {
  type: MissionType
  size?: IconSizes
}

export const NationMissionIcon = ({ type, size = IconSizes.Large }: Props) => {
  if (type === 'CUSTOM') {
    return <TokenIcon token={NETWORKS.STARKEX} />
  }

  return <Icon id={MISSION_TYPE_ICON_ID_MAPPING[type]} size={size} />
}
