import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { TokenPricesState } from './types/TokenPricesState'

const getInitialState = (): TokenPricesState => ({})

export const tokenPricesSlice = createSlice({
  name: 'tokenPrices',
  initialState: getInitialState(),
  reducers: {
    setTokenPrices(state, action: PayloadAction<TokenPricesState>) {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const { setTokenPrices } = tokenPricesSlice.actions
