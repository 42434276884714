import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import styled from 'styled-components'

type Props = {
  totalSteps: number
  currentStep: number
  onStepChange: (step: number) => void
}

export const NationOnboardingModalStepsBar = ({ totalSteps, currentStep, onStepChange }: Props) => {
  return (
    <StepsContainer>
      {Array.from({ length: totalSteps }, (_, index) => (
        <Step key={index} isActive={index === currentStep} onClick={() => onStepChange(index)} />
      ))}
    </StepsContainer>
  )
}

const StepsContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  height: 32px;
  gap: 3px;
`

const Step = styled.button<{ isActive: boolean }>`
  border: none;
  cursor: pointer;
  width: 58px;
  height: 5px;
  background: ${({ theme, isActive }) => (isActive ? theme.brand : theme.elevationL2)};
  border-radius: 2.5px;
  transition: background-color 0.3s ease;

  @media ${respondBelow('xxxs')} {
    width: 40px;
  }
`
