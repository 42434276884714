import styled, { css } from 'styled-components'

export const ComponentPanel = styled.div<{ $disabled?: boolean; $withPadding?: boolean }>`
  background-color: ${({ theme }) => theme.bridgePicker};
  border-radius: 8px;
  border: 1px solid transparent;
  transition: border-color 0.15s ease-in-out;
  padding: ${({ $withPadding }) => ($withPadding ? '16px 0 12px 0' : '0')};

  ${({ $disabled }) =>
    !$disabled
      ? css`
          &:hover,
          &:focus-within {
            border: 1px solid ${({ theme }) => theme.bridgePickerBorder};
          }
        `
      : css`
          border: none;
        `}
`

export const ComponentPanelRow = styled.div<{ $autoHeight?: boolean; $smallLeftMargin?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 16px;
  flex: 0;
  gap: 4px;

  height: ${({ $autoHeight }) => ($autoHeight ? 'auto' : '48px')};
  ${({ $smallLeftMargin }) =>
    $smallLeftMargin &&
    css`
      margin-left: 8px;
    `}
`
