import { Text, Title, TitleSize, TypographyAlign } from '@rhinofi/dvf-shared-ui'
import styled from 'styled-components'
import GemIcon from '../../../../../../assets/icons/gem.svg?react'
import { FlexContainer } from '../../../../../../components/common/Wrappers/Containers'
import { translate } from '../../../../../../intl/i18n'
import completeMissionsGif from '../../../../assets/complete-missions.webm'
import { NATION_XP_LABEL } from '../../../../constants/nationGeneral.constants'

export const NationOnboardingModalCompleteChallengesStep = () => {
  return (
    <FlexContainer $direction="column" $padding="0 0 24px" $fullWidth $flexGrow="1">
      <Title size={TitleSize.XS} align={TypographyAlign.Center}>
        {translate('nation.complete_challenges_title')}
      </Title>
      <Text align={TypographyAlign.Center}>{translate('nation.complete_challenges_desc')}</Text>
      <GemsLabel inline noWrap>
        +1 <GemIcon /> = +1 <Text color="secondary60">{NATION_XP_LABEL}</Text>
      </GemsLabel>
      <video id="complete-missions-video" playsInline muted autoPlay loop preload="metadata" width={326} height={182}>
        <source id="complete-missions-video-source" src={completeMissionsGif} type="video/webm" />
        {translate('helpers.not_supported_video_tag')}
      </video>
    </FlexContainer>
  )
}

const GemsLabel = styled(Text)`
  display: flex;
  margin-block: 16px;
  margin-bottom: 48px;
  align-items: center;
  font-size: 16px;
  margin-left: 4px;
`
