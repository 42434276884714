import { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { Title, TitleSize, Button, Text, TextSize } from '@rhinofi/dvf-shared-ui'
import { Modal } from '../../../components/common/Modal/Modal'
import { useAppDispatch } from '../../../hooks'
import { clearModal } from '../../../actions/modalActions/clearModal'
import { translate } from '../../../intl/i18n'
import type { UserContractCodec } from '../types/contractsInteractions.types'
import { FlexContainer } from '../../../components/common/Wrappers/Containers'
import { showModal } from '../../../actions/modalActions/showModal'
import { modalKeys } from '../../../constants/modalKeys'
import { capitalize } from '../../../services/helperService'
import { ContractsInteractionsGMButton } from './Tables/Cells/ContractsInteractionsGMButton'
import { ContractDetails } from './ContractDetails'
import { useAppSelector } from '../../../hooks'
import { selectAddress } from '../../../actions/selectors/userSelectors'

type Props = {
  data: UserContractCodec
}

export const ContractDetailsModal = ({ data }: Props) => {
  const dispatch = useAppDispatch()

  const address = useAppSelector(selectAddress)
  const { chain, address: contractAddress } = data._id

  const isYours = useMemo(() => data.deployer === address, [data.deployer, address])

  const closeModal = useCallback(() => clearModal(dispatch)(), [dispatch])

  const openShareModalCallback = useCallback(() => {
    showModal(dispatch)(modalKeys.contractShare, {
      contractAddress,
      chain,
    })
  }, [dispatch, contractAddress, chain])

  return (
    <Modal isVisible width="408px" onClose={closeModal} variant="info">
      <FlexContainer $direction="column" $alignItems="stretch" $gap="16px">
        <ModalTitle size={TitleSize.XXS} bold>
          {capitalize(translate('global.details'))}
        </ModalTitle>

        {!isYours && (
          <Text size={TextSize.XS}>{translate('contracts_interactions.boost_chain_activity_multiple_contracts')}</Text>
        )}

        <ContractDetails data={data} showFees={isYours} />

        <FlexContainer $gap="8px" $direction="column" $alignItems="stretch">
          {isYours && (
            <Button id="contract-details-modal-yours-share" onClick={openShareModalCallback} fullWidth>
              {translate('helpers.share')}
            </Button>
          )}

          <ContractsInteractionsGMButton
            contractAddress={contractAddress}
            chain={chain}
            text={isYours ? translate('contracts_interactions.gm_your_contract') : ''}
          />
        </FlexContainer>
      </FlexContainer>
    </Modal>
  )
}

const ModalTitle = styled(Title)`
  margin-right: 48px;
`
