import styled from 'styled-components'
import { TextSize, Text, Icon } from '@rhinofi/dvf-shared-ui'
import { SlideInTooltip } from '../../../components/SlideInTooltip/SlideInTooltip'
import { FlexContainer } from '../../../components/common/Wrappers/Containers'
import { translate } from '../../../intl/i18n'

type Props = {
  size?: TextSize
}

const TooltipContent = () => (
  <FlexContainer $gap="8px" $direction="column" $alignItems="stretch">
    <Text size={TextSize.XS} bold>
      {translate('contracts_interactions.total_gms')}
    </Text>
    <Text size={TextSize.XS}>{translate('contracts_interactions.gms_description')}</Text>
  </FlexContainer>
)

export const ContractsInteractionsGmTooltip = ({ size = TextSize.XS }: Props) => (
  <SlideInTooltip minWidth={256} direction="top" variant="elevation" renderTooltip={TooltipContent}>
    <FlexContainer $gap="2px" $alignItems="center">
      <Text size={size}>GMs</Text>

      <IconPosition>
        <Icon id="info-circle" className="icon" />
      </IconPosition>
    </FlexContainer>
  </SlideInTooltip>
)

const IconPosition = styled.div`
  display: flex;
  margin-top: -6px;

  .icon {
    font-size: 8px !important;
  }
`
