import { useMemo } from 'react'
import { isMissionFullyCompleted } from '../utils/isMissionFullyCompleted'
import { useMissionsWithUserData } from './useMissionsWithUserData'

export const useNationNotCompletedMissions = () => {
  const { isLoading, data } = useMissionsWithUserData({})

  const formattedData = useMemo(() => {
    if (!data) {
      return {
        items: [],
      }
    }

    const notCompleted = data.items.filter((missionWithUserData) => !isMissionFullyCompleted(missionWithUserData))

    return {
      items: [...notCompleted].sort(
        (elementA, elementB) => elementB.mission.gemsAwarded - elementA.mission.gemsAwarded,
      ),
    }
  }, [data])

  return {
    isLoading,
    data: formattedData,
  }
}
