import type { NationWidgetMissionsFilterItemName } from '../types/nationWidgetMissionsFilterItem.types'
import { translate } from '../../../intl/i18n'

export const getNationWidgetFilterText = (
  name: NationWidgetMissionsFilterItemName,
): NationWidgetMissionsFilterItemName => {
  if (name.type === 'translation') {
    return {
      type: 'text',
      text: translate(name.text),
    }
  }

  return name
}
