import { HttpApiClient, HttpClient, HttpClientRequest } from '@effect/platform'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type {
  PublicQuoteResponseSchema,
  PublicQuoteQuerySchema,
  ConnectedQuoteResponseSchema,
  ConnectedQuotePayloadSchema,
  UserQuotePathSchema,
  CommitQuotePathSchema,
  CommitedQuoteResponseSchema,
  UserHistoryPathSchema,
  UserHistoryQuerySchema,
  UserHistoryResponseSchema,
  UserHistoryItemSchema,
  BridgeHistoryPathSchema,
  PatchBridgeHistoryPayloadSchema,
} from '@rhinofi/bridge-api-spec'
import { apiSpec } from '@rhinofi/bridge-api-spec'
import { AuthToken, makeApiClientRequest, runEffectHttpCallback } from '../../utils/effect'
import { envConfig } from '../../env/envConfig'
import { Effect, flow, Option } from 'effect'
import { getJWTTokenFromState } from '../../utils/rtk/getJWTTokenFromState'
import { handleUserQuoteErrors } from '../../pages/Bridge/helpers/handleUserQuoteErrors'

const BASE_URL = `${envConfig.tradingApi}/bridge`

const apiClient = HttpApiClient.make(apiSpec, {
  baseUrl: BASE_URL,
  transformClient: flow(
    HttpClient.mapRequestEffect((request) =>
      Effect.gen(function* () {
        const authToken = yield* Effect.serviceOption(AuthToken)

        if (Option.isNone(authToken)) {
          return request
        }

        return HttpClientRequest.setHeader('authorization', authToken.value)(request)
      }),
    ),
  ),
})

export const bridgeApi = createApi({
  reducerPath: 'bridgeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: (builder) => ({
    // quotes
    getPublicQuote: builder.query<PublicQuoteResponseSchema, PublicQuoteQuerySchema>({
      queryFn: (query) =>
        runEffectHttpCallback(() =>
          makeApiClientRequest(apiClient, (client) =>
            client.quote.publicQuote({
              urlParams: query,
            }),
          ),
        ),
    }),
    getUserQuote: builder.query<
      ConnectedQuoteResponseSchema,
      {
        path: UserQuotePathSchema
        payload: ConnectedQuotePayloadSchema
      }
    >({
      queryFn: ({ path, payload }, api) =>
        runEffectHttpCallback(() =>
          makeApiClientRequest(
            apiClient,
            (client) =>
              client.quote.userQuote({
                path,
                payload,
              }),
            getJWTTokenFromState(api),
            handleUserQuoteErrors,
          ),
        ),
    }),
    genUserQuote: builder.mutation<
      ConnectedQuoteResponseSchema,
      { path: UserQuotePathSchema; payload: ConnectedQuotePayloadSchema }
    >({
      queryFn: ({ path, payload }, api) =>
        runEffectHttpCallback(() =>
          makeApiClientRequest(
            apiClient,
            (client) =>
              client.quote.userQuote({
                path,
                payload,
              }),
            getJWTTokenFromState(api),
          ),
        ),
    }),
    commitUserQuote: builder.mutation<CommitedQuoteResponseSchema, { path: CommitQuotePathSchema }>({
      queryFn: ({ path }, api) =>
        runEffectHttpCallback(() =>
          makeApiClientRequest(
            apiClient,
            (client) =>
              client.quote.commitQuote({
                path,
              }),
            getJWTTokenFromState(api),
          ),
        ),
    }),
    // history
    getUserHistory: builder.query<
      UserHistoryResponseSchema,
      { path: UserHistoryPathSchema; urlParams: UserHistoryQuerySchema }
    >({
      queryFn: ({ path, urlParams }, api) =>
        runEffectHttpCallback(() =>
          makeApiClientRequest(
            apiClient,
            (client) =>
              client.history.userHistory({
                path,
                urlParams,
              }),
            getJWTTokenFromState(api),
          ),
        ),
    }),
    getBridgeHistory: builder.query<{ result: UserHistoryItemSchema }, { path: BridgeHistoryPathSchema }>({
      queryFn: ({ path }, api) =>
        runEffectHttpCallback(() =>
          makeApiClientRequest(
            apiClient,
            (client) =>
              client.history
                .bridgeHistory({
                  path,
                })
                .pipe(Effect.map((result) => ({ result }))),
            getJWTTokenFromState(api),
          ),
        ),
    }),
    patchBridgeHistory: builder.mutation<
      UserHistoryItemSchema,
      { path: BridgeHistoryPathSchema; payload: PatchBridgeHistoryPayloadSchema }
    >({
      queryFn: ({ path, payload }, api) =>
        runEffectHttpCallback(() =>
          makeApiClientRequest(
            apiClient,
            (client) =>
              client.history.patchBridgeHistory({
                path,
                payload,
              }),
            getJWTTokenFromState(api),
          ),
        ),
    }),
  }),
})

export const {
  useGetPublicQuoteQuery,
  useGetUserQuoteQuery,
  useGenUserQuoteMutation,
  useCommitUserQuoteMutation,
  useGetUserHistoryQuery,
  useGetBridgeHistoryQuery,
  usePatchBridgeHistoryMutation,
} = bridgeApi
