import { useContext } from 'react'
import { ResizeContext } from './createdResizeContext'

export const useResize = () => {
  const resizeContext = useContext(ResizeContext)

  if (!resizeContext) {
    throw new Error('ResizeContextProvider required')
  }

  return resizeContext
}
