import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import styled, { css } from 'styled-components'

type PageContainerProps = {
  $centered?: boolean
  $wideLayout?: boolean
  $centeredVertical?: boolean
}

export const SimpleContainer = styled.div<PageContainerProps>`
  width: 100%;
  min-height: inherit;
  height: 100%;
  box-sizing: border-box;
  max-width: 1290px;
  margin: 0 auto;

  ${({ $centered, $centeredVertical }) =>
    $centered &&
    css`
      display: flex;
      justify-content: center;
      align-items: ${$centeredVertical ? 'center' : 'flex-start'};
    `}
`

export const ResponsivePage = styled.div`
  position: relative;
  z-index: 1;
  /* 300 px for header, socials, noticifactions */
  min-height: calc(100vh - 300px);

  box-sizing: border-box;
  flex: 1 1;
  padding: 32px 24px 72px 24px;
  margin: 0 auto;

  @media ${respondBelow('xxs')} {
    min-width: 0;
    padding: 0 8px 72px 8px;
    box-sizing: border-box;
    min-height: calc(100vh - 128px);
  }
`

export const ResponsivePageContainer = styled.div`
  box-sizing: border-box;
  max-width: clamp(0px, 100%, 1360px);
  width: clamp(0px, 100%, 1360px);
  margin: 0 auto;
`

export const ResponsivePageContainerCentered = styled(ResponsivePageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`
