import { NETWORKS } from '../../../../constants/types'
import { getParadexProvider } from '../../providers/paradexProvider'

export const getParadexBalances = async () => {
  const { Paraclear } = await import('@paradex/sdk')
  const { account, config } = getParadexProvider()

  const { size } = await Paraclear.getTokenBalance({
    provider: account,
    account,
    config,
    token: 'USDC',
  })
  // Hardcode the values as Paradex only supports USDC
  return {
    [NETWORKS.PARADEX]: {
      USDC: { balance: size, token: 'USDC' },
    },
  }
}
