import { Icon, IconSizes } from '@rhinofi/dvf-shared-ui'
import { translate } from '../../intl/i18n'
import { publicRoutes } from '../../router'
import GemIcon from '../../assets/icons/gem.svg?react'
import type { MobileLink, Trays } from './MobileNavigation.types'

export const showInvestAlertLocalStorageKey = 'shown-invest-alert'

export const mobileNavigationLinks: MobileLink[] = [
  {
    id: 'bridge',
    title: translate('bridge.bridge'),
    path: publicRoutes.bridge,
    icon: <Icon id="rainbow" size={IconSizes.Large} />,
  },
  {
    id: 'nation',
    title: translate('helpers.nation'),
    path: publicRoutes.nation,
    icon: <GemIcon />,
  },
  {
    id: 'more',
    title: translate('helpers.more'),
    tray: 'more' as const,
    icon: <Icon id="three-dots" size={IconSizes.Large} />,
  },
]

export const traySizes: Record<Trays, number> = {
  exchange: 140,
  more: 368,
}
