import { Text, TextSize, TypographyAlign } from '@rhinofi/dvf-shared-ui'
import { translate } from '../../../../../intl/i18n'
import { BoxContainer, Container, Image } from '../ContractInteractionsTxModal.styled'
import { PerpetualLoader } from '../../../../../components/common/Helpers/PerpetualLoader'

type Props = {
  image: string
}

export const ContractInteractionsTxModalWaitingWallet = ({ image }: Props) => (
  <Container>
    <BoxContainer>
      <Image src={image} alt="" />
      <Text size={TextSize.XS}>{translate('account.history_status_pending')}...</Text>
    </BoxContainer>

    <PerpetualLoader />

    <Text align={TypographyAlign.Center}>{translate('global.please_approve_in_wallet')}</Text>
  </Container>
)
