import { useMemo } from 'react'
import type { MissionFrequency, GetActiveMissionsUserDataResponse } from '@rhinofi/rhino-nation-api-spec'
import { useGetActiveMissionsUserDataQuery, useGetAllActiveMissionsQuery } from '../services/nation.api'
import type { MissionsWithUserData } from '../types/nation.types'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { getRTKErrMessage } from '../../../services/helperService'
import { shouldHideEmailMission } from '../utils/shouldHideEmailMission'
import { invalidateNationTagsOnPotentialGemsChangeAction } from '../actions/nation.actions'
import { useHasUserFullNationAccess } from './useHasUserFullNationAccess'
import { useIsUserVerifiedQuery } from '../../../services/userVerificationApi'
import { useAppSelector } from '../../../hooks'
import { selectAddress } from '../../../actions/selectors/userSelectors'

type Props = {
  frequency?: MissionFrequency | undefined
  userMissionDataPollInterval?: number | undefined
}

export const useMissionsWithUserData = ({
  frequency,
  userMissionDataPollInterval = 0,
}: Props): {
  isLoading: boolean
  error: string
  data: MissionsWithUserData | null
} => {
  const address = useAppSelector(selectAddress)
  const dispatch = useAppDispatch()
  const hasFullAccess = useHasUserFullNationAccess()
  const { data: userVerification } = useIsUserVerifiedQuery({ user: address ?? '' }, { skip: !address })

  const {
    isLoading: isLoadingMissions,
    error: missionsError,
    data: missions,
  } = useGetAllActiveMissionsQuery(frequency ? { missionFrequency: frequency } : {})

  const {
    isLoading: isLoadingMissionsUserData,
    error: missionsUserDataError,
    currentData: missionsUserData,
  } = useGetActiveMissionsUserDataQuery(
    {
      onSuccess: invalidateNationTagsOnPotentialGemsChangeAction(dispatch),
    },
    { skip: !hasFullAccess, pollingInterval: userMissionDataPollInterval },
  )

  const isLoading = hasFullAccess ? isLoadingMissions || isLoadingMissionsUserData : isLoadingMissions
  const errorInitial = hasFullAccess ? missionsError || missionsUserDataError : missionsError
  const error = errorInitial ? getRTKErrMessage(errorInitial) : ''

  const data = useMemo(() => {
    if (!missions) {
      return null
    }

    const missionsUserDataLocal: GetActiveMissionsUserDataResponse = missionsUserData ?? { items: [] }

    const hideEmailMissions = shouldHideEmailMission({
      userVerification,
      missions,
      missionsUserData: missionsUserDataLocal,
    })

    const filteredMissions = missions.items.filter(({ type }) => (hideEmailMissions ? type !== 'ENTER_EMAIL' : true))

    const items = filteredMissions.map((mission) => {
      const missionUserData = missionsUserDataLocal.items.find(({ _id: { missionId: id } }) => id === mission._id.id)

      return {
        mission,
        userData: missionUserData || null,
      }
    })

    return {
      items,
    }
  }, [missions, missionsUserData, userVerification])

  return {
    isLoading,
    error,
    data,
  }
}
