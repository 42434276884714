import { gasPriceOracleABI } from '../abis/gasPriceOracleAbi'
import { NETWORKS } from '../../constants/types'
import type { JsonRpcProvider } from 'ethers'
import { Contract, Transaction } from 'ethers'
import { _abi } from '../../contracts/DVFDepositContract/factories/DVFDepositContract__factory'

const L1_FEE_NETWORKS = {
  [NETWORKS.OPTIMISM]: '0x420000000000000000000000000000000000000F',
  [NETWORKS.MANTA]: '0x420000000000000000000000000000000000000F',
  [NETWORKS.BASE]: '0x420000000000000000000000000000000000000F',
  [NETWORKS.SCROLL]: '0x5300000000000000000000000000000000000002',
  [NETWORKS.MANTLE]: '0x420000000000000000000000000000000000000F',
  [NETWORKS.MODE]: '0x420000000000000000000000000000000000000F',
  [NETWORKS.KROMA]: '0x4200000000000000000000000000000000000005',
  [NETWORKS.BLAST]: '0x420000000000000000000000000000000000000F',
  [NETWORKS.ZIRCUIT]: '0x420000000000000000000000000000000000000F',
  [NETWORKS.INK]: '0x420000000000000000000000000000000000000F',
}

export const estimateL1DataFee = async (
  options: {
    chain: string
    functionName: string
    args: unknown[]
    to: string
  },
  provider: JsonRpcProvider,
): Promise<bigint> => {
  try {
    if (!L1_FEE_NETWORKS[options.chain]) {
      return BigInt(0)
    }

    const oracleABI = gasPriceOracleABI
    const gasPriceOracleContract = new Contract(L1_FEE_NETWORKS[options.chain] || '', oracleABI, provider)
    const bridgeContract = new Contract(options.to, _abi)
    const encodedFunctionData = bridgeContract.interface.encodeFunctionData(options.functionName, options.args)
    const tx = new Transaction()
    tx.to = options.to
    tx.data = encodedFunctionData
    tx.type = 2 // EIP1559
    const serializedTx = tx.unsignedSerialized
    if (!gasPriceOracleContract['getL1Fee']) {
      return BigInt(0)
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- know the type
    return gasPriceOracleContract['getL1Fee'](serializedTx) as Promise<bigint>
  } catch (error) {
    console.error(error)
    return BigInt(0)
  }
}
