import { Text, TextSize, TokenIcon } from '@rhinofi/dvf-shared-ui'
import { shortenAddress } from '../../../services/helperService/shortenAddress'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { selectAddress } from '../../../actions/selectors/userSelectors'
import { FlexContainer } from '../../common/Wrappers/Containers'
import { selectBridgeConfig } from '../../../store/apis/config.api'
import { findBridgeKeyForNetworkId } from '../../../services/helperService/findBridgeKeyForNetworkId'
import { selectConnectedNetwork } from '../../../reducers/userSelector'

export const EVMConnected = () => {
  const address = useAppSelector(selectAddress)
  const network = useAppSelector(selectConnectedNetwork)
  const bridgeConfig = useAppSelector(selectBridgeConfig)

  const chainID = findBridgeKeyForNetworkId(network?.toString() || '', bridgeConfig) || ''

  return (
    <FlexContainer $gap="8px" className="high-priority-display">
      <TokenIcon token={chainID} customSize="20px" />
      <Text size={TextSize.S} bold>
        {shortenAddress(address, false, 5)}
      </Text>
    </FlexContainer>
  )
}
