import { useMemo } from 'react'
import image from '../../assets/boost_card_illustration.png'
import { ContractInteractionsTxModal } from '../ContractInteractionsTxModal/ContractInteractionsTxModal'
import type { TxStatusTitleMapping } from '../../../../services/txService/txService.types'
import { TxStatus } from '../../../../services/txService/txService.types'
import { translate } from '../../../../intl/i18n'
import type { GmSuccessType } from '../../types/contractsInteractions.types'
import { ContractGMModalSuccess } from './ContractGMModalSuccess'

type Props = {
  txStatus: TxStatus
  txHash?: string | undefined
  chain: string
  successVariant: GmSuccessType
  retryFunc?: () => void | undefined
}

export const ContractGMModal = ({ txStatus, txHash, chain, successVariant, retryFunc }: Props) => {
  const title = useMemo(() => {
    const mapping: TxStatusTitleMapping = {
      [TxStatus.WAITING_WALLET]: `${translate('contracts_interactions.initiating_gm')}...`,
      [TxStatus.PENDING]: `${translate('contracts_interactions.submitting_gm')}...`,
      [TxStatus.SUCCESS]: translate('global.success'),
      [TxStatus.ERROR]: translate('contracts_interactions.submitting_gm_fail'),
    }

    return mapping[txStatus]
  }, [txStatus])

  return (
    <ContractInteractionsTxModal
      image={image}
      txStatus={txStatus}
      txHash={txHash}
      title={title}
      chain={chain}
      retryFunc={retryFunc}
      SuccessContent={<ContractGMModalSuccess variant={successVariant} />}
    />
  )
}
