import type { ReactNode } from 'react'
import type {
  MissionAPIDocument,
  UserMissionDataAPIDocument,
  PerkAPIDocument,
  UserPerkDataAPIDocument,
} from '@rhinofi/rhino-nation-api-spec'
import type { NationContentLoadingErrorTextSize } from './nationContentLoadingErrorText'

export type MissionWithUserData = {
  mission: MissionAPIDocument
  userData: UserMissionDataAPIDocument | null
}

export type MissionsWithUserData = {
  items: MissionWithUserData[]
}

export enum SectionsIds {
  EARN = 'EARN',
  SPEND = 'SPEND',
}

export enum EarnTabsIds {
  ONE_TIME = 'ONE_TIME',
  REPEATABLE = 'REPEATABLE',
  COMPLETED = 'COMPLETED',
}

export enum SpendTabsIds {
  REWARDS = 'REWARDS',
  CLAIMED = 'CLAIMED',
}

export type MissionsTitleFormatterArgs = {
  missionsWithUserData: MissionsWithUserData
}

export type PerkWithUserData = {
  perk: PerkAPIDocument
  userData: UserPerkDataAPIDocument | null
}

export type PerksWithUserData = {
  items: PerkWithUserData[]
}

export enum NationUserStatus {
  Loading = 'Loading',
  NotJoined = 'NotJoined',
  Joined = 'Joined',
  MintedNFT = 'MintedNFT',
}

export type NationContentSectionTitleItemData = {
  id: string
  label?: string | undefined
  labelSize?: NationContentLoadingErrorTextSize | undefined
  showLabelValueSeparator?: boolean | undefined
  value: number | string
  valueSize?: NationContentLoadingErrorTextSize | undefined
  valueGap?: string | undefined
  valueAlignItems?: string | undefined
  loading: boolean
  error: boolean
  postValueContent?: ReactNode | string | undefined
  postValueContentSize?: NationContentLoadingErrorTextSize | undefined
}

export type NationStorageKeyRecord = {
  ONBOARDING: 'nation-onboarding-shown'
  CAMPAIGN_USE_IT_OR_LOSE_IT: 'nation-use-it-or-lose-it-shown'
}
