import { useMemo, type ReactNode } from 'react'
import { translate } from '../../../intl/i18n'
import GemIcon from '../../../assets/icons/gem.svg?react'
import { useBannerGemsBalance } from './useBannerGemsBalance'

type BalanceItem = {
  id: string
  label: string
  loading: boolean
  error: boolean
  value: string | number
  showLabelValueSeparator: boolean
  postValueContent: ReactNode
}

export const useNationSpendSectionTitleItems = (): [BalanceItem] => {
  const { gems, isLoading, error } = useBannerGemsBalance()

  const balanceItem = useMemo(
    () => ({
      id: 'balance',
      label: translate('helpers.balance'),
      loading: isLoading,
      error: !!error,
      value: gems,
      showLabelValueSeparator: true,
      postValueContent: <GemIcon />,
    }),
    [gems, isLoading, error],
  )

  return [balanceItem]
}
