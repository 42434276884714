import { getTronProvider } from '../../providers/tronProvider'
import { nonEVMBridgeAbi } from '../../../abis/nonEVMBridgeAbi'
import type { BridgeContractCallProps } from '../../../../pages/Bridge/services/bridge.contract'

export const handleTronDeposit = ({
  bridgeContractAddress,
  tokenAddress,
  amountWithDecimals,
  commitmentId,
  callback,
}: BridgeContractCallProps) => {
  const { tronWeb } = getTronProvider()
  if (!tronWeb) {
    throw new Error('Please connect your Tron wallet first.')
  }
  const contract = tronWeb.contract(nonEVMBridgeAbi, bridgeContractAddress)
  if (!('depositWithId' in contract)) {
    return false
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- type is unknown
  const deposit = contract.depositWithId as (
    token: string,
    amount: string,
    commitmentId: string,
  ) => { send: (options?: { shouldPollResponse: boolean }) => string }
  const txHash = deposit(tokenAddress, amountWithDecimals, `0x${commitmentId}`).send({ shouldPollResponse: true })
  callback?.(txHash)

  return { transactionHash: txHash }
}
