import { get } from 'lodash-es'

const forbiddenStrings = [
  'device is already open',
  "Failed to execute 'requestDevice'",
  'denied by the user',
  '0x6d02',
  'User denied transaction signature',
  'User denied message signature',
]

export const isValidWalletError = (error: unknown) => {
  try {
    if (typeof error === 'string') {
      return !forbiddenStrings.some((forbiddenString) => error.includes(forbiddenString))
    }

    if (error instanceof Error) {
      const errorMessage = 'message' in error ? get(error, 'message') : ''

      return (
        !errorMessage.includes('User denied transaction signature') &&
        !errorMessage.includes('6985') &&
        !error.toString().includes('device is already open') &&
        !error.toString().includes("Failed to execute 'requestDevice'") &&
        !error.toString().includes('denied by the user') &&
        !error.toString().includes('User denied message signature') &&
        !error.toString().includes('0x6d02')
      )
    } else {
      return false
    }
    // eslint-disable-next-line no-unused-vars -- catch
  } catch (newError) {
    return false
  }
}
