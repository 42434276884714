import { useTheme } from 'styled-components'

export const Checkmark = ({ customFill }: { customFill?: string | undefined }) => {
  const theme = useTheme()
  return (
    <svg width="62" height="48" viewBox="0 0 31 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 8L0 11L11 24L31 3L28 0L11 13L3 8Z" fill={theme.successMain || customFill} />
    </svg>
  )
}
