import {
  Button,
  ButtonSizes,
  ButtonVariant,
  Icon,
  IconSizes,
  Spacing,
  Text,
  TextSize,
  Title,
  TypographyAlign,
} from '@rhinofi/dvf-shared-ui'
import { TitleSize } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import { translate } from '../../../../intl/i18n'
import { useBridgeProgress } from '../../hooks'
import { BridgeErrorType } from '../../slices/bridgeProgress.slice'
import { SuccessSection } from '../../Bridge.styled'

type ErrorBridgeStateProps = {
  prettyChainInName: string
}
export const ErrorBridgeState = ({ prettyChainInName }: ErrorBridgeStateProps) => {
  const { progress, resetProgressState } = useBridgeProgress()

  return (
    <>
      <Title size={TitleSize.XXS} title={prettyChainInName} align={TypographyAlign.Center} fullWidth>
        {translate('helpers.canceled')}
      </Title>
      <SuccessSection>
        <Icon id="x-circle-fill" color="errorLight" size={IconSizes.ExtraLarge} />
      </SuccessSection>
      <Text size={TextSize.S} align={TypographyAlign.Center} bold>
        {!progress.errorType && translate('errors.bridge_cancelled')}
        {progress.errorType === BridgeErrorType.WrongNetwork &&
          translate('errors.wrong_deposit_network', { '%network': prettyChainInName })}
      </Text>
      <Spacing size="24" />
      <Button id="bridge-cancelled-try-again" fullWidth onClick={resetProgressState} size={ButtonSizes.Large}>
        {translate('bridge.bridge_again')}
      </Button>
      <Spacing size="16" />
      <Button
        id="bridge-cancelled-support"
        variant={ButtonVariant.secondary}
        fullWidth
        size={ButtonSizes.Large}
        onClick={() => {
          window.open('https://support.rhino.fi/en', '_blank', 'noopener noreferrer')
        }}
        iconLeft="chat-left-text"
      >
        {translate('bridge.contact_support')}
      </Button>
    </>
  )
}
