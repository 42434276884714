import Aigle from '@rhino.fi/aigle'
import { flatMap } from 'lodash-es'
import { ethers } from 'ethers'
import type { EVMNFTType } from '../types/mintNFT.types'
import { NFTContractTypeSchema } from '../types/mintNFT.schemas'
import { getERC1155ContractConfig, getNFTContract, getNFTContractConfig } from './nftContractHelpers'
import { getNFTMetaData } from './getNFTMetaData'
import type { NFTMinter } from '../types/nfts.types'
import { getChainConfig } from '../../../utils/getChainConfig'
import { getNFTTypesForChain } from './getNFTTypesForChain'
import type { BridgeConfig } from '../../../context'

const getUserTokenIds = async ({
  type,
  chain,
  user,
  config,
}: {
  type: EVMNFTType
  chain: string
  user: string
  config: BridgeConfig | undefined
}): Promise<{ tokenId: number; index: number }[] | null> => {
  if (!config) {
    return null
  }
  const chainConfig = getChainConfig(config)({ chain })
  const { rpc, networkId, name } = chainConfig
  const network = new ethers.Network(name, networkId)
  const contract = getNFTContract(type, chain, new ethers.JsonRpcProvider(rpc, network, { staticNetwork: true }))

  if (!contract) {
    return null
  }

  const contractConfig = getNFTContractConfig(chain, type)

  if (contractConfig.type === NFTContractTypeSchema.enums.RhinoERC1155) {
    const { tokenId } = getERC1155ContractConfig(chain, type)
    const hasMinted: unknown = await contract['balanceOf']?.(user, tokenId)

    // add tokenId to an array as many times as the user has minted it
    const result = Array.from({ length: Number(hasMinted) }, (_, index) => ({
      tokenId,
      index,
    }))
    return result
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy
  const userTokenIds = (await contract['getUserTokenIds']?.(user)) as string[]

  return userTokenIds.map((id: string, index: number) => ({
    tokenId: Number(id),
    index,
  }))
}

export const getNFTsForChain = async (
  chain: string,
  user: string,
  config: BridgeConfig | undefined,
): Promise<NFTMinter[]> => {
  try {
    if (!config) {
      throw new Error('Config not defined')
    }

    const nftTypesPerChain = getNFTTypesForChain(chain)

    const nftIDs = await Aigle.map(nftTypesPerChain, (type) => getUserTokenIds({ type, chain, user, config }))

    const nftsWithMetaData = nftIDs.map((nftIdsForType, index) =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- result of maps, so should be defined and legacy
      (nftIdsForType || []).map((id) => getNFTMetaData(nftTypesPerChain[index]!, chain, id.tokenId, id.index)),
    )

    return flatMap(nftsWithMetaData)
  } catch (error) {
    console.error(`Failed to get NFTs for chain ${chain}`, error)
    return []
  }
}
