import { Schema } from 'effect'

export class DefaultRoute extends Schema.Class<DefaultRoute>('DefaultRoute')({
  sourceChain: Schema.String,
  destinationChain: Schema.String,
  token: Schema.String,
}) {}

export const getDefaultBridgeRoute = (): DefaultRoute | null => {
  try {
    const defaultRoute = localStorage.getItem('defaultBridgeRoute')

    if (!defaultRoute) {
      return null
    }

    const parsed: unknown = JSON.parse(defaultRoute)
    return Schema.decodeUnknownSync(DefaultRoute)(parsed)
  } catch (error) {
    console.error('Failed to get or decode the default bridge route:', error)
    return null
  }
}
