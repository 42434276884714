import styled from 'styled-components'
import { Button } from '@rhinofi/dvf-shared-ui'
import { FlexContainer } from '../../../../../../components/common/Wrappers/Containers'

export const CopyContainer = styled(FlexContainer)`
  #mission-copy-referral-link-wrapper {
    width: 100%;
  }
`

export const CustomButton = styled(Button)`
  flex-grow: 1;
`
