import type { NavigationLinkArray } from './NavigationDesktop.types'
import { translate } from '../../intl/i18n'
import { makeRouteTo, publicRoutes } from '../../router'
import gemIcon from '../../assets/icons/gem.svg'
import { DEFAULT_FEATURE_CHAIN } from '../../pages/Chain/constants/activityTrackers.constants'

export const topNavigationHeight = '72px'

// TODO DOPE-424 - Add campaigns
// const enabledMilestoneCampaigns = getMilestoneCampaigns()
// if migrating predictions
// const isPredictionsEnabled = isFeatureEnabled(F_FLAGS_TYPES.PREDICTIONS)

export const navigationLinks: NavigationLinkArray = [
  {
    icon: 'rainbow',
    title: translate('bridge.bridge'),
    to: publicRoutes.bridge,
  },
  {
    icon: gemIcon,
    title: translate('helpers.rhino_nation'),
    to: publicRoutes.nation,
    id: 'nav-nation',
  },
  {
    icon: 'images',
    title: translate('helpers.mint_interact'),
    subLinks: [
      {
        title: translate('helpers.mint_nfts'),
        to: makeRouteTo.nftMinter({ chain: DEFAULT_FEATURE_CHAIN }),
        nestedPath: true,
      },
      {
        title: translate('helpers.deploy_and_interact'),
        to: makeRouteTo.chainDeployContracts({ chain: DEFAULT_FEATURE_CHAIN }),
        nestedPath: true,
      },
      // TODO DOPE-422 - Integrate trackers
      // {
      //   title: translate('helpers.trackers'),
      //   to: makeRouteTo.analytics(DEFAULT_FEATURE_CHAIN),
      //   nestedPath: true,
      // },
      // {
      //   title: translate('airdrop.campaigns'),
      //   to: makeRouteTo.campaigns(enabledMilestoneCampaigns[0]),
      //   nestedPath: true,
      // },
      // ...(isPredictionsEnabled
      //   ? [
      //       {
      //         title: translate('helpers.predictions'),
      //         to: publicRoutes.predictions,
      //         nestedPath: true,
      //       },
      //     ]
      //   : []),
    ],
  },
  // {
  //   icon: 'grid',
  //   title: translate('helpers.platform'),
  //   subLinks: [
  //     {
  //       title: translate('helpers.history'),
  //       to: makeRouteTo.history(),
  //     },
  //   ],
  // },
]
