import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { ContractsInteractionsState } from '../types/contractsInteractions.types'
import { getContractsInteractionsSavedChain } from '../helpers/getContractsInteractionsSavedChain'

const initialState: ContractsInteractionsState = {
  selectedChain: getContractsInteractionsSavedChain(),
}

export const contractInteractionsSlice = createSlice({
  name: 'contractsInteractions',
  initialState,
  reducers: {
    setSelectedChain: (state, action: PayloadAction<string>) => ({
      ...state,
      selectedChain: action.payload,
    }),
  },
})

export const { setSelectedChain } = contractInteractionsSlice.actions
