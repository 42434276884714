import type { MissionWithUserData } from '../types/nation.types'

// Checks if one-time and repeatable missions with a limit (maxTimesCanBeRewarded is set) are completed
export const isMissionFullyCompleted = ({ mission, userData }: MissionWithUserData) => {
  if (!userData || mission.maxTimesCanBeRewarded === null) {
    return false
  }

  const totalTimesToAchieve = (mission.maxTimesCanBeRewarded || 1) * mission.minTimesToAchieve
  return userData.totalTimesAchieved >= totalTimesToAchieve
}
