import { NetworkType } from '@rhinofi/dvf-shared-ui'
import { Struct } from 'effect'
import { useMemo } from 'react'
import { selectBlockchainBalances } from '../../../actions/selectors/portalSelectors'
import { useAppSelector } from '../../../hooks'
import { getTokenPrice } from '../../../services/helperService/getTokenPrice'
import { selectTokenPrices } from '../../../services/usdPricesApi'
import { selectAvailableChains, selectAvailableTokens } from '../../../store/apis/config.api'
import { selectBridgeChainsConfig } from '../../../store/selectors/bridgeConfig.selectors'
import { SafeDecimal } from '../../../utils/SafeDecimal'
import { getBridgeBadge, getBridgeCategory } from '../helpers'
import { isChainAvailableForToken } from '../helpers/isChainAvailableForToken'
import { selectBridge } from '../slices/bridge.slice'
import type { ChainWithBalance, TokenWithBalance } from '../types/bridge-widget.types'

type UseBridgeDataWithBalancesOutput = {
  availableChainsWithBalances: ChainWithBalance[]
  tokensListWithBalances: TokenWithBalance[]
}

export const useBridgeDataWithBalances = (): UseBridgeDataWithBalancesOutput => {
  const availableChains = useAppSelector(selectAvailableChains)
  const availableTokens = useAppSelector(selectAvailableTokens)
  const blockchainBalances = useAppSelector(selectBlockchainBalances)
  const tokenPrices = useAppSelector(selectTokenPrices)
  const bridgeState = useAppSelector(selectBridge)
  const { chainInConfig } = useAppSelector(selectBridgeChainsConfig)

  const availableChainsWithBalances: ChainWithBalance[] = useMemo(
    () =>
      availableChains.map(({ chain, config }) => {
        const tokenUsdPrice = getTokenPrice(tokenPrices, bridgeState.token)
        const balance = blockchainBalances?.[chain]?.[bridgeState.token]?.balance ?? '0'
        const balanceUsd = SafeDecimal(balance).times(tokenUsdPrice).toString()
        const category = getBridgeCategory(config)
        const badge = getBridgeBadge(config)
        const type = config.type === 'EVM' ? NetworkType.Evm : NetworkType.NonEvm

        const baseConfig = Struct.omit('category', 'badge', 'type')(config)

        return {
          ...baseConfig,
          type,
          chain,
          chainName: config.name,
          isAvailable: isChainAvailableForToken(config, bridgeState.token),
          balance,
          balanceUsd,
          ...(config.category && { category }),
          ...(config.badge && { badge }),
        }
      }),
    [availableChains, blockchainBalances, bridgeState.token, tokenPrices],
  )

  const tokensListWithBalances: TokenWithBalance[] = useMemo(
    () =>
      availableTokens.map((token) => {
        const tokenUsdPrice = getTokenPrice(tokenPrices, token)
        const balance = blockchainBalances?.[bridgeState.chainIn]?.[token]?.balance || '0'
        const balanceUsd = SafeDecimal(balance).times(tokenUsdPrice).toString()
        return {
          token,
          balanceToken: balance,
          balanceUsd,
          ...(chainInConfig?.name && { chainName: chainInConfig.name }),
        }
      }),
    [availableTokens, blockchainBalances, bridgeState.chainIn, tokenPrices, chainInConfig?.name],
  )

  return { availableChainsWithBalances, tokensListWithBalances }
}
