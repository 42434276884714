import type { PerkWithUserData } from '../types/nation.types'

export const isPerkClaimed = ({ perkWithUserData: { perk, userData } }: { perkWithUserData: PerkWithUserData }) => {
  if (!userData) {
    return false
  }

  if (perk.maxClaimableTimes === null) {
    return false
  }

  return userData.timesPurchased >= perk.maxClaimableTimes
}
