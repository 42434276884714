import { estimateBridgeGasCost } from '../services/gas/estimateBridgeGasCost'
import { selectBridgeChainsConfig } from '../../../store/selectors/bridgeConfig.selectors'
import { selectIsAuthenticated, selectUserAddress } from '../../../reducers/userSelector'
import { useAppSelector } from '../../../hooks'
import { useEffect, useState } from 'react'
import { selectBridge } from '../slices/bridge.slice'
import { selectSecondaryWalletAddress } from '../../../actions/selectors/secondaryWalletSelectors'
import { useWallet } from '../../../hooks/useWallet'

export const useBridgeGasEstimate = () => {
  const bridgeState = useAppSelector(selectBridge)

  const { chainInConfig } = useAppSelector(selectBridgeChainsConfig)
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const address = useAppSelector(selectUserAddress)
  const secondaryWalletAddress = useAppSelector(selectSecondaryWalletAddress)

  const [gasEstimate, setGasEstimate] = useState<string>('0')

  const { ethersSigner } = useWallet()

  const estimateGas = async () => {
    if (
      !address ||
      !chainInConfig ||
      !isAuthenticated ||
      !ethersSigner ||
      !bridgeState ||
      !chainInConfig.tokens[bridgeState.token]
    ) {
      return
    }
    const { token } = bridgeState
    const isNative = token === chainInConfig.nativeTokenName
    const cost = await estimateBridgeGasCost({
      address,
      isNative,
      chainInConfig,
      secondaryWalletAddress,
      chain: bridgeState.chainIn,
      tokenAddress: chainInConfig.tokens?.[token]?.address || '',
    })
    setGasEstimate(cost)
  }

  useEffect(() => {
    void estimateGas()
    // eslint-disable-next-line react-hooks/exhaustive-deps -- run only on select param change
  }, [chainInConfig, bridgeState.token])

  return {
    gasEstimate,
  }
}
