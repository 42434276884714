import { tronListenerFunction } from './createTronListener'

export const disconnectTronWallet = () => {
  try {
    if (tronListenerFunction) {
      window.removeEventListener('message', tronListenerFunction)
    }
    // eslint-disable-next-line no-unused-vars -- we want this to fail silently
  } catch (error) {
    /* empty */
  }
}
