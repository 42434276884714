import type { BridgeConfigEntrySchema } from '@rhinofi/bridge-api-spec'
import type { BadgePropItem } from '@rhinofi/dvf-shared-ui'
import { translate } from '../../../intl/i18n'

export const getBridgeBadge = (chainConfig?: BridgeConfigEntrySchema): BadgePropItem | undefined => {
  if (!chainConfig?.badge) {
    return
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- badgeType is using a schema from backend and do not change
  const badgeType = chainConfig.badge.type as BadgePropItem['type']

  return {
    type: badgeType,
    label: translate(`helpers.${chainConfig.badge.translationKey}`),
  }
}
