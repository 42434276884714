import styled from 'styled-components'

export const DefaultBellIndicator = () => <Container />

const Container = styled.span`
  position: absolute;
  left: calc(50% + 2px);
  top: calc(50% - 7.5px);
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  background: ${(props) => props.theme.brandA2};
  animation: bubble 1.5s infinite;

  @keyframes bubble {
    0% {
      box-shadow:
        0 0 0 0px rgba(122, 245, 191, 0.25),
        0 0 0 0px rgba(122, 245, 191, 0.15);
    }
    25% {
      box-shadow:
        0 0 0 0px rgba(122, 245, 191, 0.25),
        0 0 0 10px rgba(122, 245, 191, 0.15);
    }
    50% {
      box-shadow:
        0 0 0 5px rgba(122, 245, 191, 0.25),
        0 0 0 10px rgba(122, 245, 191, 0.15);
    }
    100% {
      box-shadow:
        0 0 0 0px rgba(122, 245, 191, 0.25),
        0 0 0 0px rgba(122, 245, 191, 0.15);
    }
  }
`
