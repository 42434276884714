import { useCallback, useMemo } from 'react'
import moment from 'moment'
import type { MissionId } from '@rhinofi/rhino-nation-api-spec'
import { Text, TextSize } from '@rhinofi/dvf-shared-ui'
import type { MissionWithUserData } from '../../../../types/nation.types'
import GemIcon from '../../../../../../assets/icons/gem.svg?react'
import { FlexContainer } from '../../../../../../components/common/Wrappers/Containers'
import { translate } from '../../../../../../intl/i18n'
import { NationMissionIcon } from '../../../NationMissionIcon/NationMissionIcon'
import { getStatEndDateValue } from '../../../../utils/getStatEndDateValue'
import { isMissionFullyCompleted } from '../../../../utils/isMissionFullyCompleted'
import { getMissionCompletedPercentage } from '../../../../utils/getMissionCompletedPercentage'
import { NationMissionProgressStats } from '../../NationMissionProgressStats/NationMissionProgressStats'
import { NationMissionProgressBar } from '../../NationMissionProgressBar/NationMissionProgressBar'
import {
  ContentContainer,
  ContentWrapper,
  DescriptionContainer,
  EllipsisText,
  CompletedContainer,
  GemsContainer,
  CheckMarkIcon,
  ProgressInfoContainer,
} from './NationMissionRow.styled'
import { hasDatePassed } from '../../../../../../utils/hasDatePassed'

type Props = {
  data: MissionWithUserData
  isHistory?: boolean | undefined
  openModalFunc: (missionId: MissionId) => void
  titleMaxWidth?: string | undefined
  fixedId: string
}

export const NationMissionRow = ({ data, isHistory, openModalFunc, titleMaxWidth, fixedId }: Props) => {
  const { mission } = data

  const isCompleted = isMissionFullyCompleted(data) && !isHistory
  const showProgressBar = mission.minTimesToAchieve > 1 && !isCompleted

  const completedPercentage = getMissionCompletedPercentage(data)

  const openModalCallback = useCallback(() => {
    openModalFunc(mission._id)
  }, [openModalFunc, mission])

  const showLimitedTime = useMemo(() => {
    if (!mission.endDate || isHistory) {
      return false
    }

    if (mission.endDate) {
      const endDateMoment = moment(new Date(mission.endDate))
      const nowMoment = moment(new Date())

      const diffDays = endDateMoment.diff(nowMoment, 'days')

      if (diffDays > 30) {
        return false
      }
    }

    return !hasDatePassed(mission.endDate)
  }, [mission.endDate, isHistory])

  return (
    <FlexContainer $gap="4px" $direction="column" $alignItems="stretch">
      {showLimitedTime && (
        <ProgressInfoContainer $justifyContent="space-between" $alignItems="stretch">
          <Text size={TextSize.XS} color="brandA1">
            {`${translate('nation.limited_time')}!`}
          </Text>

          <Text size={TextSize.XS} color="secondary60">
            {mission.endDate !== null ? getStatEndDateValue(mission.endDate) : null}
          </Text>
        </ProgressInfoContainer>
      )}

      <ContentContainer onClick={openModalCallback} id={`${fixedId}-${mission._id.id}`}>
        <ContentWrapper $direction="column" $justifyContent="space-between" $alignItems="stretch">
          <FlexContainer $justifyContent="space-between" $alignItems="stretch">
            <DescriptionContainer $gap="12px">
              <NationMissionIcon type={mission.type} />
              <EllipsisText $maxWidth={titleMaxWidth}>{mission.title}</EllipsisText>
            </DescriptionContainer>

            {isCompleted ? (
              <CompletedContainer>
                <CheckMarkIcon isOnButton id="check" />
              </CompletedContainer>
            ) : (
              <GemsContainer $gap="8px">
                <Text>+{mission.gemsAwarded}</Text>
                <GemIcon />
              </GemsContainer>
            )}
          </FlexContainer>

          {showProgressBar && <NationMissionProgressBar percentage={completedPercentage} />}
        </ContentWrapper>
      </ContentContainer>

      <NationMissionProgressStats data={data} isHistory={isHistory} />
    </FlexContainer>
  )
}
