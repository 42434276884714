import { useMemo } from 'react'
import { NATION_ALL_FILTER_OPTION_DATA } from '../constants/nationAllFilterOptionData.constants'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { selectNationWidgetSelectedChain, selectNationWidgetSelectedFeature } from '../selectors/nationWidget.selectors'
import { useNationNotCompletedMissions } from './useNationNotCompletedMissions'

export const useNationWidgetFilteredMissions = () => {
  const { isLoading, data } = useNationNotCompletedMissions()

  const selectedChain = useAppSelector(selectNationWidgetSelectedChain)
  const selectedFeature = useAppSelector(selectNationWidgetSelectedFeature)

  const filtered = useMemo(
    () => ({
      items: data.items.filter(({ mission }) => {
        // eslint-disable-next-line functional/no-let -- legacy
        let passChain = true
        // eslint-disable-next-line functional/no-let -- legacy
        let passFeature = true

        if (selectedChain !== NATION_ALL_FILTER_OPTION_DATA.key) {
          passChain = false

          if ('chain' in mission && mission.chain !== null) {
            passChain = selectedChain === mission.chain
          }

          if ('chainTo' in mission && mission.chainTo !== null) {
            passChain = selectedChain === mission.chainTo
          }
        }

        if (selectedFeature !== NATION_ALL_FILTER_OPTION_DATA.key) {
          passFeature = selectedFeature === mission.type
        }

        return passChain && passFeature
      }),
    }),
    [data, selectedChain, selectedFeature],
  )

  return {
    data: filtered,
    isLoading,
  }
}
