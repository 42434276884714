import styled from 'styled-components'

export const FeeBreakdownPanel = styled.div<{ $isCollapsed: boolean }>`
  height: ${({ $isCollapsed }) => ($isCollapsed ? '0' : 'auto')};
  overflow: hidden;

  display: flex;
  gap: 12px;
  flex-direction: column;
`

export const SeeDetailsButton = styled.button`
  /* disable default button styles */
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  &:focus {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.textLink};
  }
`
