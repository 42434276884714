import { envConfig } from '../../../env/envConfig'
import type { EVMNFTType } from '../types/mintNFT.types'

export const getNFTTypesForChain = (chain: string): EVMNFTType[] => {
  const chainNFTTypes = envConfig.nftsContracts[chain]

  if (!chainNFTTypes) {
    return []
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- We know the type
  return Object.keys(chainNFTTypes) as EVMNFTType[]
}
