import { useCallback, useState } from 'react'
import type { BridgeConfirmationPromise, BridgeConfirmationScreen } from '../types/bridge-widget.types'

export const useConfirmationScreen = () => {
  const [confirmationPromise, setConfirmationPromise] = useState<BridgeConfirmationPromise | null>(null)

  const triggerConfirmationScreen = useCallback(async (type: BridgeConfirmationScreen) => {
    // eslint-disable-next-line functional/no-let -- pattern requires let
    let confirmation
    const promise = new Promise<boolean>((resolve) => {
      confirmation = {
        resolve,
        type,
      }
    })
    if (confirmation) {
      setConfirmationPromise(confirmation)
    }

    return await promise
  }, [])

  return {
    triggerConfirmationScreen,
    confirmationPromise,
    setConfirmationPromise,
  }
}
