import type { MissionAPIDocument } from '@rhinofi/rhino-nation-api-spec'
import { Button, ButtonVariant } from '@rhinofi/dvf-shared-ui'
import { FlexContainer } from '../../../../../../components/common/Wrappers/Containers'
import { translate } from '../../../../../../intl/i18n'
import { modalSteps } from '../NationOnboardingModal.steps'

type Props = {
  modalStepIndex: number
  data: { missions: MissionAPIDocument[]; totalGemsEarned: number }
  onPrevStep: () => void
  onNextStep: () => void
}

export const NationOnboardingModalSteps = ({ modalStepIndex, data, onPrevStep, onNextStep }: Props) => {
  const currentStep = modalSteps[modalStepIndex]
  if (!currentStep) {
    return null
  }
  const CurrentStepComponent = currentStep.component

  return (
    <>
      <CurrentStepComponent totalGemsEarned={data.totalGemsEarned} missionsCompleted={data.missions} />

      <FlexContainer $gap="16px" $direction="row" $alignItems="center" $maxWidth="335px" $fullWidth>
        {modalStepIndex > 0 && (
          <Button
            id="onboarding-modal-back-button"
            iconLeft="arrow-left"
            variant={ButtonVariant.secondary}
            onClick={onPrevStep}
          >
            {translate('helpers.back')}
          </Button>
        )}
        <Button id={`onboarding-modal-next-button-${currentStep.ctaText}`} onClick={onNextStep} fullWidth>
          {currentStep.ctaText}
        </Button>
      </FlexContainer>
    </>
  )
}
