import type { Dispatch, SetStateAction } from 'react'
import { BridgeToAnyConfirmation } from './BridgeToAnyConfirmation'
import { LowAmountConfirmation } from './LowAmountConfirmation'
import type { BridgeConfirmationPromise } from '../../types/bridge-widget.types'
import { BridgeConfirmationScreen } from '../../types/bridge-widget.types'

export type BridgeConfirmationModalProps = {
  confirmationPromise: BridgeConfirmationPromise
  setConfirmationPromise: Dispatch<SetStateAction<BridgeConfirmationPromise | null>>
}

export const ConfirmationModals = ({ confirmationPromise, setConfirmationPromise }: BridgeConfirmationModalProps) => {
  if (!confirmationPromise) {
    return null
  }
  return (
    <>
      {confirmationPromise.type === BridgeConfirmationScreen.bridgeToAny && (
        <BridgeToAnyConfirmation
          confirmationPromise={confirmationPromise}
          setConfirmationPromise={setConfirmationPromise}
        />
      )}
      {confirmationPromise.type === BridgeConfirmationScreen.lessThanDollar && (
        <LowAmountConfirmation
          confirmationPromise={confirmationPromise}
          setConfirmationPromise={setConfirmationPromise}
        />
      )}
    </>
  )
}
