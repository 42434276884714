import styled from 'styled-components'
import { FlexContainer } from '../../../../../components/common/Wrappers/Containers'
import { NationMissionsList } from '../../NationContent/NationMissionsList/NationMissionsList'
import { SkeletonCard } from '../../../../../components/common/Skeletons/Skeleton.styled'
import { useWidgetMissionOpenModal } from '../../../hooks/useWidgetMissionOpenModal'
import { useNationWidgetFilteredMissions } from '../../../hooks/useNationWidgetFilteredMissions'
import { NationWidgetMissionsEmptyState } from './NationWidgetMissionsEmptyState'

export const NationWidgetMissionsContent = () => {
  const { data, isLoading } = useNationWidgetFilteredMissions()

  const openModal = useWidgetMissionOpenModal()

  if (isLoading) {
    return (
      <Container>
        <FlexContainer $direction="column" $gap="16px">
          <SkeletonCard $height="56px" />
          <SkeletonCard $height="56px" />
          <SkeletonCard $height="56px" />
        </FlexContainer>
      </Container>
    )
  }

  if (data.items.length === 0) {
    return (
      <Container>
        <NationWidgetMissionsEmptyState />
      </Container>
    )
  }

  return (
    <Container>
      <NationMissionsList
        missionFixedId="mission-row-nation-widget"
        loading={false}
        error=""
        data={data}
        rowTitleMaxWidth="145px"
        openModalFunc={openModal}
      />
    </Container>
  )
}

const Container = styled(FlexContainer)`
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
  height: 200px;
`
