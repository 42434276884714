import { createContext } from 'react'
import type { Kits, SupportedWallet, WalletTypeProps } from '../services/wallet/wallet.types'
import type { EIP6963ProviderDetail } from '../services/wallet/eip6963/EthereumProvider.types'
import type { Signer } from 'ethers'
import type { EIP1193Provider } from '../services/wallet/kits/eip1193/EIP1193ConnectionKit'
import type EthereumProvider from '@walletconnect/ethereum-provider'

export type WalletContextProps = {
  connectionKit: Kits | null
  eip6963Providers: EIP6963ProviderDetail[]
  connect: (connectProps: WalletTypeProps) => Promise<void>
  disconnect: () => Promise<void>
  connectingWallet: {
    isConnecting: boolean
    name?: string | undefined
    walletType?: SupportedWallet
  }
  ethersSigner: Signer | null | undefined
  getEthersSigner: () => Signer | null | undefined
  provider: EIP1193Provider | EthereumProvider | null | undefined
  getProvider: () => EIP1193Provider | EthereumProvider | null | undefined
  connectionError: string
}

export const WalletContext = createContext<WalletContextProps | undefined>(undefined)
