import styled, { css } from 'styled-components'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import { zIndices } from '../../constants/zIndex'

export const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;

  @media ${respondBelow('l')} {
    flex-direction: column;
  }
`

export const BottomCommsPosition = styled.div<{ $isMobile: boolean; $topMargin?: number | undefined }>`
  position: fixed;
  display: inline-flex;
  margin-top: ${({ $topMargin = 0 }) => `${$topMargin}px`};
  align-items: center;
  flex-direction: column;
  gap: 8px;

  max-width: 80vw;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);

  z-index: ${zIndices.bottomComms};

  .core-card-styler {
    background: ${({ theme }) => `${theme.primary}`};
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 16px;
  }

  .core-card-wrapper {
    width: max-content;
    max-width: 100%;
  }

  @media ${respondBelow('xl')} {
    max-width: 360px;
    align-items: flex-start;

    .core-card-styler {
      flex-direction: column;
      gap: 16px;
    }
  }

  ${({ $isMobile }) =>
    $isMobile
      ? css`
          position: relative;
          z-index: 1;
          margin-bottom: 32px;

          @media ${respondBelow('l')} {
            .core-card-wrapper {
              width: 100%;
              max-width: 100%;
            }
            max-width: 100%;
            width: 100%;

            bottom: unset;
            left: unset;
            transform: none;
          }
        `
      : css``}
`
