import { useMemo } from 'react'
import moment from 'moment'
import Decimal from 'decimal.js'
import { translate } from '../../../intl/i18n'
import { ContractsInteractionsGmTooltip } from '../components/ContractsInteractionsGmTooltip'
import type { ContractDetailsFeeType, UserContractCodec } from '../types/contractsInteractions.types'
import { useGetContractFeeQuery } from '../services/contractsInteractions.api'
import { useChainKeyToName } from '../../../hooks/useChainKeyToName'
import { useConfig } from '../../../hooks'
import { getTokenInfoOrThrow } from '../../../utils/getTokenInfoOrThrow'

type Props = {
  showFees: boolean
  contractData: UserContractCodec
  feeType?: ContractDetailsFeeType | undefined
  showChain?: boolean | undefined
  showPlusSignForFees?: boolean | undefined
}

export const useContractDetailsInfoItems = ({
  showFees,
  feeType = 'cumulative',
  contractData,
  showChain = false,
  showPlusSignForFees = true,
}: Props) => {
  const { config } = useConfig()
  const chainName = useChainKeyToName(contractData._id.chain)

  const { currentData, isFetching, isError } = useGetContractFeeQuery(
    { contractAddress: contractData._id.address, chain: contractData._id.chain, config },
    {
      skip: !showFees || !config,
    },
  )

  const chainNativeTokenSymbol = useMemo(() => {
    if (!config) {
      return ''
    }

    const chainConfig = config[contractData._id.chain]

    if (!chainConfig) {
      return ''
    }

    return chainConfig.nativeTokenName
  }, [contractData._id.chain, config])

  const fee = useMemo(() => {
    if (!currentData || !config) {
      return null
    }

    const tokenConfig = getTokenInfoOrThrow(config)(chainNativeTokenSymbol, contractData._id.chain)
    if (!tokenConfig) {
      return null
    }

    return Number(currentData) / 10 ** tokenConfig.decimals
  }, [chainNativeTokenSymbol, currentData, contractData._id.chain, config])

  const feesCollected = useMemo(
    () => (fee ? new Decimal(fee).mul(contractData.interactionsCounter).toString() : ''),
    [fee, contractData.interactionsCounter],
  )

  const feesValue = useMemo(() => {
    if (isFetching) {
      return translate('helpers.loading')
    } else if (isError) {
      return translate('helpers.error')
    } else if (feeType === 'single') {
      return `${fee || ''} ${chainNativeTokenSymbol}`
    } else {
      return feesCollected
        ? `${feesCollected !== '0' && showPlusSignForFees ? '+' : ''}${feesCollected} ${chainNativeTokenSymbol}`
        : ''
    }
  }, [fee, feeType, feesCollected, isFetching, isError, chainNativeTokenSymbol, showPlusSignForFees])

  return useMemo(() => {
    const initial = [
      {
        id: 'chain',
        Label: translate('yield.chain'),
        value: chainName,
        show: showChain,
      },
      {
        id: 'deployed_date',
        Label: translate('contracts_interactions.deployed_date'),
        value: moment(new Date(contractData.deployedAt)).format('HH:mm DD/MM/YYYY'),
        show: true,
      },
      {
        id: 'gm_count',
        Label: ContractsInteractionsGmTooltip,
        value: contractData.interactionsCounter,
        show: true,
      },
      {
        id: 'fees',
        Label: feeType === 'cumulative' ? translate('contracts_interactions.fees_earned') : translate('helpers.fee'),
        value: feesValue,
        show: showFees,
      },
    ]

    return initial.filter(({ show }) => show)
  }, [showFees, showChain, feeType, contractData, feesValue, chainName])
}
