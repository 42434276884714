import styled from 'styled-components'
import { Text, TextSize } from '@rhinofi/dvf-shared-ui'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'
import { translate } from '../../../../intl/i18n'
import banner from '../../assets/bitrefill_cashback_banner.png'
import { envConfig } from '../../../../env/envConfig'
import { useGetActivePerkQuery } from '../../services/nation.api'
import { useWidgetPerkOpenModal } from '../../hooks/useWidgetPerkOpenModal'

export const NationWidgetPromo = () => {
  useGetActivePerkQuery({ perkId: envConfig.NATION_WIDGET_PROMO_PERK_ID })
  const openModal = useWidgetPerkOpenModal({ perkId: envConfig.NATION_WIDGET_PROMO_PERK_ID })

  return (
    <FlexContainer $gap="4px" $direction="column" $alignItems="stretch">
      <Text size={TextSize.XS} color="secondary80">
        {translate('global.promo')}
      </Text>

      <BannerWrapper onClick={openModal} id={`nation-widget-promo-banner-${envConfig.NATION_WIDGET_PROMO_PERK_ID}`}>
        <Image src={banner} alt="active-promo-banner" />
      </BannerWrapper>
    </FlexContainer>
  )
}

const BannerWrapper = styled.div`
  cursor: pointer;
`

const Image = styled.img`
  max-height: 84px;
`
