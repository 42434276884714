/* eslint-disable -- legacy */
import React from 'react'
import { FullWidthCell } from './Table.Utils'

type FooterProps = {
  footer?: React.ReactNode
  size?: number
}

const Footer = (props: FooterProps = {}) => {
  const { footer, size = 1 } = props

  return footer ? <FullWidthCell size={size}> {footer} </FullWidthCell> : null
}

export { Footer }
