import moment from 'moment'
import { translate } from '../../../intl/i18n'
import { hasDatePassed } from '../../../utils/hasDatePassed'

export const getStatEndDateValue = (date: Date) => {
  const hasEnded = hasDatePassed(date)

  const dateMoment = moment(date)

  const args = {
    '%time': `@${dateMoment.format('HH:mm')}`,
    '%date': dateMoment.format('DD/MM/YYYY'),
  }

  return hasEnded ? translate('nation.ended_on', args) : translate('nation.ends_on', args)
}
