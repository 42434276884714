import type { FC } from 'react'
import { Link, type LinkProps } from 'react-router-dom'
import { useBridgeNavigate } from '../hooks/bridge'

export const BridgeLink: FC<LinkProps> = ({ children, ...rest }) => {
  const bridgeNavigate = useBridgeNavigate()

  return (
    <Link
      {...rest}
      onClick={(event) => {
        event.preventDefault()
        bridgeNavigate(rest.to)
      }}
    >
      {children}
    </Link>
  )
}
