import { chainRoutes, publicRoutes } from '../../../router'
import type {
  MissionTypesWithSpecificPage,
  NationWidgetFiltersRouteParamCheckOption,
} from '../types/nationWidgetFiltersPageTracking.types'

export const NATION_WIDGET_FILTERS_MISSIONS_ROUTES_CHECK: Record<MissionTypesWithSpecificPage, string> = {
  BRIDGE: publicRoutes.bridge,
  MINT_NFT: chainRoutes.mintNfts,
  DEPLOY_CONTRACT: chainRoutes.deploy,
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy
export const NATION_WIDGET_MISSIONS_ROUTES_CHECK_KEYS = Object.keys(
  NATION_WIDGET_FILTERS_MISSIONS_ROUTES_CHECK,
) as MissionTypesWithSpecificPage[]

export const NATION_WIDGET_FILTERS_MISSIONS_ROUTES_PARAMS_CHECK: Record<
  MissionTypesWithSpecificPage,
  NationWidgetFiltersRouteParamCheckOption
> = {
  BRIDGE: { type: 'searchParams', key: 'chainOut' },
  MINT_NFT: { type: 'urlPart', splitPathPosition: publicRoutes.nftMinter.split('/').indexOf(':chain') },
  DEPLOY_CONTRACT: { type: 'urlPart', splitPathPosition: publicRoutes.deployAndInteract.split('/').indexOf(':chain') },
}
