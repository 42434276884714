import styled from 'styled-components'
import { DropdownSelect } from '@rhinofi/dvf-shared-ui'
import type { NationWidgetMissionsFilterItemState } from '../../../types/nationWidgetMissionsFilterItem.types'

export const CustomDropdownSelect = styled(DropdownSelect<NationWidgetMissionsFilterItemState>)`
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;

  .select-option-selected.select-option {
    min-height: 32px;
  }

  .select-list-wrapper {
    width: 200px;

    .select-list {
      background: ${({ theme }) => theme.elevationL3};
      bottom: 1px;
    }
  }

  .select-option {
    padding: 0 8px;
    overflow: hidden;
    justify-content: space-between;

    .button-content {
      overflow: hidden;
      gap: 8px;
    }
  }
`
