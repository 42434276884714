import { validateYupSchema, yupToFormErrors } from 'formik'
import type { ISchema, InferType } from 'yup'

export const dynamicAsyncSchemaCreator = async <TSchema extends ISchema<object>>(
  value: InferType<TSchema>,
  schema: TSchema,
  additionalContext?: object,
) => {
  try {
    await validateYupSchema(value, schema, false, {
      ...value,
      ...additionalContext,
    })
  } catch (err) {
    // console.error(err)
    // console.error(yupToFormErrors(err))
    return yupToFormErrors(err)
  }
  return {}
}
