import { TextSize } from '@rhinofi/dvf-shared-ui'
import { FlexContainer } from '../../../../../components/common/Wrappers/Containers'
import { SkeletonCard } from '../../../../../components/common/Skeletons/Skeleton.styled'
import { NationContentLoadingErrorText } from '../NationContentLoadingErrorText/NationContentLoadingErrorText'
import type { NationContentSectionTitleItemData } from '../../../types/nation.types'
import { LineSeparator } from './NationContentSectionTitle.styled'

type Props = {
  data: NationContentSectionTitleItemData
}

export const NationContentSectionTitleItem = ({
  data: {
    loading,
    error,
    value,
    label,
    postValueContent,
    valueGap,
    valueAlignItems,
    showLabelValueSeparator,
    valueSize,
    labelSize = { type: 'text', value: TextSize.XS },
    postValueContentSize = { type: 'text', value: TextSize.XS },
  },
}: Props) => (
  <FlexContainer $gap="8px">
    {label ? (
      <NationContentLoadingErrorText color="secondary60" loading={false} error={false} text={label} size={labelSize} />
    ) : null}

    {showLabelValueSeparator ? <LineSeparator /> : null}

    <FlexContainer $gap={valueGap || '4px'} $alignItems={valueAlignItems || 'center'}>
      {loading ? (
        <SkeletonCard $height="24px" $width="24px" />
      ) : (
        <NationContentLoadingErrorText loading={false} error={error} text={value} size={valueSize} />
      )}

      {typeof postValueContent === 'string' ? (
        <NationContentLoadingErrorText
          color="secondary60"
          loading={false}
          error={false}
          text={postValueContent}
          size={postValueContentSize}
        />
      ) : (
        postValueContent
      )}
    </FlexContainer>
  </FlexContainer>
)
