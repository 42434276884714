import type { JsonRpcProvider, Signer } from 'ethers'
import { NETWORKS } from '../../../constants/types'
import { isNonEVMChain } from '../../../services/helperService/isNonEVMChain'
import { getTronTokenAllowance } from '../../../services/secondaryWallet/services/tronService/getTronTokenAllowance'
import { getEVMTokenAllowance } from './getEVMTokenAllowance'

export type AllowanceCallProps = {
  walletAddress: string
  bridgeContractAddress: string
  tokenAddress: string
  decimals: number
  chain: string
  ethersSigner?: JsonRpcProvider | Signer | null | undefined
  secondaryWalletAddress?: string
}

export const callCheckAllowance = (props: AllowanceCallProps) => {
  const { chain } = props

  if (isNonEVMChain(chain)) {
    if (chain === NETWORKS.TRON) {
      return getTronTokenAllowance(props)
    } else {
      throw new Error('Allowance check not available for this chain')
    }
  } else {
    return getEVMTokenAllowance(props)
  }
}
