import { useCallback } from 'react'
import { useConfig, useAppSelector } from '../../hooks'
import { useWallet } from '../../hooks/useWallet'
import { selectConnectedNetwork } from '../../reducers/userSelector'
import { getChainConfig } from '../../utils/getChainConfig'
import { ethers } from 'ethers'
import { isNonEVMChain } from '../helperService/isNonEVMChain'

export const useGetReadSigner = () => {
  const { config } = useConfig()
  const wallet = useWallet()
  const connectedNetwork = useAppSelector(selectConnectedNetwork)

  const getReadSigner = useCallback(
    (chain: string) => {
      if (!connectedNetwork || !config || isNonEVMChain(chain)) {
        return null
      }

      // get connected network config
      const connectedChainConfig = getChainConfig(config)({
        chainId: connectedNetwork.toString(),
      })

      // if chain is connected network - return wallet singer
      if (chain === connectedChainConfig.chainKey) {
        return wallet.ethersSigner
      }

      const chainConfig = getChainConfig(config)({
        chain,
      })

      // else return json RPC signer
      const network = new ethers.Network(chainConfig.name, chainConfig.networkId)
      return new ethers.JsonRpcProvider(chainConfig.rpc, network, { staticNetwork: true })
    },
    [config, connectedNetwork, wallet.ethersSigner],
  )

  return getReadSigner
}
