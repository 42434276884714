import type { TransactionReceipt } from 'ethers'
import type { ChainTxArgs, ChainTxHashCallbackFunc } from '../../../services/txService/txService.types'
import type { GmContractArgs } from '../types/contractsInteractions.types'
import { RhinoGMContract__factory } from '../../../contracts/RhinoGMContract'

export const gmContract =
  ({ contractAddress }: GmContractArgs) =>
  async ({ signer }: ChainTxArgs, txHashCallbackFunc: ChainTxHashCallbackFunc): Promise<TransactionReceipt> => {
    const contract = RhinoGMContract__factory.connect(contractAddress, signer)

    const feeOnGM = await contract.feeOnGM()

    const res = await contract.gm({ value: feeOnGM })

    txHashCallbackFunc(null, res.hash)

    const receipt = await res.wait()

    if (receipt === null) {
      throw new Error('No receipt')
    }

    return receipt
  }
