import { Button } from '@rhinofi/dvf-shared-ui'
import type { MissionAPIDocument } from '@rhinofi/rhino-nation-api-spec'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { clearModal } from '../../../../../../actions/modalActions/clearModal'
import { useBridgeNavigate } from '../../../../../../hooks/bridge'
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch'
import { getMissionCTAButtonTextForMission } from '../../../../utils/getMissionCTAButtonTextForMission'
import { getUrlPathForMission } from '../../../../utils/getUrlPathForMission'
import { NationCTAAccessWrapper } from '../../../NationCTAAccessWrapper/NationCTAAccessWrapper'

type Props = {
  mission: MissionAPIDocument
}

export const NationMissionModalCTAButton = ({ mission }: Props) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const bridgeNavigate = useBridgeNavigate()

  const handleButtonClickCallback = useCallback(() => {
    const path = getUrlPathForMission({ mission })

    if (mission.type === 'BRIDGE') {
      bridgeNavigate(path)
    } else {
      navigate(path)
    }

    clearModal(dispatch)()
  }, [mission, dispatch, bridgeNavigate, navigate])

  const buttonText = getMissionCTAButtonTextForMission({ mission })

  if (buttonText === '') {
    return null
  }

  return (
    <NationCTAAccessWrapper>
      <Button id={`mission-modal-cta-${mission._id.id}`} onClick={handleButtonClickCallback}>
        {buttonText}
      </Button>
    </NationCTAAccessWrapper>
  )
}
