/* eslint-disable @typescript-eslint/consistent-type-assertions -- recommended implementation uses as */
import { ExcludeFalsy } from '../../../utils/ExcludeFalsy'
import type { EIP6963AnnounceProviderEvent, EIP6963ProviderDetail } from './EthereumProvider.types'

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions, no-unused-vars -- required for global augmentation
  interface WindowEventMap {
    // eslint-disable-next-line @typescript-eslint/naming-convention -- not our name
    'eip6963:announceProvider': CustomEvent<EIP6963AnnounceProviderEvent>
  }
}

// eslint-disable-next-line functional/no-let -- recommended implementation uses let
let providers: EIP6963ProviderDetail[] = []

export const store = {
  value: () => providers,

  subscribe: (callback: () => void) => {
    function onAnnouncement(event: EIP6963AnnounceProviderEvent) {
      // Prevent adding a provider if EIP6963 spec is not followed.
      if (!event.detail) {
        return
      }

      // Make sure existing providers are not null
      providers = providers.filter(ExcludeFalsy)

      // Prevent adding a provider if it already exists in the list based on its uuid.
      if (providers.some((provider) => provider.info.uuid === event.detail.info.uuid)) {
        return
      }

      // Add the new provider to the list and call the provided callback function.
      providers = [...providers, event.detail]
      callback()
    }

    window.addEventListener('eip6963:announceProvider', onAnnouncement as unknown as EventListener)
    window.dispatchEvent(new Event('eip6963:requestProvider'))

    return () => window.removeEventListener('eip6963:announceProvider', onAnnouncement as unknown as EventListener)
  },
}
