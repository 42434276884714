import styled from 'styled-components'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import { topNavigationHeight } from './NavigationDesktop.constants'

export const NavigationDesktopWrapper = styled.div`
  width: 100%;
  padding: 16px;
  height: ${topNavigationHeight};
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;

  .medium-priority-display {
    @media ${respondBelow('s')} {
      display: none;
    }
  }
  .low-priority-display {
    @media ${respondBelow('xl')} {
      display: none;
    }
  }
`

export const NavigationLogo = styled.img`
  max-height: 38px;
  margin-right: 48px;

  @media ${respondBelow('s')} {
    max-height: 24px;
    margin-right: 16px;
  }

  @media all and (max-width: 670px) {
    margin-right: 8px;
  }
`

export const NavigationLinksContent = styled.nav`
  display: flex;
  align-items: center;

  .logo-link {
    font-size: 0;
  }

  @media ${respondBelow('xs')} {
    gap: 4px;
  }
`

export const Separator = styled.div`
  height: 16px;
  width: 1px;
  background: ${({ theme }) => theme.secondary};
`
