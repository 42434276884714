import { useTheme } from 'styled-components'

export const ErrorCross = () => {
  const theme = useTheme()

  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6L6 0L24 12L42 0L48 6L36 24L48 42L42 48L24 36L6 48L0 42L12 24L0 6Z"
        fill={theme.errorMain}
      />
    </svg>
  )
}
