/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment -- starknet lib issue */
import { isFeatureEnabled } from '../../helperService/isFeatureEnabled'
import { F_FLAGS_TYPES, NETWORKS } from '../../../constants/types'
import { setStarknetProvider } from '../providers/starknetProvider'
import { createStarknetListener } from '../services/starknetService/createStarknetListener'
import { starknetLookup } from '../../nameServices/starknetIdService'
import type { SecondaryConnectionPayload } from './connectors.types'
import type { AppDispatch } from '../../../store/configureStore'
import { WALLET_CONNECT_PROJECT_ID } from '../../../constants/portalConfig'

export const connectToStarknet = async (dispatch: AppDispatch, rpcUrl: string): Promise<SecondaryConnectionPayload> => {
  const { InjectedConnector } = await import('starknetkit/injected')
  const { ArgentMobileConnector } = await import('starknetkit/argentMobile')
  const { connect } = await import('starknetkit')
  const { shortString } = await import('starknet')

  const { wallet } = await connect({
    modalMode: 'alwaysAsk',
    dappName: 'rhino.fi',
    connectors: [
      new InjectedConnector({
        options: { id: 'argentX' },
      }),
      new InjectedConnector({
        options: { id: 'braavos' },
      }),
      ...(isFeatureEnabled(F_FLAGS_TYPES.STARKNET_ARGENT_MOBILE)
        ? [
            new ArgentMobileConnector({
              projectId: WALLET_CONNECT_PROJECT_ID,
            }),
          ]
        : []),
    ],
  })

  if (!wallet?.selectedAddress || !wallet) {
    throw new Error('Something went wrong when connecting your Starknet wallet.')
  }
  await setStarknetProvider(wallet, rpcUrl)
  void createStarknetListener(dispatch, wallet)
  const rawNetworkId = await wallet.account.getChainId()
  const networkId = shortString.decodeShortString(rawNetworkId)

  const domain = await starknetLookup(wallet.selectedAddress)

  return {
    address: wallet.selectedAddress,
    networkId: networkId,
    domain,
    connectedChain: NETWORKS.STARKNET,
  }
}
