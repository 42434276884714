import type { AppDispatch } from '../../store/configureStore'
import { clearModal as clearModalAction } from '../../reducers/modalSlice'

export type ModalClearAction = {
  type: 'MODAL_CLEAR'
}

export const clearModal = (dispatch: AppDispatch) => () => {
  dispatch(clearModalAction())
}
