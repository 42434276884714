import { TextSize, Text, Icon, IconSizes } from '@rhinofi/dvf-shared-ui'
import { useLocation } from 'react-router-dom'
import { useCallback } from 'react'
import { navigationLinks } from './NavigationDesktop.constants'
import {
  NavigationLink,
  NavigationLinksContent,
  NavigationSubLink,
  NavigationSubLinksContent,
} from './NavigationLinks.styled'
import type { NavigationLinkSingle } from './NavigationDesktop.types'
import { NoUnderlineLink } from '../common/Helpers/LinkHelpers'
import { Dropdown } from '../Dropdown/Dropdown'
import { FlexContainer } from '../common/Wrappers/Containers'

export const NavigationLinks = () => {
  const { pathname } = useLocation()

  const isNavItemActive = useCallback(
    (navigationLink: NavigationLinkSingle) => {
      if ('to' in navigationLink) {
        if (navigationLink.nestedPath) {
          return navigationLink.to.split('/').pop() === pathname.split('/').pop()
        }
        return pathname.includes(navigationLink.to)
      } else {
        return navigationLink.subLinks.some((subLink) => pathname.includes(subLink.to))
      }
    },
    [pathname],
  )

  return (
    <NavigationLinksContent>
      {navigationLinks.map((link) =>
        'to' in link ? (
          <NoUnderlineLink to={link.to} id={link?.id || ''} key={link.title}>
            <NavigationLink id={`navigation-link-${link.id}`} $isActive={isNavItemActive(link)}>
              {/*{link.icon.includes('/') ? (*/}
              {/*  <img className="navigation-link-img" src={link.icon} alt="Navigation icon" />*/}
              {/*) : (*/}
              {/*  <Icon className="navigation-link-icon" id={link.icon} size={IconSizes.Medium} />*/}
              {/*)}*/}
              <Text className="navigation-link-text" size={TextSize.S} bold>
                {link.title}
              </Text>
            </NavigationLink>
          </NoUnderlineLink>
        ) : (
          <Dropdown
            key={link.title}
            allowClick
            isSecondaryCard
            element={
              <NavigationLink id={`navigation-link-${link.title}`} $isActive={isNavItemActive(link)} $noHover>
                {/*{link.icon.includes('/') ? (*/}
                {/*  <img className="navigation-link-img" src={link.icon} alt="Navigation icon" />*/}
                {/*) : (*/}
                {/*  <Icon className="navigation-link-icon" id={link.icon} size={IconSizes.Medium} />*/}
                {/*)}*/}
                <Text className="navigation-link-text" size={TextSize.S} bold>
                  {link.title}
                </Text>
                <Icon id="caret-down-fill" size={IconSizes.XSmall} />
              </NavigationLink>
            }
          >
            <NavigationSubLinksContent>
              <FlexContainer $direction="column" $gap="4px" $alignItems="flex-start">
                {link.subLinks.map((subLink) => (
                  <NavigationSubLink key={subLink.title} to={subLink.to} $isActive={isNavItemActive(subLink)}>
                    {subLink.title}
                  </NavigationSubLink>
                ))}
              </FlexContainer>
            </NavigationSubLinksContent>
          </Dropdown>
        ),
      )}
    </NavigationLinksContent>
  )
}
