import type { FormikErrors } from 'formik'
import { isEmpty } from 'lodash-es'
import type { SerializedError } from '@reduxjs/toolkit'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'

export type NormalizedError = {
  overSubmitError: string | null
  buttonDisabledError?: string | null
  errorKey?: string | null
}

export const normalizeErrors = ({
  quoteError,
  formErrors,
  hasDepositAmount,
  hasRecipient,
}: {
  quoteError: FetchBaseQueryError | SerializedError | undefined
  formErrors: FormikErrors<unknown>
  hasDepositAmount: boolean
  hasRecipient: boolean
}): NormalizedError => {
  if (!hasDepositAmount) {
    return {
      overSubmitError: null,
    }
  }

  if (!isEmpty(formErrors)) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the keys are strings
    const keys = Object.keys(formErrors) as (keyof typeof formErrors)[]
    const firstErrorKey = keys.find((key) => formErrors[key])
    if (firstErrorKey) {
      const firstError = formErrors[firstErrorKey]
      // we don't want to show the error when recipient address is empty
      if (firstError && firstErrorKey === 'recipient' && !hasRecipient) {
        return {
          overSubmitError: null,
          buttonDisabledError: firstError,
          errorKey: firstErrorKey,
        }
      }

      if (firstError) {
        if (String(firstError).length < 38) {
          return {
            overSubmitError: null,
            buttonDisabledError: firstError,
            errorKey: firstErrorKey,
          }
        }
        return {
          overSubmitError: firstError,
          errorKey: firstErrorKey,
        }
      }
    }
  }

  if (quoteError && 'data' in quoteError && typeof quoteError.data === 'string') {
    return {
      overSubmitError: quoteError.data !== '-' ? quoteError.data : null,
    }
  }

  return {
    overSubmitError: null,
  }
}
