import { useCallback, useState, useMemo } from 'react'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import styled from 'styled-components'
import type { GetAllGemsTransactionsResponse } from '@rhinofi/rhino-nation-api-spec'
import { FlexContainer } from '../../../../../components/common/Wrappers/Containers'
import { usePollForMissionsNotifications } from '../../../hooks/usePollForMissionsNotifications'
import { zIndices } from '../../../../../constants/zIndex'
import { NationMissionNotification } from './NationMissionNotification/NationMissionNotification'

export const NationMissionsNotifications = () => {
  const [txs, setTxs] = useState<GetAllGemsTransactionsResponse['items']>([])
  const [dismissedIds, setDismissedIds] = useState<string[]>([])

  const setTxsCallback = useCallback((txsLocal: GetAllGemsTransactionsResponse['items']) => {
    setTxs((prevState) => [...prevState, ...txsLocal])
  }, [])

  const setDismissedIdsCallback = useCallback((dismissedIdsLocal: string[]) => {
    setDismissedIds((prevState) => [...prevState, ...dismissedIdsLocal])
  }, [])

  const clearStateCallback = useCallback(() => {
    setTxs([])
    setDismissedIds([])
  }, [])

  const txsToShow = useMemo(() => txs.filter((tx) => !dismissedIds.includes(tx._id)), [dismissedIds, txs])

  usePollForMissionsNotifications({
    setTxs: setTxsCallback,
    clearState: clearStateCallback,
  })

  return (
    <Container $gap="16px" $direction="column" $alignItems="stretch">
      {txsToShow.map((item) => (
        <NationMissionNotification key={item._id} gemsTransaction={item} setDismissedIds={setDismissedIdsCallback} />
      ))}
    </Container>
  )
}

const Container = styled(FlexContainer)`
  max-width: 360px;
  width: 100%;
  position: fixed;
  z-index: ${zIndices.rhinoNationNotifications};
  right: 0;
  top: 88px;
  padding: 0 16px;

  @media ${respondBelow('xxs')} {
    top: 16px;
  }
`
