import rhinoAsset from '../../../../assets/icons/rhino-solo.svg'
import { translate } from '../../../../intl/i18n'
import type { I18nData } from '../../../../intl/i18n.types'
import { isAcrossRoute } from '../../helpers/isAcrossRoute'
import acrossAsset from './assets/across.svg'

type PoweredByConfigName = 'across' | 'rhino' | 'tether'

type PoweredByConfig = {
  title: keyof I18nData
  desc: keyof I18nData
  desc2?: keyof I18nData
  url: string
  asset: { type: 'path' | 'token'; value: string }
}

const blogURL = 'https://rhino.fi/blog/rhino-fi-and-across-integration/'

const configs: Record<PoweredByConfigName, PoweredByConfig> = {
  across: {
    title: 'bridge.powered_by_across',
    desc: 'bridge.powered_by_across_desc',
    url: blogURL,
    asset: { type: 'path', value: acrossAsset },
  },
  rhino: {
    title: 'bridge.powered_by_rhino',
    desc: 'bridge.powered_by_rhino_desc',
    url: blogURL,
    asset: { type: 'path', value: rhinoAsset },
  },
  tether: {
    title: 'bridge.powered_by_tether',
    desc: 'bridge.powered_by_tether_desc',
    desc2: 'bridge.powered_by_tether_desc2',
    url: 'https://rhino.fi/blog/tether-and-rhino-fi-forge-strategic-alliance-to-enhance-usdt-utility-within-defi-prioritizing-user-experience/',
    asset: { type: 'token', value: 'USDT' },
  },
}

const getConfigTranslated = (config: PoweredByConfig) => {
  return {
    title: translate(config.title),
    desc: translate(config.desc),
    desc2: config.desc2 ? translate(config.desc2) : '',
    url: config.url,
    asset: config.asset,
  }
}

export const useBridgePoweredByConfig = ({
  chainIn,
  chainOut,
  token,
}: {
  chainIn: string
  chainOut: string
  token: string
}) => {
  if (token === 'USDT') {
    return getConfigTranslated(configs.tether)
  }
  if (isAcrossRoute(chainIn, chainOut)) {
    return getConfigTranslated(configs.across)
  }
  return getConfigTranslated(configs.rhino)
}
