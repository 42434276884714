import Decimal from 'decimal.js'
import { useMemo } from 'react'
import { useAppSelector } from '../useAppSelector'
import { selectBridgeConfigForChain } from '../../store/selectors/bridgeConfig.selectors'

type Props = {
  feeAmount: number
  chain: string
}

export const useGmFeeLabel = ({ feeAmount, chain }: Props) => {
  const chainConfig = useAppSelector((state) => selectBridgeConfigForChain(state, chain))

  return useMemo(() => {
    if (!chainConfig) {
      return ''
    }

    const feeAmountFormatted = chainConfig
      ? new Decimal(feeAmount).div(10 ** chainConfig.nativeTokenDecimals).toString()
      : ''

    return `${feeAmountFormatted} ${chainConfig.nativeTokenName}`
  }, [feeAmount, chainConfig])
}
