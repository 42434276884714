import styled from 'styled-components'
import type { ReactElement, ReactNode } from 'react'
import { useRef, useState, cloneElement, isValidElement } from 'react'
import { CSSTransition } from 'react-transition-group'
import { HoverCard, Icon, IconSizes } from '@rhinofi/dvf-shared-ui'
import { useOutsideClickHandler } from '../../hooks/dom'
import { FadeTransitionWithZIndex } from '../common/animations/Transitions'

export const Dropdown = ({
  element,
  children,
  triggerOnClick,
  showCaret,
  allowClick,
  clampRight,
  fullWidth,
  isSecondaryCard,
  zIndex,
  strongBoxShadow,
  propsToChildren,
}: {
  element: ReactElement
  children: ReactNode
  allowClick?: boolean
  showCaret?: boolean
  triggerOnClick?: boolean
  clampRight?: boolean
  fullWidth?: boolean
  isSecondaryCard?: boolean
  zIndex?: number
  strongBoxShadow?: boolean
  propsToChildren?: boolean
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropDownRef = useRef<HTMLDivElement>(null)

  useOutsideClickHandler(dropDownRef, () => setIsOpen(false))

  const handleHoverEvent = (value: boolean) => {
    if (triggerOnClick) {
      return
    }
    setIsOpen(value)
  }

  const handleClickEvent = (value: boolean) => {
    if (!triggerOnClick && !allowClick) {
      return
    }
    if (isOpen) {
      setIsOpen(false)
      return
    }
    setIsOpen(value)
  }

  const animationRef = useRef(null)

  return (
    <DropdownContainer ref={dropDownRef} onMouseLeave={() => handleHoverEvent(false)}>
      <DropdownClickArea onClick={() => handleClickEvent(true)} onMouseOver={() => handleHoverEvent(true)}>
        {element} {showCaret && <Icon className="dropdown-caret" id="caret-down-fill" size={IconSizes.XSmall} />}
      </DropdownClickArea>
      <FadeTransitionWithZIndex $zIndex={zIndex}>
        <CSSTransition timeout={300} in={isOpen} classNames="fade" nodeRef={animationRef} unmountOnExit>
          <div ref={animationRef}>
            <DropdownContent
              $clampRight={clampRight}
              $triggerOnClick={triggerOnClick}
              $fullWidth={fullWidth}
              $showCaret={showCaret}
            >
              {children && isValidElement<{ close: () => void }>(children) && (
                <HoverCard isSecondary={!!isSecondaryCard} strongBoxShadow={!!strongBoxShadow}>
                  {cloneElement(children, propsToChildren ? { close: () => setIsOpen(false) } : {})}
                </HoverCard>
              )}
            </DropdownContent>
          </div>
        </CSSTransition>
      </FadeTransitionWithZIndex>
    </DropdownContainer>
  )
}

const DropdownContainer = styled.div`
  position: relative;
`

const DropdownClickArea = styled.div`
  user-select: none;
  display: flex;
  align-items: center;

  .dropdown-caret {
    margin-left: 8px;
  }
`

const DropdownContent = styled.div<{
  $fullWidth?: boolean | undefined
  $clampRight?: boolean | undefined
  $triggerOnClick?: boolean | undefined
  $showCaret?: boolean | undefined
}>`
  position: absolute;
  z-index: 3;
  left: -4px;
  top: -2px;
  transition: all 0.3s ease-out;
  min-width: 120px;
  max-width: 360px;
  margin-top: ${({ $showCaret }) => ($showCaret ? '0' : '-6px')};
  margin-right: ${({ $showCaret }) => ($showCaret ? '0' : '8px')};

  ${({ $clampRight }) =>
    $clampRight
      ? `
      right: -8px;
    `
      : ''}

  ${({ $triggerOnClick }) =>
    $triggerOnClick
      ? `
      top: 16px;
      padding: 0;
      right: -8px;
      left: auto;
    `
      : ''}

    ${({ $fullWidth }) =>
    $fullWidth
      ? `
      width: 360px;
    `
      : ''}
`
