import { BridgeTab, HistoryCount } from './BridgeTabs.styled'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'
import { Title } from '@rhinofi/dvf-shared-ui'
import type { ReactNode } from 'react'

type TabProps<T extends string | number> = {
  label: string | ReactNode
  id: T
  notificationCount?: number | undefined
}

type TabsProps<T extends string | number> = {
  tabs: TabProps<T>[]
  onClick: (id: T) => void
  activeTab: T
  fullWidth?: boolean
}

export const TabSelect = <T extends string | number>({ tabs, onClick, activeTab, fullWidth }: TabsProps<T>) => (
  <FlexContainer $gap="8px" $flexWrap="wrap" $fullWidth={fullWidth}>
    {tabs.map(({ label, id, notificationCount }) => (
      <BridgeTab id={`bridge-tab-${id}`} key={id} onClick={() => onClick(id)} $active={activeTab === id}>
        <Title color={activeTab === id ? undefined : 'textDisabled'}>{label}</Title>

        {notificationCount && <HistoryCount>{notificationCount}</HistoryCount>}
      </BridgeTab>
    ))}
  </FlexContainer>
)
