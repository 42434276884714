import styled from 'styled-components'
import { Text, TextSize } from '@rhinofi/dvf-shared-ui'
import { FlexContainer } from '../../../../../components/common/Wrappers/Containers'
import type { NationWidgetMissionsFilterItemState } from '../../../types/nationWidgetMissionsFilterItem.types'
import { NationWidgetMissionsFilterItemIcon } from './NationWidgetMissionsFilterItemIcon'

type Props<I> = {
  item: I
}

export const NationWidgetMissionsFilterItem = <
  I extends NationWidgetMissionsFilterItemState & { isSelected: boolean },
>({
  item,
}: Props<I>) => (
  <OptionWrapper $gap="8px">
    <NationWidgetMissionsFilterItemIcon item={item} />
    <OptionText size={item.isSelected ? TextSize.XS : TextSize.M}>{item.name.text}</OptionText>
  </OptionWrapper>
)

const OptionWrapper = styled(FlexContainer)`
  overflow: hidden;
`

const OptionText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
